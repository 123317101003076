import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { alpha, useTheme } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { ReactNode, useCallback, useMemo } from 'react'

import { useDrawersContext } from '@providers/drawers/drawers_context'

export const Drawer = ({
  id,
  label,
  status,
  children,
  allowExpand = true,
}: {
  id: string
  label: ReactNode
  status?: ReactNode
  children: ReactNode
  allowExpand?: boolean
}) => {
  const { palette } = useTheme()
  const ctx = useDrawersContext()
  const isOpen = useMemo(() => ctx.state.openDrawerId === id, [id, ctx.state.openDrawerId])
  const isDisabled = useMemo(() => ctx.state.isOpenDrawerDirty, [ctx.state.isOpenDrawerDirty])

  const toggleDrawer = useCallback(() => {
    if (isOpen) {
      ctx.dispatch({ type: 'CLOSE_DRAWER' })
    } else {
      ctx.dispatch({ type: 'OPEN_DRAWER', payload: { value: id } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, id])

  return (
    <Paper
      variant={!isOpen && isDisabled ? 'outlined' : 'elevation'}
      sx={{
        borderColor: palette.divider,
        backgroundColor: !isOpen && isDisabled ? alpha(palette.primary.default!, 0.08) : palette.background.paper,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
        {label}
        <Stack direction="row" alignItems="center" spacing={1}>
          {status}
          {!isDisabled && allowExpand && (
            <IconButton aria-label="toggle" sx={{ color: palette.primary.default }} onClick={toggleDrawer}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Collapse in={isOpen} timeout="auto" mountOnEnter unmountOnExit>
        {children}
      </Collapse>
    </Paper>
  )
}
