import { Services, Types } from 'service-api'
import * as yup from 'yup'
import {
  FlexBookingConfWithValue,
  INTAKE_PETINFO_FIELD_ALTERED,
  INTAKE_PETINFO_FIELD_BIRTHDATE,
  INTAKE_PETINFO_FIELD_SEX,
  STATIC_PETINFO_FIELD_BREED,
  STATIC_PETINFO_FIELD_NAME,
  STATIC_PETINFO_FIELD_WEIGHT,
  petInfoFields,
  FlexTarget,
} from './helper'
import { ClientPetFamiliesService, ClientPetProfilesService, ClientPetsService } from 'service-api/src/services'
import slug from 'slug'
import { i18WithParams as t } from 'components-ui'

export type OrderAsset = Types.AssetClientDto & { assetUsageId: string }
export type TFormikValues = {
  vaxRecords?: (OrderAsset | File)[]
} & Record<string, unknown>

export const buildSchema = (intakeFlexConfs: Types.FlexBookingConfClientDto[], showStaticFields?: boolean) => {
  const intakeFlexConfsSchema = intakeFlexConfs.reduce(
    (acc, conf) => {
      switch (conf.subtype) {
        case 'PHONE': {
          acc[conf.name!] = conf.fieldRequired
            ? yup.string().length(10).required().label(conf.description!)
            : yup.string().length(10).label(conf.description!)
          break
        }
        default: {
          acc[conf.name!] = conf.fieldRequired
            ? yup.string().max(200).required().label(conf.description!)
            : yup.string().max(200).label(conf.description!)
          break
        }
      }
      return acc
    },
    {} as Record<string, yup.AnySchema>
  )

  const staticPetInfoSchema = {
    [STATIC_PETINFO_FIELD_NAME]: yup.string().required().label(t('global.label.name')),
    [STATIC_PETINFO_FIELD_BREED]: yup.object({
      id: yup.string().required().label(t('global.label.breed')),
    }),
    [STATIC_PETINFO_FIELD_WEIGHT]: yup.string().required().label(t('global.label.weight')),
  }

  return yup.object({
    ...intakeFlexConfsSchema,
    ...(showStaticFields && staticPetInfoSchema),
  })
}

export const setupInitialValues = ({
  target,
  intakeFlexConfs,
  invoicePet,
  orderData,
  profileData,
  invoicePetAttachments,
  previouslySubmitted,
  isFormReadOnly,
  showStaticFields,
}: {
  target: FlexTarget
  intakeFlexConfs: Types.FlexBookingConfClientDto[]
  invoicePet?: Types.InvoicePetClientDto
  orderData: Record<string, never> | undefined
  profileData: Record<string, never> | undefined
  invoicePetAttachments?: Types.AssetUsageClientDto[]
  previouslySubmitted: boolean
  isFormReadOnly: boolean
  showStaticFields?: boolean
}): { hasPrepopulatedData: boolean; initialValues: TFormikValues } => {
  let hasPrepopulatedData = !!showStaticFields
  const flexFields = intakeFlexConfs?.reduce(
    (acc, conf) => {
      if (petInfoFields.includes(conf.name!)) {
        // These are not flex fields and are initialized at the reducer initializer below
        return acc
      }
      const orderDataFlexConf = orderData?.flexBookingConfs?.[conf.name!] as FlexBookingConfWithValue | undefined,
        profileDataFlexConf = profileData?.flexBookingConfs?.[conf.name!] as FlexBookingConfWithValue | undefined
      if (previouslySubmitted || isFormReadOnly) {
        acc[conf.name!] = orderDataFlexConf?.value || ''
      } else {
        acc[conf.name!] = profileDataFlexConf?.value || ''
        hasPrepopulatedData = hasPrepopulatedData || !!acc[conf.name!]
      }

      return acc
    },
    {
      ...(target === 'PET'
        ? {
            ...(showStaticFields
              ? {
                  [STATIC_PETINFO_FIELD_NAME]: invoicePet?.displayName || '',
                  [STATIC_PETINFO_FIELD_BREED]: invoicePet?.breed || '',
                  [STATIC_PETINFO_FIELD_WEIGHT]: orderData?.weight == undefined ? '' : String(orderData?.weight),
                }
              : {}),
            [INTAKE_PETINFO_FIELD_BIRTHDATE]: orderData?.birthdate || '',
            [INTAKE_PETINFO_FIELD_ALTERED]:
              orderData?.altered === true ? 'Yes' : orderData?.altered === false ? 'No' : '',
            [INTAKE_PETINFO_FIELD_SEX]: (orderData?.sex && (orderData.sex === 'MALE' ? 'Male' : 'Female')) || '',
          }
        : {}),
    } as Record<string, unknown>
  )

  return {
    hasPrepopulatedData,
    initialValues: {
      ...(invoicePetAttachments === undefined
        ? {}
        : {
            vaxRecords: invoicePetAttachments.map((assetUsage) => ({
              ...assetUsage.asset,
              assetUsageId: assetUsage.id!,
            })),
          }),
      ...flexFields,
    },
  }
}

const saveVaxRecords = (
  locationName: string,
  invoicePetId: string,
  vaxRecords: (OrderAsset | File)[],
  initialVaxRecords: OrderAsset[],
  locationPetProfileId?: string
) => {
  const promises: Promise<Types.AssetClientDto | Types.AssetUsageClientDto>[] = []
  const existingVaxRecords: Record<string, boolean> = {}
  vaxRecords.forEach((record) => {
    const assetId = (record as OrderAsset)?.id
    if (assetId) {
      existingVaxRecords[assetId] = true
    } else {
      promises.push(
        Services.ClientAssetService.create(locationName, 'USER', 'RECORD', record as File, [
          {
            type: 'INVOICE_PET',
            invoicePetId,
            subtype: 'VET_RECORD',
          },
          ...(locationPetProfileId
            ? [
                {
                  type: 'LOCATION_PET_PROFILE_ATTACHMENT',
                  locationPetProfileAttachmentsId: locationPetProfileId,
                  subtype: 'VET_RECORD',
                } as Types.AssetUsageUpdateInput,
              ]
            : []),
        ])
      )
    }
  })
  initialVaxRecords.forEach((record) => {
    if (record.id && !existingVaxRecords[record.id]) {
      promises.push(Services.ClientAssetUsageService.remove(locationName, record.assetUsageId))
    }
  })
  return promises
}

const createPet = async ({ locationName, values }: { locationName: string; values: TFormikValues }) => {
  const getPetFamilies = await ClientPetFamiliesService.families(locationName)
  const petFamilyId = getPetFamilies.results.at(0)?.id || ''
  const basicPet = {
    name: slug(values[STATIC_PETINFO_FIELD_NAME] as string),
    displayName: values[STATIC_PETINFO_FIELD_NAME] as string,
    petFamilyId,
  }
  const createdPet = await ClientPetsService.create(locationName, petFamilyId, basicPet)
  const breed = values[STATIC_PETINFO_FIELD_BREED] as Types.SpeciesClientDto

  await ClientPetProfilesService.create(locationName, petFamilyId, {
    petId: createdPet.id as string,
    sex:
      values[INTAKE_PETINFO_FIELD_SEX] === 'Male'
        ? 'MALE'
        : values[INTAKE_PETINFO_FIELD_SEX] === 'Female'
          ? 'FEMALE'
          : undefined,
    altered:
      values[INTAKE_PETINFO_FIELD_ALTERED] === 'Yes'
        ? true
        : values[INTAKE_PETINFO_FIELD_ALTERED] === 'No'
          ? false
          : undefined,
    weight: Number(values[STATIC_PETINFO_FIELD_WEIGHT]),
    birthdate: (values[INTAKE_PETINFO_FIELD_BIRTHDATE] as string) || undefined,
    breedId: breed?.id || '',
    ...basicPet,
  })
  return {
    id: createdPet?.id || '',
  }
}

const addFieldToUpdatePetBody = (
  invoicePetId: string,
  previouslySubmitted: boolean,
  fieldName: string,
  value: unknown,
  initialValue: unknown,
  reqBody: Types.UpdateBookingOrderClientInput
): boolean => {
  if (previouslySubmitted) {
    if (fieldName === STATIC_PETINFO_FIELD_BREED) {
      if ((value as Types.SpeciesClientDto)?.id === (initialValue as Types.SpeciesClientDto)?.id) {
        return false
      }
    } else if (String(value).trim() === String(initialValue)) {
      return false
    }
  }

  if (!reqBody.pets?.length) {
    reqBody.pets = [{ id: invoicePetId }]
  }
  switch (fieldName) {
    case STATIC_PETINFO_FIELD_NAME: {
      reqBody.pets[0].displayName = (value as string) || undefined
      return true
    }
    case STATIC_PETINFO_FIELD_BREED: {
      reqBody.pets[0].breedId = (value as Types.SpeciesClientDto | undefined)?.id || undefined
      return true
    }
    case STATIC_PETINFO_FIELD_WEIGHT: {
      reqBody.pets[0].data = {
        ...reqBody.pets[0].data,
        weight: Number(value),
      }
      return true
    }
    case INTAKE_PETINFO_FIELD_BIRTHDATE: {
      reqBody.pets[0].data = {
        ...reqBody.pets[0].data,
        birthdate: (value as string) || null,
      }
      return true
    }
    case INTAKE_PETINFO_FIELD_ALTERED: {
      reqBody.pets[0].data = {
        ...reqBody.pets[0].data,
        altered: value === 'Yes' ? true : value === 'No' ? false : null,
      }
      return true
    }
    case INTAKE_PETINFO_FIELD_SEX: {
      reqBody.pets[0].data = {
        ...reqBody.pets[0].data,
        sex: value === 'Male' ? 'MALE' : value === 'Female' ? 'FEMALE' : null,
      }
      return true
    }
  }
  return false
}

export const handleSubmit = async ({
  locationName,
  orderId,
  invoicePetId,
  locationPetProfileId,
  values,
  initialValues,
  previouslySubmitted,
  shouldSavePet,
}: {
  locationName: string
  orderId: string
  invoicePetId?: string
  locationPetProfileId?: string
  values: TFormikValues
  initialValues: TFormikValues
  previouslySubmitted: boolean
  shouldSavePet?: boolean
}): Promise<Types.OrderClientDto> => {
  const { vaxRecords, ...formValues } = values
  let orderUpdateInputHasValues = false
  const orderUpdateInput = Object.entries(formValues).reduce((acc, [fieldName, fieldValue]) => {
    if (!petInfoFields.includes(fieldName)) {
      // save to flex bookings confs
      const newVal = (fieldValue as string)?.trim() || '',
        initialVal = (initialValues[fieldName] || '') as string
      if (!previouslySubmitted || newVal !== initialVal) {
        // if a form was not previously submitted, initialValues are auto-filled with petProfile data so it would be valid to submit them
        orderUpdateInputHasValues = true
        if (!acc.flexBookingConfs) {
          acc.flexBookingConfs = {}
        }
        acc.flexBookingConfs[fieldName] = [{ id: invoicePetId, value: newVal || null }]
      }
    } else if (invoicePetId) {
      // if invoicePetId, then this is pet drawer not the owner drawer -> save static fields to invoice pet
      const hasUpdatedBody = addFieldToUpdatePetBody(
        invoicePetId,
        previouslySubmitted,
        fieldName,
        fieldValue,
        initialValues[fieldName],
        acc
      )
      orderUpdateInputHasValues = orderUpdateInputHasValues || hasUpdatedBody
    }
    return acc
  }, {} as Types.UpdateBookingOrderClientInput)

  if (!invoicePetId) {
    // submitting owner flex booking confs
    return orderUpdateInputHasValues
      ? Services.ClientOrderService.update(orderId, locationName, orderUpdateInput).then((res) => res.order || {})
      : Promise.resolve({})
  }

  await Promise.all(
    saveVaxRecords(
      locationName,
      invoicePetId,
      vaxRecords || [],
      (initialValues.vaxRecords || []) as OrderAsset[],
      locationPetProfileId
    )
  )

  if (shouldSavePet) {
    const { id } = await createPet({ locationName, values })
    orderUpdateInputHasValues = true
    orderUpdateInput.pets = [
      {
        ...(orderUpdateInput.pets?.[0] || {}),
        id: invoicePetId,
        petId: id,
      },
    ]
  }

  return orderUpdateInputHasValues
    ? Services.ClientOrderService.update(orderId, locationName, orderUpdateInput).then((res) => res.order || {})
    : Services.ClientOrderService.getOrder(locationName, orderId)
}
