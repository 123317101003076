import { Types } from 'service-api'

interface TPriceRange {
  minRate: number | undefined
  maxRate: number | undefined
}

const getPriceRange = (acc: TPriceRange, available: boolean, price: Types.AvailabilityPriceDto) => {
  if (available) {
    let rate = price?.rate ? parseFloat(price.rate) : undefined
    rate = rate === undefined || isNaN(rate) ? undefined : rate
    acc.minRate = acc.minRate === undefined || (rate !== undefined && rate < acc.minRate) ? rate : acc.minRate
    acc.maxRate = acc.maxRate === undefined || (rate !== undefined && rate > acc.maxRate) ? rate : acc.maxRate
  }
  return acc
}

export const getPriceRangeForOffers = (index: number, groups?: Types.AvailabilityGroupClientDto[]) => {
  return (
    groups?.[index]?.availabilities?.reduce(
      (acc, { available, price }) => getPriceRange(acc, !!available, price || {}),
      { minRate: undefined, maxRate: undefined } as TPriceRange
    ) || ({ minRate: undefined, maxRate: undefined } as TPriceRange)
  )
}

export const getPriceRangeForOffersByDay = (groups?: Types.AvailabilityGroupClientDto[][]) => {
  return (
    groups?.reduce(
      (acc, group) => {
        group?.forEach(
          ({ availabilities }) =>
            availabilities?.forEach(({ available, price }) => getPriceRange(acc, !!available, price || {}))
        )
        return acc
      },
      { minRate: undefined, maxRate: undefined } as TPriceRange
    ) || ({ minRate: undefined, maxRate: undefined } as TPriceRange)
  )
}
