import type { TFormatNumberOptions } from './index.d'
import { ReactNode } from 'react'
import { i18WithDefault as t, i18WithParams as tp, pl } from './locales'

export const formatNumber = (value?: string | number, options?: TFormatNumberOptions): string => {
  if (typeof value === 'undefined') {
    return ''
  }
  const defaultValues = { prefix: '', suffix: '', brackets: false, fixed: 2, thousand: '', keepZero: false }
  options = Object.assign({}, defaultValues, options)

  if (typeof value === 'string') {
    value = parseFloat(value)
    if (isNaN(value)) {
      return ''
    }
  }

  const bp = options.brackets && value < 0 ? '(' : ''
  const bs = options.brackets && value < 0 ? ')' : ''

  let result = value.toFixed(options.fixed)

  if (!options.keepZero) {
    result = result.replace('.00', '')
  }

  if (options.thousand !== '') {
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousand || '')
  }

  // eslint-disable-next-line prettier/prettier
  return `${bp}${options.prefix || ''}${options.brackets ? result.replace('-', '') : result}${
    options.suffix || ''
  }${bs}`
}

export const formatNumberAsPrice = (value?: string | number, thousand?: string, fixed?: number): string => {
  return formatNumber(value, { prefix: '$', keepZero: true, brackets: true, thousand, fixed: fixed || 2 })
}

export const formatNumberAsPercent = (value?: string | number, options?: { keepZero?: boolean }): string => {
  const { keepZero = true } = options || {}
  return formatNumber(value, { suffix: '%', keepZero, brackets: true })
}

export const capitalize = (value?: string) => {
  if (!value) {
    return ''
  }
  value = value.toString().toLowerCase()
  value = value.charAt(0).toUpperCase() + value.slice(1)
  return value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    // eslint-disable-next-line no-prototype-builtins
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}

export const shallowEqual = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  )
}

// checks if arrays have the same items in any order
export const arraysShallowEqual = <T>(arr1: T[], arr2: T[]) => {
  if (arr1.length != arr2.length) return false
  const sortedArr1 = arr1.slice().sort(),
    sortedArr2 = arr2.slice().sort()
  for (let i = 0; i < sortedArr1.length; ++i) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false
    }
  }
  return true
}

interface sortCB<T> {
  (a: T, b: T): number
}
export const simpleSort = <T, K>(cb: (v: T) => K): sortCB<T> => {
  return (a: T, b: T): number => {
    if (cb(a) < cb(b)) {
      return -1
    }
    if (cb(a) > cb(b)) {
      return 1
    }
    return 0
  }
}

export const addDecimals = (value: number | string, defaultValue?: number): number | undefined => {
  const rc = parseFloatDef(value, defaultValue)
  if (!rc) {
    return rc
  }
  const tmp = String(rc)
  if (tmp.includes('.')) {
    return rc
  }

  return parseFloat(`${tmp}.01`)
}

export const parseFloatDef = (value: number | string, defaultValue?: number): number | undefined => {
  if (typeof value === 'number') {
    return value
  }
  const rc = parseFloat(value)
  if (isNaN(rc)) {
    return defaultValue
  }
  return rc
}

export const capitalizeFirstLetter = (value?: string): string => {
  if (!value) {
    return ''
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const genId = (): string => Math.floor(Math.random() * 99999999999).toString(36)

export const parseInteger = (value: string, defaultValue = 0): number => {
  const rc = parseInt(value, 10)
  if (isNaN(rc)) {
    return defaultValue
  }
  return rc
}

export const generatePhoneFromString = (str?: string): string => {
  if (!str) {
    return ''
  }
  // if the phone number includes a country code, cut it off for render
  str = str.slice(-10)
  return `(${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6, 10)}`
}

export const getNthOfThis = (count: number, node: (idx: number) => ReactNode): ReactNode[] =>
  Array.from(Array(count), (_, idx) => node(idx))

export const isHTML = (text?: string | null) => !!text?.startsWith('<')

// scroll to the top of the page
export const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

// scroll to the bottom of the page
export const scrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight || document.documentElement.scrollHeight,
    behavior: 'smooth',
  })
}

export const sleep = (timeout: number) => new Promise((resolve) => setTimeout(resolve, timeout))

export const valueInRange = (value: number, min?: number | null, max?: number | null) => {
  if (isEmpty(min) && isEmpty(max)) {
    return true
  }
  if (isEmpty(min)) {
    return value < max!
  }
  if (isEmpty(max)) {
    return value >= min!
  }
  return value >= min! && value < max!
}

export const convertMinutesToHours = (minutes: number) => Math.floor(minutes / 60)

export const convertMinutesToHoursAndMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const strMinute = pl(t('convertMinutesToHoursAndMinutes.minute'), remainingMinutes, true)
  const strHour = pl(t('convertMinutesToHoursAndMinutes.hour'), hours, true)

  if (hours === 0) {
    return strMinute
  } else if (remainingMinutes === 0) {
    return strHour
  } else {
    return tp('convertMinutesToHoursAndMinutes.hoursAndMinutes', { hours: strHour, minutes: strMinute })
  }
}

export const generatePassword = (length: number = 12) => {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowercase = 'abcdefghijklmnopqrstuvwxyz'
  const numbers = '0123456789'
  const symbols = '!@#$%^&*()_+'
  const charset = [uppercase, lowercase, numbers, symbols].join('')

  let password = ''
  // Insure at least 1 uppercase, 1 lowercase, 1 number and 1 symbol exist
  password += symbols[Math.floor(Math.random() * symbols.length)]
  password += uppercase[Math.floor(Math.random() * uppercase.length)]
  password += lowercase[Math.floor(Math.random() * lowercase.length)]
  password += numbers[Math.floor(Math.random() * numbers.length)]

  password += window.crypto
    .getRandomValues(new Uint8Array(length - 4))
    .reduce((newPass, item) => (newPass += charset[item % charset.length]), '')

  // randomized the order
  return password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('')
}

export const at = <T>(obj: T, path: string, defaultValue: unknown = null): T => {
  const keys = path.split(/\.|\[(\d+)\]/).filter(Boolean)
  for (const key of keys) {
    if (!obj || typeof obj !== 'object' || (!(key in obj) && !Array.isArray(obj))) {
      return defaultValue as T
    }
    if (Array.isArray(obj) && /^\d+$/.test(key)) {
      const index = parseInt(key, 10)
      if (index < 0 || index >= obj.length) {
        return defaultValue as T
      }
      obj = obj[index] as unknown as T
    } else {
      obj = (obj as Record<string, unknown>)[key] as unknown as T
    }
  }
  return obj !== undefined ? obj : (defaultValue as T)
}
export const sanitizeText = (txt: string) => {
  return txt != null
    ? String(txt)
        .trim()
        .replace(/null|undefined/g, '')
    : ''
}

export const roundNPlaces = (value: number, places = 2) => {
  const p = Math.pow(10, places)
  return Math.round((value + Number.EPSILON) * p) / p
}

interface THasId {
  id?: string
}

export const binarySearchById = <T extends THasId>(arr: T[], targetId: string): T | undefined => {
  if (!arr.length) {
    return
  }

  let low = 0
  let high = arr.length - 1

  while (low <= high) {
    const mid = Math.floor((low + high) / 2)
    const currentId = arr[mid].id || ''

    if (currentId === targetId) {
      return arr[mid]
    } else if (currentId < targetId) {
      low = mid + 1
    } else {
      high = mid - 1
    }
  }

  return
}

export const isNumber = (value?: string | number | null) => {
  if (isEmpty(value) || (typeof value === 'string' && !value.trim())) {
    return false
  }
  const numValue = Number(value)
  return !isNaN(numValue) && isFinite(numValue)
}

export const formatListWithConjunction = (list: (string | null | undefined)[] = []) => {
  const words = list.filter((word) => !!word?.trim()?.length).map((word) => word!.trim())
  if (!words.length) return ''
  if (words.length === 1) return words[0]
  if (words.length === 2) return words.join(` ${t('global.label.and')} `)

  const lastWord = words.pop()
  return `${words.join(', ')} ${t('global.label.and')} ${lastWord}`
}
