import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useCallback } from 'react'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import { i18WithDefault as t } from '@shared/locale'
import { TextInputPhone } from 'components-ui'
import { TVeterinarianProps } from './details_policies.d'
import { useBookingContext } from '@providers/booking'

const fieldMap: GenericSimpleBag = {
  vetName: 'name',
  vetPhone: 'phone',
}

export const VeterinarianInformation = ({ formik }: TVeterinarianProps) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ev: React.ChangeEvent<any>) => {
      const { name, id, value } = ev.target
      bookingCtx.dispatch({
        type: 'SET_VET_FORM',
        payload: {
          value: {
            fieldName: fieldMap[name || id],
            value,
          },
        },
      })

      formik.handleChange(ev)
    },
    [bookingCtx, formik]
  )

  return (
    <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
      <Typography variant="h5" mb={2}>
        {t('detailsPolicies.veterinarian.title')}
      </Typography>
      <Typography variant="body1" mb={2} mt={3}>
        {t('detailsPolicies.veterinarian.subtitle')}
      </Typography>

      <TextField
        id="vetName"
        label={t('veterinarianInfoForm.vetName')}
        variant="outlined"
        size="small"
        fullWidth
        value={formik.values.vetName}
        onChange={onChange}
        error={formik.touched.vetName && Boolean(formik.errors.vetName)}
        helperText={formik.touched.vetName && formik.errors.vetName}
      />
      <TextInputPhone
        id="vetPhone"
        name="vetPhone"
        label={t('veterinarianInfoForm.vetPhone')}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ mt: 2 }}
        value={formik.values.vetPhone}
        onChange={onChange}
        error={formik.touched.vetPhone && Boolean(formik.errors.vetPhone)}
        helperText={formik.touched.vetPhone && formik.errors.vetPhone}
      />
    </Paper>
  )
}
