import pluralize from 'pluralize'
import { enLocale } from './locales_en'
import { ReactNode, isValidElement, cloneElement } from 'react'
interface Ti18ParamOptions {
  defaultValue: string
}

const i18Translation = (key: string | undefined): string | undefined => enLocale[key ?? '']

export const i18WithDefault = (key: string | undefined, options?: Ti18ParamOptions) =>
  i18Translation(key) || options?.defaultValue || ''

export const i18WithParams = (key: string | undefined, params: { [key: string]: string } = {}) => {
  return Object.entries(params).reduce(
    (res, [paramKey, paramValue]) => res.replaceAll(`{{${paramKey}}}`, paramValue),
    i18WithDefault(key)
  )
}

export const i18WithComponent = (key: string, params: { [key: string]: ReactNode } = {}): string | ReactNode => {
  const text = i18WithDefault(key)
  const keys = text.match(new RegExp(/[^{{}]+(?=}})/, 'g')) || []
  const keyRegExp = new RegExp(keys.map((key) => `{{${key}}}`).join('|'), 'g')
  const parts = text!.split(keyRegExp)

  if (Object.keys(params).length >= 1) {
    const result: ReactNode[] = []
    parts.forEach((part, index) => {
      result.push(part)
      if (index < keys.length) {
        const replacement = params[keys[index]]
        if (isValidElement(replacement)) {
          result.push(cloneElement(replacement, { key: `${key}-${index}` }))
        } else {
          result.push(replacement)
        }
      }
    })

    return result
  } else if (parts.length > 0 && keys.length > 0) {
    return parts.filter(Boolean)
  }

  return text
}

export default i18Translation

export const pl = (text: string, count: number, include = false) => pluralize(text, count, include)
