import { Types } from 'service-api'
import { isEmpty } from '@shared/common'
import type { TDaysOfWeek } from './dates.d'
import { dayToJSDate, formatAmPm, getTzDateInUtc } from './dates'

export const restrictionForSpecie = (specieId?: string, restrictions?: Types.RestrictionClientDto[]) => {
  if (!specieId || !restrictions) {
    return []
  }
  return restrictions.filter((r) => r.restrictions?.some((s) => s.type === 'SPECIES' && s.text === specieId))
}

export const getThresholds = (restrictions?: Types.RestrictionClientDto[], specieId?: string) => {
  if (!restrictions || !specieId) {
    return
  }

  const specieRestriction = restrictions.filter(
    (r) => !(isEmpty(r.min) && isEmpty(r.max)) && (r.restrictions?.findIndex((rr) => rr.text === specieId) ?? -1) >= 0
  )

  if (specieRestriction.length > 0) {
    // note: min/max are inverted
    return {
      min: specieRestriction[0].max || null,
      max: specieRestriction[0].min || null,
    }
  }

  return
}

export const isSelectedDayInPeriodRestrictions = (
  restrictions: Types.RestrictionClientDto[],
  dayOfWeek: TDaysOfWeek
): boolean => restrictions.some((restriction) => restriction.periods?.find((period) => period.dayOfWeek === dayOfWeek))

export const isSelectedTimeInPeriodRestrictions = (
  restrictions: Types.RestrictionClientDto[],
  dayOfWeek: TDaysOfWeek,
  time: string
): boolean =>
  restrictions.some(
    (restriction) =>
      restriction.periods?.some((period) => {
        return period.dayOfWeek === dayOfWeek && period.startTime && period.endTime
          ? time >= period.startTime && time < period.endTime
          : false
      })
  )

export const isSelectedDateInPeriodRestrictionsRelation = (
  restrictionRelations: Types.RestrictionRelationAdminDto[],
  dayOfWeek: TDaysOfWeek,
  date: string
): boolean =>
  restrictionRelations.some(
    (relation) =>
      relation.restriction?.periods?.some((period) => {
        const startDate = dayToJSDate(period.startDate?.substring(0, 10))?.toISOString()
        const endDate = dayToJSDate(period.endDate?.substring(0, 10))?.toISOString()
        const selectedDate = dayToJSDate(date)?.toISOString()

        return period.dayOfWeek === dayOfWeek && startDate && endDate && selectedDate
          ? startDate <= selectedDate && selectedDate <= endDate
          : period.dayOfWeek === dayOfWeek || false
      })
  )

export const displayHoursInPeriodRestrictions = (
  restrictions: Types.RestrictionClientDto[],
  day: TDaysOfWeek
): string => {
  const periodsForDay = [] as { startTime: string; endTime: string }[]

  restrictions.forEach((restriction) => {
    restriction.periods?.forEach((period) => {
      if (period.dayOfWeek === day && period.startTime && period.endTime) {
        periodsForDay.push({
          startTime: period.startTime,
          endTime: period.endTime,
        })
      }
    })
  })

  periodsForDay.sort((a, b) => {
    if (a.startTime === b.startTime) return a.endTime.localeCompare(b.endTime)
    return a.startTime.localeCompare(b.startTime)
  })

  const displayHours = periodsForDay.map((period) => `${formatAmPm(period.startTime)}-${formatAmPm(period.endTime)}`)

  return displayHours.join(', ')
}

export const temperTestAheadTime = (restriction?: Types.RestrictionAdminDto | Types.RestrictionClientDto): number => {
  if (!restriction) {
    return 0
  }
  return Math.round((restriction.max ?? 0) / 60 / 24)
}
