import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'

import { useBookingContext } from '@providers/booking'
import { getSelectedGroom } from '@providers/booking/helper'
import { TBookingPet } from '@providers/booking/types'
import { i18WithDefault as t } from '@shared/locale'
import { Spacer1 } from 'components-ui'
import { GroomNotice } from 'components-ui/src/groom_notice/groom_notice'
import { formatNumberAsPrice } from 'components-ui/src/shared'
import { Types } from 'service-api'
import { getPriceRangeForOffers } from '../addon_item_picker/helper'
import { PetCard } from './pet_card'

export interface TGroomingSelectorProps {
  groom: Types.BookingSearchClientDto
  groupIdx: number
  petsInGroup: TBookingPet[]
}

export const GroomingSelector = ({ groom, groupIdx, petsInGroup }: TGroomingSelectorProps) => {
  const bookingCtx = useBookingContext()
  const offer = groom.offer!

  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const isSelected = useMemo(() => {
    return getSelectedGroom(bookingCtx.state, groupIdx, offer.id || '')?.id ? true : false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupIdx, bookingCtx.state.cart.groups[groupIdx].grooms])

  const { minRate, maxRate } = useMemo(() => {
    let firstAvailable: number | undefined = undefined
    groom.availabilityGroups?.forEach((ag, agIdx) => {
      const idx = ag.availabilities?.findIndex((a) => a.available)
      if (idx !== -1 && firstAvailable === undefined) firstAvailable = agIdx
    })
    if (firstAvailable === -1) {
      return { minRate: undefined, maxRate: undefined }
    }
    return getPriceRangeForOffers(firstAvailable!, (groom as Types.BookingSearchClientDto).availabilityGroups)
  }, [groom])

  const onSelectItem = () => {
    bookingCtx.state.cart.groups[groupIdx].pets.map((_pet, i) => {
      if (groom.availabilityGroups?.[i].availabilities?.some((a) => a.available)) {
        bookingCtx.dispatch({
          type: 'TOGGLE_GROOM_IN_PET_GROOM',
          payload: { value: groom, groupIdx: groupIdx, petIdx: i },
        })
      }
    })
  }

  const togglePetInGroom = (petIdx: number) => {
    bookingCtx.dispatch({
      type: 'TOGGLE_GROOM_IN_PET_GROOM',
      payload: { value: groom, groupIdx: groupIdx, petIdx: petIdx },
    })
  }

  const BtnIcon = useMemo(() => (isSelected ? CheckIcon : AddIcon), [isSelected])
  const priceVaries = offer?.priceVaries

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ maxWidth: '640px', margin: '0 auto', width: '100%' }}
      >
        <Stack direction="row" width="100%" spacing={1} justifyContent={'space-between'}>
          <Stack direction="column" sx={{ mb: 1 }}>
            <Typography flexGrow={1} variant="h6">
              {offer.displayName}
            </Typography>
            <Typography flexGrow={1} variant="body2">
              {offer.description}
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="end" spacing={1}>
            {minRate && maxRate && (
              <Box alignSelf={'center'}>
                <Typography variant="body2" component="span" sx={{ paddingRight: '4px' }}>
                  {priceVaries && t('offers.startingAt')}
                </Typography>
                <Typography variant="body2" component="span">
                  <strong>
                    {minRate === maxRate
                      ? formatNumberAsPrice(minRate)
                      : `${formatNumberAsPrice(minRate)} - ${formatNumberAsPrice(maxRate)}`}
                  </strong>
                </Typography>
                <Typography variant="body2" component="span">
                  /{t('global.label.each')}
                </Typography>
              </Box>
            )}
            {isBelowMD && <Spacer1 />}
            <Button
              variant={isSelected ? 'contained' : 'outlined'}
              size="small"
              startIcon={<BtnIcon />}
              onClick={onSelectItem}
              sx={{
                marginLeft: isBelowMD ? 0 : '32px',
                minWidth: '120px',
                py: 1,
              }}
            >
              {isSelected ? t('global.label.added') : t('global.label.add')}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Spacer1 />
      {isSelected && (
        <Box sx={{ maxWidth: '640px', margin: '0 auto', width: '100%', mt: 0.5, mb: 0.5 }}>
          <GroomNotice />
        </Box>
      )}
      {isSelected &&
        petsInGroup.length > 1 &&
        petsInGroup.map((pet, petIdx) => (
          <PetCard
            petIdx={petIdx}
            key={`${pet.id}_${groom.offer!.id}`}
            pet={pet}
            groupIdx={groupIdx}
            groom={groom}
            togglePetInGroom={(petIdx) => togglePetInGroom(petIdx)}
          />
        ))}
    </Box>
  )
}
