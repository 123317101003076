import { getAPIPath, requestGooseAPI } from '../shared/request'
import {
  APIPetFamilyClientDtoResponse,
  APIPetProfileClientDtoResponse,
  CLIENT_PET_FAMILIES_PATH,
  CLIENT_PET_PROFILES_PATH,
} from '../types'

export const ClientPetFamiliesService = {
  families: (locationName: string): Promise<APIPetFamilyClientDtoResponse> => {
    return requestGooseAPI<APIPetFamilyClientDtoResponse>({
      url: getAPIPath(CLIENT_PET_FAMILIES_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        includes: ['petProfiles'],
      },
    })
  },

  familyPets: async (locationName: string): Promise<APIPetProfileClientDtoResponse | null> => {
    const families = await requestGooseAPI<APIPetFamilyClientDtoResponse>({
      url: getAPIPath(CLIENT_PET_FAMILIES_PATH, { locationId: locationName }),
      method: 'GET',
      // params: {
      //   includes: ['petProfiles'],
      // },
    })

    const familyId = families.results[0]?.id
    if (!familyId) {
      return null
    }

    return await requestGooseAPI<APIPetProfileClientDtoResponse>({
      url: getAPIPath(CLIENT_PET_PROFILES_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        petFamily: familyId,
        includes: ['pet', 'breed'],
      },
    })
  },
}
