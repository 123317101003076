import { createContext, ReactNode, useContext, useState } from 'react'
import { Theme } from '@mui/material/styles'

import { Types } from 'service-api'
import { buildClientTheme, getDefaultTheme } from 'components-ui'

type TSetThemeFx = (themeData: Types.LocationThemeClientDto) => Theme

export const AppThemeContext = createContext<{
  theme: Theme
  updateTheme: TSetThemeFx
} | null>(null)

export const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  const { Provider } = AppThemeContext
  const [theme, setTheme] = useState(getDefaultTheme())

  const updateTheme = (themeData: Types.LocationThemeClientDto): Theme => {
    const t = buildClientTheme(themeData.primaryColor!.code!, themeData.secondaryColor!.code!)
    setTheme(t)
    return t
  }

  return <Provider value={{ theme, updateTheme }}>{children}</Provider>
}

export const useAppThemeContext = () => {
  const ctx = useContext(AppThemeContext)
  if (ctx === null) {
    throw new Error('useAppThemeContext must be used within a AppThemeProvider')
  }
  return ctx
}
