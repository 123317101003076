import { CustomInvoiceAdminDto, LocationPetProfileAdminDto } from '../types'

export const MAX_BATCH_SIZE = 10

export interface TFileImage {
  assetId: string
  deleted: boolean
  file?: File & { preview: string }
  url?: string
}

export interface TUserInvitation {
  email?: string
  role: string
  divisions: string[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericSimpleBag = Record<string, any>

export type TBookingStep = 'BOOKING' | 'INTAKE' | 'PAYMENT' | 'CONFIRMATION'
export interface TBookingSearchOptions {
  startDate: string
  endDate: string
  petGroups: TBookingSearchPetGroup[]
  locationServiceType?: string
  type?: string
  offersType: 'PRIMARY' | 'SERVICE' | 'PET'
  offerName?: string
  locationUserProfileId?: string
  subServiceType?: string
}

export interface TBookingSearchPetGroup {
  locationSpecies: string
  petCount: number
  petWeights?: number[]
  temperTested?: boolean[]
  parentOffer?: string
  serviceTypeName?: string
}

export interface TOrderSearchOptions {
  limit?: number
  nextToken?: string
  name?: string
  orderStatus?: string
  owner?: { id?: string }
  locationUserProfile?: string
  createdBy?: string
  primaryServiceType?: string
  sort?: string
  order?: 'asc' | 'desc'
  dynamic?: string
  includes?: string[]
  hasSharedCRM?: boolean
}

export interface TInvoiceSearchOptions {
  dynamic?: string
  includes?: string[]
  limit?: number
  'order.locationUserProfileId'?: string
  name?: string
  nextToken?: string
  invoiceStatus?: string
  'items.locationServiceTypeId'?: string
  sortOrder?: 'asc' | 'desc'
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface TInvoiceListOptions {
  visitDate?: string
  visitStatus?: 'ARRIVING' | 'HERE' | 'DEPARTING'
  visitSubStatus?: 'HERE' | 'NOT_HERE'
  invoiceStatus?: Status[]
  invoiceSubStatus?: SubStatus[]
  limit?: number
  nextToken?: string
  sort?: string
  sortOrder?: SortOrder
  order?: string
  'items.locationServiceTypeId'?: string
  'items.offerType'?: string
  'order.locationUserProfileId'?: string
  'order.orderStatus'?: string
  'period.startDate'?: string
  'period.endDate'?: string
  'resourceUsages.resourceId'?: string
  distinguishes?: string[] | null
  includes?: string[]
  dynamic?: string
  hasSharedCRM?: boolean
  notUseDefaultIncludes?: boolean
}

export enum Status {
  ALL_STATUS = 'all-statuses',
  CANCELLED = 'canceled',
  CONFIRMED = 'confirmed',
  PARTIALLY_CANCELLED = 'partially-canceled',
}

export enum SubStatus {
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
  DECLINED = 'DECLINED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELED',
}

export interface TResourceUsagesOptions {
  startDate?: string
  endDate?: string
  invoice?: string
  serviceId?: string[]
  limit?: number
  status?: string
  nextToken?: string
  includes?: string[]
  distinguishes?: string[]
  'resource.type'?: string[]
  'invoiceItem.locationServiceTypeId'?: string
  'service.isPrimary'?: boolean
}

// TODO: use a generic interface and not one dependent on a custom shape
export interface TDivisionSchema {
  displayName: string
  description: string
  parentDivisionId: string
}

export interface TLocationSchema {
  displayName: string
  divisionId: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TOfferState = Record<string, any>
export type TOfferSchedulingRule = 'CHECK_IN' | 'LAST_NIGHT' | 'CHECK_OUT'

export interface TOfferService {
  id: string
  joinId?: string
  displayName: string
  frequency: string
  quantity: string
  unitRate: string
  itemizedRate: string
  schedulingRule: TOfferSchedulingRule
}

export interface TQueryConfig {
  enabled?: boolean
}

export const ContractType = {
  ServiceAgreement: 'SERVICE_AGREEMENT',
} as const

export type TContractType = (typeof ContractType)[keyof typeof ContractType]

export type TPaymentPayFacType =
  | 'TILLED'
  | 'OFFLINE'
  | 'ADYEN'
  | 'CASH'
  | 'OTHER'
  | 'ADYEN_CARD_PRESENT'
  | 'VOUCHER_OFFER'
  | 'VOUCHER_CASH'

export interface TInvoicePetHistory {
  orderId: string
  invoices: CustomInvoiceAdminDto[]
}

export interface TInvoicePetHistoryList {
  [key: string]: {
    pet: LocationPetProfileAdminDto
    history: TInvoicePetHistory[]
  }
}
