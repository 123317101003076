import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'

import { Skeleton } from '@mui/material'
import { useBookingContext } from '@providers/booking'
import { pl, i18WithDefault as t } from '@shared/locale'
import { DaysSkeleton } from './days_skeleton'
import { StaticModalPicker } from './static_modal_picker'

export const BookedDays = () => {
  const ctx = useBookingContext()
  const bookedDays = differenceInDays(ctx.state.endDate || 0, ctx.state.startDate || 0)
  const overnight = ctx.state.data.serviceType?.serviceType?.overnight
  const strategy = ctx.state.config.location?.pricingStrategy

  if (bookedDays <= 0 || !strategy || typeof overnight === 'undefined') {
    return null
  }

  const label = strategy === 'DAILY' ? t('global.label.day') : t('global.label.night')
  const daysStr = pl(label, bookedDays, true)
  return <Box component="span" sx={{ pl: 1 }}>{`(${daysStr})`}</Box>
}

export const DateSelector = () => {
  const ctx = useBookingContext()
  const strategy = ctx.state.config.location?.pricingStrategy

  return (
    <Stack direction="column" alignItems="start" justifyContent="center" sx={{ flexShrink: 0, minWidth: '230px' }}>
      <Box>
        <Typography variant="subtitle2">
          {t('global.label.dates')}
          <BookedDays />
        </Typography>
        <Box>
          {strategy ? (
            <StaticModalPicker>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6" component="div" color="primary.main">
                  {ctx.state.startDate ? (
                    format(ctx.state.startDate || 0, 'MMM d')
                  ) : (
                    <Skeleton variant="text" width="80px" height={22} />
                  )}
                </Typography>
                <Typography variant="body1" component="span" sx={{ mx: 1 }}>
                  {t('global.label.to')}
                </Typography>
                <Typography variant="h6" component="div" color="primary.main">
                  {ctx.state.endDate ? (
                    format(ctx.state.endDate || 0, 'MMM d')
                  ) : (
                    <Skeleton variant="text" width="80px" height={22} />
                  )}
                </Typography>
              </Stack>
            </StaticModalPicker>
          ) : (
            <DaysSkeleton />
          )}
        </Box>
      </Box>
    </Stack>
  )
}
