import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin'
import { OnPaymentCompletedData } from '@adyen/adyen-web/dist/types/components/types'
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { useEffect, useRef } from 'react'

const PAYMENT_TYPE = 'dropin'

export interface TBillingAddress {
  street: string
  postalCode: string
  city: string
  country: string
  houseNumberOrName: string
  stateOrProvince?: string
}

export const submitPaymentForm = (paymentFormId: string) => {
  const frm = document.getElementById(paymentFormId) as HTMLFormElement
  frm?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
}

// https://docs.adyen.com/online-payments/payment-result-codes/
const FAILED_CODES = ['Cancelled', 'Error', 'Refused']
const isPaymentFailed = (resultCode?: string | null) => {
  if (!resultCode) return false
  return FAILED_CODES.includes(resultCode)
}

const getAdyenConfig = (
  amount: number,
  currency: string,
  billingAddress: TBillingAddress,
  storeDetailsMsg: string,
  isAdmin: boolean
): CoreOptions => {
  return {
    paymentMethodsConfiguration: {
      card: {
        name: 'New Card',
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: true,
        positionHolderNameOnTop: true,
        maskSecurityCode: true,
        showPayButton: false,
        billingAddressAllowedCountries: ['US'],
        amount: {
          value: amount * 100,
          currency: currency,
        },
        data: {
          billingAddress,
        },
      },
      storedCard: {
        hideCVC: isAdmin,
        maskSecurityCode: true,
        showPayButton: false,
      },
    },
    locale: 'en-US',
    translations: {
      'en-US': {
        storeDetails: storeDetailsMsg,
      },
    },
    clientKey: import.meta.env.VITE_ADYEN_CLIENT_KEY,
    environment: import.meta.env.VITE_TILLED_ENVIRONMENT === 'prod' ? 'live-us' : 'test',
  }
}

interface TAdyenCardPaymentProps {
  sessionId?: string | null
  sessionData?: string | null
  currency: 'USD'
  amount: number
  paymentFormId: string
  billingAddress: TBillingAddress
  storeDetailsMsg: string
  showRegularPaymentMethods?: boolean
  showStoredPaymentMethods?: boolean
  isAdmin?: boolean
  onPaymentCompleted: (result: TOnPaymentCompleted) => void
  onError: (err: Error) => void
}

export interface TOnPaymentCompleted {
  failed?: boolean
  sessionResult: string
}

export const AdyenCardPayment = ({
  sessionId,
  sessionData,
  currency,
  amount,
  paymentFormId,
  storeDetailsMsg,
  showRegularPaymentMethods = true,
  showStoredPaymentMethods = true,
  isAdmin = false,
  onPaymentCompleted,
  onError,
  billingAddress,
}: TAdyenCardPaymentProps) => {
  const paymentContainer = useRef(null)
  const dropin = useRef<DropinElement | null>(null)
  const onComplete = (data: OnPaymentCompletedData) => {
    const failed = isPaymentFailed(data.resultCode)
    if (failed) {
      dropin.current?.setElementStatus('ready')
    }
    onPaymentCompleted({
      failed,
      sessionResult: data.sessionResult,
    })
  }

  useEffect(() => {
    // Did I said that I ate react ?
    // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
    // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
    let ignore = false

    if (!sessionId || !sessionData || !paymentContainer.current) {
      return
    }

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...getAdyenConfig(amount, currency, billingAddress, storeDetailsMsg, isAdmin),
        session: {
          id: sessionId,
          sessionData,
        },
        onPaymentCompleted: onComplete,
        onError,
      })

      if (paymentContainer.current && !ignore) {
        dropin.current = checkout
          .create(PAYMENT_TYPE, {
            showPaymentMethods: showRegularPaymentMethods,
            showStoredPaymentMethods,
          })
          .mount(paymentContainer.current)
      }
    }
    createCheckout()

    return () => {
      ignore = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, currency, sessionData, sessionId])

  const onSubmitForm = () => {
    if (dropin.current?.isValid) {
      dropin.current?.submit()
    } else {
      dropin.current?.showValidation()
      onError(new Error('invalid-form'))
    }
  }

  return (
    <div className="payment-container">
      <form id={paymentFormId} onSubmit={onSubmitForm}>
        <div ref={paymentContainer} className="payment"></div>
      </form>
    </div>
  )
}
