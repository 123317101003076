import { useFormik } from 'formik'
import { Auth } from 'aws-amplify'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'

import { SubmitError } from './submit_error'
import { ModalType, TChangePasswordProps } from './index.d'
import { changePasswordSchema } from './change_password_schema'
import { i18WithDefault as t } from '../shared/locales'

const initialValues = {
  password: '',
  newPassword: '',
}

export const ChangePasswordForm = ({ switchModalType, user }: TChangePasswordProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const changePasswordSubmit = async (newPassword: string, setStatus: (status?: string) => void) => {
    setIsSubmitting(true)
    try {
      Auth.completeNewPassword(user, newPassword)
      switchModalType(ModalType.signIn)
    } catch (err) {
      setStatus((err as Error).message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setStatus }) => {
      await changePasswordSubmit(values.newPassword, setStatus)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="password"
        name="password"
        label={t('confirmResetPassword.newPasswordField')}
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        sx={{ marginBottom: 2 }}
        FormHelperTextProps={{ style: { marginLeft: 0 } }}
      />
      <TextField
        fullWidth
        id="newPassword"
        name="newPassword"
        label={t('confirmResetPassword.repeatPasswordField')}
        type="password"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        FormHelperTextProps={{ style: { marginLeft: 0 } }}
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
      />
      <SubmitError value={formik.status} />
      <LoadingButton
        color="secondary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ height: 42, my: 2 }}
        loading={isSubmitting}
      >
        {t('confirmResetPassword.submitButton')}
      </LoadingButton>
    </form>
  )
}
