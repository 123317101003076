import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LOCATION_SERVICE_TYPE_PATH } from '../types/constants'
import { APILocationServiceTypeClientDtoResponse } from '../types/api'
import { TClientLocationServiceTypeOptions } from '../hooks/hooks_types.d'

export const ClientLocationServiceTypeService = {
  getServiceTypes: (
    locationName: string,
    options?: TClientLocationServiceTypeOptions
  ): Promise<APILocationServiceTypeClientDtoResponse> => {
    const includes = ['serviceType']
    if (options?.restrictions) {
      includes.push('restrictionRelations.restriction.periods')
    }

    return requestGooseAPI<APILocationServiceTypeClientDtoResponse>({
      url: getAPIPath(CLIENT_LOCATION_SERVICE_TYPE_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        includes,
      },
    })
  },
}
