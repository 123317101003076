import { Link } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { i18WithParams as t } from '@shared/locale'

import { Spacer3 } from 'components-ui'
import { AddressLocation, Phone, Website } from '../address'

export const Footer = () => {
  return (
    <Box sx={{ '& a': { textDecoration: 'none', color: 'inherit' } }}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        paddingLeft={{ xs: 3, md: 'none' }}
        justifyContent={{ xs: 'left', md: 'center' }}
      >
        <Website />
        <AddressLocation />
        <Phone />
      </Stack>

      <Spacer3 />

      <Stack direction="row" spacing={2} paddingLeft={{ xs: 3, md: 'none' }} justifyContent="center">
        <Typography variant="small">{t('footer.poweredBy', { brand: 'Goose' })}</Typography>
        <Typography variant="small">|</Typography>
        <Link href="https://www.goose.pet/terms-conditions" target="_blank">
          <Typography variant="small">{t('footer.terms')}</Typography>
        </Link>
        <Typography variant="small">|</Typography>
        <Link href="https://www.goose.pet/privacy-policy" target="_blank">
          <Typography variant="small">{t('footer.privacy')}</Typography>
        </Link>
      </Stack>
    </Box>
  )
}
