import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ClientBookingSearchConfigsService } from '../services/client_booking_search_configs.service'
import { BookingSearchConfigsClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientBookingSearchConfigs = (
  locationName: string,
  serviceTypeName: string
): UseQueryResult<BookingSearchConfigsClientDto, unknown> => {
  return useQuery<BookingSearchConfigsClientDto>({
    queryKey: ['clientBookingSearchConfigs', locationName, serviceTypeName],
    queryFn: () => ClientBookingSearchConfigsService.getData(locationName, serviceTypeName),
    ...defaultQueryOptions,
  })
}
