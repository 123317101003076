import { useQuery } from '@tanstack/react-query'
import { ClientLocationService } from '../services'
import { LocationClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientLocation = (locationName: string, locationId: string) => {
  return useQuery<LocationClientDto>({
    queryKey: ['clientLocation', locationName, locationId],
    queryFn: () => ClientLocationService.getLocation(locationName, locationId),
    enabled: Boolean(locationId),
    ...defaultQueryOptions,
  })
}
