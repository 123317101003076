import Chip from '@mui/material/Chip'
import { TOfferIncludesProps } from './offer_card.d'

export const OfferIncludes = ({
  service,
  locationPricingStrategy,
  showStrategy = true,
  showQty = true,
}: TOfferIncludesProps) => {
  let freqLabel = 'day'
  const sb = [`${service.service?.displayName || service.service?.name} `]

  if (service.offerFrequency === 'DAILY') {
    freqLabel = locationPricingStrategy === 'DAILY' ? 'day' : 'night'
  } else if (service.offerFrequency === 'STAY') {
    freqLabel = 'stay'
  }

  if (showQty) {
    if (showStrategy) {
      sb.push(showStrategy ? `(${service.qty}/${freqLabel})` : ')')
    } else {
      if ((service.qty || 0) > 1) {
        sb.push(`(${service.qty})`)
      }
    }
  }

  return <Chip key={`offer-chip-${service.id}`} sx={{ marginRight: 1, marginBottom: 1 }} label={sb.join('')} />
}
