import { createContext, useContext } from 'react'

import type { TBookingContext, TBookingContextDef, TBookingCart, TBookingData } from './types'
import { TBookingGroup } from './types'
import { newContact, newPetGroup } from './helper'

export const MAX_PET_GROUPS = 4

export const newCart = (): TBookingCart => ({
  total: 0,
  contact: newContact(),
  payment: {
    method: 'payNow',
  },
  checkInTime: '',
  checkOutTime: '',
  vet: {
    name: '',
    phone: '',
  },
  groups: [],
})
export const newStateData = (): TBookingData => ({
  offersSearchResults: undefined,
  offersAddOns: undefined,
  petsAddOns: undefined,
  offerAvailability: undefined,
  temperTestOffer: undefined,
  formHandler: undefined,
  serviceType: undefined,
  petProfiles: undefined,
  grooms: undefined,
})

export const initialState: TBookingContext = {
  serviceTypeName: 'boarding',
  isNewCustomer: true,
  startDate: undefined,
  endDate: undefined,
  isValid: false,
  searchBarOpen: false,
  maxGroups: MAX_PET_GROUPS,
  closedDates: [],
  openDays: [],
  currentState: 'search',
  groups: [newPetGroup()] as TBookingGroup[],
  asyncAction: false,
  config: {
    theme: undefined,
    booking: undefined,
    serviceType: undefined,
    species: undefined,
    location: undefined,
  },
  cart: newCart(),
  data: newStateData(),
  confirmation: {
    groups: [],
  },
  currentGroup: 0,
  shouldFireGAPurchaseEvent: false,
}

export const BookingContext = createContext<TBookingContextDef | null>(null)

export const useBookingContext = () => {
  const ctx = useContext(BookingContext)
  if (ctx === null) {
    throw new Error('useSessionContext must be used within a SessionProvider')
  }
  return ctx
}
