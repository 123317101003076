import { SiteHeader } from '@components/site_header'
import { Box, useTheme } from '@mui/material'
import { STICKY_HEADER_HEIGHT_PX } from '@shared/constants'

export const Header = () => {
  const theme = useTheme()

  return (
    <Box sx={{ position: 'sticky', top: 0, height: STICKY_HEADER_HEIGHT_PX, zIndex: theme.zIndex.appBar }}>
      <SiteHeader />
    </Box>
  )
}
