import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'

import { useBookingContext } from '@providers/booking'
import { ActionPrompt } from '../action_prompt'
import TagManager from 'react-gtm-module'

export const PetSearchSimple = ({ children }: { children: ReactNode }) => {
  const bookingCtx = useBookingContext()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [enterPetsManually, setEnterPetsManually] = useState<boolean>(false)
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const onClick = (ev: MouseEvent<HTMLElement>) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'PET_MODAL_OPEN',
      },
    })
    if (bookingCtx.state.config.location?.pricingStrategy) {
      bookingCtx.dispatch({ type: 'TOGGLE_SEARCH_BAR', payload: { action: 'open' } })
      setAnchorEl(ev.currentTarget)
    }
  }
  const onCloseModal = () => {
    bookingCtx.dispatch({ type: 'TOGGLE_SEARCH_BAR', payload: { action: 'close' } })
    setAnchorEl(null)
  }
  const onManualPetEntryClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'PET_MODAL_SKIP_LOGIN',
      },
    })
    setEnterPetsManually(true)
  }

  // this is to sync the anchor element value with the state of the search bar
  // the search bar can be close from multiple places
  useEffect(() => {
    if (!bookingCtx.state.searchBarOpen) {
      setAnchorEl(null)
    }
  }, [bookingCtx.state.searchBarOpen])

  return (
    <Box>
      <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Box>
      {anchorEl && (
        <Popover
          open={bookingCtx.state.searchBarOpen}
          onClose={onCloseModal}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={anchorEl}
          slotProps={{
            paper: {
              style: { width: `${isBelowMD ? 'calc(100vw - 32px)' : '600px'}`, marginTop: '24px' },
            },
          }}
        >
          <ActionPrompt
            onClose={onCloseModal}
            enterPetsManually={enterPetsManually}
            onManualPetEntryClick={onManualPetEntryClick}
          />
        </Popover>
      )}
    </Box>
  )
}
