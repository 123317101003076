import { LicenseInfo } from '@mui/x-license-pro'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { Amplify } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'

import { App } from 'app'

TagManager.initialize({
  gtmId: import.meta.env.VITE_GTM_ID,
  preview: import.meta.env.VITE_GTM_PREVIEW,
  auth: import.meta.env.VITE_GTM_AUTH,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT || 'dev',
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
})

// const intercomAppId = window.env?.INTERCOM_APP_ID || ''
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: import.meta.env.VITE_COGNITO_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: '/',
      redirectSignOut: '/',
      responseType: 'code',
    },
  },
})
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_COMPONENTS)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
