import { Types } from 'service-api'
import type { TBookingContext, TCartGroup } from '@providers/booking/types'
import { TPet, TShowOffersResultsPetGroup } from './booking_groups.d'
import { requiresWeightForSpecie } from './service_types'
import cloneDeep from 'lodash.clonedeep'
import { parseInteger } from 'components-ui/src/shared/common'

export const pendingOfferSelection = (groups: TCartGroup[], withValue: number) => {
  let idx = -1

  for (let i = 0; i < groups.length; i++) {
    if (i !== withValue && !groups[i]?.offerSelected) {
      idx = i
      break
    }
  }
  return idx
}

export const joinPetNames = (groups?: TCartGroup[], withTemperTest = false) => {
  return (
    groups?.map((group) =>
      group.pets
        .reduce((acc, pet) => {
          if (withTemperTest) {
            if (!pet.temperTested) {
              acc.push(pet.name)
            }
          } else {
            acc.push(pet.name)
          }
          return acc
        }, [] as string[])
        .join(', ')
    ) || []
  )
}

export const buildPetTabGroups = (groups: TCartGroup[]) => {
  return joinPetNames(groups).map((pet) => ({ label: pet }))
}

export const sortOrders = (original: Types.BookingSearchClientDto[]): Types.BookingSearchClientDto[] => {
  const offers: Types.BookingSearchClientDto[] = cloneDeep(original)

  offers.sort((offerA, offerB) => {
    // if group is empty move it to the end
    const aGroup = offerA.offer!.group ? offerA.offer!.group : 'ZZZZZZZZZZ'
    const bGroup = offerB.offer!.group ? offerB.offer!.group : 'ZZZZZZZZZZ'
    const aName = `${aGroup}${String(offerA.offer!.order || 0).padStart(3, '0')}`
    const bName = `${bGroup}${String(offerB.offer!.order || 0).padStart(3, '0')}`

    return aName.localeCompare(bName)
  })

  return offers
}

export const buildPetGroups = (
  ctx: TBookingContext,
  isAddOnSearch?: boolean,
  isGroom?: boolean
): TShowOffersResultsPetGroup[] => {
  const groups = ctx.cart.groups
  const wfs = requiresWeightForSpecie(ctx.config?.booking)
  const rc = [] as TShowOffersResultsPetGroup[]
  groups.forEach((group, index) => {
    if (isGroom) {
      group.pets.map((pet) => {
        rc.push({
          locationSpecies: group.specieId,
          petCount: 1,
          pets: [
            {
              petId: pet.id.length < 12 ? null : pet.id,
              displayName: pet.name,
              breedId: pet.breedId,
              // weight is always required for groom, so we can not check for restrictions
              weight: parseInt(pet.weight),
            },
          ],
        })
      })
    } else {
      rc.push({
        locationSpecies: group.specieId,
        petCount: group.pets.length,
        pets: group.pets.map(
          ({ id, name, breedId, weight }) =>
            ({
              petId: id.length < 12 ? null : id,
              displayName: name,
              breedId,
              weight: wfs[group.specieId] ? parseInt(weight) : undefined,
            }) as TPet
        ),
        parentOffer: isAddOnSearch ? ctx.cart?.groups?.[index]?.offerSelected?.offer?.id : undefined,
      })
    }
  })
  return rc
}

export const buildPetGroupsTempered = (ctx: TBookingContext): TShowOffersResultsPetGroup[] => {
  return ctx.cart.groups.reduce((acc, group) => {
    if (group.specieName !== 'dog') {
      return acc
    }
    const petParams = group.pets.map((p) => {
      const data = {
        breedId: p.breedId || '',
        displayName: p.name,
        petId: p.id,
      } as Record<string, unknown>
      const wgh = parseInteger(p.weight, -1)
      if (wgh > 0) {
        data.weight = wgh
      }
      return data
    })

    acc[0] =
      acc[0] ||
      ({
        locationSpecies: group.specieId,
        petCount: petParams.length,
        pets: petParams as unknown as TPet[],
      } as TShowOffersResultsPetGroup)

    return acc
  }, [] as TShowOffersResultsPetGroup[])
}
