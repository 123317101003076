import { generatePassword } from '@shared/common'
import { i18WithDefault as t } from '@shared/locales'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { EnterEmailForm } from './enter_email_form'
import { VerifyEmailForm } from './verify_email_form'
import { DataLayerArgs, TagManagerArgs } from 'react-gtm-module'

export type TFormikValues = {
  email: string
  inputCode: boolean
  code: string
}

export type ChallengeResponseType = {
  username: string
  answer: string
}

type SignInTooltipContentProps = {
  onError?: (error: Error) => void
  onSuccessfulSignIn?: () => void
  onFailedSignIn?: () => void
  simpleView?: boolean
  onChange?: (value: string) => void
  continueLabel?: string
  showSignUpForm?: boolean
  onCodeRequested?: () => void
  onUseDifferentEmail?: () => void
  registerIfNeeded?: boolean
  onRegistering?: () => void
  onSuccessfulRegistration?: () => void
  disableSubmit?: boolean
  newUserEmail?: string
  TagManager?: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
}

export const SignInTooltipContent = ({
  onError,
  onSuccessfulSignIn,
  onFailedSignIn,
  simpleView = false,
  onChange,
  onCodeRequested,
  onUseDifferentEmail,
  continueLabel,
  showSignUpForm = false,
  registerIfNeeded = false,
  onRegistering,
  onSuccessfulRegistration,
  disableSubmit,
  newUserEmail,
  TagManager,
}: SignInTooltipContentProps) => {
  const [showVerifyForm, setShowVerifyForm] = React.useState(false)
  const [cognitoUser, setCognitoUser] = React.useState(null)
  const [isNewUser, setIsNewUser] = React.useState(false)

  const formik = useFormik<TFormikValues>({
    initialValues: {
      email: newUserEmail || '',
      inputCode: false,
      code: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('global.label.invalidEmail')).required(t('global.label.required')),
      inputCode: Yup.boolean(),
      code: Yup.string().when('inputCode', {
        is: true,
        then: Yup.string().length(6, t('global.label.invalidCode')).required(t('global.label.required')),
      }),
    }),
    onSubmit: ({ email, code }, { setSubmitting }) => {
      if (email) {
        if (!code && !showSignUpForm) {
          TagManager?.dataLayer({
            dataLayer: {
              event: 'LOGIN_EMAIL',
            },
          })
          Auth.signIn(email.toLowerCase().trim())
            .then((result) => {
              setCognitoUser(result)
              setShowVerifyForm(true)
              onCodeRequested?.()
              formik.setFieldValue('inputCode', true)
              formik.setTouched({ code: false })
            })
            .catch((_error) => {
              if (registerIfNeeded) {
                onRegistering?.()
                Auth.signUp({
                  username: email.toLowerCase().trim(),
                  password: generatePassword(),
                  attributes: {
                    email: email.toLowerCase().trim(),
                  },
                  autoSignIn: {
                    enabled: true,
                  },
                })
                  .then((_result) => {
                    setIsNewUser(true)
                    setShowVerifyForm(true)
                    onCodeRequested?.()
                    onSuccessfulRegistration?.()
                    formik.setFieldValue('inputCode', true)
                    formik.setTouched({ code: false })
                  })
                  .catch((_error) => {
                    onFailedSignIn?.()
                  })
                  .finally(() => setSubmitting(false))
              } else {
                onFailedSignIn?.()
              }
            })
            .finally(() => setSubmitting(false))
        } else if (cognitoUser) {
          TagManager?.dataLayer({
            dataLayer: {
              event: 'LOGIN_CODE',
            },
          })
          Auth.sendCustomChallengeAnswer(cognitoUser, code.replace(/\s/g, ''))
            .then((result) => {
              if (!result.signInUserSession) {
                formik.setFieldError('code', t('global.label.wrongCode'))
              } else {
                onSuccessfulSignIn?.()
                TagManager?.dataLayer({
                  dataLayer: {
                    event: 'login',
                  },
                })
              }
            })
            .catch((error) => {
              formik.setFieldError('code', t('global.verifyCodeError'))
              onError?.(error)
            })
            .finally(() => setSubmitting(false))
        } else if (showSignUpForm && !isNewUser) {
          Auth.signUp({
            username: email.toLowerCase().trim(),
            password: generatePassword(),
            attributes: {
              email: email.toLowerCase().trim(),
            },
            autoSignIn: {
              enabled: true,
            },
          })
            .then((_result) => {
              setIsNewUser(true)
              setShowVerifyForm(true)
              onCodeRequested?.()
              formik.setFieldValue('inputCode', true)
              formik.setTouched({ code: false })
            })
            .catch((_error) => {
              onFailedSignIn?.()
            })
            .finally(() => setSubmitting(false))
        } else if (isNewUser) {
          TagManager?.dataLayer({
            dataLayer: {
              event: 'LOGIN_CODE',
            },
          })
          Auth.confirmSignUp(email.toLowerCase().trim(), code)
            .then((_result) => {
              onSuccessfulSignIn?.()
              onCodeRequested?.()
              TagManager?.dataLayer({
                dataLayer: {
                  event: 'sign_up',
                },
              })
            })
            .catch((error) => {
              formik.setFieldError('code', t('global.verifyCodeError'))
              onError?.(error)
            })
            .finally(() => setSubmitting(false))
        }
      }
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {showVerifyForm ? (
          <VerifyEmailForm
            formik={formik}
            setShowVerifyForm={setShowVerifyForm}
            setCognitoUser={setCognitoUser}
            simpleView={simpleView}
            isNewUser={isNewUser}
            onUseDifferentEmail={onUseDifferentEmail}
          />
        ) : (
          <EnterEmailForm
            formik={formik}
            simpleView={simpleView}
            onChange={onChange}
            continueLabel={continueLabel}
            disableSubmit={disableSubmit}
          />
        )}
      </form>
    </>
  )
}

export default SignInTooltipContent
