import { getAPIPath, requestGooseAPI } from '../shared/request'
import {
  APIInvoiceAdminDtoResponse,
  CancelInvoiceInput,
  ChildInvoiceCreateInput,
  CustomInvoiceAdminDto,
  CustomOrderAdminDto,
  InvoiceAdminDto,
  InvoiceCreateInput,
  InvoiceUpdateInput,
  OrderAdminDto,
  TInvoiceListOptions,
} from '../types'
import {
  ADMIN_CANCEL_INVOICE_PATH,
  ADMIN_INVOICE_ID_PATH,
  ADMIN_INVOICE_PATH,
  ADMIN_LOCATION_INVOICES_ID_PATH,
  ADMIN_LOCATION_INVOICES_PATH,
} from '../types/constants'

const getListBaseIncludes = [
  'activities.unitActivityRelations.resourceUnit',
  'location',
  'period',
  'order.pets.locationPetProfile.breed',
  'order.pets.locationPetProfile.profileImage',
  'order.pets.locationPetProfile.vaccinations',
  'order.locationUserProfile.agreements',
  'order.pets.locationSpecies.species',
  'order.invoices.items.locationServiceType',
  'order.payments.transactions',
  'order.locationUserProfile.associatedContacts',
  'order.locationUserProfile.tagRelations.tag',
  'order.primaryServiceType.serviceType',
  'activities.petActivityRelations',
  'locationServiceType.serviceType',
  'items.petRelations.invoicePet.locationPetProfile.profileImage.asset',
  'items.petRelations.invoicePet.locationPetProfile.tags.tag',
  'instructions.tags.tag',
]

export const AdminInvoiceService = {
  cancelInvoice: (
    locationId: string,
    orderId: string,
    invoiceId: string,
    data: CancelInvoiceInput = {}
  ): Promise<OrderAdminDto> => {
    return requestGooseAPI<OrderAdminDto>({
      url: getAPIPath(ADMIN_CANCEL_INVOICE_PATH, { locationId, orderId, invoiceId }),
      method: 'PUT',
      data,
    })
  },

  get: (locationId: string, invoiceId: string): Promise<CustomInvoiceAdminDto> => {
    return requestGooseAPI<CustomInvoiceAdminDto>({
      url: getAPIPath(ADMIN_LOCATION_INVOICES_ID_PATH, { locationId, invoiceId }),
      method: 'GET',
      params: {
        includes: [
          'activities.unitActivityRelations.resourceUnit',
          'activities.petActivityRelations',
          'instructions.tags.tag',
          'notes',
          'location',
          'locationServiceType.serviceType',
          'items.petRelations.invoicePet.pet',
          'items.petRelations.invoicePet.data',
          'items.petRelations.invoicePet.locationPetProfile.profileImage.asset',
          'items.petRelations.invoicePet.locationPetProfile.tags.tag',
          'period',
          'resourceUsages',
          'order.payments.transactions',
          'order.pets.attachments.asset',
          'order.pets.locationPetProfile.breed',
          'order.pets.locationPetProfile.petInstructions.consumables',
          'order.pets.locationSpecies.species',
          'order.pets.breed',
          'order.locationUserProfile.associatedContacts',
          'order.locationUserProfile.agreements',
          'order.primaryServiceType.serviceType',
          'order.userData',
          'order.invoices.locationServiceType.serviceType',
          'order.invoices.items.petRelations',
          'order.invoices.period',
        ],
      },
    })
  },

  getList: (locationId: string, options: TInvoiceListOptions): Promise<APIInvoiceAdminDtoResponse> => {
    const { includes = [], notUseDefaultIncludes, ...rest } = options
    const includesList = notUseDefaultIncludes ? new Set() : new Set(getListBaseIncludes)
    includes.forEach((i) => includesList.add(i))
    const includeShared = options.hasSharedCRM

    if (options.hasSharedCRM) {
      includesList.add('location.delegateLocation')
    }

    return requestGooseAPI<APIInvoiceAdminDtoResponse>({
      url: getAPIPath(ADMIN_LOCATION_INVOICES_PATH, { locationId }),
      method: 'GET',
      params: {
        ...rest,
        includes: [...includesList],
        includeShared,
      },
    })
  },

  updateInvoice: (
    locationId: string,
    orderId: string,
    invoiceId: string,
    data: InvoiceUpdateInput,
    includes: string[] = []
  ): Promise<CustomOrderAdminDto> => {
    return requestGooseAPI<CustomOrderAdminDto>({
      url: getAPIPath(ADMIN_INVOICE_ID_PATH, { locationId, orderId, invoiceId }),
      method: 'PUT',
      params: {
        includes,
      },
      data,
    })
  },

  getServiceTypePetsHistory: (
    locationId: string,
    serviceTypeId: string,
    locationPetProfileIds: string[]
  ): Promise<APIInvoiceAdminDtoResponse[]> => {
    const promises = locationPetProfileIds.map((petId) => {
      return requestGooseAPI<APIInvoiceAdminDtoResponse>({
        url: getAPIPath(ADMIN_LOCATION_INVOICES_PATH, { locationId }),
        params: {
          sortOrder: 'desc',
          limit: 20,
          'invoiceSubStatus[]': 'SCHEDULED',
          'locationServiceType.serviceTypeId': serviceTypeId,
          'items.petRelations.invoicePet.locationPetProfileId': petId,
          includes: [
            'items.resourceUsages.resource',
            'items.petRelations.invoicePet.pet',
            'items.petRelations.invoicePet.locationPetProfile',
            'period',
            'instructions',
          ],
        },
      })
    })

    return Promise.all(promises)
  },

  createEmptyInvoice: (
    locationName: string,
    orderId: string,
    payload: InvoiceCreateInput
  ): Promise<InvoiceAdminDto> => {
    return requestGooseAPI<CustomInvoiceAdminDto>({
      url: getAPIPath(ADMIN_INVOICE_PATH, { locationId: locationName, orderId }),
      method: 'POST',
      data: payload,
    })
  },

  createChildInvoice: (
    locationName: string,
    orderId: string,
    invoiceId: string,
    payload: ChildInvoiceCreateInput
  ): Promise<InvoiceAdminDto> => {
    return requestGooseAPI<CustomOrderAdminDto>({
      url: getAPIPath(ADMIN_INVOICE_ID_PATH, { locationId: locationName, orderId, invoiceId }),
      method: 'POST',
      data: payload,
    })
  },
}
