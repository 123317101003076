// 5 minutes
export const DEFAULT_CACHE_TIME = 300000

export const defaultQueryOptions = {
  staleTime: 0,
  cacheTime: 0,
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  retry: false,
}
