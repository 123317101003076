import { isEmpty } from '@shared/common'
import { Types } from 'service-api'

export const getGroupMinimumPrice = (
  availabilityGroups: Types.AvailabilityGroupClientDto[][] | undefined
): Types.AvailabilityGroupClientDto => {
  if (!availabilityGroups || availabilityGroups.length == 0 || availabilityGroups[0].length == 0) {
    return {}
  }

  return availabilityGroups.reduce(
    (acc, group) => {
      const offerGroup = group[0].availabilities![0]!
      const accGroup = acc.availabilities![0]!.price!.value

      if (!offerGroup.available || isEmpty(offerGroup.price?.value)) {
        return acc
      }
      return accGroup === undefined || offerGroup.price!.value! < accGroup ? group[0] : acc
    },
    { availabilities: [{ price: { value: undefined } }] } as Types.AvailabilityGroupClientDto
  )
}

export const getBaseGroup = (
  serviceTypeName: string,
  petGroup: number,
  availabilityGroups: Types.AvailabilityGroupClientDto[] | Types.AvailabilityGroupClientDto[][] | undefined
): Types.AvailabilityGroupClientDto => {
  if (!availabilityGroups) {
    return {}
  }

  if (serviceTypeName === 'boarding') {
    return (availabilityGroups as Types.AvailabilityGroupClientDto[])?.[petGroup] || {}
  }

  return getGroupMinimumPrice(availabilityGroups as Types.AvailabilityGroupClientDto[][])
}

export const isDaycareOfferUnavailable = (availabilityGroups: Types.AvailabilityGroupClientDto[][] | undefined) => {
  let allDaysUnavailable = true,
    atLeastOneUnavailableReasonApplies = false
  availabilityGroups?.forEach((group) => {
    const availability = group?.[0]?.availabilities?.[0]
    if (availability?.available) {
      allDaysUnavailable = false
    }
    if (
      ['WEIGHT', 'TOTAL_WEIGHT', 'PET_COUNT', 'SALES_CHANNEL'].includes(availability?.unavailableReason?.type || '') ||
      (availability?.unavailableReason?.type === 'TEMPER_TEST' &&
        availability.condition?.reason?.type !== 'TEMPER_TEST')
    ) {
      atLeastOneUnavailableReasonApplies = true
    }
  })
  return allDaysUnavailable && atLeastOneUnavailableReasonApplies
}
