import { useQuery } from '@tanstack/react-query'
import { ClientLocationSpeciesService } from '../services'
import { APILocationSpeciesClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'

export const useClientLocationSpecies = (locationName: string, includeBreeds = false) => {
  return useQuery<APILocationSpeciesClientDtoResponse>({
    queryKey: ['clientLocationSpecies', locationName],
    queryFn: () => ClientLocationSpeciesService.getSpecies(locationName, includeBreeds),
    ...defaultQueryOptions,
  })
}
