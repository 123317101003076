import { Fragment } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { PetAvatarBooking } from '@components/pet_avatar/pet_avatar'
import { ErrorMessages } from '../pet_search_simple/error_messages'
import { PetThresholds } from '../pet_search_simple/pet_thresholds'
import { RoomSelector } from './room_selector'
import { Spacer2, capitalize } from 'components-ui'
import { pl, i18WithDefault as t } from '@shared/locale'
import { useBookingContext } from '@providers/booking'
import { TBookingPet } from '@providers/booking/types'

export const PetSelector = ({
  onEditAction,
  addNewPetAction,
}: {
  onEditAction: (pet: TBookingPet, groupIdx: number, petIdx: number) => void
  addNewPetAction: () => void
}) => {
  const bookingCtx = useBookingContext()
  const petGroups = bookingCtx.state.groups

  const onValidateForm = () => {
    bookingCtx.dispatch({ type: 'SET_DONE_FORM_VALIDATION' })
  }

  const groupPetsCount = petGroups.reduce((sum, group) => (sum += group.pets.length), 0)
  const showNoPetsTitle = groupPetsCount === 0 || petGroups.length === 0
  return (
    <Stack direction="column" justifyContent="center" p={2}>
      {showNoPetsTitle && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('searchBar.noPets.title')}
        </Typography>
      )}
      {!showNoPetsTitle &&
        petGroups.map((group, groupIdx) => {
          return (
            <Fragment key={group.id}>
              <Typography variant="h6">{pl(capitalize(group.specieName), 2, false)}</Typography>
              <PetThresholds specieId={group.specieId} />
              <Spacer2 />
              <ErrorMessages groupIdx={groupIdx} />
              {group.pets.map((pet, petIdx) => {
                return (
                  <Box key={`${pet.id}_${petIdx}`} sx={{ mb: 2 }}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems="center">
                      <PetAvatarBooking
                        pet={pet}
                        subheaderAction={() => {
                          onEditAction?.(pet, groupIdx, petIdx)
                        }}
                        subheaderActionText={t('global.label.edit')}
                      />
                      <RoomSelector
                        id={group.id}
                        groupIdx={groupIdx}
                        petIdx={petIdx}
                        prefix={capitalize(group.specieName)}
                      />
                    </Stack>
                  </Box>
                )
              })}
            </Fragment>
          )
        })}
      <Divider />
      <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
        <Button variant="outlined" onClick={() => addNewPetAction?.()} startIcon={<AddIcon fontSize="small" />}>
          {t('global.label.add')}
        </Button>
        <Button variant="text" onClick={onValidateForm}>
          {t('global.label.done')}
        </Button>
      </Stack>
    </Stack>
  )
}
