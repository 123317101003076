import { useQuery } from '@tanstack/react-query'
import { ClientOrderService } from '../services'
import { OrderClientDto } from '../types'
import { defaultQueryOptions } from './common'
import { AxiosError } from 'axios'

export const useClientOrder = (locationName: string, orderId: string) => {
  return useQuery<OrderClientDto, AxiosError<{ error: string }>>({
    queryKey: ['clientOrder', locationName, orderId],
    queryFn: () => ClientOrderService.getOrder(locationName, orderId),
    ...defaultQueryOptions,
  })
}
