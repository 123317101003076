import * as yup from 'yup'
import { i18WithDefault as t } from '../shared/locales'

export const confirmResetPassword = yup.object({
  code: yup.string().required('Code is required'),
  password: yup.string().min(8, t('password.minLength.error')).required(t('password.required.error')),
  newPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], t('password.matching.error'))
    .required(t('password.required.error')),
})
