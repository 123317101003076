import { Booking } from '@views/booking'
import { HomePage } from '@views/home_page'
import { SignIn } from '@views/signin'
import { SearchOffers } from '@views/search_offers/search_offers'
import { SearchRoomAddOns } from '@views/search_room_addons/search_room_addons'
import { SearchPetAddOns } from '@views/search_pet_addons/search_pet_addons'
import { PurchaseHistory } from '@views/purchase_history'
import { PurchaseSummary } from '@views/purchase_summary'
import { DatesSelection } from '@views/dates_selection'
import { TemperTest } from '@views/temper_test'
import { VerificationModal } from '@views/details_policies/verification_modal'
import { DetailsPolicies } from '@views/details_policies'
import { Payment } from '@views/payment'
import { BookingConfirmation } from '@views/booking_confirmation'
import { ManageAccount } from '@views/manage_account'
import { PrivacyPolicy } from '@views/privacy_policy/privacy_policy'

import type { TRoutesOpt, TRouteNames } from './app.d'
import { RedirectWithParams } from 'components-ui'
import { NotFound } from '@views/not_found'

export const routes = {
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-[ GENERAL ]
  homePage: {
    uid: '',
    title: 'Home Page',
    path: '/',
    component: HomePage,
    section: 'pages',
    preResolve: true,
    authenticated: false,
  },
  locationHome: {
    uid: '',
    title: '',
    path: '/:accountName',
    component: () => <RedirectWithParams to={(params) => `/${params.accountName}/search/boarding/pets`} />,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  signIn: {
    uid: '',
    title: 'Sign In',
    path: '/:accountName/signin',
    component: SignIn,
    section: 'noHeaderPages',
    preResolve: true,
    authenticated: false,
  },
  searchServiceType: {
    uid: '',
    title: 'Booking Home',
    path: '/:accountName/search/:serviceTypeName/pets',
    component: Booking,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  searchOffersResults: {
    uid: '',
    title: 'Offers Search Results',
    path: '/:accountName/search/:serviceTypeName/offers',
    component: SearchOffers,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  searchRoomAddOns: {
    uid: '',
    title: 'Offer Room AddOns',
    path: '/:accountName/search/:serviceTypeName/room-addons',
    component: SearchRoomAddOns,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  searchPetAddOns: {
    uid: '',
    title: 'Offer Pet AddOns',
    path: '/:accountName/search/:serviceTypeName/pet-addons',
    component: SearchPetAddOns,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  loginCheck: {
    uid: '',
    title: 'Pet Owner Verification',
    path: '/:accountName/search/:serviceTypeName/verification',
    component: VerificationModal,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  detailsPolicies: {
    uid: '',
    title: 'DetailsAndPolicies',
    path: '/:accountName/booking/details-policies',
    component: DetailsPolicies,
    section: 'offers',
    preResolve: false,
    authenticated: true,
  },
  purchaseHistory: {
    uid: '',
    title: 'Purchase History',
    path: '/:accountName/account/bookings/purchases',
    component: PurchaseHistory,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  purchaseSummary: {
    uid: '',
    title: 'Purchase Summary',
    path: '/:accountName/account/bookings/:bookingId',
    component: PurchaseSummary,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  purchaseHistory_legacy: {
    uid: '',
    title: 'Purchase History',
    path: `/kennels/:accountName/bookings`,
    component: () => <RedirectWithParams to={(params) => `/${params.accountName}/account/bookings/purchases`} />,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  purchaseSummary_legacy: {
    uid: '',
    title: 'Purchase Summary',
    path: `/kennels/:accountName/bookings/:bookingId/:shortId`,
    component: () => (
      <RedirectWithParams to={(params) => `/${params.accountName}/account/bookings/${params.bookingId}`} />
    ),
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  purchaseSummaryCancel_legacy: {
    uid: '',
    title: 'Purchase Summary',
    path: `/kennels/:accountName/cancel-reservation/:bookingId`,
    component: () => (
      <RedirectWithParams to={(params) => `/${params.accountName}/account/bookings/${params.bookingId}`} />
    ),
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  rest_legacy: {
    uid: '',
    title: '',
    path: `/kennels/:accountName/*`,
    component: () => <RedirectWithParams to={(params) => `/${params.accountName}`} />,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  datesSelection: {
    uid: '',
    title: 'Dates Selection',
    path: '/:accountName/search/:serviceTypeName/dates',
    component: DatesSelection,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  temperTest: {
    uid: '',
    title: 'Temperamental Test',
    path: '/:accountName/search/:serviceTypeName/temper-test',
    component: TemperTest,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  paymentForm: {
    uid: '',
    title: 'Payment',
    path: '/:accountName/booking/payment',
    component: Payment,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  paymentSummary: {
    uid: '',
    title: 'Payment Summary',
    path: '/:accountName/booking/confirmation/:orderId',
    component: BookingConfirmation,
    section: 'offers',
    preResolve: false,
    authenticated: false,
  },
  manageAccount: {
    uid: '',
    title: 'Manage Account',
    path: '/:accountName/account/overview',
    component: ManageAccount,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  privacyPolicy: {
    uid: '',
    title: 'Privacy Policy',
    path: '/:accountName/privacy-policy',
    component: PrivacyPolicy,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
  notFound: {
    uid: '',
    title: 'Not Found',
    path: '/:accountName/not-found',
    component: NotFound,
    section: 'pages',
    preResolve: false,
    authenticated: false,
  },
}

export const routeKeys = Object.keys(routes as TRoutesOpt) as TRouteNames[]
export const pageKeys = routeKeys.filter((k) => routes[k].section === 'pages')
export const noHeaderPageKeys = routeKeys.filter((k) => routes[k].section === 'noHeaderPages')
export const offerKeys = routeKeys.filter((k) => routes[k].section === 'offers')

// sets uid as the name of the route
routeKeys.forEach((k) => (routes[k].uid = k))
