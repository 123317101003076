import { add, addDays, format, formatISO, isValid, parse } from 'date-fns'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { Types } from 'service-api'
import { parseInteger } from './common'
import type { TComparatorCB, TDaysOfWeek, THours } from './dates.d'
import { i18WithParams as t } from './locales'

const START_TIME = '00:00'

const END_TIME = '23:59'

export const DOW = {
  length: 7,
  shortUppercaseArr: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  shortLowercaseArr: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
  shortCapitalizeStr: 'Sun, Mon, Tue, Wed, Thu, Fri, Sat',
  getIndex: (dow: string) => DOW.shortUppercaseArr.findIndex((d) => d === dow?.toUpperCase()),
  getDow: (date?: Date | null) => DOW.shortUppercaseArr[date?.getDay() ?? 1],
  geti18nDayName: (dow: TDaysOfWeek) => t(`global.label.${dow}`),
  upperCaseSort: (a: TDaysOfWeek, b: TDaysOfWeek): number =>
    DOW.shortUppercaseArr.indexOf(a) - DOW.shortUppercaseArr.indexOf(b),
} as const

export const DEFAULT_TIMEZONE = 'America/Chicago'

export const getTzDateInUtc = (date?: string | null, time?: string | null, timezone?: string | null) => {
  if (!date) return new Date(0)
  const fullDate = time ? `${date}T${time}` : date
  return zonedTimeToUtc(fullDate, timezone || DEFAULT_TIMEZONE)
}

export const isSameDayInTZ = (dateA: Date, dateB: Date, timezone?: string | null) =>
  formatInTimeZone(dateA, timezone || DEFAULT_TIMEZONE, 'yyyy-MM-dd') ===
  formatInTimeZone(dateB, timezone || DEFAULT_TIMEZONE, 'yyyy-MM-dd')

export const isTodayInTZ = (date: Date, timezone?: string | null) => isSameDayInTZ(new Date(), date, timezone)

export const isoTodayTZ = (timezone?: string | null) =>
  formatInTimeZone(new Date(), timezone || DEFAULT_TIMEZONE, 'yyyy-MM-dd')

export const completeISODate = (isoDate: string) => {
  return `${isoDate}T12:30:00Z`
}

// js Date => 2023-04-28
export const toISODate = (date?: Date) => {
  if (!isValid(date)) return ''
  return formatISO(date!, { representation: 'date' })
}

// js Date => 13:30
export const toISOTime = (date?: Date) => {
  if (!isValid(date)) return ''
  const isoTimeParts = formatISO(date!, { representation: 'time' }).split(':')
  return `${isoTimeParts[0]}:${isoTimeParts[1]}`
}

// when try to convert a ISO date like '2022-03-02'
// the browser will set time to 00:00:00 and TZ to UTC
// but when the date is used will convert it to local TZ and
// all times to the west of Greenwich will be one day before.
//
// this function wil use 12:00:00 to compensate the TZ,
// not ideal but a working solution.
//
// 2022-03-02 => 2022-03-02T12:00:00Z => js Date
//
export const dayToJSDate = (value?: string | null): Date | undefined => {
  const rhsUTC = 'T12:00:00Z'

  if (!value) {
    return
  }
  const newDate = new Date(Date.parse(`${value}${rhsUTC}`))
  if (newDate.toString() === 'Invalid Date') {
    return
  }
  return newDate
}

export interface TDiffDOW {
  toAdd: TDaysOfWeek[]
  toRemove: TDaysOfWeek[]
  toUpdate: TDaysOfWeek[]
}
// Helper function to calculate the diff between the DOW stored on the database
// and the new selection from the user.
// The outcome will be an object with the DOWs to add and remove on the database
// The toUpdate is because the Period holds the date range and the user could change it too
// so we need to update those records with the date range
export const diffDOW = (storedDOW: TDaysOfWeek[], newDOW: TDaysOfWeek[]): TDiffDOW => {
  const toUpdate = new Set<string>()

  // If the user selected all days, we need to remove all days
  if (newDOW.length === DOW.length) {
    return {
      toRemove: storedDOW,
      toAdd: [],
      toUpdate: [],
    }
  }

  // What is stored that the user doesn't selected
  const toRemove = storedDOW.reduce((acc, d) => {
    if (!newDOW.includes(d)) {
      acc.push(d)
    } else {
      toUpdate.add(d)
    }
    return acc
  }, [] as TDaysOfWeek[])

  // What the user selected that is not stored
  const toAdd = newDOW.reduce((acc, d) => {
    if (!storedDOW.includes(d)) {
      acc.push(d)
    } else {
      toUpdate.add(d)
    }
    return acc
  }, [] as TDaysOfWeek[])

  return {
    toRemove,
    toAdd,
    toUpdate: Array.from(toUpdate) as TDaysOfWeek[],
  }
}

export const formatDate = (
  date?: Date,
  locale = 'en-US',
  options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }
): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return ''
  }
  return new Intl.DateTimeFormat(locale, options).format(date as Date)
}

export const formatDateShort = (date?: Date, locale = 'en-US'): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return ''
  }
  return formatDate(date, locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })
}

export const formatDateLong = (date?: Date, locale = 'en-US'): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return ''
  }
  return formatDate(date, locale, {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
  })
}

export const formatDateLongWithYear = (date?: Date, locale = 'en-US'): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return ''
  }
  return formatDate(date, locale, {
    weekday: 'short',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })
}

export const formatDateToday = (date?: Date, locale = 'en-US'): string => {
  if (!date || date.toString() === 'Invalid Date') {
    return ''
  }
  const dateFormatted = formatDate(date, locale, {
    day: '2-digit',
    month: 'long',
    year: undefined,
  })
  return `${t('global.label.today')}, ${dateFormatted}`
}

// Date format: 2022-03-02
// will not work with ISO DateTime format (2022-03-02T12:00:00Z)
export const isInRange = (date: string, periods?: Types.PeriodClientDto[]) => {
  if (!periods || !periods.length) {
    return true
  }
  if (date && (!isValid(new Date(date)) || date.length !== 10)) {
    return false
  }

  return periods.some(({ startDate, endDate, dayOfWeek }) => {
    let isInRange = true
    if (startDate && endDate) {
      isInRange = date >= startDate && date < endDate
    } else if (startDate) {
      isInRange = date >= startDate
    } else if (endDate) {
      isInRange = date < endDate
    }

    if (isInRange && dayOfWeek) {
      isInRange = new Date(`${date}T00:00:00.000`).getUTCDay() === DOW.getIndex(dayOfWeek)
    }

    return isInRange
  })
}

export const buildDateRangeComparator = (periodsToCheck?: Types.PeriodClientDto[]): TComparatorCB => {
  const periods = periodsToCheck ? periodsToCheck.map((period) => ({ ...period })) : []
  return (date: string): boolean => {
    return isInRange(date, periods)
  }
}

export const getHoursByDate = (date: string | Date, restrictions: Types.RestrictionClientDto[]) => {
  let hours: THours[] = []
  const dateStr = typeof date === 'string' ? date : format(date, 'yyyy-MM-dd')
  restrictions.some(({ available, periods }) => {
    if (isInRange(dateStr, periods)) {
      if (!available) {
        hours = []
        return true
      }

      if (!periods?.length) {
        hours.push({
          startTime: START_TIME,
          endTime: END_TIME,
        })
      } else {
        periods.forEach((period) => {
          if (isInRange(dateStr, [period])) {
            hours.push({
              startTime: period.startTime || START_TIME,
              endTime: period.endTime || END_TIME,
            })
          }
        })
      }
    }
    return false
  })

  hours.sort((a, b) => {
    if (a.startTime === b.startTime) {
      return a.endTime === b.endTime ? 0 : a.endTime < b.endTime ? -1 : 1
    }

    return a.startTime < b.startTime ? -1 : 1
  })

  // Merge overlapping hours
  return hours.reduce((acc: THours[], hour) => {
    if (!acc.length) {
      acc.push(hour)
    } else {
      const previousHour = acc[acc.length - 1]
      if (hour.startTime > previousHour.endTime) {
        acc.push(hour)
      } else if (hour.endTime > previousHour.endTime) {
        previousHour.endTime = hour.endTime
      }
    }

    return acc
  }, [])
}

export const formatAmPmDate = (date: Date, customFormat: string = 'hh:mmaaa') => {
  return format(date, customFormat)
}

export const formatAmPm = (time: string, customFormat: string = 'hh:mmaaa') => {
  return formatAmPmDate(new Date(`1970-01-01T${time}`), customFormat)
}

export const formatSchedule = (date?: Date, customFormat: string = 'hh:mmaaa') => {
  if (!date || date.toString() === 'Invalid Date') {
    return ''
  }
  const data = formatDate(date, 'en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  })
  const time = formatAmPmDate(date, customFormat)
  return t('global.label.scheduleTime', { date: data, time })
}

export const getDOWFromStr = (day: string) => DOW.shortUppercaseArr[parse(day, 'yyyy-MM-dd', new Date()).getDay()]

export const isInvalidDate = (date?: Date | null) => {
  if (!date) return true
  return date.toString() === 'Invalid Date'
}

export const isoToSlashDate = (dateString: string) => {
  const dateComponents = (dateString || '').split('-')
  if (dateComponents.length !== 3 || !isValid(new Date(dateString))) {
    return '-'
  }
  const [year, month, day] = dateComponents
  return `${month}/${day}/${year}`
}

// Format a date range as
// - "Jan 21 - 23" if the start and end dates are in the same month
// - "Jan 21 - Feb 23" if the start and end dates are in different months
export const formatDateRange = (startDate?: Date, endDate?: Date, locale: string | undefined = undefined) => {
  if (!startDate || !endDate) return ''
  if (!isValid(startDate) || !isValid(endDate)) return ''
  if (startDate > endDate) return ''
  const options = {
    day: 'numeric',
    month: 'short',
  } as Intl.DateTimeFormatOptions

  const formatter = new Intl.DateTimeFormat(locale, options)

  if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
    return `${formatter.format(startDate)} - ${endDate.getDate()}`
  } else {
    return `${formatter.format(startDate)} - ${formatter.format(endDate)}`
  }
}

export const millisecondsToDays = (ms: number) => {
  return ms / 1000 / 60 / 60 / 24
}

export const formatToTimeRange = (start?: Date, end?: Date) => {
  if (!start || !end || !isValid(start) || !isValid(end)) return ''
  return `${format(start, 'h:mm')} - ${format(end, 'h:mmaaa')}`
}

export const getFullDayQuery = (date: Date, timezone: string) => {
  if (isValid(date)) {
    const today = zonedTimeToUtc(date, timezone)?.toISOString()?.substring(0, 19)
    const tomorrow = zonedTimeToUtc(addDays(date, 1), timezone)?.toISOString().substring(0, 19)
    return `gte_${today}_lt_${tomorrow}`
  }
}

export const dateTimeToPeriod = (date: Date, duration: number) => {
  if (!date || date.toString() === 'Invalid Date' || !duration || parseInteger(String(duration), -1) < 0) {
    return null
  }

  const endDate = add(date, { days: 1 })
  const endTime = add(date, { minutes: duration })

  return {
    startDate: toISODate(date),
    endDate: toISODate(endDate),
    startTime: toISOTime(date),
    endTime: toISOTime(endTime),
  }
}

export const ISOTimeToDate = (time: string) => {
  return new Date(`1970-01-01T${time}`)
}

export const ISOTimeToCurrentDate = (date: Date, time: string) => {
  const dateStr = format(date, 'yyyy-MM-dd')
  return new Date(`${dateStr}T${time}`)
}
