import { unstable_composeClasses as composeClasses, generateUtilityClass, generateUtilityClasses } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { SxProps, Theme, styled, useThemeProps } from '@mui/material/styles'
import * as React from 'react'
type HTMLDivProps = React.JSX.IntrinsicElements['div']

const DAY_SIZE = 36
const DAY_MARGIN = 2

export interface DayCalendarSkeletonClasses {
  /** Styles applied to the root element. */
  root: string
  /** Styles applied to the week element. */
  week: string
  /** Styles applied to the day element. */
  daySkeleton: string
}

export type DayCalendarSkeletonClassKey = keyof DayCalendarSkeletonClasses

export interface DayCalendarSkeletonProps extends HTMLDivProps {
  classes?: Partial<DayCalendarSkeletonClasses>
  sx?: SxProps<Theme>
  ref?: React.Ref<HTMLDivElement>
}

const useUtilityClasses = (ownerState: DayCalendarSkeletonProps) => {
  const { classes } = ownerState
  const slots = {
    root: ['root'],
    week: ['week'],
    daySkeleton: ['daySkeleton'],
  }

  return composeClasses(slots, getDayCalendarSkeletonUtilityClass, classes)
}

const getDayCalendarSkeletonUtilityClass = (slot: string) => generateUtilityClass('MuiDayCalendarSkeleton', slot)

const DayCalendarSkeletonClasses: DayCalendarSkeletonClasses = generateUtilityClasses('MuiDayCalendarSkeleton', [
  'root',
  'week',
  'daySkeleton',
])

const DayCalendarSkeletonRoot = styled('div', {
  name: 'MuiDayCalendarSkeleton',
  slot: 'Root',
  overridesResolver: (_props, styles) => styles.root,
})({
  alignSelf: 'start',
})

const DayCalendarSkeletonWeek = styled('div', {
  name: 'MuiDayCalendarSkeleton',
  slot: 'Week',
  overridesResolver: (_props, styles) => styles.week,
})({
  margin: `${DAY_MARGIN}px 0`,
  display: 'flex',
  justifyContent: 'center',
})

const DayCalendarSkeletonDay = styled(Skeleton, {
  name: 'MuiDayCalendarSkeleton',
  slot: 'DaySkeleton',
  overridesResolver: (_props, styles) => styles.daySkeleton,
})<{ ownerState: { day: number } }>(({ ownerState }) => ({
  margin: `0 ${DAY_MARGIN}px`,
  ...(ownerState.day === 0 && {
    visibility: 'hidden',
  }),
}))

const monthMap = [
  [0, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 1, 1],
  [1, 1, 1, 1, 0, 0, 0],
]

function DayCalendarSkeleton(inProps: DayCalendarSkeletonProps) {
  const props = useThemeProps({
    props: inProps,
    name: 'MuiDayCalendarSkeleton',
  })

  const { className, ...other } = props

  const classes = useUtilityClasses(other)

  return (
    <DayCalendarSkeletonRoot className="MuiDayCalendarSkeleton-root" {...other}>
      {monthMap.map((week, index) => (
        <DayCalendarSkeletonWeek key={index} className={classes.week}>
          {week.map((day, index2) => (
            <DayCalendarSkeletonDay
              key={index2}
              variant="circular"
              width={DAY_SIZE}
              height={DAY_SIZE}
              className={classes.daySkeleton}
              ownerState={{ day }}
            />
          ))}
        </DayCalendarSkeletonWeek>
      ))}
    </DayCalendarSkeletonRoot>
  )
}

export { DayCalendarSkeleton }
