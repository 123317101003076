import { CognitoUser } from '@aws-amplify/auth'
import { LoadingButton } from '@mui/lab'
import { Box, Link, TextField, Typography } from '@mui/material'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import { useState } from 'react'

import { PRIVACY_POLICY_URL, TOS_URL } from '../shared/constants'
import { i18WithDefault as t } from '../shared/locales'
import { ModalType } from './index.d'
import { logInSchema } from './log_in_schema'
import { SubmitError } from './submit_error'
import { sanitizeEmail } from './helper'

const initialValues = {
  email: '',
  password: '',
  acceptTerms: false,
}

interface Props {
  switchModalType: (modal: ModalType) => void
  saveUser: (user: CognitoUser | null) => void
}

export const LogInForm = ({ switchModalType, saveUser }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const signIn = async (email: string, password: string, setStatus: (status?: string) => void) => {
    setIsSubmitting(true)
    const sanitizedEmail = sanitizeEmail(email)
    try {
      await Auth.signIn(sanitizedEmail, password).then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          switchModalType(ModalType.changePassword)
          saveUser(user)
        }
      })
    } catch (err) {
      setStatus((err as Error).message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: logInSchema,
    onSubmit: async (values, { setStatus }) => {
      await signIn(values.email, values.password, setStatus)
    },
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit} data-testid="log-in-submit">
        <TextField
          fullWidth
          id="email"
          name="email"
          label={t('logInForm.emailField')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ marginBottom: 3 }}
          inputProps={{
            'data-testid': 'email',
          }}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label={t('logInForm.passwordField')}
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{ marginBottom: 3 }}
          inputProps={{
            'data-testid': 'password',
          }}
        />
        {formik !== undefined ? (
          <SubmitError value={formik.errors.acceptTerms ? formik.errors.acceptTerms : undefined} />
        ) : null}
        <SubmitError value={formik.status} />
        <LoadingButton variant="contained" size="large" fullWidth type="submit" sx={{ mb: 3 }} loading={isSubmitting}>
          {t('global.label.signIn')}
        </LoadingButton>
        <Box mb={3}>
          <Link href="#" onClick={() => switchModalType(ModalType.forgotPassword)}>
            {t('logInForm.forgotPassword')}
          </Link>
        </Box>
        <Box mb={3}>
          <Link href="#" onClick={() => switchModalType(ModalType.signUp)}>
            {t('loginForm.newAccount')}
          </Link>
        </Box>
        <Typography variant="body2" mb={1}>
          <strong>
            <span>{t('logInForm.disclaimer')}</span>{' '}
            <Link target="_blank" href={PRIVACY_POLICY_URL}>
              {t('logInForm.privacyPolicy')}
            </Link>
            <span>&nbsp;and&nbsp;</span>
            <Link target="_blank" href={TOS_URL}>
              {t('logInForm.tos')}
            </Link>
            .
          </strong>
        </Typography>
      </form>
    </div>
  )
}
