import { Delete } from '@mui/icons-material'
import { IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { buildErrorMessage } from '@shared/error'
import { i18WithParams as t } from '@shared/locale'
import { ButtonActions, capitalizeFirstLetter, SimpleModal, Spacer2, Spacer4 } from 'components-ui'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Services, Types } from 'service-api'
import { Card, CardContainer, CardsListContainer } from './common'
import { captureError } from 'service-api/src/shared'

const PaymentMethodCard = ({
  paymentMethod,
  onDelete,
}: {
  paymentMethod: Types.StoredPaymentMethodClientDto
  onDelete: (id: string) => Promise<void>
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDelete = async () => {
    await onDelete(paymentMethod.id!)
    setIsDeleteModalOpen(false)
  }
  return (
    <>
      <CardContainer key={paymentMethod.id}>
        <Card
          height={161}
          title={capitalizeFirstLetter(paymentMethod.name || paymentMethod.brand)}
          subheader={`*${paymentMethod.lastFour}`}
          action={
            <IconButton onClick={() => setIsDeleteModalOpen(true)}>
              <Delete />
            </IconButton>
          }
        >
          <Typography variant="body1">{paymentMethod.holderName}</Typography>
          <Typography variant="body1">
            {t('manageAccount.paymentMethod.expires', {
              date: `${paymentMethod.expiryMonth}/${paymentMethod.expiryYear}`,
            })}
          </Typography>
        </Card>
      </CardContainer>
      <SimpleModal
        sx={{ pl: 3, pr: 1, pt: 3, pb: 1, width: 444 }}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Typography variant="h6">{t('manageAccount.paymentMethod.delete.title')}</Typography>
        <Spacer4 />
        <Typography variant="body1">{t('manageAccount.paymentMethod.delete.lead')}</Typography>
        <Spacer2 />
        <Stack direction="row" justifyContent="flex-end">
          <ButtonActions
            onAction={handleDelete}
            onCancel={async () => setIsDeleteModalOpen(false)}
            actionEnabled={true}
            actionVariant="secondary"
            onCancelLabel={t('global.label.cancel')}
            onActionLabel={t('global.label.continue')}
          />
        </Stack>
      </SimpleModal>
    </>
  )
}

export const PaymentMethodCardList = ({
  storedPaymentMethods,
  isLoadingStoredPaymentMethods,
  onDelete,
}: {
  storedPaymentMethods: Types.StoredPaymentMethodClientDto[]
  isLoadingStoredPaymentMethods: boolean
  onDelete: (id: string) => void
}) => {
  const { accountName: locationName = '' } = useParams()

  if (isLoadingStoredPaymentMethods)
    return (
      <CardsListContainer>
        <Skeleton variant="rounded" width={349} height={222} />
        <Skeleton variant="rounded" width={349} height={222} />
      </CardsListContainer>
    )

  if (!storedPaymentMethods?.length) {
    return <Typography variant="body1">{t('manageAccount.paymentMethod.noSavedPayments')}</Typography>
  }

  const handleDelete = async (id: string) => {
    try {
      await Services.ClientStoredPaymentMethodsService.delete(locationName, id)
      enqueueSnackbar(t('manageAccount.paymentMethod.delete.success'), { variant: 'success' })
      onDelete(id)
    } catch (error) {
      enqueueSnackbar(buildErrorMessage(error), { variant: 'error' })
      captureError(error as Error)
    }
  }

  return (
    <>
      <CardsListContainer>
        {storedPaymentMethods.map((paymentMethod) => (
          <PaymentMethodCard key={paymentMethod.id} paymentMethod={paymentMethod} onDelete={handleDelete} />
        ))}
      </CardsListContainer>
    </>
  )
}
