/* eslint-disable max-len */
type TranslationKeys = Record<string, string>

export const enLocale: TranslationKeys = {
  'booking.roomAddOns.emptyMessage': 'There are no room upgrades available for {{petNames}}',
  'booking.petAddOns.emptyMessage': 'There are no individual perks available for {{petNames}}',
  'editAccountHolder.success': 'Account information was updated successfully',
  'intake.aboutYourPet': 'About Your Pet',
  'intake.sendNowInfoMsg': 'Look good? Send now! Otherwise, edit any details before sending.',
  'intake.pet.success': 'Information about your pet was submitted successfully.',
  'intake.emergencyContact': 'Emergency Contact Information',
  'intake.ownerInfo.success': 'Emergency Contact Information was submitted successfully.',
  'searchBar.rooms.title.boarding': 'Rooms and Pets',
  'searchBar.rooms.title.daycare': 'Pets',
  'searchBar.defaultSearchText': 'Enter details to search availability',
  'searchBar.errors.petWeightMax': 'Well, this is ruff. We cannot accommodate your pet at this time.',
  'searchBar.errors.petCountMax':
    'Uh oh! There are too many pets in one of the rooms. Please add these pets to separate rooms.',
  'searchBar.errors.petWeightGroup':
    'Uh oh! Together, these pets weigh too much for one room. Please add these pets to separate rooms.',
  'searchBar.errors.unselectedSpecie': 'Please select the type of pet',
  'searchBar.errors.required': 'Required',
  'searchBar.errors.maxWeight': 'max weight',
  'searchBar.errors.minWeight': 'Weight not valid',
  'searchBar.temperTest':
    'A {{temperTestName}} is required at least <b>{{leadTime}}</b> before attending {{serviceTypeName}}. You can schedule it with this booking.',
  'searchBar.newVisitor': "We're a new visitor",
  'searchBar.addPet': 'Add {{petName}}',
  'searchBar.actionPrompt.loginTitle': 'Let’s start with your email!',
  'searchBar.actionPrompt.loginText':
    'No need to type in all your pet info if you have booked with us before.  Enter your email and we’ll find your details!',
  'searchBar.actionPrompt.declineLogin': 'Skip For Now',
  'searchBar.actionPrompt.continueAsGuest': 'Continue as New Customer',
  'searchBar.actionPrompt.manualEnterTitle': 'New visitors are paw-some!  Tell us about your pet(s).',
  'searchBar.actionPrompt.unknownEmail':
    'Looks like you don’t have an online account set up. Continue as New Customer to set up an account.',
  'searchBar.actionPrompt.temperTestBanner.main':
    'For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchBar.actionPrompt.temperTestBanner.sub': 'To continue booking, <b>please call us at {{phoneNumber}}.</b>',
  'searchBar.thresholds.perRom': 'Per room',
  'searchBar.thresholds.maxPet': 'max pets {{maxPets}}',
  'searchBar.thresholds.maxPetWeight': 'max pet weight {{maxPetWeight}}',
  'searchBar.thresholds.maxRoomWeight': 'max room weight {{maxRoomWeight}}',
  'searchBar.noPets.title': `Looks like you don't have any pets saved yet. Add a pet to get started.`,
  'forcedLogin.enterEmail': 'Enter your email to continue',
  'forcedLogin.text': 'To continue booking, please enter your email.',
  'forcedLogin.exit': 'Cancel',
  'forcedLogin.register.title': 'Let’s get you set up to book with us.',
  'forcedLogin.register.info':
    'For your security, we sent a code to {{email}}. Please enter the code below. Verification codes are valid for 15 minutes.',
  'searchBar.petSelect.selectPets': 'Select Pets',
  'searchBar.petSelect.attending': 'Attending',
  'searchBar.petSelect.room': 'Room',
  'searchResults.title.boarding': 'Select your boarding offers',
  'searchResults.title.daycare': 'Select a daycare type',
  'searchResults.title.selectDates': 'Select daycare date(s)',
  'searchResults.warning.petFailed.main': 'Unable to Book: Previously, your pet has not passed a meet and greet.',
  'searchResults.warning.petFailed.sub': 'If behavior has changed, <b>please call us at {{phoneNumber}}.</b>',
  'searchResults.warning.tooSoonToBook.newPets.main':
    'For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchResults.warning.tooSoonToBook.newPets.sub':
    'This booking seems to be short notice, <b>please call us at {{phoneNumber}}.</b> We would love to accommodate you if we can.',
  'searchResults.warning.tooSoonToBook.existingPet.main':
    '{{petNames}} has not visited us before! For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchResults.warning.tooSoonToBook.existingPets.main':
    '{{petNames}} have not visited us before! For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchResults.warning.tooSoonToBook.existingPets.sub':
    'This booking seems to be short notice, <b>please call us at {{phoneNumber}}.</b> We would love to accommodate you if we can.',
  'searchResults.warning.allNonTestedPetsCannotBook.newPets.main':
    'For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchResults.warning.allNonTestedPetsCannotBook.newPets.sub':
    'To continue booking, <b>please call us at {{phoneNumber}}.</b>',
  'searchResults.warning.allNonTestedPetsCannotBook.existingPet.main':
    '{{petNames}} has not visited us before! For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchResults.warning.allNonTestedPetsCannotBook.existingPets.main':
    '{{petNames}} have not visited us before! For new pets, we require a {{temperTestName}} before their stay with us.',
  'searchResults.warning.allNonTestedPetsCannotBook.existingPets.sub':
    'To continue booking, <b>please call us at {{phoneNumber}}.</b>',
  'searchRoomAddOns.title': 'Upgrade your pets’ rooms daily!',
  'searchRoomAddOns.genericTitle': 'Other Room Add-Ons',
  'searchRoomAddOns.summary': 'All room extras are priced per day of stay & apply to all pets staying in the room.',
  'searchPetAddOns.title': 'Add individual perks for your pets',
  'searchPetAddOns.genericTitle': 'Other Pet Add-Ons',
  'searchPetAddOns.summary': 'A la carte add-ons are priced per instance.',
  'searchPetAddOns.grooming': 'Grooming',
  'addonItemPicker.addItem': 'Add 1 item',
  'addonItemPicker.removeItem': 'Remove 1 item',
  'addonDatePicker.calendarTitle': 'Select Dates',
  'addonDatePicker.summary': 'now booking through',
  'addonDatePicker.removeDay': 'remove day',
  'purchaseHistory.title': 'Purchase History',
  'purchaseHistory.emptyTable': 'No purchases found',
  'purchaseHistory.viewSummary': 'View Summary',
  'purchaseSummary.title': 'Purchase Summary',
  'purchaseSummary.notFound': "We didn't find anything to show. Did you mean to be logged in?",
  'purchaseSummary.notAuthorized': "It doesn't look like this record is yours. Return to purchase history?",
  'purchaseSummary.linkText': 'Purchase History',
  'temperTest.defaultName': 'Temperamental Test',
  'temperTest.title': 'Schedule a {{temperTestName}}',
  'temperTest.subtitle': '{{petNames}}’s {{temperTestName}}',
  'temperTest.byline':
    'Select a day and time. All dogs must pass a {{temperTestName}} before they can attend {{serviceTypeName}}.',
  'temperTest.timesAvailable': 'Times available on',
  'temperTest.information.title.singlePet':
    '{{petNames}} has not visited us before! We require a {{temperTestName}} any day before or on <b>{{date}}</b>.',
  'temperTest.information.title.multiplePets':
    '{{petNames}} have not visited us before! We require a {{temperTestName}} any day before or on <b>{{date}}</b>.',
  'temperTest.information.content': 'Please select your preferred date and time below.',
  'header.loggedInButton': 'My Account',
  'header.welcomeMsg': 'Been here before?',
  'loginMenu.manageAccount': 'Manage Account',
  'loginMenu.myBookings': 'My Bookings',
  'loginMenu.logOut': 'Log Out',
  'detailsPolicies.title': 'Details & Policies',
  'detailsPolicies.contactInformation': 'Contact Information',
  'detailsPolicies.vaccinationRecordsRequired': 'Upload Vaccination Records',
  'detailsPolicies.vaccinationRecordsOptional': 'Upload Vaccination Records (optional)',
  'detailsPolicies.vaccinationRecords.upload.success': 'Vaccination record(s) added .',
  'detailsPolicies.vaccinationRecords.upload.error': 'Some file(s) could not be uploaded.',
  'detailsPolicies.vaccinationRecords.deleted': 'Vaccination record deleted.',
  'detailsPolicies.vaccinationRecords.deletedError': 'Error deleting Vaccination record.',
  'detailsPolicies.vaccinationRecords.copy':
    'Drag and drop files here or {{upload}}. Supported images must be pdf, jpeg, or png and must be 10 MB or smaller',
  'detailsPolicies.veterinarian.title': 'Veterinarian',
  'detailsPolicies.veterinarian.subtitle': 'Provide contact information for your veterinarian in case of an emergency.',
  'detailsPolicies.facilityRulesPolicies': 'Facility Rules & Policies',
  'detailsPolicies.vaccinationPolicy': 'Vaccination Policy',
  'detailsPolicies.confirmation': 'By clicking “Agree & Continue” you agree to these {{termConditionsLink}}.',
  'detailsPolicies.tcAnchorText': 'Terms and Conditions',
  'detailsPolicies.messageConsent':
    'By providing your mobile phone number, you are giving your consent to receive calls and SMS/MMS messages to that number from {{facilityName}}. Message frequency varies. Message and data rates may apply. You can text HELP for help at any time. To stop receiving text messages from {{facilityName}} reply STOP. View {{terms}}',
  'detailsPolicies.continueButton': 'Agree & Continue',
  'detailsPolicies.requiredField': 'required field',
  'detailsPolicies.notEnoughOffers':
    'Oops! We had a problem fetching the selection for {{offerName}}. Please try again.',
  'detailsPolicies.terms': 'Messaging Terms & Privacy Policy.',
  'detailsPolicies.privacyPolicy': 'Privacy Policy',
  'contactForm.firstName': 'First Name',
  'contactForm.lastName': 'Last Name',
  'contactForm.email': 'Email',
  'contactForm.phone': 'Phone',
  'contactForm.streetLine1': 'Street Address',
  'contactForm.streetLine2': 'Street Line 2',
  'contactForm.city': 'City',
  'contactForm.state': 'State',
  'contactForm.zipCode': 'Zip',
  'contactForm.country': 'Country',
  'contactForm.mailingAddress': 'Mailing Address',
  'veterinarianInfoForm.vetName': 'Name',
  'veterinarianInfoForm.vetPhone': 'Phone Number',
  'specialInstructions.title': 'Special Instructions (optional)',
  'specialInstructions.subtitle': 'Enter any care details, e.g. receives 16mg Apoquel every morning.',
  'specialInstructions.careDetails': 'Care Details',
  'checkInOutForm.title': 'Arrival and Departure Time',
  'checkInOutForm.subtitle': 'Make your check in and check out processes smoother by giving us time to prepare!',
  'checkInOutForm.checkIn': 'Arrival',
  'checkInOutForm.checkOut': 'Departure',
  'checkInOutForm.warning': 'Fees may apply due to your arrival/departure times.',
  'checkInOutForm.duration': 'Each night allows a 24 hour stay. Exceeding this number of hours may incur a late fee.',
  'checkInOutForm.checkInTimes': 'Check In Hours',
  'checkInOutForm.checkOutTimes': 'Check Out Hours',
  'paymentForm.title': 'Payment',
  'paymentForm.method': 'Payment Method',
  'paymentForm.storePaymentMethod.msg':
    'By booking this reservation you agree to store your card with {{locationName}} for future use.',
  'paymentForm.options': 'Payment Options',
  'paymentForm.nameOnCard': 'Name on Card',
  'paymentForm.sameAsMailing': 'Same as Mailing Address',
  'paymentForm.cardNumber': 'Card Number',
  'paymentForm.expiration': 'Expiration',
  'paymentForm.cvv': 'CVV',
  'paymentForm.billingAddress': 'Billing Address',
  'paymentForm.useMailingAddress': 'Same as Mailing Address',
  'paymentForm.payNow': 'Pay Now',
  'paymentForm.prePaid': 'Use a pre-paid package',
  'paymentForm.dueAtFacility': 'Payment due at {{locationDisplayName}}',
  'paymentForm.cancellation_refund': 'Cancellation & Refund Policy',
  'paymentForm.orderExpired': 'Information about your booking may have changed, please try again.',
  'paymentForm.bookNow': 'Book Now',
  'paymentSummary.title': 'Thank you for booking!',
  'paymentSummary.subtitle': 'Receipt and Additional Details',
  'payments.error.general': 'Payment Failed',
  'payments.success': 'Payment Successful',
  'intake.confirmation.title': 'Make check-in easy by pre-registering now!',
  'intake.confirmation.content': 'Send us your pet’s care details for this visit.',
  'intake.confirmation.button': 'Pre-Register',
  'petForm.title.edit': 'Edit Pet',
  'petForm.title.add': 'Add Pet',
  'petForm.isAltered': 'Is the {{petSpecies}} spayed/neutered (optional)',
  'petForm.create.success': 'Pet was successfully added',
  'petForm.edit.success': 'Pet was successfully updated',
  'petForm.delete.success': 'Pet was successfully deleted',
  'title.startBooking': 'Start a Booking',
  'title.purchases': 'Purchases',
  'title.purchaseSummary': 'Purchase Summary',
  'title.accountOverview': 'My Account',
  'title.selectOffer': 'Select an Offer',
  'title.addRoom': 'Add Room Extras',
  'title.addPetPerks': 'Add Pet Perks',
  'title.scheduleEvaluation': 'Schedule Evaluation',
  'title.addDetail': 'Add Details & Review Policies',
  'title.addPayment': 'Add Payment',
  'title.bookingConfirmation': 'Booking Confirmation',
  'title.preRegister': 'Pre-Register',
  'global.label.Search': 'Search',
  'global.label.continue': 'Continue',
  'global.label.save': 'Save',
  'global.label.send': 'Send',
  'global.label.sent': 'Sent',
  'global.label.cancel': 'Cancel',
  'global.label.clearAllChanges': 'Clear All Changes',
  'global.label.room': 'room',
  'global.label.pet': 'pet',
  'global.label.to': 'to',
  'global.label.required': 'Required',
  'global.label.optional': 'Optional',
  'global.label.total': 'Total',
  'global.label.subtotal': 'Subtotal',
  'global.label.pets': 'Pets',
  'global.label.login': 'Sign In',
  'global.label.edit': 'Edit',
  'global.label.dates': 'Dates',
  'global.label.each': 'each',
  'global.label.add': 'Add',
  'global.label.added': 'Added',
  'global.label.back': 'Back',
  'global.label.next': 'Next',
  'global.label.open': 'open',
  'global.label.signIn': 'Sign In',
  'global.label.done': 'Done',
  'global.label.name': 'Name',
  'global.label.breed': 'Breed',
  'global.label.weight': 'Weight',
  'global.label.remove': 'Remove',
  'global.label.day': 'day',
  'global.label.night': 'night',
  'global.label.mybookings': 'My Bookings',
  'global.select.USA': 'United States of America',
  'global.label.purchaseDate': 'Purchase Date',
  'global.label.service': 'Service',
  'global.label.loading': 'Loading...',
  'global.label.dateOfBirth_optional': 'Date of Birth (optional)',
  'global.label.sex_optional': 'Sex (optional)',
  'global.label.yes': 'Yes',
  'global.label.no': 'No',
  'global.label.delete': 'Delete',
  'global.label.male': 'Male',
  'global.label.female': 'Female',
  'global.label.serviceDates': 'Service Dates',
  'global.label.amount': 'Amount',
  'global.label.actions': 'Actions',
  'global.label.streetLine1': 'Street Address',
  'global.label.streetLine2': 'Street Line 2',
  'global.label.city': 'City',
  'global.label.state': 'State',
  'global.label.zipCode': 'Zip Code',
  'global.label.country': 'Country',
  'global.label.many': 'Many',
  'global.label.N/A': 'N/A',
  'global.label.notStaying': 'Not Staying',
  'global.label.unexpectedError': 'Oops, something went wrong! Please try again later.',
  'global.label.upload': 'Drag and drop your files here or',
  'global.label.chooseFile': 'Choose file',
  'global.label.find!': 'Find!',
  'global.createAccountAlert': "The account you entered doesn't exist.",
  'global.createAccount': 'Create account',
  'global.goToAccount': 'Go to account',
  'global.notFound': "We got caught up in something. Let's give it another go.",
  'global.notFound.tryAgain': 'Try Again',
  'global.notFound.notAvailable': 'not available',
  'manageAccount.title': 'Manage Account',
  'manageAccount.people': 'People',
  'manageAccount.people.accountHolder': 'Account Holder',
  'manageAccount.paymentMethods': 'Payment Methods',
  'manageAccount.paymentMethod.expires': 'Expires: {{date}}',
  'manageAccount.paymentMethod.noSavedPayments': 'No saved payments',
  'manageAccount.paymentMethod.delete.title': 'Confirm Delete',
  'manageAccount.paymentMethod.delete.lead': 'Are you sure you want to delete this saved payment method?',
  'manageAccount.paymentMethod.delete.success': 'Payment Method was deleted successfully.',
  'manageAccount.pets': 'Pets',
  'manageAccount.pets.add': '+ Add Pet',
  'signin.enterEmail.title': 'Visited us before?',
  'signin.enterEmail.email': 'Email Address',
  'signin.verify.title': 'Verify your account',
  'signin.verify.modal.title': "We're excited to have you back!",
  'signin.verify.copy': 'Enter the code emailed to you.',
  'signin.verify.code': 'Code',
  'signin.verify.requestNewCode': 'Request New Code',
  'offers.startingAt': 'starting at',
  'serviceAgreement.title': 'Service Agreement',
  'serviceAgreement.lead': 'Please read and sign this agreement.',
  'serviceAgreement.form.accept': 'I agree to the terms included in this agreement.',
  'serviceAgreement.form.sign.help': 'Please type your first and last name to sign this agreement.',
  'serviceAgreement.sign.error': 'There was an error signing the agreement. Please try again.',
  'global.label.uploadOnly': 'upload',
  'forcedLogin.letsTryThisAgain': "Let's try this again",
  'forcedLogin.restartBookingText':
    "To ensure we are using your saved pet's information to determine pricing and availability, restart your booking.",
  'loginForm.newAccount': 'Create New Online Account',
  'actionPrompt.continueAsANewCustomer': 'Continue As New Customer',
  'forcedLogin.existingUser.title': 'Welcome Back',
  'forcedLogin.existingUser.info':
    'Enter the code emailed to you for your security. Verification codes sent via email are valid for 15 minutes',
  'footer.poweredBy': 'Powered by {{brand}}',
  'footer.terms': 'Terms and Conditions',
  'footer.privacy': 'Privacy Policy',
  'privacyPolicy.headerText': 'Terms & Conditions',
  'privacyPolicy.body1.title': 'What are your mobile terms?',
  'privacyPolicy.body1.text': `{{title}} {{spacer}} When you provide us with your mobile phone number, you agree that {{locationName}} may send you text messages (including SMS and MMS) to that phone number. You will receive a confirmation text message, and you may need to reply as instructed to complete registration. The frequency at which you'll receive our text messages may vary from month to month. Message and data rates may apply. Reply {{stop}} to cancel, {{help}} for help. You agree to receive a final text message confirming your opt-out. You may opt out at any time by texting the word {{stop}}. For help, send a text message with the keyword {{help}}. Texts may be sent through an automatic telephone dialing system. Consent is not required to purchase our goods or services. You agree to notify us of any changes to your mobile number and update your account to reflect this change. Your carrier may prohibit or restrict certain mobile features and certain mobile features may be incompatible with your carrier or mobile device. Contact your carrier with questions regarding these issues.
  `,
  'sms.help': 'HELP',
  'sms.stop': 'STOP',
  'privacyPolicy.body2.title': 'How do I sign up for these text messages?',
  'privacyPolicy.body2.text': `{{title}} {{spacer}} All you have to do is text {{locationName}}. (Message frequency may vary, message and data rates may apply, and you can always text {{help}} for help & {{stop}} to cancel.) When you opt-in to the service, we will send you an SMS message to confirm your signup.
  {{spacer}}
  If at any time you forget what keywords are supported, just text {{help}}. After you send the SMS message {{help}} to us, we will respond with instructions on how to use our service as well as how to unsubscribe.`,
  'privacyPolicy.body3.title': 'How do I opt out of these text messages?',
  'privacyPolicy.body3.text': `{{title}} {{spacer}}
  You can cancel the SMS service at any time. Just text {{stop}}. After you send the SMS message {{stop}} to us, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us. If you want to join again, just sign up as you did the first time and we will start sending SMS messages to you again.`,
  'privacyPolicy.body4.title': 'Will I be charged for the text messages I receive?',
  'privacyPolicy.body4.text': `{{title}} {{spacer}}
  {{locationName}} will never charge you for the text messages you receive however standard message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider. For all questions about the services provided through the {{locationName}} number, you can send an email to {{locationName}}.`,
  'privacyPolicy.body5.title': 'Supported wireless carriers',
  'privacyPolicy.body5.subTitle': 'United States',
  'privacyPolicy.body5.subTitle2': 'Canada',
  'privacyPolicy.body5.text': `{{title}} {{spacer}} {{subtitle}}
  {{spacer}}
  We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T, Verizon Wireless, Sprint, T-Mobile, MetroPCS, U.S. Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless).
  {{spacer}}
  {{subtitle2}}
  {{spacer}}
  Bell (including NorthernTel, Solo Mobile, and Telebec), Fido, MTS, Rogers, SaskTel, Telus (including Koodo Mobile and Public Mobile), Videotron, Virgin Mobile, and Wind.
  {{spacer}}
  ***This service and the carriers are not liable for delayed or undelivered messages***
`,
  'privacyPolicy.body6.text':
    '{{locationName}} is dedicated to protecting your privacy and work hard to ensure our website, and services are both safe and secure for our visitors, clients, and subscribers.',
  'privacyPolicy.body7.title': 'Protection of Information',
  'privacyPolicy.body7.text': `{{title}} {{spacer}}
    Any information communicated to {{locationName}} for text messaging and or storage will remain in the ownership of the user, which will be stored securely in accordance with our security policy and the law. {{locationName}} will take the necessary steps to secure your personal information with safeguards appropriate to the sensitivity of the information. {{locationName}} will, at no time, rent or redistribute this information without your consent, except where legally required to do so.`,
  'privacyPolicy.body8.title': 'Subscribing and Unsubscribing',
  'privacyPolicy.body8.text': `{{title}} {{spacer}}
  It is policy, that just as a user must opt into a text marketing list, they have the right to opt out of a particular text marketing list as well. When a user opts out of a text marketing list, they will no longer receive any texts unless they, themselves, re-subscribe.`,
  'privacyPolicy.body9.title': 'Limits On Our Abilities',
  'privacyPolicy.body9.text': `{{title}} {{spacer}}
  Even though your privacy is of the utmost importance to us, due to standing legalities, we cannot promise that personal information will not be divulged to third parties in ways not explained by this privacy policy. Furthermore, we can (and you give us permission to) divulge any information about you to private entities, law enforcement or other government officials, as we, in our sole vigilance, believe needed to address and/or answer questions or resolve problems.
`,
  'privacyPolicy.body10.title': 'Subscribing and Unsubscribing',
  'privacyPolicy.body10.text': `{{title}} {{spacer}}
  No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
  {{spacer}}
  {{locationName}} reserves the right to change this privacy policy at its discretion. Your continued use of this service after changes to this privacy policy have been posted is taken as acceptance of those changes. It is your responsibility to monitor the privacy policy to determine whether any changes have been made. This privacy policy is subject to and applicable to all privacy laws.
  {{spacer}}
  You acknowledge that accepting this privacy policy is a condition of your relationship with {{locationName}} and its services and you agree to be bound by all of its terms and conditions.`,
  'signupComponent.login': 'Login',
  'signupComponent.createAccount': 'Create Your Account',
  'signupComponent.subtitle': 'View or modify upcoming bookings, pre-register your pets for easier check in and more!',
  'signupComponent.confirmItsYou': 'Confirm its You',
  'signupComponent.confirmSubtitle': 'Enter the code sent to your email {{email}}.',
  'signupComponent.verifyCodeError': 'Code could not be verified. Please try again.',
  'signupComponent.label.invalidCode': 'Code must have 6 digits',
  'signupComponent.label.wrongCode': 'Invalid verification code. Please check and re-enter.',
} as const
