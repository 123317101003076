import { useQuery } from '@tanstack/react-query'
import { ClientOrderSearchService } from '../services'
import { APIOrderSearchClientDtoResponse, TOrderSearchOptions } from '../types'
import { defaultQueryOptions } from './common'

export const useClientOrderSearch = (locationName: string, options: TOrderSearchOptions) => {
  return useQuery<APIOrderSearchClientDtoResponse>({
    queryKey: ['clientOrderSearch', locationName, options],
    queryFn: () => ClientOrderSearchService.getData(locationName, options),
    ...defaultQueryOptions,
  })
}
