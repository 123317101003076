import { useParams } from 'react-router-dom'

import { SearchOffersGeneral } from './search_offers_general'
import { SearchOffersByDay } from './search_offers_by_day'
import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { useEffect, useLayoutEffect } from 'react'

export const SearchOffers = () => {
  const { serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()

  useEffect(() => {
    document.title = t('title.selectOffer')
  }, [])

  useLayoutEffect(() => {
    bookingCtx.dispatch({ type: 'CLEAR_OFFERS_SEARCH_RESULTS' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return serviceTypeName === 'daycare' ? <SearchOffersByDay /> : <SearchOffersGeneral />
}
