import { useTheme } from '@mui/material'
import { DefaultThemeOptions } from '@themes/theme'
import { i18WithParams as t } from '@shared/locales'
import { capitalize } from 'shared'
import { InvoiceClientDto } from 'service-api/src/types'
import { ColorChip } from 'color_chip'

export const GroomStatus = ({ invoice }: { invoice: InvoiceClientDto }) => {
  const theme: DefaultThemeOptions = useTheme()
  const { primary, success, error } = theme.palette!

  if (invoice.invoiceSubStatus === 'SCHEDULED') {
    return (
      <ColorChip
        label={t(`global.label.invoiceSubStatus.${invoice.invoiceSubStatus.toLowerCase()}`)}
        bgColor={success.shade12}
        textColor={success.main}
      />
    )
  } else if (
    invoice.invoiceSubStatus === 'CANCELED' ||
    invoice.invoiceSubStatus === 'DECLINED' ||
    invoice.invoiceStatus === 'CANCELED'
  ) {
    return (
      <ColorChip
        label={t(
          `global.label.invoiceSubStatus.${
            invoice.invoiceSubStatus?.toLowerCase() || invoice.invoiceStatus?.toLowerCase()
          }`
        )}
        bgColor={error.shade12}
        textColor={error.main}
      />
    )
  } else {
    return (
      <ColorChip
        label={
          invoice.invoiceSubStatus
            ? t(`global.label.invoiceSubStatus.${invoice.invoiceSubStatus.toLowerCase()}`)
            : t('groomNotice.pill.fallback')
        }
        bgColor={primary.shade12}
        textColor={primary.main}
      />
    )
  }
}
