import { Checkbox, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { useBookingContext } from '@providers/booking'
import { TBookingPet } from '@providers/booking/types'
import { formatNumberAsPrice } from '@shared/common'
import { i18WithDefault as t } from '@shared/locale'
import { Types } from 'service-api'

export const PetCard = ({
  petIdx,
  pet,
  groupIdx,
  groom,
  togglePetInGroom,
}: {
  petIdx: number
  pet: TBookingPet
  groupIdx: number
  groom: Types.BookingSearchClientDto
  togglePetInGroom: (petIdx: number) => void
}) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const [isSelected, setIsSelected] = useState<boolean>(true)

  useEffect(() => {
    const currentPetGroom = bookingCtx.state.cart.groups[groupIdx].grooms[petIdx]?.filter(
      (g) => g.offer.id === groom.offer?.id
    )
    setIsSelected(currentPetGroom?.[0]?.offer.id ? true : false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.cart.groups[groupIdx].grooms[petIdx], groupIdx])

  const isAvailable = useMemo(
    () => groom.availabilityGroups?.[petIdx].availabilities?.some((a) => a.available),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groom]
  )

  return (
    <Stack
      key={`group-${pet.id}`}
      direction="row"
      alignItems="center"
      spacing={3}
      sx={{
        maxWidth: '640px',
        px: 2,
        py: 0.5,
        margin: '0 auto',
        width: '100%',
        backgroundColor: theme.palette!.primary?.shade5,
      }}
    >
      <Typography variant="body2" flexGrow={1}>
        {pet.name}
      </Typography>
      {groom.availabilityGroups?.[petIdx].availabilities?.[0].price?.rate && (
        <>{formatNumberAsPrice(groom.availabilityGroups?.[petIdx].availabilities?.[0].price?.rate)}</>
      )}
      <Stack direction="row" justifyContent="center" alignItems="center">
        {isAvailable ? (
          <Tooltip
            title={isSelected ? t('addonItemPicker.removeItem') : t('addonItemPicker.addItem')}
            disableInteractive
          >
            <IconButton
              onClick={() => {
                togglePetInGroom(petIdx)
              }}
              sx={{ p: '6px' }}
            >
              <Checkbox sx={{ py: 0.5, ml: 0 }} data-testid="selected-check" checked={isSelected} />
            </IconButton>
          </Tooltip>
        ) : (
          <Typography p={1}>{t('global.notFound.notAvailable')}</Typography>
        )}
      </Stack>
    </Stack>
  )
}
