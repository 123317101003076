import { requestGooseAPI, getAPIPath } from '../shared/request'
import { TBookingStep, ReceiptDto, CLIENT_RECEIPT_PATH } from '../types'

export const ClientReceiptService = {
  getReceipt: async (locationName: string, orderId: string, step: TBookingStep): Promise<ReceiptDto> => {
    return requestGooseAPI<ReceiptDto>({
      url: getAPIPath(CLIENT_RECEIPT_PATH, { locationId: locationName, id: orderId }),
      method: 'GET',
      params: {
        step,
      },
    })
  },
}
