import { getAPIPath, requestGooseAPI } from '../shared/request'
import { BookingReservationClientDto, CLIENT_BOOKING_PAYMENT_PATH, CLIENT_BOOKING_CONFIRM_PATH } from '../types'

const goosePayment = (
  locationName: string,
  orderId: string,
  type: string,
  moreData: Record<string, string> = {}
): Promise<BookingReservationClientDto> => {
  return requestGooseAPI<BookingReservationClientDto>({
    url: getAPIPath(CLIENT_BOOKING_CONFIRM_PATH, { locationId: locationName, orderId }),
    method: 'POST',
    data: {
      payment: {
        type,
        ...moreData,
      },
    },
  })
}

interface TClientBookingPaymentService {
  initializePayment: (locationName: string, orderId: string) => Promise<BookingReservationClientDto>
  offlinePayment: (locationName: string, orderId: string) => Promise<BookingReservationClientDto>
  tilledPayment: (locationName: string, orderId: string) => Promise<BookingReservationClientDto>
  adyenPayment: (locationName: string, orderId: string, sessionResult: string) => Promise<BookingReservationClientDto>
}
export const ClientBookingPaymentService: TClientBookingPaymentService = {
  initializePayment: (locationName: string, orderId: string): Promise<BookingReservationClientDto> => {
    return requestGooseAPI<BookingReservationClientDto>({
      url: getAPIPath(CLIENT_BOOKING_PAYMENT_PATH, { locationId: locationName, orderId }),
      method: 'POST',
      data: {
        payment: {},
      },
    })
  },

  offlinePayment: (locationName: string, orderId: string) => goosePayment(locationName, orderId, 'OFFLINE'),
  tilledPayment: (locationName: string, orderId: string) => goosePayment(locationName, orderId, 'TILLED'),
  adyenPayment: (locationName: string, orderId: string, sessionResult: string) =>
    goosePayment(locationName, orderId, 'ADYEN', { sessionResult }),
}
