import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { i18WithParams as t } from '@shared/locales'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { useMemo, useState } from 'react'

import { formatNumberAsPrice } from '@shared/common'
import { GroomNotice } from 'groom_notice/groom_notice'
import { Link as NavLink, useParams } from 'react-router-dom'
import { Types } from 'service-api'
import {
  DEFAULT_TIMEZONE,
  getInvoiceDates,
  getPrimaryServiceType,
  getInvoiceItemsForDisplayName,
  getInvoiceSubStatus,
  isInvoiceCanceled,
} from 'shared'
import { CancelInvoiceModal } from './cancel_invoice'
import { canCancelInvoice } from './helper'
import { InvoiceStatus } from './invoice_status'
import { GroomStatus } from './groom_status'

const DEFAULT_DATE_FORMAT = 'MMM d, yyyy'

export type RefundSetting = {
  id: string
  before: number
  after: number
  order: number
  rate: string
  status: string
}

type Props = {
  invoice: Types.CustomInvoiceAdminDto
  order: Types.CustomOrderAdminDto
  isAdmin?: boolean
  isPackage?: boolean
  canAccessOS?: boolean
  onCancelInvoice: () => void
}

export const InvoiceCard = ({ invoice, order, isAdmin, canAccessOS, onCancelInvoice, isPackage }: Props) => {
  const theme = useTheme()
  const { accountName: locationName = '' } = useParams()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const timezone = invoice.location?.timezone || DEFAULT_TIMEZONE
  const isGroom = invoice.items?.[0].locationServiceType?.name === 'grooming'
  const cancelButton = useMemo(() => {
    return canCancelInvoice(invoice, order, !!isAdmin) ? (
      <Box>
        <Button variant="outlined" color="error" size="small" onClick={() => setCancelModalOpen(true)}>
          {t('global.label.cancelBooking')}
        </Button>
      </Box>
    ) : null
  }, [invoice, order, isAdmin])

  const invoiceFor = useMemo(() => {
    const item = isPackage ? invoice.items?.[0] : (invoice.items || []).find((item) => item.offerType === 'PRIMARY')
    const primaryOfferName = item?.offer?.displayName || item?.offer?.locationServiceType?.displayName
    const allOfferNames = getInvoiceItemsForDisplayName(invoice)
    const petNames = (item?.petRelations || [])
      .map(({ invoicePet }) => invoicePet?.displayName || invoicePet?.name || '')
      .filter((name) => !!name)
      .join(', ')
    return `${isGroom ? allOfferNames : primaryOfferName}${petNames ? ` (${petNames})` : ''}`
  }, [invoice, isPackage, isGroom])

  const invoiceServiceDates = useMemo(() => {
    const { startDate, endDate } = getInvoiceDates(invoice)
    if (getPrimaryServiceType(invoice)?.overnight) {
      return `${formatInTimeZone(startDate, timezone, DEFAULT_DATE_FORMAT)} - ${formatInTimeZone(
        endDate,
        timezone,
        DEFAULT_DATE_FORMAT
      )}`
    }
    // eslint-disable-next-line no-extra-boolean-cast
    const timeFormat = !!invoice.period?.[0].startTime ? ' @ h:mm aaaa' : ''
    return formatInTimeZone(startDate, timezone, `EEE MMM d, yyyy${timeFormat}`)
  }, [invoice, timezone])

  const cancelBy = useMemo(() => {
    const refundSettings: RefundSetting[] = order.data?.refundSettings || []
    const { startDate: reservationDate } = getInvoiceDates(invoice)
    const minutesToReservation = differenceInMinutes(reservationDate, new Date())
    const numMinutesCanCancel = refundSettings.reduce((res, setting) => {
      if (minutesToReservation > setting.before) {
        return res ? Math.min(res, setting.before) : setting.before
      }
      return res
    }, 0)
    return numMinutesCanCancel
      ? formatInTimeZone(addMinutes(reservationDate, -numMinutesCanCancel), timezone, DEFAULT_DATE_FORMAT)
      : t('global.label.N/A')
  }, [invoice, order, timezone])

  const cancellationDetails = useMemo(() => {
    const { canceledAt, cancellationReason } = invoice
    const date = canceledAt ? formatInTimeZone(canceledAt, timezone, DEFAULT_DATE_FORMAT) : ''
    switch (cancellationReason) {
      case 'USER': {
        return t('invoiceCard.cancelByCustomer', { date })
      }
      case 'LOCATION': {
        return t('invoiceCard.cancelByStaff', { date })
      }
      default: {
        return t('invoiceCard.cancelByOther', { date })
      }
    }
  }, [invoice, timezone])

  const refundDetails = useMemo(() => {
    return invoice.refunded
      ? t('invoiceCard.refunded', {
          refund: formatNumberAsPrice(invoice.refunded),
          deposit: formatNumberAsPrice(invoice.charged || 0),
        })
      : ''
  }, [invoice.refunded, invoice.charged])

  const tilledLink = useMemo(() => {
    const existingPayment = order.payments?.[0] || {}
    const env = import.meta.env.VITE_TILLED_ENVIRONMENT
    const tilledDomain =
      env === 'prod' ? `https://goose.paymentsonline.io` : `https://goose-${env}.sandbox-paymentsonline.io`
    const linkURL = existingPayment.merchantId
      ? `${tilledDomain}/${existingPayment.merchantId}/payments/${existingPayment.paymentIntentId}`
      : ''
    return isAdmin &&
      existingPayment.merchantId &&
      existingPayment.paymentIntentId &&
      existingPayment.payFacType === 'TILLED' ? (
      <Button variant="outlined" size="small" target="_blank" href={linkURL}>
        {t('invoiceCard.viewPaymentTransaction')}
      </Button>
    ) : null
  }, [order.payments, isAdmin])

  const invoiceOSLink = useMemo(() => {
    if (!isAdmin || !canAccessOS) {
      return null
    }
    return (
      <Button component={NavLink} to={`/v2/${locationName}/invoice/${invoice.id}`} variant="outlined">
        {t('invoiceCard.viewInvoiceInOS')}
      </Button>
    )
  }, [isAdmin, canAccessOS, locationName, invoice.id])

  return (
    <>
      <Paper sx={{ borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
        {isGroom && getInvoiceSubStatus(invoice).isRequested && !isInvoiceCanceled(invoice) && <GroomNotice isPast />}
        <Box p={2}>
          <Stack gap={1} direction={'row'} justifyContent="space-between" alignItems="center" flexWrap="wrap">
            <Stack direction={isBelowMD ? 'column' : 'row'} spacing={2} alignItems={isBelowMD ? 'start' : 'center'}>
              {!isPackage && (isGroom ? <GroomStatus invoice={invoice} /> : <InvoiceStatus invoice={invoice} />)}
              <Stack>
                {isGroom ? (
                  <Typography variant="body1">{t('groomNotice.header')}</Typography>
                ) : (
                  <Typography variant="body1">
                    {t('invoiceCard.confirmationNum', {
                      num: invoice?.name?.toString() ?? '',
                    })}
                  </Typography>
                )}
                {invoice.invoiceStatus === 'CANCELED' && invoice.canceledAt && (
                  <Typography variant="body2">{`${cancellationDetails} ${refundDetails}`}</Typography>
                )}
              </Stack>
            </Stack>
            {!isPackage && cancelButton}
          </Stack>
          {isBelowMD ? (
            <Box sx={{ mt: 4 }}>
              <Stack mb={1}>
                <Typography variant="caption">{t('global.label.for')}</Typography>
                <Typography variant="body2">{invoiceFor}</Typography>
                {isGroom ? null : <Typography variant="body2">{invoiceServiceDates}</Typography>}
              </Stack>
              <Stack direction={'row'} mb={1}>
                <Stack flexGrow={1}>
                  <Typography variant="caption">{t('global.label.reservedOn')}</Typography>
                  <Typography variant="body2">
                    {formatInTimeZone(invoice.createdAt!, timezone, DEFAULT_DATE_FORMAT)}
                  </Typography>
                </Stack>
                <Stack flexGrow={1}>
                  <Typography variant="caption">{t('global.label.cancelBy')}</Typography>
                  <Typography variant="body2">{cancelBy}</Typography>
                </Stack>
              </Stack>
            </Box>
          ) : (
            <TableContainer sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('global.label.for')}</TableCell>
                    <TableCell>{t('global.label.reservedOn')}</TableCell>
                    <TableCell>{t('global.label.cancelBy')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ verticalAlign: 'baseline' }}>
                    <TableCell width="40%">
                      <Typography variant="body2">{invoiceFor}</Typography>
                      <Typography variant="body2">{!isGroom && invoiceServiceDates}</Typography>
                    </TableCell>
                    <TableCell width="30%">
                      {formatInTimeZone(invoice.createdAt!, timezone, DEFAULT_DATE_FORMAT)}
                    </TableCell>
                    <TableCell>{cancelBy}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Stack direction="row" spacing={2} mt={4}>
            {tilledLink}
            {invoiceOSLink}
          </Stack>
        </Box>
      </Paper>
      <CancelInvoiceModal
        invoice={invoice}
        order={order}
        canOverrideRefund={isAdmin}
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onCancelInvoice={onCancelInvoice}
      />
    </>
  )
}
