import * as Sentry from '@sentry/react'

import type { TTilledFormResponse, TBillingDetails } from './types'
import { Types } from 'service-api'
import { TCartContact } from '@providers/booking/types'
import { TPaymentFormSchema } from './payment_schema'
import { customColors } from 'components-ui'

const PAYMENT_METHOD = 'card'

export const initializeTilled = async (
  paymentData: Types.BookingReservationClientDto
): Promise<TTilledFormResponse> => {
  if (!window.Tilled) {
    Sentry.captureMessage('Tilled object not found')
  }

  let processor = null
  const { payments } = paymentData.order || {}
  if (!payments) {
    throw new Error('Unable to find order.payments data')
  }

  processor = new window.Tilled(import.meta.env.VITE_TILLED_PUBLIC_KEY, payments[payments.length - 1].merchantId, {
    sandbox: import.meta.env.VITE_TILLED_ENVIRONMENT !== 'prod',
  })

  return {
    processor,
    form: await processor.form({ payment_method: PAYMENT_METHOD }),
  }
}

export const formStyles = {
  styles: {
    base: {
      color: customColors.textPrimary,
      fontWeight: '300',
      fontSize: '1rem',
    },
    valid: {
      color: 'black',
    },
    invalid: {
      color: 'red',
    },
  },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createTilledForm = async (form: any): Promise<void> => {
  form.createField('cardNumber', { ...formStyles, selector: '#cardNumber' })
  form.createField('cardExpiry', { ...formStyles, placeholder: 'MM/YY', selector: '#cardExpiry' })
  form.createField('cardCvv', { ...formStyles, selector: '#cardCvv' })

  return form.build()
}

export const resolveBillingDetails = (cartContact: TCartContact, paymentForm: TPaymentFormSchema): TBillingDetails => {
  const { useMailingAddress } = paymentForm

  return {
    name: paymentForm.nameOnCard,
    address: {
      country: useMailingAddress ? cartContact.mailingAddress.country : paymentForm.country || '',
      zip: useMailingAddress ? cartContact.mailingAddress.zipCode : paymentForm.zipCode || '',
      state: useMailingAddress ? cartContact.mailingAddress.state : paymentForm.state || '',
      city: useMailingAddress ? cartContact.mailingAddress.city : paymentForm.city || '',
      street: useMailingAddress
        ? `${cartContact.mailingAddress.streetLine1} ${cartContact.mailingAddress.streetLine2}`.trim()
        : `${paymentForm.streetLine1} ${paymentForm.streetLine2}`.trim(),
    },
  }
}
