const storeInterface = localStorage

export const Vault = {
  set: (key: string, value: string) => storeInterface.setItem(key, value),
  get: (key: string): string | null => storeInterface.getItem(key),
  remove: (key: string) => storeInterface.removeItem(key),
  clear: () => storeInterface.clear(),
  setObj: (key: string, value: GenericSimpleBag) => storeInterface.setItem(key, JSON.stringify(value)),
  getObj: <T>(key: string) => JSON.parse(storeInterface.getItem(key) || 'null') as T,
}
