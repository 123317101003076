import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import { BoardingIcon } from './boarding_icon'
import { OFFER_IMAGE_MAX_WIDTH } from '@shared/constants'

export const BoardingDefaultImg = ({
  width = 320,
  bgColor,
  fgColor,
  imageUrl,
}: {
  width?: number
  bgColor?: string
  fgColor?: string
  imageUrl?: string
}) => {
  const theme = useTheme()
  const height = width * 0.625 // 320x200 aspect ratio
  return imageUrl ? (
    <img
      alt=""
      src={imageUrl}
      style={{
        display: 'block',
        width: 'auto',
        height: 'width',
        maxWidth: OFFER_IMAGE_MAX_WIDTH,
        maxHeight: 200,
      }}
    />
  ) : (
    <Box sx={{ width, height, backgroundColor: bgColor || theme.palette.background.default, flexShrink: 0 }}>
      <BoardingIcon
        viewBox={`-5 -5 30 30`}
        sx={{ width: '100%', height: '100%', fill: fgColor || theme.palette.text.disabled }}
      />
    </Box>
  )
}
