import { i18WithParams as t } from '@shared/locale'
import { UserClientDto } from 'service-api/src/types'
import * as yup from 'yup'

export const accountHolderSchema = yup.object({
  firstName: yup.string().max(200).required().label(t('contactForm.firstName')),
  lastName: yup.string().max(200).required().label(t('contactForm.lastName')),
  phone: yup.string().length(10).required().label(t('contactForm.phone')),
  streetAddress: yup.string().max(200).required().label(t('contactForm.streetLine1')),
  streetAddress2: yup.string().max(200).label(t('contactForm.streetLine2')),
  city: yup.string().max(50).required().label(t('contactForm.city')),
  state: yup.string().max(2).required().label(t('contactForm.state')),
  zipCode: yup.string().max(5).required().label(t('contactForm.zipCode')),
  country: yup.string().length(2).required().label(t('contactForm.country')),
})

export const setupInitialValues = (user: UserClientDto): { [key: string]: string } => {
  const profile = user.userProfile?.[0]
  return {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    email: user.email || '',
    phone: profile?.phone || '',
    streetAddress: profile?.streetAddress || '',
    streetAddress2: profile?.streetAddress2 || '',
    city: profile?.city || '',
    state: profile?.state || '',
    zipCode: profile?.zipCode || '',
    country: 'US',
  }
}

export const buildRequestBody = (formikValues: Record<string, string>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email, country, ...values } = formikValues
  return Object.entries(values).reduce(
    (res, [key, value]) => ({
      ...res,
      [key]: value || null,
    }),
    {}
  )
}
