import Box from '@mui/material/Box'

interface TProps {
  width?: number
  height: number
}

const BASE_GRID_SIZE = 8

export const Spacer = ({ width = 100, height }: TProps) => (
  <Box width={width} height={height * BASE_GRID_SIZE} flexShrink={0} />
)
export const Spacer1 = () => <Spacer height={1} />
export const Spacer2 = () => <Spacer height={2} />
export const Spacer3 = () => <Spacer height={3} />
export const Spacer4 = () => <Spacer height={4} />
