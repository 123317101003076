import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { getSelectedOffer } from '@providers/booking/helper'
import { useSessionContext } from '@providers/session'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { buildErrorMessage } from '@shared/error'
import { i18WithParams as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { Spacer2, Spacer3 } from 'components-ui'
import { useBookingReservation } from 'hooks/use_booking_reservation'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'
import { enqueueSnackbar } from 'notistack'
import { Hooks } from 'service-api'
import { getAxiosErrorMessage } from 'service-api/src/shared/request'
import { DetailsPoliciesForm } from './details_policies_form'
import { getOfferIdFromErrorMsg } from './helper'
import { PanelSkeleton } from './panel_skeleton'
import { captureError } from 'service-api/src/shared'

export const DetailsPolicies = () => {
  const { accountName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const theme = useTheme()
  const renderStyle = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop'
  const isMobile = renderStyle === 'mobile'
  const { navigateRoute } = useRoutes()

  useEffect(() => {
    bookingCtx.dispatch({
      type: 'SET_SEARCH_STATE',
      payload: { value: 'detailsPolicies', formHandler: 'details-policies-form' },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reservation = useBookingReservation(accountName)
  const userProfile = Hooks.useClientUser(accountName, sessionCtx.state.user?.userId || '')

  useRedirectNoData()

  const renderSkeleton = () => {
    return (
      <>
        <Stack direction="column" spacing={2} flex={1} width="100%">
          <PanelSkeleton />
          <PanelSkeleton />
          <PanelSkeleton />
          <PanelSkeleton />
          <PanelSkeleton />
        </Stack>
        <Stack direction="column" spacing={2} flex={1} mt={isMobile ? 2 : 0} width="100%">
          <PanelSkeleton />
          <PanelSkeleton />
        </Stack>
      </>
    )
  }

  useEffect(() => {
    if (reservation.isError) {
      const errMsg = getAxiosErrorMessage(reservation.error)
      if (errMsg && errMsg.includes('does not have enough available qty')) {
        const selectedOffer = getSelectedOffer(bookingCtx.state, getOfferIdFromErrorMsg(errMsg) || '')
        enqueueSnackbar(t('detailsPolicies.notEnoughOffers', { offerName: selectedOffer?.displayName || '' }), {
          variant: 'error',
        })
      } else {
        enqueueSnackbar(buildErrorMessage(reservation.error), { variant: 'error' })
      }
      captureError({ cause: buildErrorMessage(reservation.error) } as Error)
      bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'searchResults' } })
      navigateRoute('searchOffersResults', { serviceTypeName: bookingCtx.state.serviceTypeName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation.isError])

  useEffect(() => {
    document.title = t('title.addDetail')
  }, [])

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer3 />
      <Typography variant="h1" sx={{ px: 2 }}>
        {t('detailsPolicies.title')}
      </Typography>
      <Spacer2 />
      <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={2} flex={1}>
        {reservation.isLoading || userProfile.isLoading ? (
          renderSkeleton()
        ) : reservation.isSuccess ? (
          <DetailsPoliciesForm reservation={reservation.data!} userProfile={userProfile.data!} />
        ) : null}
      </Stack>
    </Box>
  )
}
