import { createContext, useContext } from 'react'

import { Vault } from 'shared'
import { USER_STORE_KEY } from './helpers'
import type { TSessionContextValues, TUserStoreData } from './session'

const userStore = Vault.getObj<TUserStoreData>(USER_STORE_KEY)

export const initialState: TSessionContextValues = userStore
  ? {
      status: 'INITIALIZING',
      user: userStore.user,
      currentLocation: userStore.currentLocation,
    }
  : {
      status: 'INITIALIZING',
      user: { id: '', token: '', username: '', claims: [], idHash: '', email: '' },
      currentLocation: { id: '', name: '', displayName: '' },
    }

export const SessionContext = createContext<{
  state: TSessionContextValues
  dispatch: React.Dispatch<TReducerAction>
} | null>(null)

export const useSessionContext = () => {
  const ctx = useContext(SessionContext)
  if (ctx === null) {
    throw new Error('useSessionContext must be used within a SessionProvider')
  }
  return ctx
}
