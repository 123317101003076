import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LOCATION_VACCINES_PATH } from '../types/constants'
import { APILocationVaccineClientDtoResponse } from '../types/api'

export const ClientLocationVaccinesService = {
  list: (locationName: string) =>
    requestGooseAPI<APILocationVaccineClientDtoResponse>({
      url: getAPIPath(CLIENT_LOCATION_VACCINES_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        includes: ['vaccineType'],
        status: 'ACTIVE',
      },
    }),
}
