import { IconButton, Skeleton, Typography } from '@mui/material'
import { generatePhoneFromString } from 'components-ui'
import { useState, useEffect } from 'react'
import { UserClientDto, UserProfileClientDto } from 'service-api/src/types'
import { AccountHolderForm } from '../account_holder_form'
import { Card, CardContainer, CardsListContainer } from './common'
import { i18WithParams as t } from '@shared/locale'
import { Edit } from '@mui/icons-material'

type AccountHolderCardProps = {
  user?: UserClientDto
}

export const AccountHolderCard = (props: AccountHolderCardProps) => {
  const [editMode, setEditMode] = useState(false)
  const [user, setUser] = useState<UserClientDto | undefined>()

  const handleEditClick = () => {
    setEditMode(true)
  }

  const handleCancel = () => {
    setEditMode(false)
  }

  const handleSave = (newUserProfile: UserProfileClientDto) => {
    setUser((prevUser) => ({ ...prevUser, userProfile: [newUserProfile] }))
    setEditMode(false)
  }

  useEffect(() => {
    setUser(props.user)
  }, [props.user])

  if (!user) {
    return (
      <CardsListContainer>
        <Skeleton variant="rounded" width={349} height={248} />
      </CardsListContainer>
    )
  }

  if (!user.userProfile) {
    return null
  }

  const { firstName, lastName, phone, streetAddress, streetAddress2 } = user.userProfile[0]
  return (
    <CardsListContainer>
      <CardContainer>
        {editMode ? (
          <AccountHolderForm onCancel={handleCancel} onSave={handleSave} user={user} />
        ) : (
          <Card
            title={t('manageAccount.people.accountHolder')}
            action={
              <IconButton onClick={handleEditClick}>
                <Edit />
              </IconButton>
            }
          >
            {[
              `${firstName || ''} ${lastName || ''}`,
              generatePhoneFromString(phone || ''),
              user?.email || '',
              streetAddress || '',
              streetAddress2 || '',
            ]
              .filter((e) => e.trim() !== '')
              ?.map((line) => (
                <Typography key={line} variant="body1">
                  {line}
                </Typography>
              ))}
          </Card>
        )}
      </CardContainer>
    </CardsListContainer>
  )
}

export default AccountHolderCard
