import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { createPet } from '@components/pet_form/helper'
import { useBookingContext } from '@providers/booking'
import { TBookingPet } from '@providers/booking/types'
import { useSessionContext } from '@providers/session'
import { isUserLoggedIn } from '@providers/session/helpers'
import { Services, Types } from 'service-api'

const createNewPet = async (locationName: string, pet: TBookingPet, petFamilyId: string) => {
  const result: Types.PetClientDto | unknown = await createPet(
    {
      id: '',
      profileId: '',
      displayName: pet.name,
      breed: { id: pet.breedId },
      weight: pet.weight,
      birthdate: pet.birthdate || '',
      sex: pet.sex || '',
      altered: `${pet.altered}` || '',
    },
    locationName,
    petFamilyId
  )
  return result
}

export const usePetFamilies = () => {
  const { accountName: locationName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const isLoading = useRef(false)
  const alreadyRun = useRef(false)
  const [hasPets, setHasPets] = useState<boolean>(false)
  const [continueBooking, setContinueBooking] = useState<boolean>(false)

  if (!bookingCtx.state.isNewCustomer || !isUserLoggedIn(sessionCtx.state.user)) {
    return { isLoading: isLoading.current, hasPets, continueBooking }
  }

  if (hasPets || alreadyRun.current) {
    return { isLoading: isLoading.current, hasPets, continueBooking }
  }

  alreadyRun.current = true
  // setIsLoading(true)
  isLoading.current = true

  Services.ClientPetFamiliesService.families(locationName).then(
    (familyResponse: Types.APIPetFamilyClientDtoResponse) => {
      // setPetFamily(familyResponse.results)
      const familyId = familyResponse.results[0].id || ''

      Services.ClientPetsService.get(locationName, familyId).then(async (petsResponse) => {
        const withPets = petsResponse.results.length > 0

        // if the user has pets on his profile, there is nothing to do here
        if (withPets) {
          setHasPets(withPets)
          isLoading.current = false
          return
        }

        const groups = bookingCtx.state.cart.groups
        let groupIdx = 0
        for (const group of groups) {
          const pets = group.pets || []
          let petIdx = 0
          for (const pet of pets) {
            const newPet = await createNewPet(locationName, pet, familyId)
            if (newPet) {
              bookingCtx.dispatch({
                type: 'SET_PET_CART_FIELD',
                payload: {
                  groupIdx,
                  petIdx,
                  fieldName: 'id',
                  value: (newPet as Types.PetClientDto).id,
                },
              })
            }
            petIdx++
          }
          groupIdx++
        }
        isLoading.current = false
        setContinueBooking(true)
      })
    }
  )

  return {
    isLoading: isLoading.current,
    hasPets,
    continueBooking,
  }
}
