export const isEmailValid = (email: string) => {
  const emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
  return emailRegex.test(email)
}

export const sanitizeEmail = (email: string) => {
  if (!isEmailValid(email)) return ''
  if (!email) return ''
  return email.toLowerCase()
}
