import { useQuery } from '@tanstack/react-query'
import { ClientPetsService } from '../services'
import { APIPetClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'

export const useClientPets = (locationId: string, petFamilyId?: string) => {
  return useQuery<APIPetClientDtoResponse>({
    queryKey: ['clientPets', locationId],
    queryFn: () => ClientPetsService.get(locationId, petFamilyId || ''),
    enabled: !!locationId && !!petFamilyId,
    ...defaultQueryOptions,
  })
}
