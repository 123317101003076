import { i18WithDefault as t, pl } from '@shared/locale'
import type { TCartGroup } from '@providers/booking/types'

export const genId = (): string => Math.floor(Math.random() * 99999999999).toString(36)
export const isCuid = (id: string): boolean => id.startsWith('c') && id.length >= 25

export const getPetNames = (petGroups: TCartGroup[], defaultLabel?: string) => {
  const groups = petGroups.filter((g) => g.pets.filter((p) => p.name.trim() != '').length > 0)
  if (groups.length === 0) {
    return defaultLabel
  }
  const pets = petGroups.reduce<string[]>((acc, group) => {
    const petsInGroup = group.pets.reduce<string[]>((a, pet) => {
      if (pet.name.trim() !== '') {
        a.push(pet.name)
      }
      return a
    }, [])
    acc = acc.concat([...petsInGroup])
    return acc
  }, [])

  return pets.join(', ')
}

export const getPetGroupSummary = (petGroups: TCartGroup[], serviceTypeName: string) => {
  const petsAndGroups = petGroups.reduce(
    (acc, group) => {
      const petsInGroup = group.pets.filter((p) => p.name !== '' && p.room > 0)
      const roomsInGroup = new Set(petsInGroup.map((p) => p.room))
      acc.pets += petsInGroup.length
      acc.rooms += roomsInGroup.size
      return acc
    },
    { rooms: 0, pets: 0 }
  )

  if (petsAndGroups.rooms === 0) {
    return ''
  }

  const strRoom = pl(t('global.label.room'), petsAndGroups.rooms, true)
  const strPets = pl(t('global.label.pet'), petsAndGroups.pets, true)

  return serviceTypeName === 'boarding' ? `(${strRoom}, ${strPets})` : `(${strPets})`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    // eslint-disable-next-line no-prototype-builtins
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}

export interface TFormatNumberOptions {
  prefix?: string
  suffix?: string
  brackets?: boolean
  thousand?: string
  fixed?: number
  keepZero?: boolean
}
export const formatNumber = (value?: string | number, options?: TFormatNumberOptions): string => {
  if (typeof value === 'undefined') {
    return ''
  }
  const defaultValues = { prefix: '', suffix: '', brackets: false, fixed: 2, thousand: '', keepZero: false }
  options = Object.assign({}, defaultValues, options)

  if (typeof value === 'string') {
    value = parseFloat(value)
    if (isNaN(value)) {
      return ''
    }
  }

  const bp = options.brackets && value < 0 ? '(' : ''
  const bs = options.brackets && value < 0 ? ')' : ''

  let result = value.toFixed(options.fixed)

  if (!options.keepZero) {
    result = result.replace('.00', '')
  }

  if (options.thousand !== '') {
    result = result.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousand || '')
  }

  const withBrackets = options.brackets ? result.replace('-', '') : result
  return `${bp}${options.prefix || ''}${withBrackets}${options.suffix || ''}${bs}`
}

export const formatNumberAsPrice = (value?: string | number): string => {
  return formatNumber(value, { prefix: '$', keepZero: true, brackets: true })
}

export const formatNumberAsPercent = (value?: string | number): string => {
  return formatNumber(value, { suffix: '%', keepZero: true, brackets: true })
}

// scroll to the top of the page
export const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
