import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useLocationContext } from '@providers/location'
import { i18WithComponent as t } from '@shared/locale'
import { Spacer } from 'components-ui'
import React from 'react'

export const PrivacyPolicy = () => {
  const { isLoading: isLoadingLocation, location } = useLocationContext()
  const theme = useTheme()

  if (isLoadingLocation) return null

  const renderTitleText = (text: React.ReactNode, variant: 'h3' | 'h6' = 'h3') => (
    <Typography variant={variant} sx={{ textTransform: 'capitalize!important', color: theme.palette?.primary?.main }}>
      {text}
    </Typography>
  )

  return (
    <Box sx={{ maxWidth: '75ch', margin: '0 auto' }}>
      <Spacer height={8.5} />
      <Stack direction="column" spacing={2}>
        <Typography variant="h1" textAlign={'center'}>
          {t('privacyPolicy.headerText')}
        </Typography>
      </Stack>
      <Spacer height={8.5} />
      <Stack direction={'column'} spacing={2}>
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body1.text', {
            locationName: location?.displayName,
            title: renderTitleText(t('privacyPolicy.body1.title')),
            spacer: <Spacer height={2} />,
            help: <strong>{t('sms.help')}</strong>,
            stop: <strong>{t('sms.stop')}</strong>,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body2.text', {
            locationName: location?.displayName,
            title: renderTitleText(t('privacyPolicy.body2.title')),
            spacer: <Spacer height={2} />,
            help: <strong>{t('sms.help')}</strong>,
            stop: <strong>{t('sms.stop')}</strong>,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body3.text', {
            title: renderTitleText(t('privacyPolicy.body3.title')),
            spacer: <Spacer height={2} />,
            stop: <strong>{t('sms.stop')}</strong>,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body4.text', {
            locationName: location?.displayName,
            title: renderTitleText(t('privacyPolicy.body4.title')),
            spacer: <Spacer height={2} />,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body5.text', {
            title: renderTitleText(t('privacyPolicy.body5.title')),
            subtitle: renderTitleText(t('privacyPolicy.body5.subTitle'), 'h6'),
            subtitle2: renderTitleText(t('privacyPolicy.body5.subTitle2'), 'h6'),
            spacer: <Spacer height={2} />,
          })}
        </Typography>
        <Spacer height={8.5} />
        <Stack direction="column" spacing={2}>
          <Typography variant="h1" textAlign={'center'}>
            {t('detailsPolicies.privacyPolicy')}
          </Typography>
        </Stack>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body6.text', {
            locationName: location?.displayName,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body7.text', {
            locationName: location?.displayName,
            title: renderTitleText(t('privacyPolicy.body7.title')),
            spacer: <Spacer height={2} />,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body8.text', {
            title: renderTitleText(t('privacyPolicy.body8.title')),
            spacer: <Spacer height={2} />,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body9.text', {
            title: renderTitleText(t('privacyPolicy.body9.title')),
            spacer: <Spacer height={2} />,
          })}
        </Typography>
        <Spacer height={4} />
        <Typography variant="body2" sx={{ lineHeight: '27px!important' }}>
          {t('privacyPolicy.body10.text', {
            locationName: location?.displayName,
            title: renderTitleText(t('privacyPolicy.body10.title')),
            spacer: <Spacer height={2} />,
          })}
        </Typography>
        <Spacer height={10} />
      </Stack>
    </Box>
  )
}
