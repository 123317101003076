import { ReceiptDto } from 'service-api/src/types'
import { i18WithDefault as t } from '@shared/locales'

export const getPoliciesData = (receipt: ReceiptDto, hideRefundPolicy?: boolean) => {
  const flatBookingConf = (receipt?.locationBookingConf || []).map((group) => {
    const { items = [], ...rest } = group
    return [rest, ...items]
  })
  return receipt?.refundPolicy?.length && !hideRefundPolicy
    ? [[{ displayName: t('booking.refundPolicy') }, { content: receipt.refundPolicy }], ...flatBookingConf]
    : flatBookingConf
}
