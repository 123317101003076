import { NumericFormat, NumericFormatProps } from 'react-number-format'
import TextField, { TextFieldProps } from '@mui/material/TextField'

type FormatWithoutSize = Omit<NumericFormatProps, 'size'>

export const TextInputMoney = (props: TextFieldProps & FormatWithoutSize) => {
  const { onChange, ...others } = props
  return (
    <NumericFormat
      isAllowed={(num) => num.floatValue === undefined || num.floatValue <= Number.MAX_SAFE_INTEGER}
      {...others}
      value={props.value as string}
      thousandSeparator={true}
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
      prefix="$"
      type="text"
      customInput={TextField}
      valueIsNumericString={true}
      onValueChange={({ value }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange?.({ target: { name: props.name || '', value } })
      }}
    />
  )
}
