import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import format from 'date-fns/format'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { BackContinue } from '@components/back_continue/back_continue'
import { useBookingContext } from '@providers/booking'
import { MAX_WIDGET_SIZE, STICKY_FOOTER_HEIGHT } from '@shared/constants'
import { maximumSelectableDate } from '@shared/service_types'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { OfferCard, Spacer, Spacer3 } from 'components-ui'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'
import { Types } from 'service-api'
import { DatePicker } from './date_picker'
import { DatesViewer } from './dates_viewer'

export const DatesSelection = () => {
  const { serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const { navigateRoute } = useRoutes()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  useRedirectNoData()

  useEffect(() => {
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'datesSelection' } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.currentState])

  // redirect to home if the state has invalid data
  useEffect(() => {
    if (!bookingCtx.state.startDate) {
      navigateRoute('searchServiceType', { serviceTypeName })
    }
  })

  const onSelectOffer = useCallback(
    (_offer: Types.OfferClientDto, _availability: Types.AvailabilityGroupClientDto, _petGroup: number) => {
      // noop
    },
    []
  )

  const selectedOffer = bookingCtx.state.cart?.groups?.[0]?.offerSelected
  const canContinue = (bookingCtx.state.cart?.groups?.[0]?.daycareSelected?.length || 0) > 0
  const totalPets = useMemo(
    () => bookingCtx.state.cart.groups.reduce((acc, g) => acc + g.pets.length, 0),
    [bookingCtx.state.cart.groups]
  )

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <Typography variant="h1" sx={{ px: 2 }}>
        {selectedOffer ? t('searchResults.title.selectDates') : t('searchResults.title.daycare')}
      </Typography>
      <Spacer3 />

      <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
        {selectedOffer && (
          <OfferCard
            searchResult={{
              availabilityGroupsByDays: [[selectedOffer?.availability]],
              offer: selectedOffer?.offer,
            }}
            onSelectOffer={onSelectOffer}
            petGroup={0}
            serviceTypeName={serviceTypeName}
            specieTypeName="dog"
            selected
            totalPets={totalPets}
          />
        )}

        <Spacer3 />
        <Divider />
        <Spacer3 />

        <Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6" component="span">
              {t('addonDatePicker.calendarTitle')}
            </Typography>
            <Typography variant="body2" component="span" sx={{ opacity: 0.7 }}>
              {`(${t('addonDatePicker.summary')} ${format(
                maximumSelectableDate(new Date(), bookingCtx.state),
                'MMM dd, yyyy'
              )})`}
            </Typography>
          </Stack>
          <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={2}>
            {selectedOffer && <DatePicker />}
            {selectedOffer && <DatesViewer totalPets={totalPets} />}
          </Stack>
        </Box>
      </Paper>

      <Spacer height={STICKY_FOOTER_HEIGHT} />
      <BackContinue continueEnabled={canContinue} />
    </Box>
  )
}
