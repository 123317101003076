import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_STORED_PAYMENT_METHODS_ID_PATH, CLIENT_STORED_PAYMENT_METHODS_PATH } from '../types/constants'
import { StoredPaymentMethodClientDto } from '../types/api'

export const ClientStoredPaymentMethodsService = {
  list: (locationName: string): Promise<StoredPaymentMethodClientDto[]> => {
    return requestGooseAPI<StoredPaymentMethodClientDto[]>({
      url: getAPIPath(CLIENT_STORED_PAYMENT_METHODS_PATH, { locationId: locationName }),
      method: 'GET',
    })
  },

  delete: async (locationName: string, storedPaymentMethodId: string): Promise<StoredPaymentMethodClientDto> => {
    return requestGooseAPI<StoredPaymentMethodClientDto>({
      url: getAPIPath(CLIENT_STORED_PAYMENT_METHODS_ID_PATH, { locationId: locationName, id: storedPaymentMethodId }),
      method: 'DELETE',
    })
  },
}
