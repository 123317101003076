import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { captureError } from '@shared/monitoring'
import { Services, Types } from 'service-api'

export const usePayment = () => {
  const { accountName: locationName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const [isLoading, setIsLoading] = useState(true)
  const isOnFly = useRef(false)

  useEffect(() => {
    // if we already have a client secret, the payment was initialized
    // or if we don't have an order id, probably the reservation was not created
    if (
      bookingCtx.state.data.reservation?.clientSecret ||
      !bookingCtx.state.data.reservation?.order?.id ||
      isOnFly.current
    ) {
      return
    }
    isOnFly.current = true
    Services.ClientBookingPaymentService.initializePayment(
      locationName,
      bookingCtx.state.data.reservation?.order?.id || ''
    )
      .then((data: Types.BookingReservationClientDto) => {
        bookingCtx.dispatch({ type: 'SET_RESERVATION_DATA', payload: { value: data } })
        setIsLoading(false)
      })
      .catch(captureError)
    // this could generate multiple re-entries, what is this hook try to avoid
    // keeping isOnFly to true avoid the re-execution
    // .finally(() => (isOnFly.current = false))
  }, [bookingCtx, bookingCtx.state.data.reservation?.order?.id, locationName])

  return {
    isLoading,
  }
}
