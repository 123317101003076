import { getAPIPath, requestGooseAPI } from '../shared/request'
import { CLIENT_ORDER_SEARCH_PATH, APIOrderSearchClientDtoResponse, TOrderSearchOptions } from '../types'

export const ClientOrderSearchService = {
  getData: (locationName: string, options: TOrderSearchOptions): Promise<APIOrderSearchClientDtoResponse> => {
    const includes = new Set(['invoices.location', 'invoices.period', 'primaryServiceType.serviceType'])
    options.includes?.forEach((include) => includes.add(include))

    return requestGooseAPI<APIOrderSearchClientDtoResponse>({
      url: getAPIPath(CLIENT_ORDER_SEARCH_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        ...options,
        includes: Array.from(includes),
      },
    })
  },
}
