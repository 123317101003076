import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField'
import { ReadonlyTextField } from './readonly_text_field'

export type TextFieldProps = MUITextFieldProps & { readOnly?: boolean; renderEmptyReadOnly?: boolean; value?: string }

export const TextField = (props: TextFieldProps) => {
  const { readOnly, renderEmptyReadOnly, ...baseProps } = props
  return !readOnly ? (
    <MUITextField {...baseProps} />
  ) : (
    <ReadonlyTextField
      sx={baseProps.sx}
      label={baseProps.label}
      value={baseProps.value as string}
      renderIfEmpty={renderEmptyReadOnly}
    />
  )
}
