import { Box } from '@mui/material'
import type { TAssetImageProps } from './asset_image.d'

const DEFAULT_HEIGHT = 200

export const AssetImage = ({ asset, alt, height, testId }: TAssetImageProps) => {
  const offerImage = `https://${asset.bucket}/${asset?.key}`

  return (
    <Box sx={{ height: height || DEFAULT_HEIGHT, maxWidth: '100%', '& > img': { objectFit: 'contain' } }}>
      <img
        height="100%"
        width="100%"
        data-testid={testId || 'asset-image'}
        src={offerImage}
        alt={alt || asset.displayName || asset.name || ''}
        loading="lazy"
      />
    </Box>
  )
}
