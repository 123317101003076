import { Types } from 'service-api'
import { isCheckInOutTimeEnabled, isVetEnabled } from 'components-ui/src/shared'
import { ClientAssetService, ClientAssetUsageService } from 'service-api/src/services'

export const buildOrderInput = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any,
  reservation: Types.BookingReservationClientDto,
  inputCardGroups: Types.FlexBookingConfClientDto[][]
): Types.UpdateBookingOrderClientInput => {
  const data: Types.UpdateBookingOrderClientInput = {
    userData: {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      data: {
        mailingAddress: {
          streetAddress: values.streetLine1,
          streetAddress2: values.streetLine2.trim() === '' ? null : values.streetLine2,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          country: values.country,
        },
      },
    },
  }

  if (isCheckInOutTimeEnabled(reservation.locationBookingConf!)) {
    let { checkInTime: ci, checkOutTime: co } = values
    ci = ci.trim()
    co = co.trim()

    if (ci || co) {
      const cio = Object.assign({}, ci ? { checkInTime: ci } : null, co ? { checkOutTime: co } : null)
      data.data = cio
    }
  }

  if (isVetEnabled(reservation.locationBookingConf!)) {
    let { vetName: vn, vetPhone: vp } = values
    vn = vn.trim()
    vp = vp.trim()

    if (vn || vp) {
      const vet = Object.assign({}, vn ? { name: vn } : null, vp ? { phone: vp } : null)
      data.data = Object.assign({}, data.data, { vet })
    }
  }

  const fieldNames = inputCardGroups
    .reduce((acc, groups) => acc.concat(groups.map(({ name }) => name)), [] as (string | undefined)[])
    .filter(Boolean) as string[]

  fieldNames.forEach((fieldName) => {
    data.flexBookingConfs ||= {}
    data.flexBookingConfs[fieldName] = [{ value: values[fieldName]?.trim() || '' }]
  })

  return data
}

export const inputCardsFilter = (bookingConf: Types.FlexBookingConfClientDto) => {
  return (
    bookingConf.type === 'FIELD' &&
    bookingConf.target === 'ORDER' &&
    (bookingConf.step === 'BOOKING' || bookingConf.step === 'ALL')
  )
}

export const inputCardGroupsBuilder = (bookingConf: Types.FlexBookingConfClientDto[]) => {
  const groups =
    bookingConf?.filter(inputCardsFilter).reduce<Record<string, Types.FlexBookingConfClientDto[]>>((acc, bc) => {
      const group = bc.group || ''
      acc[group] ||= []
      acc[group].push(bc)
      return acc
    }, {}) || {}
  return Object.values(groups)
}

export const createAsset = ({ accountName, file, orderId }: { accountName: string; file: File; orderId?: string }) =>
  ClientAssetService.create(accountName, 'USER', 'RECORD', file).then((createdAsset) =>
    ClientAssetUsageService.create(accountName, createdAsset.id!, {
      orderId,
      type: 'ORDER_ATTACHMENT',
    })
  )

export const getOfferIdFromErrorMsg = (errorMsg: string) => {
  const match = errorMsg.match(/(c\w{24,})/)
  return match ? match[1] : null
}
