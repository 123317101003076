import * as yup from 'yup'
import { i18WithDefault as t } from '../shared'

export const mfaSchema = yup.object({
  code: yup.string().required(t('mfaCode.code.requiredMsg')),
})

export const initialValues = () => {
  return {
    code: '',
  }
}
