// Maximum with a widget can expand on desktop view
export const MAX_WIDGET_SIZE = 1000

// Sticky header height (6 * 8 = 48px)
export const STICKY_HEADER_HEIGHT = 6
export const STICKY_HEADER_HEIGHT_PX = 48

// Sticky footer on booking orders (13 * 8 = 104px)
export const STICKY_FOOTER_HEIGHT = 13
export const STICKY_FOOTER_HEIGHT_PX = 13 * 8
export const STICKY_FOOTER_MOBILE_HEIGHT = 9
export const STICKY_FOOTER_MOBILE_HEIGHT_PX = 9 * 8
