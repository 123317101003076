import { useEffect } from 'react'

import { useSessionContext } from '@providers/session'
import { useRoutes } from './use_routes'
import { useParams } from 'react-router-dom'

export const useAppRedirect = () => {
  const ctx = useSessionContext()
  const { accountName = '' } = useParams()
  const { navigateRoute } = useRoutes()

  useEffect(() => {
    if (ctx.state.status === 'DEAUTHENTICATED') {
      navigateRoute('locationHome', { accountName })
    }
  }, [accountName, ctx.state.status, navigateRoute])
}
