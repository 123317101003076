import * as yup from 'yup'
import { i18WithDefault as t } from '@shared/locale'

const conditionalValidation = {
  is: false,
  then: (field: yup.StringSchema) => field.required(),
}

const conditionalValidation2 = {
  is: (arg: string) => arg === 'prePaid',
  then: (field: yup.StringSchema) => field.optional(),
}

export const paymentSchema = yup.object({
  nameOnCard: yup
    .string()
    .max(200)
    .required()
    .label(t('paymentForm.nameOnCard'))
    .when('paymentMethod', conditionalValidation2),
  useMailingAddress: yup.bool().required().default(true).label('paymentForm.sameAsMailing'),
  streetLine1: yup
    .string()
    .max(200)
    .label(t('global.label.streetLine1'))
    .when('useMailingAddress', conditionalValidation),
  streetLine2: yup.string().max(200).label(t('global.label.streetLine2')),
  city: yup.string().max(50).label(t('global.label.city')).when('useMailingAddress', conditionalValidation),
  state: yup.string().max(2).label(t('global.label.state')).when('useMailingAddress', conditionalValidation),
  zipCode: yup.string().max(5).label(t('global.label.zipCode')).when('useMailingAddress', conditionalValidation),
  country: yup.string().length(2).label(t('global.label.country')).when('useMailingAddress', conditionalValidation),
  paymentMethod: yup.string().default('payNow').label(t('paymentForm.paymentMethod')),
})

export type TPaymentFormSchema = yup.InferType<typeof paymentSchema>

export const setupInitialValues = () => {
  return {
    nameOnCard: '',
    useMailingAddress: true,
    streetLine1: '',
    streetLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    paymentMethod: 'payNow',
  }
}
