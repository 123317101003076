import { Box, Skeleton, Stack } from '@mui/material'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { InvoiceCardSkeleton, FlexCardDisplaySkeleton, PurchaseReceiptSkeleton } from 'components-ui'

export const PurchaseSummarySkeleton = () => {
  return (
    <Stack spacing={3} sx={{ maxWidth: MAX_WIDGET_SIZE, pb: '130px', margin: '0 auto' }}>
      <Skeleton variant="text" width={500} sx={{ mt: 3 }} />
      <Stack spacing={3}>
        <InvoiceCardSkeleton />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack spacing={3} sx={{ flex: 1 }}>
          <FlexCardDisplaySkeleton />
          <FlexCardDisplaySkeleton />
        </Stack>
        <Box sx={{ flex: 1 }}>
          <PurchaseReceiptSkeleton />
        </Box>
      </Stack>
    </Stack>
  )
}
