import { getAxiosErrorMessage } from 'service-api/src/shared/request'
import { i18WithDefault as t } from '..'

// Backend to Frontend error mapping can be added here under the /error folder once implemented
export const buildErrorMessage = (error: unknown) => {
  const msg = getAxiosErrorMessage(error) || (error as Error)?.message || null
  if (import.meta.env.VITE_ENVIRONMENT === 'development') {
    // eslint-disable-next-line no-console
    console.error(msg || error)
  }
  return msg?.replace(/[."]/g, (match) => (match === '.' ? ' ' : '')) || t('global.label.unexpectedError')
}
