import { useBookingContext } from '@providers/booking'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import { useLocationContext } from '@providers/location'
import { STICKY_HEADER_HEIGHT_PX } from '@shared/constants'
import { serviceTypeForHomePage } from '@shared/service_types'
import { useRoutes } from 'app/use_routes'
import { Spacer1, Spacer2, Spacer3 } from 'components-ui'
import { Footer } from 'components/footer'
import { Logo } from 'components/logo'
import { SearchBar } from 'components/search_bar'
import { ServiceTypeSelector } from 'components/service_type_selector'
import { useUserStatus } from 'hooks/use_userstatus'
import { Hooks } from 'service-api'
import { isNotFound } from 'service-api/src/shared/request'
import { i18WithDefault as t } from '@shared/locale'
import { captureError } from 'service-api/src/shared'

export const Booking = () => {
  const { accountName = '', serviceTypeName = 'boarding' } = useParams()
  const ctx = useBookingContext()
  const userStatus = useUserStatus()
  const { buildPath } = useRoutes()
  const { location, locationTheme, isLoading: isLoadingLocation } = useLocationContext()
  const serviceTypes = Hooks.useClientLocationServiceType(accountName, {
    restrictions: true,
  })
  const configs = Hooks.useClientBookingSearchConfigs(accountName, serviceTypeName)

  const st = serviceTypes.data?.results?.find((st) => st.serviceType?.name === serviceTypeName)
  const serviceTypeConfig = Hooks.useClientLocationBookingConf(accountName, st?.id)
  const species = Hooks.useClientLocationSpecies(accountName, true)
  const petProfiles = Hooks.useClientPetProfiles(accountName, userStatus.isLoggedIn && species.isSuccess)

  useEffect(() => {
    if (configs.isError && isNotFound(configs.error)) {
      if (serviceTypes.isSuccess) {
        const stRedirect = serviceTypeForHomePage(serviceTypes.data.results)
        if (stRedirect) {
          window.location.href = buildPath('searchServiceType', {
            accountName,
            serviceTypeName: stRedirect?.serviceType?.name || 'boarding',
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs.isError, serviceTypes.isSuccess])

  useEffect(() => {
    if ((configs.data, configs.isSuccess)) {
      ctx.dispatch({
        type: 'SET_BOOKING_CONFIG',
        payload: {
          value: configs.data,
          serviceTypeName,
          isNewCustomer: !userStatus.isLoggedIn,
        },
      })
    }
    // this has to take place after booking config to render only correct species for the services offered
    if (petProfiles.data) {
      ctx.dispatch({
        type: 'SET_PET_PROFILES',
        payload: { value: petProfiles.data.results },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs.data, petProfiles.data, userStatus.isLoggedIn])

  useEffect(() => {
    if (serviceTypeConfig.data) {
      ctx.dispatch({
        type: 'SET_SERVICE_TYPE_CONFIG',
        payload: { value: serviceTypeConfig.data?.results },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypeConfig.data?.results])

  useEffect(() => {
    if (species.data?.results?.length) {
      ctx.dispatch({
        type: 'SET_SPECIES_CONFIG',
        payload: { value: species.data?.results },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [species.data])

  useEffect(() => {
    if (location) {
      ctx.dispatch({
        type: 'SET_LOCATION_CONFIG',
        payload: { value: location },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (serviceTypes.data) {
      const temperTest = serviceTypes.data.results.find((s) => s.serviceType!.name === 'temper-test')
      ctx.dispatch({ type: 'SET_TEMPER_TEST_DATA', payload: { value: temperTest } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypes.data])

  useEffect(() => {
    if (st) {
      ctx.dispatch({ type: 'SET_SERVICE_TYPE', payload: { value: st } })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [st?.id])

  useEffect(() => {
    document.title = t('title.startBooking')
  }, [])

  useEffect(() => {
    if (location?.subscriberGroup?.everflowEnabled) {
      if (typeof EF !== 'undefined') {
        EF.click({
          offer_id: EF.urlParameter('oid'),
          affiliate_id: EF.urlParameter('affid'),
          sub1: EF.urlParameter('sub1'),
          sub2: EF.urlParameter('sub2'),
          sub3: EF.urlParameter('sub3'),
          sub4: EF.urlParameter('sub4'),
          sub5: EF.urlParameter('sub5'),
          uid: EF.urlParameter('uid'),
          source_id: EF.urlParameter('source_id'),
          transaction_id: EF.urlParameter('_ef_transaction_id'),
        })
      } else {
        captureError({ cause: 'EF.click is not available' } as Error)
      }
    }
  }, [location?.subscriberGroup?.everflowEnabled])

  return (
    <Box
      sx={{
        height: `calc(100vh - ${STICKY_HEADER_HEIGHT_PX}px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Spacer1 />
        <Logo isLoading={isLoadingLocation} asset={locationTheme?.logo?.[0]?.asset} location={location} />
        <Spacer2 />
        <ServiceTypeSelector isLoading={serviceTypes.isLoading} services={serviceTypes.data?.results || []} />
        <Spacer3 />
        <SearchBar isLoading={userStatus.isLoggedIn ? !petProfiles.isSuccess : false} />
        <Spacer3 />
      </div>
      <div>
        <Footer />
        <Spacer3 />
      </div>
    </Box>
  )
}
