import { useTheme } from '@mui/material'
import { DefaultThemeOptions } from '@themes/theme'
import { i18WithParams as t } from '@shared/locales'
import { InvoiceClientDto } from 'service-api/src/types'
import { ColorChip } from 'color_chip'
import { getInvoiceDates } from 'shared'

export const InvoiceStatus = ({ invoice }: { invoice: InvoiceClientDto }) => {
  const theme: DefaultThemeOptions = useTheme()
  const { primary, success, error } = theme.palette!
  const today = new Date().getTime(),
    invoiceDates = getInvoiceDates(invoice)
  const startDate = invoiceDates.startDate.getTime(),
    endDate = invoiceDates.endDate.getTime()

  if (invoice.invoiceStatus === 'CONFIRMED') {
    if (today < startDate) {
      return <ColorChip label={t('global.label.upcoming')} bgColor={primary.shade12} textColor={primary.main} />
    } else if (today >= startDate && today <= endDate) {
      return <ColorChip label={t('global.label.inProgress')} bgColor={success.shade12} textColor={success.main} />
    } else {
      return <ColorChip label={t('global.label.completed')} />
    }
  } else if (invoice.invoiceStatus === 'CANCELED') {
    return <ColorChip label={t('global.label.canceled')} bgColor={error.shade12} textColor={error.main} />
  } else {
    return null
  }
}
