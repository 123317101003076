import React, { useState, useEffect } from 'react'

export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100]

export const usePagination = (
  numRows: number,
  loadMore: (limit: number) => void,
  rowsPerPageConfig?: number,
  config: { shouldPersist?: boolean; key?: string } = {}
) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageConfig || ROWS_PER_PAGE_OPTIONS[0])
  const { shouldPersist, key } = config

  useEffect(() => {
    if (key && shouldPersist) {
      const persistedValue = localStorage.getItem(key) || rowsPerPageConfig || ROWS_PER_PAGE_OPTIONS[0]
      setRowsPerPage(Number(persistedValue))
    }
  }, [key, rowsPerPageConfig, shouldPersist])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const missingItemsOnNewPage = newPage * rowsPerPage + rowsPerPage - numRows
    if (missingItemsOnNewPage > 0) {
      loadMore(missingItemsOnNewPage)
    }
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const val = parseInt(event.target.value, 10)
    if (val > numRows) {
      loadMore(val - numRows)
    }
    setRowsPerPage(val)
    setPage(0)
    if (shouldPersist && key) {
      localStorage.setItem(key, `${val}`)
    }
  }

  const isLastPage = (page + 1) * rowsPerPage >= numRows

  return {
    page,
    resetPage: () => {
      setPage(0)
    },
    isLastPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}
