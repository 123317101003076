import { Edit } from '@mui/icons-material'
import { IconButton, Skeleton, Typography } from '@mui/material'
import { CatIcon, DogIcon, capitalize, dayToJSDate } from 'components-ui'
import { useState } from 'react'
import { APISpeciesClientDtoResponse, PetClientDto } from 'service-api/src/types'
import { PetForm } from '@components/pet_form/pet_form'
import { Card, CardContainer, CardsListContainer } from './common'

type PetCardProps = {
  pet: PetClientDto
  avatar?: JSX.Element
  bodyLines: string[]
  petFamilyId: string
  speciesResponse?: APISpeciesClientDtoResponse
  isLoadingSpeciesResponse?: boolean
  onUpdatePet: (pet: PetClientDto) => void
  onDeletePet: (petId: string) => void
}

type PetCardListProps = {
  pets: PetClientDto[]
  isLoadingPetsResponse: boolean
  speciesResponse?: APISpeciesClientDtoResponse
  isLoadingSpeciesResponse?: boolean
  onUpdatePet: (pet: PetClientDto) => void
  onDeletePet: (petId: string) => void
}

const PetCard = ({
  pet,
  avatar,
  bodyLines,
  petFamilyId,
  speciesResponse,
  isLoadingSpeciesResponse,
  onUpdatePet,
  onDeletePet,
}: PetCardProps) => {
  const [editMode, setEditMode] = useState(false)

  const handleEditClick = () => {
    setEditMode(true)
  }

  const handleCancelClick = () => {
    setEditMode(false)
  }

  const handleSaveClick = (newPet: PetClientDto) => {
    setEditMode(false)
    onUpdatePet(newPet)
  }

  const handleDeleteClick = (petId: string) => {
    setEditMode(false)
    onDeletePet(petId)
  }

  if (editMode)
    return (
      <PetForm
        pet={pet}
        onCancel={handleCancelClick}
        onDelete={handleDeleteClick}
        onSave={handleSaveClick}
        petFamilyId={petFamilyId}
        speciesResponse={speciesResponse}
        isLoadingSpeciesResponse={isLoadingSpeciesResponse}
      />
    )

  return (
    <Card
      key={pet.id}
      height={222}
      avatar={avatar}
      title={pet.displayName}
      action={
        <IconButton onClick={handleEditClick}>
          <Edit />
        </IconButton>
      }
    >
      {bodyLines?.map((line) => (
        <Typography key={line} variant="body1">
          {line}
        </Typography>
      ))}
    </Card>
  )
}

export const PetCardList = ({
  pets,
  isLoadingPetsResponse,
  isLoadingSpeciesResponse,
  speciesResponse,
  onUpdatePet,
  onDeletePet,
}: PetCardListProps) => {
  if (isLoadingPetsResponse)
    return (
      <CardsListContainer>
        {Array(4)
          .fill(0)
          .map((_, i) => (
            <Skeleton key={i} variant="rounded" width={349} height={222} />
          ))}
      </CardsListContainer>
    )

  if (!pets.length) return null

  return (
    <CardsListContainer>
      {pets
        .filter((pet) => pet.petProfile && pet.petProfile.length > 0)
        .map((pet) => {
          const specie = pet.petProfile![0].breed?.species?.name
          const weight = pet.petProfile![0].weight
          const birthdate = pet.petProfile![0].birthdate
          const sex = pet.petProfile![0].sex
          const altered = pet.petProfile![0].altered
          let avatar
          let alteredStatus = 'Intact'

          if (specie === 'cat') {
            avatar = <CatIcon variant="secondary" />
          } else if (specie === 'dog') {
            avatar = <DogIcon variant="secondary" />
          }

          if (altered && sex === 'MALE') {
            alteredStatus = 'Neutered'
          } else if (altered && sex === 'FEMALE') {
            alteredStatus = 'Spayed'
          }

          const alteredDisplayName = sex?.length ? `${capitalize(sex)} ${alteredStatus}` : alteredStatus

          const bodyLines = [
            pet.petProfile![0].breed?.displayName,
            weight ? `${weight} lbs` : '',
            birthdate
              ? dayToJSDate(birthdate)?.toLocaleDateString('en-US', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })
              : '',
            alteredDisplayName,
          ].filter((data) => data && data?.trim() !== '') as string[]

          return (
            <CardContainer key={pet.id}>
              <PetCard
                pet={pet}
                avatar={avatar}
                bodyLines={bodyLines}
                petFamilyId={pet.petProfile![0].petFamilyId!}
                speciesResponse={speciesResponse}
                isLoadingSpeciesResponse={isLoadingSpeciesResponse}
                onUpdatePet={onUpdatePet}
                onDeletePet={onDeletePet}
              />
            </CardContainer>
          )
        })}
    </CardsListContainer>
  )
}
