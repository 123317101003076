import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'
import { BoardingIcon } from 'components-ui/src/asset/boarding_icon'
import LightModeIcon from '@mui/icons-material/LightMode'

import { Types } from 'service-api'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { useRoutes } from 'app/use_routes'
import { styled } from '@mui/material/styles'
import { DefaultThemeOptions } from 'components-ui'
import { useBookingContext } from '@providers/booking'
import TagManager from 'react-gtm-module'

const StyledButton = styled(Button)(({ theme }: { theme: DefaultThemeOptions }) => ({
  backgroundColor: theme.palette?.primary?.shade30,
  borderRadius: '30px',
  padding: '16px 24px',
  border: `1px solid ${theme.palette?.primary?.main}`,
  boxShadow: 'none',
  color: theme.palette?.primary?.main,
  '&:hover': {
    backgroundColor: theme.palette?.primary?.shade30,
  },
}))

const availableServiceTypes = ['boarding', 'daycare']

export const ServiceTypeSelector = ({
  isLoading,
  services,
}: {
  isLoading: boolean
  services: Types.LocationServiceTypeClientDto[]
}) => {
  const bookingCtx = useBookingContext()
  const { serviceTypeName } = useParams()
  const { navigateRoute } = useRoutes()
  const onClickButton = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget: t } = ev
      if (serviceTypeName !== t.id) {
        bookingCtx.dispatch({ type: 'RESET_STATE' })
        navigateRoute('searchServiceType', { serviceTypeName: t.id })
        TagManager.dataLayer({
          dataLayer: {
            event: 'SELECT_SERVICE_TYPE',
            serviceType: {
              name: t.id,
            },
          },
        })
      }
    },
    [bookingCtx, navigateRoute, serviceTypeName]
  )

  const renderPlaceholders = () => {
    return (
      <>
        <Skeleton width={130} height={54} sx={{ transform: 'scale(1,1)', borderRadius: '30px' }} />
        <Skeleton width={130} height={54} sx={{ transform: 'scale(1,1)', borderRadius: '30px' }} />
      </>
    )
  }

  const renderButtons = () => {
    return services.map((st) => {
      if (availableServiceTypes.includes(st.serviceType!.name!)) {
        const buttonProps: GenericSimpleBag = {
          id: st.serviceType?.name,
          startIcon: st.serviceType?.name === 'boarding' ? <BoardingIcon /> : <LightModeIcon />,
          variant: serviceTypeName === st.serviceType?.name ? 'contained' : 'text',
          size: 'large',
          onClick: onClickButton,
        }

        if (serviceTypeName === st.serviceType?.name) {
          return (
            <StyledButton key={st.serviceType?.id} {...buttonProps}>
              {st.serviceType?.displayName}
            </StyledButton>
          )
        } else {
          return (
            <Button key={st.serviceType?.id} {...buttonProps} sx={{ px: 3, py: 2 }}>
              {st.serviceType?.displayName}
            </Button>
          )
        }
      }
      return null
    })
  }

  if (!isLoading && services.length < 2) {
    return null
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
      {isLoading ? renderPlaceholders() : renderButtons()}
    </Stack>
  )
}
