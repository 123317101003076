import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useBookingContext } from '@providers/booking'

export const ErrorMessages = ({ groupIdx }: { groupIdx: number }) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()

  if (bookingCtx.state.groups[groupIdx].errors.length === 0) {
    return null
  }

  return (
    <Stack direction="column" width="100%">
      {bookingCtx.state.groups[groupIdx].errors.map((error) => (
        <Stack
          key={error.kind}
          direction="row"
          alignItems="center"
          padding={1}
          mb={2}
          sx={{ backgroundColor: `${theme.palette.error.shade12}`, borderRadius: '4px' }}
        >
          <InfoOutlinedIcon color="error" fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2">{error.message}</Typography>
        </Stack>
      ))}
    </Stack>
  )
}
