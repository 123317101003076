import { Box, Paper, Stack, Typography } from '@mui/material'
import { useLocationContext } from '@providers/location'
import { Logo } from 'components/logo'
import { i18WithParams as t } from '@shared/locale'
import { ContinueButton } from 'components-ui/src/continue_button'
import { Auth } from 'aws-amplify'
import { VerifyEmailForm } from 'components-ui/src/signin_tooltip/verify_email_form'
import { generatePassword } from 'components-ui/src/shared/common'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TagManager from 'react-gtm-module'

type TFormikValues = {
  email: string
  inputCode: boolean
  code: string
}

const SignUp = ({ email, userExists, onSuccess }: { email: string; userExists?: boolean; onSuccess: () => void }) => {
  const { location, locationTheme, isLoading: isLoadingLocation } = useLocationContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showVerifyForm, setShowVerifyForm] = useState<boolean>(false)
  const [cognitoUser, setCognitoUser] = useState(null)

  const formik = useFormik<TFormikValues>({
    initialValues: {
      email,
      inputCode: false,
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().when('inputCode', {
        is: true,
        then: Yup.string().length(6, t('signupComponent.label.invalidCode')).required(t('global.label.required')),
      }),
    }),
    onSubmit: ({ code }, { setSubmitting }) => {
      TagManager?.dataLayer({
        dataLayer: {
          event: 'LOGIN_CODE',
        },
      })
      if (cognitoUser) {
        Auth.sendCustomChallengeAnswer(cognitoUser, code.replace(/\s/g, ''))
          .then((result) => {
            if (!result.signInUserSession) {
              setSubmitting(false)
              formik.setFieldError('code', t('signupComponent.label.wrongCode'))
            }

            setTimeout(() => {
              setSubmitting(false)
              onSuccess()
              TagManager?.dataLayer({
                dataLayer: {
                  event: 'login',
                },
              })
            }, 2000)
          })
          .catch((_error) => {
            setSubmitting(false)
            formik.setFieldError('code', t('signupComponent.verifyCodeError'))
          })
      } else {
        Auth.confirmSignUp(email, code)
          .then((_result) =>
            setTimeout(() => {
              setSubmitting(false)
              onSuccess()
              TagManager?.dataLayer({
                dataLayer: {
                  event: 'sign_up',
                },
              })
            }, 2000)
          )
          .catch((_error) => {
            setSubmitting(false)
            formik.setFieldError('code', t('signupComponent.verifyCodeError'))
          })
      }
    },
  })

  const handleSignUp = () => {
    setIsLoading(true)
    TagManager?.dataLayer({
      dataLayer: {
        event: 'LOGIN_START_PURCHASE_SUMMARY',
      },
    })
    Auth.signUp({
      username: email,
      password: generatePassword(),
      attributes: {
        email: email,
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then((_result) => {
        setShowVerifyForm(true)
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.code === 'UsernameExistsException') {
          Auth.signIn(email.toLowerCase().trim()).then((result) => {
            setIsLoading(false)
            setCognitoUser(result)
            setShowVerifyForm(true)
          })
        }
      })
  }

  return (
    <Paper
      elevation={24}
      sx={{
        px: 2,
        py: 3,
        width: 480,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Stack spacing={2} alignItems="center">
        <Box sx={{ width: '150px', margin: '0 auto' }}>
          <Logo
            isLoading={isLoadingLocation}
            asset={locationTheme?.logo?.[0]?.asset}
            location={location}
            heightOverrides={150}
          />
        </Box>
        {showVerifyForm ? (
          <>
            <Stack spacing={2} px={4} alignItems="center">
              <Typography variant="h5">{t('signupComponent.confirmItsYou')}</Typography>
              <Typography variant="body1" textAlign="center">
                {t('signupComponent.confirmSubtitle', { email })}
              </Typography>
            </Stack>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
              <VerifyEmailForm
                formik={formik}
                simpleView
                hideUseDifferentEmail
                sx={{ width: '100%' }}
                isNewUser
                setCognitoUser={setCognitoUser}
              />
            </form>
          </>
        ) : (
          <>
            <Stack spacing={2} px={4} alignItems="center">
              <Typography variant="h5">
                {t(userExists ? 'signupComponent.login' : 'signupComponent.createAccount')}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {t('signupComponent.subtitle')}
              </Typography>
              <Typography variant="h6">{email}</Typography>
            </Stack>
            <ContinueButton sx={{ width: '100%' }} onClick={handleSignUp} loading={isLoading} />
          </>
        )}
      </Stack>
    </Paper>
  )
}

export default SignUp
