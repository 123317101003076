import { Box, FormControl, InputLabel, styled } from '@mui/material'
import { customColors } from 'components-ui'
// import { themeShades } from 'App/theme'

const StyledBox = styled(Box)({
  height: '56px',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  padding: '16.5px 14px',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '1.4375em',
  color: customColors.textPrimary,
  position: 'relative',
  cursor: 'text',
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  boxShadow: 'inset 0px 0px 44px 10px rgb(0 0 0 / 2%)',
  borderRadius: '8px',
})

const StyledLabel = styled(InputLabel)({
  color: 'rgba(0, 0, 0, 0.6)',
  backgroundColor: 'white',
  // fontFamily: themeShades.typography.fontFamily,
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.4375em',
  letterSpacing: '0.00938em',
  padding: 0,
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(133% - 24px)',
  position: 'absolute',
  left: 0,
  top: 0,
  transform: 'translate(14px, -9px) scale(0.75)',
  transition:
    // eslint-disable-next-line max-len
    'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  zIndex: 1,
  pointerEvents: 'auto',
  userSelect: 'none',
})

interface TProps {
  label: string
  id: string
}

export const TilledInput = ({ id, label }: TProps) => {
  return (
    <FormControl fullWidth>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledBox id={id} />
    </FormControl>
  )
}
