import { Box, Card as MuiCard, CardContent, CardHeader } from '@mui/material'
import { ReactNode } from 'react'

type CardProps = {
  children: React.ReactNode
  avatar?: React.ReactNode
  title?: string
  subheader?: string
  height?: number
  action: JSX.Element & ReactNode
}

export const CardsListContainer = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ display: 'flex', gap: 1.25, flexWrap: 'wrap' }}>{children}</Box>
)

export const CardContainer = ({ children }: { children: React.ReactNode }) => <Box sx={{ width: 349 }}>{children}</Box>

export const Card = ({ children, avatar, height, title, subheader, action }: CardProps) => (
  <MuiCard sx={{ height }}>
    <CardHeader avatar={avatar} action={action} title={title} subheader={subheader} />
    <CardContent style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '16px' }}>{children}</CardContent>
  </MuiCard>
)
