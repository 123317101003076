import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AdminInvoiceService } from '../services'
import {
  APIInvoiceAdminDtoResponse,
  CustomInvoiceAdminDto,
  OrderAdminDto,
  TInvoiceListOptions,
  TInvoicePetHistory,
  TInvoicePetHistoryList,
  TQueryConfig,
} from '../types'
import { defaultQueryOptions } from './common'
import { getInvoiceDates } from 'components-ui'

export const useAdminCancelInvoice = (
  locationName: string,
  orderId: string,
  invoiceId: string,
  amount?: number | null,
  invoiceSubStatus?: 'CANCELED' | 'DECLINED' | null
): UseQueryResult<OrderAdminDto> => {
  return useQuery<OrderAdminDto>({
    queryKey: ['adminCancelInvoice', locationName, orderId, invoiceId, amount],
    queryFn: () => AdminInvoiceService.cancelInvoice(locationName, orderId, invoiceId, { amount, invoiceSubStatus }),
    ...defaultQueryOptions,
    enabled: false,
  })
}

export const useAdminInvoice = (locationName: string, invoiceId: string): UseQueryResult<CustomInvoiceAdminDto> => {
  return useQuery<CustomInvoiceAdminDto>({
    queryKey: ['adminInvoice', locationName, invoiceId],
    queryFn: () => AdminInvoiceService.get(locationName, invoiceId),
    enabled: Boolean(invoiceId),
    ...defaultQueryOptions,
  })
}

export const useAdminInvoiceList = (locationName: string, options: TInvoiceListOptions, config?: TQueryConfig) => {
  const mergedConfig = Object.assign({ enabled: true }, config)

  return useQuery<APIInvoiceAdminDtoResponse>({
    queryKey: ['adminInvoiceList', locationName, options],
    queryFn: () => AdminInvoiceService.getList(locationName, options),
    enabled: mergedConfig.enabled,
    ...defaultQueryOptions,
  })
}

export const useAdminInvoicePetHistory = (
  locationName: string,
  serviceTypeid?: string,
  locationPetProfileIds?: string[],
  config?: TQueryConfig
) => {
  const mergedConfig = Object.assign({ enabled: true }, config)

  return useQuery<TInvoicePetHistoryList>({
    queryKey: ['adminInvoicePetHistory', locationName, serviceTypeid, locationPetProfileIds],
    queryFn: () =>
      AdminInvoiceService.getServiceTypePetsHistory(
        locationName,
        serviceTypeid || '',
        locationPetProfileIds || []
      ).then(groupResponseByOrderId),
    enabled: mergedConfig.enabled && Boolean(serviceTypeid) && (locationPetProfileIds?.length ?? 0) > 0,
    ...defaultQueryOptions,
  })
}

const groupResponseByOrderId = (response: APIInvoiceAdminDtoResponse[]): TInvoicePetHistoryList => {
  return response.reduce((acc, invoiceResponse) => {
    invoiceResponse.results.forEach((invoice) => {
      const today = new Date()
      const { endDate } = getInvoiceDates(invoice)
      if (endDate > today && invoice.invoiceSubStatus !== 'COMPLETED') {
        return
      }
      invoice?.items?.forEach((invoiceItem) => {
        invoiceItem?.petRelations?.forEach((petRelation) => {
          const pid = petRelation.invoicePet?.locationPetProfile?.id || ''
          acc[pid] = acc[pid] || { pet: null, history: [] as TInvoicePetHistory[] }
          acc[pid].pet = petRelation.invoicePet!.locationPetProfile!
          const order = acc[pid].history.find((item) => item.orderId === invoice.orderId)
          if (!order) {
            acc[pid].history.push({ orderId: invoice.orderId!, invoices: [invoice] })
          } else {
            order.invoices.push(invoice)
          }
        })
      })
    })
    return acc
  }, {} as TInvoicePetHistoryList)
}
