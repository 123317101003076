import { Avatar, CardHeader, Link, Typography, useTheme } from '@mui/material'
import { TBookingPet } from '@providers/booking/types'
import { Types } from 'service-api'

type TPetAvatar = {
  name: string
  breed: string
  weight: string
  profileImageUrl?: string
}

export const PetAvatarInvoice = ({ invoicePet }: { invoicePet?: Types.InvoicePetClientDto }) => {
  const petProfile = invoicePet?.pet?.petProfile?.[0]
  const asset = petProfile?.profileImage?.[0]?.asset || invoicePet?.locationPetProfile?.profileImage?.[0]?.asset
  const imageBucket = asset?.bucket
  const imageKey = asset?.key

  const mapper = {
    name: invoicePet?.displayName || invoicePet?.name || '',
    breed: invoicePet?.breed?.displayName || invoicePet?.breed?.name,
    weight: invoicePet?.data?.weight ? `${invoicePet?.data?.weight} lbs` : '',
    profileImageUrl: imageBucket && imageKey ? `https://${imageBucket}/${imageKey}` : '',
  } as TPetAvatar

  return <PetAvatar pet={mapper} />
}

export const PetAvatarBooking = ({
  pet,
  subheaderAction,
  subheaderActionText,
}: {
  pet: TBookingPet
  subheaderAction?: () => void
  subheaderActionText: string
}) => {
  const mapper = {
    name: pet.name,
    breed: pet.breed,
    weight: pet.weight ? `${pet.weight} lbs` : '',
    profileImageUrl: undefined,
  } as TPetAvatar

  return <PetAvatar pet={mapper} subheaderAction={subheaderAction} subheaderActionText={subheaderActionText} />
}

interface TAvatarProps {
  pet: TPetAvatar
  subheaderAction?: () => void
  subheaderActionText?: string
}
export const PetAvatar = (props: TAvatarProps) => {
  const { pet, subheaderAction, subheaderActionText } = props
  const { palette } = useTheme()

  const breedAndWeight = [pet.breed, pet.weight].filter(Boolean).join(', ')

  return (
    <CardHeader
      sx={{ p: 0 }}
      avatar={
        <Avatar sx={{ bgcolor: palette.text.secondaryShade60, color: 'white' }} src={pet.profileImageUrl}>
          {pet.name.charAt(0)}
        </Avatar>
      }
      title={<Typography variant="h6">{pet.name}</Typography>}
      subheader={
        <Typography variant="caption">
          {breedAndWeight}
          {subheaderAction && (
            <Link
              onClick={() => subheaderAction?.()}
              color="text.primary"
              sx={{
                cursor: 'pointer',
                marginLeft: '3px',
              }}
            >
              {subheaderActionText}
            </Link>
          )}
        </Typography>
      }
    />
  )
}
