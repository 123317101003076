/* eslint-disable max-len */
type TranslationKeys = Record<string, string>

export const enLocale: TranslationKeys = {
  'booking.refundPolicy': 'Cancellation & Refund Policy',
  'booking.startingAtPolicy':
    'Pricing for some items will be finalized upon consultation & reflected at checkout. The prices here reflect the ‘starting price’ for the service.',
  'cancelInvoice.confirmCancel': 'Confirm cancellation',
  'cancelInvoice.pastWindow.title': 'Past Cancellation Window',
  'cancelInvoice.pastWindow.description': 'You are cancelling this reservation <strong>after {{date}}.</strong>',
  'cancelInvoice.cancelDescription':
    'Cancelling this booking will process a refund of <strong>{{amount}}.</strong>  Are you sure you want to continue with cancellation? ',
  'cancelInvoice.refundAmount': 'Refund Amount',
  'cancelInvoice.overrideRefund.button': 'Override refund amount',
  'cancelInvoice.overrideRefund.title': 'Override Refund Amount',
  'cancelInvoice.success': 'Booking #{{num}} was successfully cancelled',
  'cancelInvoice.grooms.description': 'You also have {{count}} grooming appointment(s) scheduled for this visit.',
  'cancelInvoice.grooms.notice': 'Also cancel grooming appointment(s)',
  'cancelInvoice.enqueue.single.success': '{{primary}} booking canceled.',
  'cancelInvoice.enqueue.multi.success': '{{primary}} booking and grooming request(s) canceled.',
  'invoiceCard.confirmationNum': 'Booking #{{num}}',
  'invoiceCard.cancelByCustomer': 'Canceled on {{date}} by Customer.',
  'invoiceCard.cancelByStaff': 'Canceled on {{date}} by Staff.',
  'invoiceCard.cancelByOther': 'Canceled on {{date}} by Other.',
  'invoiceCard.refunded': 'Refunded {{refund}} of {{deposit}} Deposit',
  'invoiceCard.viewInvoiceInOS': 'View Invoice in OS',
  'invoiceCard.viewPaymentTransaction': 'View Payment Transaction',
  'offerCard.noAvailable': 'Sorry, there is no more online availability; please call or email to inquire.',
  'offerCard.noAvailable.temperTest': 'Unable to book.',
  'offerCard.noAvailable.callToBook': 'Call To Book',
  'logInForm.emailField': 'Email',
  'logInForm.passwordField': 'Password',
  'logInForm.forgotPassword': 'Forgot password',
  'logInForm.disclaimer': 'By using Goose, you agree to our',
  'logInForm.privacyPolicy': 'privacy policy',
  'logInForm.tos': 'terms',
  'logInForm.privacyPolicyAndTerms': 'By using Goose, you agree to our {{policy}} and {{terms}}.',
  'loginForm.newAccount': 'Create New Online Account',
  'signUpForm.newAccount': 'Create New Online Account',
  'signUpForm.passwordConfirmation': 'Password Confirmation',
  'signUpForm.passwordDetails': 'At least 8 characters, 1 number, 1 symbol',
  'signUpForm.newUserQuestion': 'Have you been here before?',
  'signUpForm.wereNew': "We're new",
  'signUpForm.oldUser': "We've stayed before",
  'signUpForm.oldUser.title': 'Welcome back!',
  'signUpForm.oldUser.description':
    "We’ll match you up with our records we have and let you know if we have any questions. We've stayed before",
  'signUpForm.alreadyHaveAccount': 'Already have an account',
  'signUpForm.genericError': 'SignUp error',
  'mfaCode.title': 'Verify your account',
  'mfaCode.subtitle': 'Enter the code emailed to you. Verification codes sent via email are valid for a few minutes.',
  'mfaCode.code': 'Code',
  'mfaCode.resendCode': 'Request new code',
  'mfaCode.code.requiredMsg': 'Code is required',
  'mfaCode.genericError': 'Error while validating your code',
  'mfaCode.resendCode.success': 'Code send successfully',
  'confirmResetPassword.submitButton': 'Change Password',
  'confirmResetPassword.newPasswordField': 'New Password',
  'confirmResetPassword.repeatPasswordField': 'Repeat New Password',
  'confirmResetPassword.codeField': 'Code',
  'forgotPassword.header': 'Forgot Password?',
  'forgotPassword.description': 'Please enter your email address below and we will send a code to reset your password.',
  'forgotPassword.link': 'Remember password?',
  'forgotPasswordForm.emailField': 'Email',
  'forgotPasswordForm.submitButton': 'Forgot Password',
  'password.minLength.error': 'Minimum length is 8 characters',
  'password.required.error': 'Password is required',
  'password.matching.error': 'Passwords must match',
  'password.email.error': 'Enter a valid email',
  'password.emailRequired.error': 'Email is required',
  'password.requirements.error': 'Password must contain at least 8 characters, maximum 200, 1 number, 1 symbol',
  'password_confirmation.requirements.error': 'Password Confirmation is required',
  'password.mustMatch': 'Passwords does not match',
  'passwordReset.message': 'We have sent a password reset code to {{email}}. Enter it below to reset your password.',
  'global.label.and': 'and',
  'global.label.amountPaid': 'Amount Paid',
  'global.label.adjustments': 'Adjustments',
  'global.label.bookingSummary': 'Booking Summary',
  'global.label.ageYearsMonths': '{{years}} yrs, {{months}} mo',
  'global.label.ageYears': '{{years}} yrs',
  'global.label.ageMonths': '{{months}} mo',
  'global.label.checkIn': 'Check-In',
  'global.label.checkOut': 'Check-Out',
  'global.label.contact': 'Contact',
  'global.label.details': 'Details',
  'global.label.payments': 'Payments',
  'global.label.dueToday': 'Due Today ({{percentage}})',
  'global.label.taxPercentage': 'Tax rate {{label}}: {{percentage}}',
  'global.label.subtotal': 'Subtotal',
  'global.label.total': 'Total',
  'global.label.includes': 'Includes',
  'global.label.select': 'Select',
  'global.label.selected': 'Selected',
  'global.label.strategy.night': 'night',
  'global.label.strategy.day': 'day',
  'global.label.signIn': 'Sign In',
  'global.label.signUp': 'Sign Up',
  'global.label.from': 'from',
  'global.label.many': 'Many',
  'global.label.per': 'per',
  'global.label.pets': 'Pets',
  'global.label.unscheduled': 'Unscheduled',
  'global.label.uploadedDate': 'uploaded {{date}}',
  'global.label.upload': 'Drag and drop your files here or',
  'global.label.chooseFile': 'Choose file',
  'global.error.upload.fileSize': '{{filename}} cannot be larger than 9MB',
  'global.error.upload.record.fileType':
    '{{filename}} is not supported. Please upload files of type .png, .jpg, .jpeg or .pdf',
  'global.label.for': 'For',
  'global.label.reservedOn': 'Reserved On',
  'global.label.cancelBy': 'Cancel By',
  'global.label.cancelBooking': 'Cancel Booking',
  'global.label.refundBooking': 'Refund Booking',
  'global.label.upcoming': 'Upcoming',
  'global.label.completed': 'Completed',
  'global.label.inProgress': 'In Progress',
  'global.label.canceled': 'Canceled',
  'global.label.partiallyCanceled': 'Partially Canceled',
  'global.label.cancel': 'Cancel',
  'global.label.save': 'Save',
  'global.label.continue': 'Continue',
  'global.label.goBack': 'Go Back',
  'global.label.veterinarian': 'Veterinarian',
  'global.label.N/A': 'N/A',
  'global.label.unexpectedError': 'Oops, something went wrong! Please try again later.',
  'global.label.SUN': 'Sunday',
  'global.label.MON': 'Monday',
  'global.label.TUE': 'Tuesday',
  'global.label.WED': 'Wednesday',
  'global.label.THU': 'Thursday',
  'global.label.FRI': 'Friday',
  'global.label.SAT': 'Saturday',
  'global.label.today': 'Today',
  'global.label.required': 'Required',
  'global.label.breed': 'Breed',
  'global.label.name': 'Name',
  'global.label.weight': 'Weight',
  'global.label.MALE': 'Male',
  'global.label.FEMALE': 'Female',
  'global.label.invoiceSubStatus.scheduled': 'Scheduled',
  'global.label.invoiceSubStatus.canceled': 'Canceled',
  'global.label.invoiceSubStatus.declined': 'Declined',
  'global.label.invoiceSubStatus.completed': 'Completed',
  'global.label.invoiceSubStatus.requested': 'Requested',
  'global.label.scheduleTime': '{{date}} at {{time}}',
  'global.label.groomingRequest': 'Grooming Request',
  'global.label.weightUnit': '{{value}} lbs',
  'groomNotice.header': 'Grooming Appointment',
  'groomNotice.text':
    'You are requesting a grooming appointment. Our staff will check availability and confirm prior to check in.',
  'groomNotice.pastText':
    'You have requested a grooming appointment. Our staff will check availability and confirm prior to check in.',
  'groomNotice.pill.fallback': 'Requested',
  'addPetForm.title': 'Add Pet',
  'addPetForm.success': 'Pet added.',
  'addPetForm.error': 'Error creating Pet.',
  'signin.enterEmail.title': 'Let’s start with your email!',
  'signin.enterEmail.email': 'Email Address',
  'signin.verify.title': 'Verify your account',
  'signin.confirm.title': 'Confirm its you',
  'signin.verify.modal.title': "We're excited to have you back!",
  'signin.verify.copy': 'Enter the code sent to your email',
  'signin.verify.code': 'Code',
  'signin.verify.requestNewCode': 'Request New Code',
  'signin.verify.correctEmail': 'Use a Different Email',
  'signin.verify.sent': 'Verification code sent! Please check email.',
  'global.label.invalidEmail': 'Invalid email address',
  'global.label.invalidCode': 'Code must have 6 digits',
  'global.label.wrongCode': 'Invalid verification code. Please check and re-enter.',
  'global.sendCodeError': 'Verification code could not be sent. Please try again.',
  'global.verifyCodeError': 'Code could not be verified. Please try again.',
  'convertMinutesToHoursAndMinutes.hour': 'hour',
  'convertMinutesToHoursAndMinutes.minute': 'minute',
  'convertMinutesToHoursAndMinutes.hoursAndMinutes': '{{hours}} and {{minutes}}',
  'surchargePolicy.title': 'Arrival and Departure Policy',
  'surchargePolicy.arrival': 'Arrival',
  'surchargePolicy.departure': 'Departure',
  'surchargePolicy.open': 'Open',
  'surchargePolicy.checkInHours': 'Check In Hours',
  'surchargePolicy.checkOutHours': 'Check Out Hours',
  'surchargePolicy.duration': 'To avoid late fees, please retrieve your pet within the amount of time you booked.',
  'feeStructure.earlyArrival.title': 'Early arrival fees are charged per pet',
  'feeStructure.lateDeparture.title': 'Late departure fees are charged per pet',
  'feeStructure.early': 'early',
  'feeStructure.late': 'late',
  'feeStructure.orMore': 'or more',
  'offers.startsAt': 'Starts at',
  'test.translation': 'This is a test translation.',
  'test.translation.withParams': 'This is a test translation, {{param}}.',
} as const
