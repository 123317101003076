import { Spacer } from 'components-ui'
import { Box, Button, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Hooks } from 'service-api'
import { useSessionContext } from '@providers/session'
import AccountHolderCard from './cards/account_holder_card'
import { PaymentMethodCardList } from './cards/payment_method_card'
import { PetCardList } from './cards/pet_card'
import { useEffect, useState } from 'react'
import { PetForm } from '@components/pet_form/pet_form'
import { PetClientDto, StoredPaymentMethodClientDto } from 'service-api/src/types'
import { i18WithParams as t } from '@shared/locale'
import { MAX_WIDGET_SIZE } from '@shared/constants'

export const ManageAccount = () => {
  const { accountName = '' } = useParams()
  const {
    state: { user: sessionUser },
  } = useSessionContext()
  const user = Hooks.useClientUser(accountName, sessionUser?.userId || '')
  const { data: storedPaymentMethodsResponse, isLoading: isLoadingStoredPaymentMethods } =
    Hooks.useClientStoredPaymentMethods(accountName)

  const petFamily = Hooks.useClientPetFamilies(accountName)
  const { data: petsResponse, isLoading: isLoadingPetsResponse } = Hooks.useClientPets(
    accountName,
    petFamily.data?.results[0]?.id
  )
  const { data: speciesResponse, isLoading: isLoadingSpeciesResponse } = Hooks.useClientSpecies(accountName, true)
  const [isAddingPet, setIsAddingPet] = useState(false)
  const [pets, setPets] = useState<PetClientDto[]>([])
  const [storedPaymentMethods, setStoredPaymentMethods] = useState<StoredPaymentMethodClientDto[]>([])

  useEffect(() => {
    document.title = t('title.accountOverview')
  }, [])

  useEffect(() => {
    setStoredPaymentMethods(storedPaymentMethodsResponse || [])
  }, [storedPaymentMethodsResponse])

  useEffect(() => {
    setPets(petsResponse?.results ?? [])
  }, [petsResponse])

  const handleAddPetClick = () => {
    setIsAddingPet(true)
  }

  const handleCancelAddPetClick = () => {
    setIsAddingPet(false)
  }

  const handleUpdatePet = (newPet: PetClientDto) => {
    setPets((prevPets) => prevPets.map((p) => (p.id === newPet.id ? newPet : p)))
  }

  const handleDeletePet = (petId: string) => {
    setPets((prevPets) => prevPets.filter((p) => p.id !== petId))
  }

  const handleAddPet = (newPet: PetClientDto) => {
    setPets((prevPets) => [...prevPets, newPet])
    setIsAddingPet(false)
  }

  const handleDeleteStoredPaymentMethod = (storedPaymentMethodId: string) => {
    setStoredPaymentMethods((prev) => prev.filter(({ id }) => id !== storedPaymentMethodId))
  }

  return (
    <>
      <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
        <Spacer height={8.5} />
        <Typography variant="h1">{t('manageAccount.title')}</Typography>
        <Spacer height={3} />
        <Typography variant="h2">{t('manageAccount.people')}</Typography>
        <Spacer height={3} />
        <Box sx={{ margin: 1.25 }}>
          <AccountHolderCard user={user.data} />
        </Box>
        <Spacer height={3} />
        <Typography variant="h2">{t('manageAccount.paymentMethods')}</Typography>
        <Spacer height={3} />
        <Box sx={{ margin: 1 }}>
          <PaymentMethodCardList
            storedPaymentMethods={storedPaymentMethods}
            isLoadingStoredPaymentMethods={isLoadingStoredPaymentMethods}
            onDelete={handleDeleteStoredPaymentMethod}
          />
        </Box>
        <Spacer height={3} />
        <Typography variant="h2">{t('manageAccount.pets')}</Typography>
        <Spacer height={3} />
        <Box sx={{ margin: 1.25 }}>
          <PetCardList
            pets={pets}
            isLoadingPetsResponse={isLoadingPetsResponse}
            speciesResponse={speciesResponse}
            isLoadingSpeciesResponse={isLoadingSpeciesResponse}
            onUpdatePet={handleUpdatePet}
            onDeletePet={handleDeletePet}
          />
        </Box>
        <Spacer height={3} />
        {isAddingPet ? (
          <Box sx={{ margin: 1.25 }}>
            <PetForm
              petFamilyId={petFamily.data?.results[0].id || ''}
              onCancel={handleCancelAddPetClick}
              onSave={handleAddPet}
              speciesResponse={speciesResponse}
              isLoadingSpeciesResponse={isLoadingSpeciesResponse}
            />
          </Box>
        ) : (
          <Button variant="text" size="large" onClick={handleAddPetClick}>
            {t('manageAccount.pets.add')}
          </Button>
        )}
        <Spacer height={16} />
      </Box>
    </>
  )
}
