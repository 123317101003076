import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

import { useBookingContext } from '@providers/booking'
import { i18WithParams as t } from '@shared/locale'
import { getThresholds } from '@shared/service_types'

interface TPetThresholdsProps {
  specieId: string
}

export const PetThresholds = ({ specieId }: TPetThresholdsProps) => {
  const bookingCtx = useBookingContext()
  const petCount = useMemo(
    () => getThresholds(bookingCtx.state.config?.booking?.petCount, specieId),
    [bookingCtx.state.config, specieId]
  )
  const totalWeight = useMemo(
    () => getThresholds(bookingCtx.state.config?.booking?.totalWeight, specieId),
    [bookingCtx.state.config, specieId]
  )
  const petWeight = useMemo(
    () => getThresholds(bookingCtx.state.config?.booking?.weight, specieId),
    [bookingCtx.state.config, specieId]
  )

  const legends = useMemo(() => {
    const rc = []
    if (petCount?.max) {
      rc.push(t('searchBar.thresholds.maxPet', { maxPets: String(Math.trunc(petCount?.max)) }))
    }
    if (petWeight?.max) {
      rc.push(t('searchBar.thresholds.maxPetWeight', { maxPetWeight: String(Math.trunc(petWeight?.max)) }))
    }
    if (totalWeight?.max) {
      rc.push(t('searchBar.thresholds.maxRoomWeight', { maxRoomWeight: String(Math.trunc(totalWeight?.max)) }))
    }
    return rc
  }, [petCount?.max, petWeight?.max, totalWeight?.max])

  if (!specieId) {
    return null
  }

  if (legends.length === 0) {
    return null
  }

  return (
    <Typography variant="caption" component="span">
      {t('searchBar.thresholds.perRom')} {legends.join(', ')}
    </Typography>
  )
}
