import * as yup from 'yup'
import { i18WithDefault as t } from '../shared'

export const signUpSchema = yup.object({
  email: yup.string().email(t('password.email.error')).required(t('password.emailRequired.error')),
})

export const initialValues = (email: string = '') => {
  return {
    email: email,
  }
}
