import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { MenuItem, TextField as MUITextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { dayToJSDate, toISODate } from '@shared/dates'
import { parseSelectOptions } from '@shared/flex_booking_conf'
import { Types } from 'service-api'
import { TextInputNumber, TextInputNumberProps } from 'textinput-number/textinput_number'
import { TextInputPhone, TextInputPhoneProps } from 'textinput-phone/textinput-phone'
import { ReadonlyTextField } from 'text_field'
import { TextField, TextFieldProps } from 'text_field/text_field'

type FlexFieldProps = (TextFieldProps | TextInputPhoneProps | TextInputNumberProps) & {
  config: Types.FlexBookingConfClientDto
  handleValueChange?: (val: string) => void
}

export const FlexField = ({ config, handleValueChange, ...props }: FlexFieldProps) => {
  const [date, setDate] = useState<Date | null | undefined>()

  useEffect(() => {
    if (config.subtype === 'DATE' && date === undefined) {
      setDate(dayToJSDate(props.value) || null)
    }
  }, [props.value, config.subtype, date])

  const baseProps = {
    id: config.name,
    name: config.name,
    label: config.description,
    helperText: props.helperText || config.content || undefined,
    fullWidth: true,
    FormHelperTextProps: { sx: { height: 'auto' } },
  }
  switch (config.subtype) {
    case 'TEXT': {
      return <TextField {...(props as TextFieldProps)} {...baseProps} />
    }
    case 'PHONE': {
      return <TextInputPhone {...(props as TextInputPhoneProps)} {...baseProps} />
    }
    case 'NUMBER': {
      return <TextInputNumber {...(props as TextInputNumberProps)} {...baseProps} />
    }
    case 'SELECT': {
      return (
        <TextField {...(props as TextFieldProps)} {...baseProps} select>
          {parseSelectOptions(config).map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      )
    }
    case 'DATE': {
      if (props.readOnly) {
        return (
          <ReadonlyTextField
            sx={{ ...props.sx, mb: 1 }}
            label={baseProps.label}
            value={date ? format(date, 'M/d/yyyy') : undefined}
            renderIfEmpty={props.renderEmptyReadOnly}
          />
        )
      }
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={date}
            onChange={(newDate) => {
              setDate(newDate)
              handleValueChange?.(toISODate(newDate || undefined))
            }}
            renderInput={(params) => <MUITextField {...params} {...baseProps} />}
          />
        </LocalizationProvider>
      )
    }
    default: {
      return null
    }
  }
}
