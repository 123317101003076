import { Box, useTheme } from '@mui/material'

export const SubmitError = ({ value }: { value?: string }) => {
  const theme = useTheme()
  if (!value) {
    return null
  }
  return (
    <Box
      data-testid="submit-error"
      sx={{
        fontSize: '0.75rem',
        textAlign: 'left',
        marginTop: 8,
        color: theme.palette.error.main,
      }}
    >
      {value}
    </Box>
  )
}
