import { SvgIcon, SvgIconProps } from '@mui/material'

/* eslint-disable max-len */
export const MastercardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="32" height="21" viewBox="0 0 32 21" fill="none" data-testid="MastercardIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
        fill="#F4F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 16C15.5376 16 18 13.5376 18 10.5C18 7.46243 15.5376 5 12.5 5C9.46243 5 7 7.46243 7 10.5C7 13.5376 9.46243 16 12.5 16Z"
        fill="#D8232A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 14.7428C16.9509 15.5282 18.1704 16 19.5 16C22.5376 16 25 13.5376 25 10.5C25 7.46243 22.5376 5 19.5 5C18.1704 5 16.9509 5.47182 16 6.25716C17.2215 7.26595 18 8.79206 18 10.5C18 12.2079 17.2215 13.734 16 14.7428Z"
        fill="#ED9F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.25708C14.7785 7.26587 14 8.79198 14 10.4999C14 12.2079 14.7785 13.734 16 14.7428C17.2215 13.734 18 12.2079 18 10.4999C18 8.79198 17.2215 7.26587 16 6.25708Z"
        fill="#D8232A"
      />
      <mask
        id="mask0_3341_28002"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="6"
        width="4"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 6.25708C14.7785 7.26587 14 8.79198 14 10.4999C14 12.2079 14.7785 13.734 16 14.7428C17.2215 13.734 18 12.2079 18 10.4999C18 8.79198 17.2215 7.26587 16 6.25708Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3341_28002)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 7H19V8H13V7ZM13 9H19V10H13V9ZM19 11H13V12H19V11ZM13 13H19V14H13V13ZM19 15H13V16H19V15Z"
          fill="#ED9F2D"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
    </SvgIcon>
  )
}
