import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useDropzone } from 'react-dropzone'

import { i18WithComponent as t } from '@shared/locale'
import React from 'react'
import { AdminAssetUsageService } from 'service-api/src/services'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { BookingReservationClientDto } from 'service-api/src/types'
import { createAsset } from './helper'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import useMediaQuery from '@mui/material/useMediaQuery'
import { captureError } from 'service-api/src/shared'

type VaccinationRecordsProps = {
  required?: boolean
  reservation: BookingReservationClientDto
}

type FileUploadType = {
  assetUsageId?: string
  name: string
  isUploading: boolean
  isUploaded: boolean
  isDeleting?: boolean
  error?: string
}

export const VaccinationRecords = ({ required = false, reservation }: VaccinationRecordsProps) => {
  const { accountName = '' } = useParams()
  const theme = useTheme()
  const [files, setFiles] = React.useState<FileUploadType[]>([])
  const [disabledDeleteButtons, setDisabledDeleteButtons] = React.useState<string[]>([])
  const isGreaterThanMD = useMediaQuery(theme.breakpoints.up('md'))

  const onDrop = React.useCallback(
    (files: File[]) => {
      // adds files to the list immediately
      setFiles((prevFiles) => [
        ...prevFiles,
        ...files.map((file) => ({
          name: file.name,
          isUploading: true,
          isUploaded: false,
        })),
      ])
      // uploads files to the server
      const promises = files.map((file) =>
        // effectivelly uploads the file
        createAsset({
          accountName,
          file,
          orderId: reservation.order?.id,
        })
          .then((createdAssetUsage) => {
            // updates the file in the list
            setFiles((prevFiles) =>
              [...prevFiles].map((prevFile) => {
                if (prevFile.name === file.name && !prevFile.assetUsageId) {
                  return {
                    ...prevFile,
                    assetUsageId: createdAssetUsage.id,
                    isUploading: false,
                    isUploaded: true,
                  }
                }
                return prevFile
              })
            )
          })
          .catch((error) => {
            setFiles((prevFiles) =>
              [...prevFiles].map((prevFile, index) => {
                if (prevFile.name === file.name && !prevFile.assetUsageId) {
                  return {
                    ...prevFile,
                    isUploading: false,
                    isUploaded: false,
                    error: error.message,
                    // Added asset usage id for the error files, this will be used to delete duplicate images with the same name
                    assetUsageId: `error-${index}`,
                  }
                }
                return prevFile
              })
            )
          })
      )
      // resolves all promises in parallel
      Promise.all(promises)
    },
    [accountName, reservation.order?.id]
  )

  const onDropRejected = React.useCallback(() => {
    enqueueSnackbar(t('detailsPolicies.vaccinationRecords.upload.error'), {
      variant: 'error',
    })
    captureError({ cause: t('detailsPolicies.vaccinationRecords.upload.error') } as Error)
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
  })

  const handleDeleteFileClick = ({ assetUsageId, name }: FileUploadType) => {
    setDisabledDeleteButtons((prev) => [...prev, `${name}_${assetUsageId}`])
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles]
      const index = newFiles.findIndex((f) => f.name === name && f.assetUsageId === assetUsageId)
      newFiles[index] = {
        ...newFiles[index],
        isUploading: false,
        isUploaded: false,
        isDeleting: true,
      }
      return newFiles
    })

    if (assetUsageId && !assetUsageId.includes(`error-`)) {
      AdminAssetUsageService.remove(accountName, assetUsageId)
        .then(() => {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles]
            const index = newFiles.findIndex((f) => f.assetUsageId === assetUsageId)
            newFiles.splice(index, 1)
            return newFiles
          })
          enqueueSnackbar(t('detailsPolicies.vaccinationRecords.deleted'), {
            variant: 'success',
          })
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          enqueueSnackbar(t('detailsPolicies.vaccinationRecords.deletedError'), { variant: 'error' })
          captureError(error as Error)
        })
        .finally(() => {
          setDisabledDeleteButtons((prev) => prev.filter((p) => p !== `${name}_${assetUsageId}`))
        })
    } else {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        const index = newFiles.findIndex((f) => f.name === name && !f.assetUsageId)
        newFiles.splice(index, 1)
        return newFiles
      })
    }
  }

  return (
    <Paper elevation={1} sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h5" mb={2}>
          {required ? t('detailsPolicies.vaccinationRecordsRequired') : t('detailsPolicies.vaccinationRecordsOptional')}
        </Typography>
        <Box>
          {isGreaterThanMD && (
            <Typography variant="body1" component="span">
              {t('detailsPolicies.vaccinationRecords.copy', {
                upload: (
                  <Link
                    onClick={open}
                    sx={{
                      cursor: 'pointer',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {t('global.label.uploadOnly')}
                  </Link>
                ),
              })}
            </Typography>
          )}
        </Box>
        <Box
          {...getRootProps()}
          sx={{
            alignItems: 'center',
            backgroundColor: theme.palette.grey[100],
            borderStyle: 'dashed',
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
            display: 'flex',
            height: 148,
            justifyContent: 'center',
          }}
        >
          <input {...getInputProps()} />
          <Stack
            spacing={2}
            alignItems="center"
            sx={{
              width: !isGreaterThanMD ? '90%' : 'auto',
            }}
          >
            {files.some((file) => file.isUploading) ? (
              <CircularProgress />
            ) : (
              <>
                {isGreaterThanMD && <Typography>{t('global.label.upload')}</Typography>}
                <Button variant="outlined" onClick={open} fullWidth={!isGreaterThanMD}>
                  {t('global.label.chooseFile')}
                </Button>
              </>
            )}
          </Stack>
        </Box>
        {files.some((file) => file.error) && (
          <Alert severity="error">{t('detailsPolicies.vaccinationRecords.upload.error')}</Alert>
        )}
        {files.length > 0 && files.every((file) => file.isUploaded) && (
          <Alert severity="success">{t('detailsPolicies.vaccinationRecords.upload.success')}</Alert>
        )}
        <List>
          {files.map((file) => (
            <React.Fragment key={`${file.name}_${file.assetUsageId}`}>
              <ListItem>
                <ListItemIcon>
                  {file.isUploading || file.isDeleting ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {file.isUploaded && <CheckCircleIcon color="success" />}
                      {file.error && <ErrorIcon color="error" />}
                    </>
                  )}
                </ListItemIcon>
                <ListItemText primary={file.name} />
                <IconButton
                  onClick={() => handleDeleteFileClick(file)}
                  disabled={
                    file.isUploading ||
                    file.isDeleting ||
                    disabledDeleteButtons.includes(`${file.name}_${file.assetUsageId}`)
                  }
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Stack>
    </Paper>
  )
}
