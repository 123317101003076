import { i18WithParams as t } from '@shared/locale'
import { FormikProps, useFormik } from 'formik'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { CheckCircleOutline } from '@mui/icons-material'
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { PetAvatarInvoice } from '@components/pet_avatar/pet_avatar'
import { useDrawersContext } from '@providers/drawers/drawers_context'
import { buildErrorMessage } from '@shared/error'
import { ButtonActions, FlexField, Spacer2, Spacer3, TextField, TextInputNumber } from 'components-ui'
import { FlexFileUpload } from 'components-ui/src/flex_file_upload/flex_file_upload'
import { enqueueSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { Hooks, Types } from 'service-api'
import { Drawer } from './drawer'
import { TFormikValues, buildSchema, handleSubmit, setupInitialValues } from './form_handlers'
import {
  STATIC_PETINFO_FIELD_BREED,
  STATIC_PETINFO_FIELD_NAME,
  STATIC_PETINFO_FIELD_WEIGHT,
  groupedFlexConfs,
  hasIntakeData,
  partitionFlexBookingConfs,
} from './helper'
import { PetVaccines } from './pet_vaccines'
import TagManager from 'react-gtm-module'
import { captureError } from '@shared/monitoring'

interface StaticPetFieldsProps {
  invoicePet: Types.InvoicePetClientDto
  formikProps: FormikProps<TFormikValues>
  readOnly: boolean
}

const INSTRUCTIONS_FLEX_GROUP = 'instructions',
  PETINFO_FLEX_GROUP = 'petinfo'

export const StaticPetFields = ({ invoicePet, formikProps, readOnly }: StaticPetFieldsProps) => {
  const { accountName: locationName = '' } = useParams()
  const locationSpecies = Hooks.useClientLocationSpecies(locationName, true)
  const locationSpeciesList = locationSpecies.data?.results || []
  const selectedSpecies = locationSpeciesList.find(({ species }) => species!.id === invoicePet.breed?.speciesId)

  return (
    <Stack spacing={readOnly ? 2 : 0}>
      <Stack>
        <TextField
          id={STATIC_PETINFO_FIELD_NAME}
          name={STATIC_PETINFO_FIELD_NAME}
          value={formikProps.values[STATIC_PETINFO_FIELD_NAME] as string}
          readOnly={readOnly}
          label={t('global.label.name')}
          onChange={formikProps.handleChange}
          error={
            formikProps.touched[STATIC_PETINFO_FIELD_NAME] && Boolean(formikProps.errors[STATIC_PETINFO_FIELD_NAME])
          }
          helperText={
            formikProps.touched[STATIC_PETINFO_FIELD_NAME] && formikProps.errors[STATIC_PETINFO_FIELD_NAME]
              ? formikProps.errors[STATIC_PETINFO_FIELD_NAME]
              : null
          }
          FormHelperTextProps={{ sx: { height: 'auto' } }}
        />
      </Stack>
      <Stack>
        {readOnly ? (
          <TextField
            id={STATIC_PETINFO_FIELD_BREED}
            name={STATIC_PETINFO_FIELD_BREED}
            value={(formikProps.values[STATIC_PETINFO_FIELD_BREED] as Types.BreedClientDto)?.displayName || ''}
            readOnly={true}
            label={t('global.label.breed')}
            variant="outlined"
            error={
              formikProps.touched[STATIC_PETINFO_FIELD_BREED] && Boolean(formikProps.errors[STATIC_PETINFO_FIELD_BREED])
            }
            helperText={
              formikProps.touched[STATIC_PETINFO_FIELD_BREED] && formikProps.errors[STATIC_PETINFO_FIELD_BREED]
                ? formikProps.errors[STATIC_PETINFO_FIELD_BREED]
                : null
            }
          />
        ) : (
          <Autocomplete
            fullWidth
            freeSolo
            onChange={(_, value) => formikProps.setFieldValue(STATIC_PETINFO_FIELD_BREED, value)}
            id={STATIC_PETINFO_FIELD_BREED}
            options={selectedSpecies?.species?.breeds || []}
            value={formikProps.values[STATIC_PETINFO_FIELD_BREED]}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('global.label.breed')}
                fullWidth
                error={
                  formikProps.touched[STATIC_PETINFO_FIELD_BREED] &&
                  Boolean(formikProps.errors[STATIC_PETINFO_FIELD_BREED])
                }
                helperText={
                  formikProps.touched[STATIC_PETINFO_FIELD_BREED] && formikProps.errors[STATIC_PETINFO_FIELD_BREED]
                    ? formikProps.errors[STATIC_PETINFO_FIELD_BREED]
                    : null
                }
                inputProps={{
                  ...params.inputProps,
                }}
                FormHelperTextProps={{ sx: { height: 'auto' } }}
              />
            )}
            renderOption={(props, option) => <li {...props}>{(option as Types.BreedClientDto).displayName || ''}</li>}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option
              }
              return (option as Types.BreedClientDto).displayName || ''
            }}
            isOptionEqualToValue={(option, value) => {
              return (option as Types.BreedClientDto).id === (value as Types.BreedClientDto)?.id
            }}
          />
        )}
      </Stack>
      <Stack>
        <TextInputNumber
          id={STATIC_PETINFO_FIELD_WEIGHT}
          label={t('global.label.weight')}
          onChange={formikProps.handleChange}
          value={formikProps.values[STATIC_PETINFO_FIELD_WEIGHT] as string}
          readOnly={readOnly}
          error={
            formikProps.touched[STATIC_PETINFO_FIELD_WEIGHT] && Boolean(formikProps.errors[STATIC_PETINFO_FIELD_WEIGHT])
          }
          helperText={
            formikProps.touched[STATIC_PETINFO_FIELD_WEIGHT] && formikProps.errors[STATIC_PETINFO_FIELD_WEIGHT]
              ? formikProps.errors[STATIC_PETINFO_FIELD_WEIGHT]
              : null
          }
          FormHelperTextProps={{ sx: { height: 'auto' } }}
        />
      </Stack>
    </Stack>
  )
}

export const PetForm = ({
  invoicePet,
  flexBookingConfs,
  readOnly,
  previouslySubmitted,
  onSave,
  onFormChange,
}: {
  invoicePet: Types.InvoicePetClientDto
  flexBookingConfs: Types.FlexBookingConfClientDto[]
  readOnly: boolean
  previouslySubmitted: boolean
  onSave: (newOrder: Types.OrderClientDto) => void
  onFormChange: (dirty: boolean) => void
}) => {
  const showStaticFields = !invoicePet.pet?.id && !invoicePet.petId

  const { accountName: locationName = '' } = useParams()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { intakeFlexConfs, intakeFlexFileConfs } = partitionFlexBookingConfs(flexBookingConfs, 'PET')

  const { initialValues, hasPrepopulatedData } = setupInitialValues({
    target: 'PET',
    intakeFlexConfs,
    invoicePet,
    orderData: invoicePet.data,
    profileData: invoicePet.pet?.petProfile?.[0]?.data,
    previouslySubmitted,
    isFormReadOnly: readOnly,
    showStaticFields,
  })

  const showTwoPanelView = !isBelowMD && intakeFlexFileConfs.length > 0
  const petName = invoicePet.displayName || invoicePet.locationPetProfile?.displayName

  const formik = useFormik({
    initialValues,
    validationSchema: buildSchema(intakeFlexConfs, showStaticFields),
    onSubmit: async (values) => {
      setIsSubmitting(true)
      try {
        const res = await handleSubmit({
          locationName,
          invoicePetId: invoicePet.id!,
          locationPetProfileId: invoicePet.locationPetProfileId || invoicePet?.locationPetProfile?.id,
          orderId: invoicePet.orderId!,
          values,
          initialValues,
          previouslySubmitted,
          shouldSavePet: showStaticFields,
        })
        if (res?.id) {
          enqueueSnackbar(t('intake.pet.success'), { variant: 'success' })
          TagManager.dataLayer({
            dataLayer: {
              event: 'INTAKE_SEND',
              type: 'PET',
              petId: invoicePet.id,
            },
          })
          onSave(res)
        } else {
          enqueueSnackbar(buildErrorMessage(res), { variant: 'error' })
          captureError({ cause: buildErrorMessage(res) } as Error)
        }
      } catch (err) {
        enqueueSnackbar(buildErrorMessage(err), { variant: 'error' })
        captureError(err as Error)
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  useEffect(() => {
    onFormChange?.(formik.dirty)
  }, [formik.dirty])

  const fieldSx = (confsInGroup: Types.FlexBookingConfClientDto[], fieldIdx: number): SxProps<Theme> => {
    if (isBelowMD) {
      return {}
    }
    switch (confsInGroup.length) {
      case 2: {
        return { flex: !fieldIdx ? 5 : 3 }
      }
      case 3: {
        return confsInGroup[0].group === PETINFO_FLEX_GROUP
          ? { flex: !fieldIdx ? '100%' : 'calc(50% - 8px)', mr: fieldIdx === 1 ? 2 : 0 }
          : {}
      }
      default: {
        return {}
      }
    }
  }

  return (
    <Box p={2}>
      {hasPrepopulatedData && (
        <Alert severity="info" sx={{ mb: 3 }}>
          {t('intake.sendNowInfoMsg')}
        </Alert>
      )}
      <Stack px={1} direction={showTwoPanelView ? 'row' : 'column'} spacing={2}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h5" fontSize="22px !important">
            {t('intake.aboutYourPet')}
          </Typography>
          <Spacer3 />
          {showStaticFields && (
            <>
              <StaticPetFields invoicePet={invoicePet} formikProps={formik} readOnly={readOnly} />
              {readOnly && <Spacer2 />}
            </>
          )}
          <Stack spacing={3}>
            {groupedFlexConfs(intakeFlexConfs).map(([groupName, confs]) => (
              <Box key={groupName}>
                {!!confs[0].displayName && (
                  <>
                    <Typography variant="h6">{confs[0].displayName}</Typography>
                    <Spacer2 />
                  </>
                )}
                <Stack
                  spacing={confs[0].group === PETINFO_FLEX_GROUP ? 0 : 2}
                  direction={
                    (confs.length === 2 || confs[0].group === PETINFO_FLEX_GROUP) && !isBelowMD ? 'row' : 'column'
                  }
                  sx={confs[0].group === PETINFO_FLEX_GROUP ? { flexWrap: 'wrap' } : {}}
                >
                  {confs.map((conf, index) => (
                    <FlexField
                      key={conf.id}
                      sx={fieldSx(confs, index)}
                      config={conf}
                      readOnly={readOnly}
                      renderEmptyReadOnly={true}
                      multiline={groupName === INSTRUCTIONS_FLEX_GROUP}
                      value={formik.values[conf.name!] as string}
                      onChange={formik.handleChange}
                      handleValueChange={(val) => formik.setFieldValue(conf.name!, val)}
                      error={formik.touched[conf.name!] && Boolean(formik.errors[conf.name!])}
                      helperText={
                        formik.touched[conf.name!] && formik.errors[conf.name!] ? formik.errors[conf.name!] : null
                      }
                    />
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
        {showTwoPanelView && <Divider orientation="vertical" flexItem />}
        {!!intakeFlexFileConfs.length && (
          <Box sx={{ flex: 1 }}>
            {intakeFlexFileConfs
              .sort((conf1, conf2) => conf1.order! - conf2.order!)
              .map((conf) => (
                <Fragment key={conf.id}>
                  <FlexFileUpload
                    config={conf}
                    subheader={
                      <PetVaccines speciesId={invoicePet.breed?.speciesId || invoicePet.breed?.species?.id || ''} />
                    }
                    readOnly={readOnly}
                    type="RECORD"
                    filenamePrefix={petName ? `${petName}_VaccinationRecord` : ''}
                    assets={formik.values.vaxRecords || []}
                    onUpload={(files) => {
                      formik.setFieldValue('vaxRecords', [...(formik.values.vaxRecords || []), ...files])
                    }}
                    onDelete={(fileIndex) => {
                      formik.setFieldValue(
                        'vaxRecords',
                        (formik.values.vaxRecords || []).filter((_, idx) => idx !== fileIndex)
                      )
                    }}
                  />
                </Fragment>
              ))}
          </Box>
        )}
      </Stack>
      <Spacer3 />
      {!readOnly && (
        <ButtonActions
          invertButtons
          orientation={isBelowMD ? 'vertical' : 'horizontal'}
          size="small"
          actionEnabled={formik.dirty || hasPrepopulatedData}
          onActionLabel={t('global.label.send')}
          onAction={formik.submitForm}
          onCancel={formik.dirty ? async () => formik.resetForm() : undefined}
          onCancelLabel={t('global.label.clearAllChanges')}
          cancelEnabled={!isSubmitting}
        />
      )}
    </Box>
  )
}

export const PetDrawer = ({
  invoicePet,
  flexBookingConfs,
  shouldOpenByDefault,
  readOnly,
  onSave,
}: {
  invoicePet: Types.InvoicePetClientDto
  flexBookingConfs: Types.FlexBookingConfClientDto[]
  shouldOpenByDefault: boolean
  readOnly: boolean
  onSave: (newOrder: Types.OrderClientDto) => void
}) => {
  const { dispatch } = useDrawersContext()
  const onFormChange = useCallback(
    (dirty: boolean) => {
      dispatch({ type: 'SET_IS_OPEN_DRAWER_DIRTY', payload: { value: dirty } })
    },
    [dispatch]
  )
  const drawerId = `pet-${invoicePet.id}`
  const previouslySubmitted = useMemo(() => hasIntakeData(invoicePet.data, 'PET'), [invoicePet])

  useEffect(() => {
    if (shouldOpenByDefault) {
      dispatch({ type: 'OPEN_DRAWER', payload: { value: drawerId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenByDefault])

  return (
    <Drawer
      id={drawerId}
      label={<PetAvatarInvoice invoicePet={invoicePet} />}
      status={
        previouslySubmitted ? (
          <Stack direction="row" alignItems="center" spacing={0.5} color="success.main">
            <CheckCircleOutline />
            <Typography variant="body1">{t('global.label.sent')}</Typography>
          </Stack>
        ) : null
      }
    >
      <PetForm
        invoicePet={invoicePet}
        flexBookingConfs={flexBookingConfs}
        readOnly={readOnly}
        previouslySubmitted={previouslySubmitted}
        onSave={onSave}
        onFormChange={onFormChange}
      />
    </Drawer>
  )
}
