import { Fragment, ReactNode } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { SUPPORTED_FILE_TYPES } from '../shared/constants'
import { i18WithParams as t } from '../shared/locales'
import { Box, Button, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material'
import { Types } from 'service-api'
import { CheckCircle, DeleteOutline } from '@mui/icons-material'
import { format } from 'date-fns'
import { Spacer1 } from '../spacer/spacer'
import { enqueueSnackbar } from 'notistack'
import { rename } from './helper'
import { captureError } from 'service-api/src/shared'

type Props = {
  config: Types.FlexBookingConfClientDto
  subheader?: ReactNode
  readOnly?: boolean
  filenamePrefix?: string
  type: 'IMAGE' | 'FILE' | 'RECORD'
  assets: (Types.AssetClientDto | File)[]
  onUpload: (file: File[]) => void
  onDelete: (index: number) => void
}

const uploadErrorMsg = (rejectedFile: FileRejection) => {
  switch (rejectedFile.errors[0].code) {
    case 'file-too-large': {
      return t('global.error.upload.fileSize', { filename: rejectedFile.file.name })
    }
    case 'file-invalid-type': {
      return t('global.error.upload.record.fileType', { filename: rejectedFile.file.name })
    }
    default: {
      return rejectedFile.errors[0].message
    }
  }
}

export const FlexFileUpload = ({
  config,
  subheader,
  readOnly = false,
  filenamePrefix,
  type,
  assets,
  onUpload,
  onDelete,
}: Props) => {
  const { palette } = useTheme()
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        enqueueSnackbar(uploadErrorMsg(rejectedFiles[0]), { variant: 'error' })
        captureError({ cause: uploadErrorMsg(rejectedFiles[0]) } as Error)
      }
      if (acceptedFiles.length) {
        onUpload(filenamePrefix ? rename(filenamePrefix, assets, acceptedFiles) : acceptedFiles)
      }
    },
    maxSize: 9000000, // ~9MB since max request payload in API Gateway is 10MB
    accept: SUPPORTED_FILE_TYPES[type],
  })

  return (
    <Box>
      <Stack spacing={2}>
        <Typography variant="h5" fontSize="22px !important">
          {config.displayName}
        </Typography>
        {subheader}
        {!readOnly && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Stack
              spacing={2}
              sx={{
                borderWidth: 1,
                borderRadius: '4px',
                borderStyle: 'dashed',
                borderColor: palette.text.secondaryShade60,
                backgroundColor: palette.action.hover,
                padding: 3,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1">{t('global.label.upload')}</Typography>
              <Button variant="outlined" onClick={open}>
                {t('global.label.chooseFile')}
              </Button>
            </Stack>
          </div>
        )}
        {readOnly && <Spacer1 />}
        <Stack>
          {assets.map((asset, index) => {
            const uploadedDate = (asset as Types.AssetClientDto)?.createdAt
              ? new Date((asset as Types.AssetClientDto).createdAt!)
              : Date.now()
            return (
              <Fragment key={index}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack px={1} spacing={2} direction="row" alignItems="center">
                    <CheckCircle color="success" />
                    <Box>
                      <Typography variant="body1">
                        {(asset as Types.AssetClientDto)?.displayName || asset.name}
                      </Typography>
                      <Typography variant="body2">
                        {`(${t('global.label.uploadedDate', { date: format(uploadedDate, 'M/d/yyyy') })})`}
                      </Typography>
                    </Box>
                  </Stack>
                  {!readOnly && (
                    <IconButton onClick={() => onDelete(index)}>
                      <DeleteOutline />
                    </IconButton>
                  )}
                </Stack>
                <Divider sx={{ my: 1 }} />
              </Fragment>
            )
          })}
        </Stack>
      </Stack>
    </Box>
  )
}
