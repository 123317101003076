import { useEffect, useRef } from 'react'
import { generatePassword } from '@shared/common'
import { i18WithDefault as t } from '@shared/locales'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { VerifyEmailForm } from './verify_email_form'

export type TFormikValues = {
  inputCode: boolean
  code: string
}

type SignInTooltipContentProps = {
  email: string
  onError?: (error: Error) => void
  onSuccessfulSignIn?: () => void
  onFailedSignIn?: (email: string) => void
  simpleView?: boolean
  onChange?: (value: string) => void
  continueLabel?: string
  onUseDifferentEmail?: () => void
  tryAgain: () => void
}

export const RegistrationTooltipContents = ({
  email,
  onError,
  onSuccessfulSignIn,
  simpleView = false,
  onUseDifferentEmail,
  tryAgain,
}: SignInTooltipContentProps) => {
  const [cognitoUser, setCognitoUser] = React.useState(null)
  const [isNewUser, setIsNewUser] = React.useState(false)

  const effectRan = useRef(false)
  useEffect(() => {
    if (!effectRan.current) {
      Auth.signUp({
        username: email.toLowerCase().trim(),
        password: generatePassword(),
        attributes: {
          email: email.toLowerCase().trim(),
        },
        autoSignIn: {
          enabled: true,
        },
      }).then((_result) => {
        setIsNewUser(true)
        formik.setFieldValue('inputCode', true)
        formik.setTouched({ code: false })
      })
    }

    return () => {
      effectRan.current = true
    }
  }, [])

  const formik = useFormik<TFormikValues>({
    initialValues: {
      inputCode: false,
      code: '',
    },
    validationSchema: Yup.object({
      inputCode: Yup.boolean(),
      code: Yup.string().when('inputCode', {
        is: true,
        then: Yup.string().length(6, t('global.label.invalidCode')).required(t('global.label.required')),
      }),
    }),
    onSubmit: ({ code }, { setSubmitting }) => {
      Auth.confirmSignUp(email, code)
        .then((_result) => {
          onSuccessfulSignIn?.()
        })
        .catch((error) => {
          formik.setFieldError('code', t('global.verifyCodeError'))
          onError?.(error)
        })
        .finally(() => setSubmitting(false))
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <VerifyEmailForm
          formik={formik}
          setShowVerifyForm={tryAgain}
          setCognitoUser={setCognitoUser}
          simpleView={simpleView}
          isNewUser={isNewUser}
          onUseDifferentEmail={onUseDifferentEmail}
        />
      </form>
    </>
  )
}
