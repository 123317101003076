import { Box, Button, Link, Paper, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { i18WithDefault as t, i18WithComponent as tc } from '@shared/locales'
import { CatIcon, DogIcon } from 'asset'
import { AuthDialog } from 'auth_dialog'
import React from 'react'
import { SignInTooltipContent } from './signin_tooltip_content'
import { PrivacyPolicyTermsAgreement } from 'privacy_policy_terms_agreement'
import { DataLayerArgs, TagManagerArgs } from 'react-gtm-module'

export type TFormikValues = {
  email: string
  inputCode: boolean
  code: string
}

const petWrapperStyle: SxProps<Theme> = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 48,
  height: 48,
  borderRadius: 24,
  backgroundColor: theme.palette.primary.shade30,
})

export type ChallengeResponseType = {
  username: string
  answer: string
}

type SignInSignUpProps = {
  onError?: (error: Error) => void
  onSuccessfulSignIn?: () => void
  onFailedSignIn?: () => void
  sx?: SxProps<Theme>
  TagManager?: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
}

export const SignInSignUpTooltip = ({
  onError,
  sx,
  onSuccessfulSignIn,
  onFailedSignIn,
  TagManager,
}: SignInSignUpProps) => {
  const theme = useTheme()
  const [isSignIn, setIsSignIn] = React.useState(true)
  const [isVerification, setIsVerification] = React.useState(false)
  const [email, setEmail] = React.useState('')

  const handlePaperClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
  }

  const handlePaperTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return (
    <Paper
      elevation={24}
      sx={{ px: 2, py: 3, width: 287, ...sx }}
      onClick={handlePaperClick}
      onTouchEnd={handlePaperTouchEnd}
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} justifyContent="center">
          <Box sx={petWrapperStyle(theme)}>
            <DogIcon viewBox="0 -2 28 28" style={{ color: theme.palette.primary.main }} />
          </Box>
          <Box sx={petWrapperStyle(theme)}>
            <CatIcon viewBox="0 -2 28 28" style={{ color: theme.palette.primary.main }} />
          </Box>
        </Stack>
        {isSignIn ? (
          <Stack spacing={1}>
            <SignInTooltipContent
              registerIfNeeded
              onError={onError}
              onSuccessfulSignIn={onSuccessfulSignIn}
              onFailedSignIn={() => setIsSignIn(false)}
              onChange={(email: string) => setEmail(email)}
              onCodeRequested={() => setIsVerification(true)}
              TagManager={TagManager}
            />
            {!isVerification && <PrivacyPolicyTermsAgreement />}
          </Stack>
        ) : (
          <>
            <AuthDialog modalStep="signUp" email={email} />
            <Button variant="text" onClick={() => setIsSignIn(true)}>
              {t('global.label.signIn')}
            </Button>
          </>
        )}
      </Stack>
    </Paper>
  )
}
