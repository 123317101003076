import { Box, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BackContinue } from '@components/back_continue/back_continue'
import { useBookingContext } from '@providers/booking'
import { MAX_WIDGET_SIZE, STICKY_FOOTER_HEIGHT } from '@shared/constants'
import { buildErrorMessage } from '@shared/error'
import { i18WithParams as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import {
  AmexIcon,
  DiscoverIcon,
  FlexCardDisplay,
  MastercardIcon,
  PurchaseReceipt,
  PurchaseReceiptSkeleton,
  Spacer,
  Spacer2,
  Spacer3,
  SurchargePolicy,
  VisaIcon,
  getPoliciesData,
} from 'components-ui'
import { allowCardAndPrepaidPayments, allowNoPayments } from 'components-ui/src/shared'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'
import { Hooks, Types } from 'service-api'
import { ClientBookingPaymentService } from 'service-api/src/services'
import { isHttpGone } from 'service-api/src/shared/request'
import { BillingAddress } from './billing_address'
import { resolveBillingDetails } from './helper'
import { TPaymentFormSchema, paymentSchema, setupInitialValues } from './payment_schema'
import { TilledInput } from './tiled_input'
import { useTilled } from './use_tilled'
import { captureError } from 'service-api/src/shared'

export const TiledPayment = () => {
  const bookingCtx = useBookingContext()
  const { accountName: locationName = '' } = useParams()
  const tilled = useTilled()
  const theme = useTheme()
  const { navigateRoute } = useRoutes()
  const renderStyle = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop'
  const isMobile = renderStyle === 'mobile'
  const { data: receipt, isLoading: isReceiptLoading } = Hooks.useClientReceipt(
    locationName,
    bookingCtx.state.data.reservation?.order?.id || '',
    'PAYMENT'
  )
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  useRedirectNoData()

  useEffect(() => {
    if (tilled.paymentData?.order) {
      bookingCtx.dispatch({ type: 'SET_CART_TOTAL', payload: { value: tilled.paymentData?.order } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.currentState, tilled.paymentData])

  useEffect(() => {
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'payment' } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.currentState])

  const allowCardAndPrepaid = allowCardAndPrepaidPayments(bookingCtx.state.data.reservation?.locationBookingConf)
  const noPaymentsAllowed = allowNoPayments(bookingCtx.state.data.reservation?.locationBookingConf)

  const isCardPayment = !noPaymentsAllowed && bookingCtx.state.cart.payment.method === 'payNow'
  const isPrepaid = !noPaymentsAllowed && bookingCtx.state.cart.payment.method === 'prePaid'

  const dueToday = useMemo(
    () => (isPrepaid || noPaymentsAllowed ? null : tilled.paymentData?.order?.payments?.[0]?.depositDue ?? null),
    [tilled.paymentData, isPrepaid, noPaymentsAllowed]
  )
  const policyCardsData = useMemo(
    () => (receipt ? getPoliciesData(receipt, noPaymentsAllowed) : []),
    [receipt, noPaymentsAllowed]
  )

  const formik = useFormik<TPaymentFormSchema>({
    enableReinitialize: true,
    initialValues: setupInitialValues(),
    validationSchema: paymentSchema,
    onSubmit: async (values) => {
      bookingCtx.dispatch({ type: 'SET_PAYMENT_BILLING_ADDRESS', payload: { value: values } })
      setErrorMsg(undefined)
      let reservationResponse: Types.BookingReservationClientDto = {}
      if (isCardPayment) {
        const tilledResponse = await tilled.submitHandler(resolveBillingDetails(bookingCtx.state.cart.contact!, values))

        if (!tilledResponse || tilledResponse?.last_payment_error) {
          setErrorMsg(tilledResponse?.last_payment_error?.message)
          return false
        }

        reservationResponse = await ClientBookingPaymentService.tilledPayment(
          locationName,
          bookingCtx.state.data.reservation?.order?.id || ''
        )
      } else if (isPrepaid) {
        reservationResponse = await ClientBookingPaymentService.offlinePayment(
          locationName,
          bookingCtx.state.data.reservation?.order?.id || ''
        )
      }
      navigateRoute('paymentSummary', { orderId: reservationResponse.order?.id || '' })
      return true
    },
  })

  const payAtFacility = useCallback(async () => {
    const reservationResponse = await ClientBookingPaymentService.offlinePayment(
      locationName,
      bookingCtx.state.data.reservation?.order?.id || ''
    )
    navigateRoute('paymentSummary', { orderId: reservationResponse.order?.id || '' })
    return true
  }, [locationName, bookingCtx.state.data.reservation?.order?.id, navigateRoute])

  const onClickContinue = useCallback(async () => {
    try {
      const rc = await (noPaymentsAllowed || isPrepaid ? payAtFacility() : formik.submitForm())
      return typeof rc !== 'undefined'
    } catch (err) {
      if (isHttpGone(err)) {
        navigateRoute('detailsPolicies', {})
        enqueueSnackbar(t('paymentForm.orderExpired'), { variant: 'info' })
      } else {
        enqueueSnackbar(buildErrorMessage(err), { variant: 'error' })
      }
      captureError(err as Error)
      return false
    }
  }, [formik, isPrepaid, navigateRoute, noPaymentsAllowed, payAtFacility])

  const onChangePaymentMethod = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { value } = ev.target
      formik.setFieldValue('paymentMethod', value)
      bookingCtx.dispatch({ type: 'SET_PAYMENT_METHOD', payload: { value: value } })
    },
    [bookingCtx, formik]
  )

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <Typography variant="h1" sx={{ px: 2 }}>
        {t('paymentForm.title')}
      </Typography>
      <Spacer2 />

      <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={3} flex={1}>
        <Stack direction="column" spacing={3} flex={1} width="100%">
          {allowCardAndPrepaid && (
            <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isMobile ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="h5">{t('paymentForm.options')}</Typography>
              <Spacer3 />
              <RadioGroup value={bookingCtx.state.cart.payment.method} onChange={onChangePaymentMethod}>
                <FormControlLabel value="payNow" control={<Radio />} label={t('paymentForm.payNow')} />
                <FormControlLabel value="prePaid" control={<Radio />} label={t('paymentForm.prePaid')} />
              </RadioGroup>
            </Paper>
          )}

          {noPaymentsAllowed && (
            <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isMobile ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="h5">{t('paymentForm.options')}</Typography>
              <Spacer3 />
              <Typography variant="body1">
                {t('paymentForm.dueAtFacility', {
                  locationDisplayName: bookingCtx.state.data.reservation?.order?.location?.displayName ?? '',
                })}
              </Typography>
            </Paper>
          )}

          {isCardPayment && (
            <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isMobile ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="h5">{t('paymentForm.method')}</Typography>
              <Spacer3 />

              {errorMsg && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorMsg}
                </Alert>
              )}

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="nameOnCard"
                    label={t('paymentForm.nameOnCard')}
                    variant="outlined"
                    fullWidth
                    value={formik.values.nameOnCard}
                    onChange={formik.handleChange}
                    error={formik.touched.nameOnCard && Boolean(formik.errors.nameOnCard)}
                    helperText={formik.touched.nameOnCard && formik.errors.nameOnCard}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <TilledInput id="cardNumber" label={t('paymentForm.cardNumber')} />
                    <VisaIcon />
                    <MastercardIcon />
                    <AmexIcon />
                    <DiscoverIcon />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TilledInput id="cardExpiry" label={t('paymentForm.expiration')} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TilledInput id="cardCvv" label={t('paymentForm.cvv')} />
                </Grid>
              </Grid>

              <Box mt={2}>
                <Typography variant="h6">{t('paymentForm.billingAddress')}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="useMailingAddress"
                      checked={formik.values.useMailingAddress}
                      onChange={formik.handleChange}
                    />
                  }
                  label={t('paymentForm.useMailingAddress')}
                />
              </Box>
              <BillingAddress formik={formik} />
            </Paper>
          )}

          <Stack spacing={3} sx={{ flex: '1 0 49%' }}>
            {bookingCtx.state.data.reservation && <SurchargePolicy reservation={bookingCtx.state.data.reservation} />}
            {policyCardsData.map((group, index) => (
              <FlexCardDisplay key={index} cardData={group} />
            ))}
          </Stack>
        </Stack>

        <Stack direction="column" spacing={3} flex={1} mt={isMobile ? 3 : 0} width="100%">
          {isReceiptLoading || tilled.loadingPaymentData ? (
            <PurchaseReceiptSkeleton />
          ) : (
            <PurchaseReceipt receipt={receipt} dueToday={dueToday} />
          )}
        </Stack>
      </Stack>

      <Spacer height={STICKY_FOOTER_HEIGHT} />
      <BackContinue continueLabel="Book Now" onContinue={onClickContinue} />
    </Box>
  )
}
