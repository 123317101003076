import { i18WithParams as t } from '@shared/locale'
import { useFormik } from 'formik'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { CheckCircleOutline, Person as PersonIcon } from '@mui/icons-material'
import { Alert, Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useDrawersContext } from '@providers/drawers/drawers_context'
import { buildErrorMessage } from '@shared/error'
import { ButtonActions, FlexField, Spacer2, Spacer3 } from 'components-ui'
import { enqueueSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'
import { Types } from 'service-api'
import { Drawer } from './drawer'
import { buildSchema, handleSubmit, setupInitialValues } from './form_handlers'
import { groupedFlexConfs, hasIntakeData, partitionFlexBookingConfs } from './helper'
import TagManager from 'react-gtm-module'
import { captureError } from 'service-api/src/shared'

const DRAWER_ID = 'owner'

export const OwnerForm = ({
  orderId,
  ownerOrderData,
  userProfile,
  flexBookingConfs,
  readOnly,
  previouslySubmitted,
  onSave,
  onFormChange,
}: {
  orderId: string
  ownerOrderData?: Record<string, never>
  userProfile?: Types.UserProfileClientDto
  flexBookingConfs: Types.FlexBookingConfClientDto[]
  readOnly: boolean
  previouslySubmitted: boolean
  onSave: (newOrder: Types.OrderClientDto) => void
  onFormChange: (dirty: boolean) => void
}) => {
  const { accountName: locationName = '' } = useParams()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { intakeFlexConfs } = partitionFlexBookingConfs(flexBookingConfs, 'OWNER')
  const { initialValues, hasPrepopulatedData } = setupInitialValues({
    target: 'OWNER',
    intakeFlexConfs,
    orderData: ownerOrderData,
    profileData: userProfile?.data,
    previouslySubmitted,
    isFormReadOnly: readOnly,
  })

  const formik = useFormik({
    initialValues,
    validationSchema: buildSchema(intakeFlexConfs),
    onSubmit: async (values) => {
      setIsSubmitting(true)
      try {
        const res = await handleSubmit({
          locationName,
          orderId,
          values,
          initialValues,
          previouslySubmitted,
        })
        if (res?.id) {
          enqueueSnackbar(t('intake.ownerInfo.success'), { variant: 'success' })
          TagManager.dataLayer({
            dataLayer: {
              event: 'INTAKE_SEND',
              type: 'OWNER',
            },
          })
          onSave(res)
        } else {
          enqueueSnackbar(buildErrorMessage(res), { variant: 'error' })
          captureError(buildErrorMessage(res) as unknown as Error)
        }
      } catch (err) {
        enqueueSnackbar(buildErrorMessage(err), { variant: 'error' })
        captureError(err as Error)
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  useEffect(() => {
    onFormChange?.(formik.dirty)
  }, [formik.dirty])

  return (
    <Box p={2}>
      {hasPrepopulatedData && (
        <Alert severity="info" sx={{ mb: 3 }}>
          {t('intake.sendNowInfoMsg')}
        </Alert>
      )}
      <Box>
        {groupedFlexConfs(intakeFlexConfs).map(([groupName, confs]) => (
          <Box key={groupName}>
            <Typography variant="h6">{confs[0].displayName}</Typography>
            <Spacer2 />
            <Stack direction={isBelowMD ? 'column' : 'row'} spacing={2}>
              {confs.map((conf) => (
                <FlexField
                  key={conf.id}
                  sx={{ flex: 1 }}
                  config={conf}
                  readOnly={readOnly}
                  renderEmptyReadOnly={true}
                  value={formik.values[conf.name!] as string}
                  onChange={formik.handleChange}
                  error={formik.touched[conf.name!] && Boolean(formik.errors[conf.name!])}
                  helperText={
                    formik.touched[conf.name!] && formik.errors[conf.name!] ? formik.errors[conf.name!] : null
                  }
                />
              ))}
            </Stack>
          </Box>
        ))}
      </Box>
      <Spacer3 />
      {!readOnly && (
        <ButtonActions
          invertButtons
          orientation={isBelowMD ? 'vertical' : 'horizontal'}
          size="small"
          actionEnabled={formik.dirty || hasPrepopulatedData}
          onActionLabel={t('global.label.send')}
          onAction={formik.submitForm}
          onCancel={formik.dirty ? async () => formik.resetForm() : undefined}
          onCancelLabel={t('global.label.clearAllChanges')}
          cancelEnabled={!isSubmitting}
        />
      )}
    </Box>
  )
}

export const OwnerDrawer = ({
  orderId,
  ownerOrderData,
  userProfile,
  flexBookingConfs,
  readOnly,
  onSave,
}: {
  orderId: string
  ownerOrderData?: Record<string, never>
  userProfile?: Types.UserProfileClientDto
  flexBookingConfs: Types.FlexBookingConfClientDto[]
  readOnly: boolean
  onSave: (newOrder: Types.OrderClientDto) => void
}) => {
  const { dispatch } = useDrawersContext()
  const onFormChange = useCallback(
    (dirty: boolean) => {
      dispatch({ type: 'SET_IS_OPEN_DRAWER_DIRTY', payload: { value: dirty } })
    },
    [dispatch]
  )

  const previouslySubmitted = useMemo(() => hasIntakeData(ownerOrderData, 'OWNER'), [ownerOrderData])

  return (
    <Drawer
      id={DRAWER_ID}
      label={
        <Stack direction="row" alignItems="center" spacing={2}>
          <PersonIcon sx={{ color: 'action.active' }} />
          <Typography variant="h5">{t('intake.emergencyContact')}</Typography>
        </Stack>
      }
      status={
        previouslySubmitted ? (
          <Stack direction="row" alignItems="center" spacing={0.5} color="success.main">
            <CheckCircleOutline />
            <Typography variant="body1">{t('global.label.sent')}</Typography>
          </Stack>
        ) : null
      }
    >
      <OwnerForm
        orderId={orderId}
        ownerOrderData={ownerOrderData}
        userProfile={userProfile}
        flexBookingConfs={flexBookingConfs}
        readOnly={readOnly}
        previouslySubmitted={previouslySubmitted}
        onSave={onSave}
        onFormChange={onFormChange}
      />
    </Drawer>
  )
}
