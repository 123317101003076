import { Box, Link, useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { BackContinue } from '@components/back_continue/back_continue'
import { i18WithComponent as t } from '@shared/locale'
import { detailsPoliciesSchema, getProfileValues, setupInitialValues } from './details_policies_schema'
// import type { TDetailsPoliciesSchema } from './details_policies.d'
import { ContactInformation } from './contact_information'
// import { VaccinationRecords } from './vaccination_records'
import { useBookingContext } from '@providers/booking'
import { FlexCardDisplay, FlexCardField, Spacer, TCardEntry } from 'components-ui'
import { Services, Types } from 'service-api'
import { CheckInOut } from './check_in_out'
// import { SpecialInstructions } from './special_instructions'
import { useLocationContext } from '@providers/location'
import { STICKY_FOOTER_HEIGHT } from '@shared/constants'
import { buildErrorMessage } from '@shared/error'
import { continueNavigation } from '@shared/header_navigation'
import { useRoutes } from 'app/use_routes'
import {
  isCheckInOutTimeEnabled,
  isVaccinationEnabled,
  isVaccinationRequired,
  isVetEnabled,
} from 'components-ui/src/shared'
import React from 'react'
import { captureError } from 'service-api/src/shared'
import { buildOrderInput, inputCardGroupsBuilder } from './helper'
import { VaccinationRecords } from './vaccination_records'
import { VeterinarianInformation } from './veterinarian_information'

export const DetailsPoliciesForm = ({
  reservation,
  userProfile,
}: {
  reservation: Types.BookingReservationClientDto
  userProfile: Types.UserClientDto
}) => {
  const bookingCtx = useBookingContext()
  const { location } = useLocationContext()
  const { accountName = '' } = useParams()
  const theme = useTheme()
  const renderStyle = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop'
  const isMobile = renderStyle === 'mobile'
  const { navigateRoute } = useRoutes()
  const serviceTypeName = reservation.order?.primaryServiceType?.serviceType?.name ?? ''

  const inputCardGroups = useMemo(
    () => inputCardGroupsBuilder(reservation.flexBookingConfs || []),
    [reservation.flexBookingConfs]
  )

  const formik = useFormik({
    initialValues: setupInitialValues(bookingCtx.state.cart, userProfile),
    validationSchema: detailsPoliciesSchema(reservation, inputCardGroups),
    onSubmit: async (values): Promise<boolean> => {
      const data = buildOrderInput(values, reservation, inputCardGroups)

      try {
        const profileRecord = userProfile.userProfile?.[0]
        await Services.ClientUserProfileService.put(accountName, profileRecord?.id || '', getProfileValues(values))
        const order = await Services.ClientBookingReservationService.updateReservation(
          reservation.order!.id!,
          accountName,
          data
        )
        bookingCtx.dispatch({
          type: 'SET_PAYMENT_CONTACT',
          payload: { value: values },
        })
        bookingCtx.dispatch({
          type: 'SET_RESERVATION_DATA',
          payload: { value: order },
        })
        return true
      } catch (err) {
        enqueueSnackbar(buildErrorMessage(err), { variant: 'error' })
        captureError(err as Error)
        return false
      }
    },
  })

  const onBackHandler = useCallback(
    async (ev?: React.SyntheticEvent) => {
      ev?.preventDefault()
      navigateRoute('searchOffersResults', { serviceTypeName })
    },
    [navigateRoute, serviceTypeName]
  )

  const onContinueHandler = useCallback(
    async (ev?: React.SyntheticEvent) => {
      ev?.preventDefault()
      if (await formik.submitForm()) {
        const route = await continueNavigation(bookingCtx, accountName)
        if (route) {
          navigateRoute(route, { serviceTypeName })
        }
      }

      // always returns false because we are handling the navigation too
      // this way short-circuit the BackContinue navigation handling
      return false
    },
    [accountName, bookingCtx, formik, navigateRoute, serviceTypeName]
  )

  const policies = useMemo(() => {
    const policyByGroup = (reservation.flexBookingConfs || []).reduce<Record<string, TCardEntry[]>>((res, c) => {
      if (
        c.group &&
        c.target === 'ORDER' &&
        (c.step === 'BOOKING' || c.step === 'ALL') &&
        (c.type === 'INFO' || c.type === 'URL')
      ) {
        const groupedList = res?.[c.group] ?? []
        return {
          ...res,
          [c.group]: [...groupedList, c],
        }
      }
      return res
    }, {})
    return Object.values(policyByGroup)
  }, [reservation])

  // NOTE: the formId is used with the header's Continue button, to be able to submit
  // a form from outside the component tree
  return (
    <form id="details-policies-form" action="" onSubmit={onContinueHandler} style={{ width: '100%' }}>
      <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={2} flex={1}>
        <Stack direction="column" spacing={3} flex={1} width="100%">
          <ContactInformation formik={formik} />
          {isCheckInOutTimeEnabled(reservation.locationBookingConf!) && (
            <CheckInOut formik={formik} reservation={reservation} />
          )}
          {isVetEnabled(reservation.locationBookingConf!) && <VeterinarianInformation formik={formik} />}
          {Boolean(userProfile?.id) && isVaccinationEnabled(reservation.locationBookingConf!) && (
            <VaccinationRecords
              required={isVaccinationRequired(reservation.locationBookingConf!)}
              reservation={reservation}
            />
          )}
          {/* <SpecialInstructions formik={formik} /> */}
          {inputCardGroups.map((group, idx) => {
            return formik && <FlexCardField key={idx} fields={group} formik={formik} />
          })}
        </Stack>
        <Stack direction="column" spacing={3} flex={1} mt={isMobile ? 3 : 0} width="100%">
          {policies.map((policy, idx) => (
            <FlexCardDisplay key={idx} cardData={policy} />
          ))}
          <Box sx={{ padding: isMobile ? 2 : 0 }}>
            <Typography variant="body1" sx={{ opacity: 0.8 }} textAlign="left">
              {t('detailsPolicies.messageConsent', {
                facilityName: location?.displayName || '',
                terms: (
                  <Link target="_blank" href={`/${accountName}/privacy-policy`} key="terms">
                    {t('detailsPolicies.terms')}
                  </Link>
                ),
              })}
            </Typography>
            <Spacer height={2} />
            <Typography variant="body1" sx={{ opacity: 0.8 }} textAlign="left">
              {t('detailsPolicies.confirmation', {
                termConditionsLink: (
                  <Link target="_blank" href={'https://www.goose.pet/terms-conditions'} key="terms">
                    {t('detailsPolicies.tcAnchorText')}
                  </Link>
                ),
              })}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Spacer height={STICKY_FOOTER_HEIGHT} />
      <BackContinue
        continueLabel={t('detailsPolicies.continueButton') as string}
        onContinue={onContinueHandler}
        onBack={onBackHandler}
      />
    </form>
  )
}
