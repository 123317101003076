import { List, ListItem, Typography, Stack } from '@mui/material'
import { InfoRounded } from '@mui/icons-material'
import { convertMinutesToHoursAndMinutes } from '@shared/common'
import { i18WithDefault as t } from '@shared/locales'
import { PaymentSettingAdminDto } from 'service-api/src/types'

export interface FeeStructureProps {
  title: string
  fees: PaymentSettingAdminDto[]
  type: 'earlyArrival' | 'lateDeparture'
}

export const FeeStructure = ({ title, fees, type }: FeeStructureProps) => {
  const generateFeeStructureText = (fee: PaymentSettingAdminDto, feeIdx: number) => {
    const orMoreText = feeIdx === fees.length - 1 ? t('feeStructure.orMore') : ''
    switch (type) {
      case 'earlyArrival': {
        return `${convertMinutesToHoursAndMinutes(fee.before!)} ${orMoreText} ${t('feeStructure.early')}: $${fee.value}`
      }
      case 'lateDeparture': {
        return `${convertMinutesToHoursAndMinutes(fee.after!)} ${orMoreText} ${t('feeStructure.late')}: $${fee.value}`
      }
      default: {
        return
      }
    }
  }

  return (
    <Stack>
      <Stack flexDirection="row" alignItems="center" mt={1}>
        <InfoRounded color="disabled" />
        <Typography variant="body2" ml={1}>
          {title}
        </Typography>
      </Stack>
      <List sx={{ pt: 0, pl: 4, listStyleType: 'disc' }}>
        {fees
          .sort((feeA, feeB) => (type === 'earlyArrival' ? feeA.before! - feeB.before! : feeA.after! - feeB.after!))
          .map((fee, feeIdx: number) => (
            <ListItem key={feeIdx} sx={{ display: 'list-item', p: 0, fontSize: 14 }} disableGutters>
              {generateFeeStructureText(fee, feeIdx)}
            </ListItem>
          ))}
      </List>
    </Stack>
  )
}
