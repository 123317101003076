import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Fragment } from 'react'
import { FormikProps } from 'formik'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Types } from 'service-api'
import { isEmpty } from '@shared/common'
import { Spacer2 } from '../spacer'

export const FlexCardField = ({
  fields,
  formik,
}: {
  fields: Types.FlexBookingConfClientDto[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>
}) => {
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Paper sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
      {fields.map((field, idx) => {
        const isHead = idx === 0
        const fieldName = field.name || ''

        return (
          <Fragment key={field.id}>
            {isHead && !isEmpty(field.displayName) && (
              <Typography variant="h5" mb={2}>
                {field.displayName}
              </Typography>
            )}
            {field.description && (
              <Typography variant="body1" mb={2}>
                {field.description}
              </Typography>
            )}
            <TextField
              id={field.name}
              name={field.name}
              label={field.content}
              size="small"
              fullWidth
              multiline
              value={formik.values[fieldName]}
              onChange={formik.handleChange}
              error={Boolean(formik.errors[fieldName])}
              helperText={(formik.errors[fieldName] || '') as string}
            />
            {idx !== fields.length - 1 && <Spacer2 />}
          </Fragment>
        )
      })}
    </Paper>
  )
}
