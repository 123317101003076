import { useQuery } from '@tanstack/react-query'
import { ClientSpeciesService } from '../services'
import { APISpeciesClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'

export const useClientSpecies = (locationName: string, includeBreeds = false) => {
  return useQuery<APISpeciesClientDtoResponse>({
    queryKey: ['clientSpecies', locationName],
    queryFn: () => ClientSpeciesService.getSpecies(locationName, includeBreeds),
    ...defaultQueryOptions,
  })
}
