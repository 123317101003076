import { AuthDescription } from './auth_description'
import { AuthHeader } from './auth_header'
import { AuthLink } from './auth_link'
import { ForgotPasswordForm } from './forgot_password_form'
import { ModalType, TForgotPasswordProps } from './index.d'
import { i18WithDefault as t } from '../shared/locales'

export const ForgotPassword = ({ switchModalType, saveEmail }: TForgotPasswordProps) => (
  <>
    <AuthHeader value={t('forgotPassword.header')} />
    <AuthDescription value={t('forgotPassword.description')} />
    <ForgotPasswordForm switchModalType={switchModalType} saveEmail={saveEmail} />
    <AuthLink label={t('forgotPassword.link')} onClick={() => switchModalType(ModalType.signIn)} />
  </>
)
