import { AuthDescription } from './auth_description'
import { AuthHeader } from './auth_header'
import { ConfirmResetPasswordForm } from './confirm_reset_password_form'
import { TConfirmResetPasswordProps } from './index.d'
import { i18WithDefault as t } from '../shared/locales'

export const ConfirmResetPassword = ({ switchModalType, email }: TConfirmResetPasswordProps) => {
  const msg = t('passwordReset.message').replace('{{email}}', email)
  return (
    <>
      <AuthHeader value={t('logInForm.forgotPassword')} />
      <AuthDescription value={msg} />
      <ConfirmResetPasswordForm switchModalType={switchModalType} email={email} />
    </>
  )
}
