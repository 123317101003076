import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { FormikProps } from 'formik'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { states } from 'service-api/src/shared/states'
import { TPaymentFormSchema } from './payment_schema'

interface TProps {
  formik: FormikProps<TPaymentFormSchema>
}

export const BillingAddress = ({ formik }: TProps) => {
  const ctx = useBookingContext()

  const { contact } = ctx.state.cart

  return (
    <Box mt={2}>
      {!formik.values.useMailingAddress && (
        <>
          <TextField
            id="streetLine1"
            label={t('global.label.streetLine1')}
            variant="outlined"
            fullWidth
            value={formik.values.streetLine1}
            onChange={formik.handleChange}
            error={formik.touched.streetLine1 && Boolean(formik.errors.streetLine1)}
            helperText={formik.touched.streetLine1 && formik.errors.streetLine1}
          />
          <TextField
            id="streetLine2"
            label={t('global.label.streetLine2')}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            value={formik.values.streetLine2}
            onChange={formik.handleChange}
            error={formik.touched.streetLine2 && Boolean(formik.errors.streetLine2)}
            helperText={formik.touched.streetLine2 && formik.errors.streetLine2}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
            <TextField
              id="city"
              label={t('global.label.city')}
              variant="outlined"
              sx={{ width: { md: 0.5 } }}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
              id="state"
              name="state"
              label={t('global.label.state')}
              variant="outlined"
              select
              sx={{ width: { md: 0.5 } }}
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            >
              {states.map((state) => (
                <MenuItem key={state.code} value={state.code}>
                  {state.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="zipCode"
              label={t('global.label.zipCode')}
              variant="outlined"
              sx={{ width: { md: 0.5 } }}
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
            />
          </Stack>
          <TextField
            id="country"
            name="country"
            label={t('global.label.country')}
            variant="outlined"
            select
            fullWidth
            sx={{ mt: 2 }}
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          >
            <MenuItem value="US">{t('global.select.USA')}</MenuItem>
          </TextField>
        </>
      )}
      {formik.values.useMailingAddress && (
        <Box>
          <Typography variant="caption" component="p">
            <strong>
              {contact?.firstName} {contact?.lastName}
            </strong>
          </Typography>
          <Typography variant="caption" component="p">
            <strong>
              {contact?.mailingAddress.streetLine1}
              <br />
              {contact?.mailingAddress.streetLine2}
            </strong>
          </Typography>
          <Typography variant="caption" component="p">
            <strong>
              {contact?.mailingAddress.city}, {contact?.mailingAddress.state} {contact?.mailingAddress.zipCode}
            </strong>
          </Typography>
        </Box>
      )}
    </Box>
  )
}
