import { useAppRedirect } from 'app/use_app_redirect'
import { useLocationSetup } from 'hooks/use_location_setup'
import { useMonitoringData } from 'hooks/use_monitoring_data'
import { Outlet, ScrollRestoration } from 'react-router-dom'

export const AppLayout = () => {
  useAppRedirect()
  useLocationSetup()
  useMonitoringData()

  return (
    <>
      <ScrollRestoration />
      <Outlet />
    </>
  )
}
