import { Alert, Box, FormControlLabel, Paper, Radio, RadioGroup, Stack, Typography, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { enqueueSnackbar } from 'notistack'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BackContinue } from '@components/back_continue/back_continue'
import { useBookingContext } from '@providers/booking'
import { MAX_WIDGET_SIZE, STICKY_FOOTER_HEIGHT } from '@shared/constants'
import { buildErrorMessage } from '@shared/error'
import { i18WithParams as t } from '@shared/locale'
import { captureError } from '@shared/monitoring'
import { useRoutes } from 'app/use_routes'
import {
  AdyenCardPayment,
  FlexCardDisplay,
  PurchaseReceipt,
  PurchaseReceiptSkeleton,
  Spacer,
  Spacer2,
  Spacer3,
  SurchargePolicy,
  TOnPaymentCompleted,
  getPoliciesData,
  submitPaymentForm,
} from 'components-ui'
import { allowCardAndPrepaidPayments, allowNoPayments } from 'components-ui/src/shared/location'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'
import { Hooks, Services } from 'service-api'
import { isHttpGone } from 'service-api/src/shared/request'
import { getBillingAddress, removeDecimal } from './helper'
import { usePayment } from './use_payment'

let promiseResolve: (value: boolean | PromiseLike<boolean>) => void, promiseReject: (err: unknown) => void

export const AdyenPayment = () => {
  const bookingCtx = useBookingContext()
  const { accountName: locationName = '' } = useParams()
  const theme = useTheme()
  const { navigateRoute } = useRoutes()
  const renderStyle = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop'
  const isMobile = renderStyle === 'mobile'
  const receipt = Hooks.useClientReceipt(locationName, bookingCtx.state.data.reservation?.order?.id || '', 'PAYMENT')
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  usePayment()
  useRedirectNoData()
  useEffect(() => {
    if (bookingCtx.state.currentState !== 'payment') {
      bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'payment' } })
    }
  })

  const locationDisplayName = bookingCtx.state.data.reservation?.order?.location?.displayName ?? locationName ?? ''
  const allowCardAndPrepaid = allowCardAndPrepaidPayments(bookingCtx.state.data.reservation?.locationBookingConf)
  const noPaymentsAllowed = allowNoPayments(bookingCtx.state.data.reservation?.locationBookingConf)

  const isCardPayment = !noPaymentsAllowed && bookingCtx.state.cart.payment.method === 'payNow'
  const isPrepaid = !noPaymentsAllowed && bookingCtx.state.cart.payment.method === 'prePaid'

  const dueToday =
    isPrepaid || noPaymentsAllowed ? null : bookingCtx.state.data.reservation?.order?.payments?.[0]?.depositDue ?? null

  const policyCardsData = useMemo(
    () => (receipt.data ? getPoliciesData(receipt.data, noPaymentsAllowed) : []),
    [receipt.data, noPaymentsAllowed]
  )

  const payAtFacility = async () => {
    const reservationResponse = await Services.ClientBookingPaymentService.offlinePayment(
      locationName,
      bookingCtx.state.data.reservation?.order?.id || ''
    )
    navigateRoute('paymentSummary', { orderId: reservationResponse.order?.id || '' })
    return true
  }

  const adyenPay = () => {
    const promise = new Promise<boolean>((resolve, reject) => {
      promiseResolve = resolve
      promiseReject = reject
    })

    // this is only initiating the payment, the function is synchronous
    // the actual payment is done in the AdyenCardPayment component and
    // the result is passed to the onPaymentCompleted callback or onError
    // on those callbacks the promise will be resolved or rejected
    submitPaymentForm('paymentFormId')
    return promise
  }

  const onClickContinue = async () => {
    try {
      const rc = await (noPaymentsAllowed || isPrepaid ? payAtFacility() : adyenPay())
      bookingCtx.dispatch({
        type: 'SET_FIRE_PURCHASE_EVENT',
        payload: { value: true },
      })
      return typeof rc !== 'undefined'
    } catch (err) {
      if (isHttpGone(err)) {
        navigateRoute('detailsPolicies', {})
        enqueueSnackbar(t('paymentForm.orderExpired'), { variant: 'info' })
      } else {
        enqueueSnackbar(buildErrorMessage(err), { variant: 'error' })
      }
      captureError(err as Error)
      return false
    }
  }

  const onChangePaymentMethod = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    bookingCtx.dispatch({ type: 'SET_PAYMENT_METHOD', payload: { value: value } })
  }

  const onPaymentCompleted = async (result: TOnPaymentCompleted) => {
    if (result.failed) {
      setErrorMsg(t('payments.error.general'))
      // close the promise created on adyenPay function
      promiseResolve(false)
      return
    }

    try {
      const reservationResponse = await Services.ClientBookingPaymentService.adyenPayment(
        locationName,
        bookingCtx.state.data.reservation?.order?.id || '',
        result.sessionResult
      )
      enqueueSnackbar(t('payments.success'), { variant: 'success' })
      navigateRoute('paymentSummary', { orderId: reservationResponse.order?.id || '' })
    } catch (e) {
      captureError(e as Error)
      setErrorMsg(t('payments.error.general'))
    }

    // close the promise created on adyenPay function
    promiseResolve(false)
  }

  const onPaymentError = (err: Error) => {
    if (err.message === 'invalid-form') {
      // this type of error is handled by the form component
      // so we don't need to show it here
      promiseResolve(false)
    } else {
      captureError(err)
      // close the promise created on adyenPay function
      promiseReject(err)
    }
  }

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <Typography variant="h1" sx={{ px: 2 }}>
        {t('paymentForm.title')}
      </Typography>
      <Spacer2 />

      <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={3} flex={1}>
        <Stack direction="column" spacing={3} flex={1} width="100%">
          {allowCardAndPrepaid && (
            <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isMobile ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="h5">{t('paymentForm.options')}</Typography>
              <Spacer3 />
              <RadioGroup value={bookingCtx.state.cart.payment.method} onChange={onChangePaymentMethod}>
                <FormControlLabel value="payNow" control={<Radio />} label={t('paymentForm.payNow')} />
                <FormControlLabel value="prePaid" control={<Radio />} label={t('paymentForm.prePaid')} />
              </RadioGroup>
            </Paper>
          )}

          {noPaymentsAllowed && (
            <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isMobile ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="h5">{t('paymentForm.options')}</Typography>
              <Spacer3 />
              <Typography variant="body1">{t('paymentForm.dueAtFacility', { locationDisplayName })}</Typography>
            </Paper>
          )}

          {isCardPayment && (
            <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isMobile ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="h5">{t('paymentForm.method')}</Typography>
              {!!bookingCtx.state.data.reservation?.storePaymentMethod && (
                <Typography variant="body1">
                  {t('paymentForm.storePaymentMethod.msg', { locationName: locationDisplayName })}
                </Typography>
              )}
              <Spacer3 />

              {errorMsg && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorMsg}
                </Alert>
              )}

              <AdyenCardPayment
                sessionId={bookingCtx.state.data.reservation?.order?.payments?.[0]?.paymentIntentId}
                sessionData={bookingCtx.state.data.reservation?.clientSecret}
                amount={removeDecimal(bookingCtx.state.data.reservation?.order?.amountDue)}
                billingAddress={getBillingAddress(bookingCtx.state.cart.contact!)}
                currency="USD"
                paymentFormId="paymentFormId"
                storeDetailsMsg={`Save card and share with ${locationDisplayName} for future payments`}
                onPaymentCompleted={onPaymentCompleted}
                onError={onPaymentError}
              />
            </Paper>
          )}

          <Stack spacing={3} sx={{ flex: '1 0 49%' }}>
            {bookingCtx.state.data.reservation && <SurchargePolicy reservation={bookingCtx.state.data.reservation} />}
            {policyCardsData.map((group, index) => (
              <FlexCardDisplay key={index} cardData={group} />
            ))}
          </Stack>
        </Stack>

        <Stack direction="column" spacing={3} flex={1} mt={isMobile ? 3 : 0} width="100%">
          {receipt.isLoading ? (
            <PurchaseReceiptSkeleton />
          ) : (
            <PurchaseReceipt receipt={receipt.data} dueToday={dueToday} />
          )}
        </Stack>
      </Stack>

      <Spacer height={STICKY_FOOTER_HEIGHT} />
      <BackContinue continueLabel={t('paymentForm.bookNow')} onContinue={onClickContinue} />
    </Box>
  )
}
