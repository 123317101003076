import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'

import { BackContinue } from '@components/back_continue/back_continue'
import { useBookingContext } from '@providers/booking'
import { buildPetTabGroups } from '@shared/booking_groups'
import type { TOfferAddonGroups } from '@shared/booking_groups.d'
import { MAX_WIDGET_SIZE, STICKY_FOOTER_HEIGHT } from '@shared/constants'
import { i18WithParams as t } from '@shared/locale'
import { AddOnItem } from '@views/search_pet_addons/addon_item_picker/addon_item_picker'
import { BoardingIcon, Spacer, Spacer1, Spacer3, Tabs } from 'components-ui'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'

export const SearchRoomAddOns = () => {
  const bookingCtx = useBookingContext()
  const [currentTabIndex, setTabIndex] = useState(0)
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const onChangeTab = useCallback((_label: string, index: number) => setTabIndex(index), [])

  // redirect to home if the state has invalid data
  useRedirectNoData()

  useEffect(() => {
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'roomAddOns' } })
  })

  const addonGroups = useMemo(() => {
    const emptyGroup = { order: [], items: {} } as TOfferAddonGroups
    return (
      bookingCtx.state.data.offersAddOns?.reduce((acc, searchAddOn) => {
        const groupName = searchAddOn.offer!.group || ''
        // const groupName = searchAddOn.offer!.offerGroup?.displayName || searchAddOn.offer!.group || ''
        if (acc.order.findIndex((o) => o === groupName) < 0) {
          acc.order.push(groupName)
        }
        acc.items[groupName] = acc.items[groupName] || []
        acc.items[groupName].push(searchAddOn)
        return acc
      }, emptyGroup) || emptyGroup
    )
  }, [bookingCtx.state.data.offersAddOns])

  const addOnsToRender = useMemo(() => {
    return addonGroups.order
      .map((og) => {
        const currentPetGroupOfferId = bookingCtx.state.cart.groups?.[currentTabIndex]?.offerSelected?.offer?.id
        const itemsToRender = addonGroups.items[og].filter(
          (item) =>
            item.offer?.associatedOffers?.some(({ parentOfferId }) => parentOfferId === currentPetGroupOfferId) &&
            item.availabilityGroups?.[currentTabIndex].availabilities?.some(({ available }) => !!available)
        )
        return itemsToRender.length ? (
          <Box key={og === '' ? '_empty_' : og} sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto 24px' }}>
            <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
              <>
                <Stack direction="row" spacing={1} alignItems="center">
                  <BoardingIcon />
                  <Typography variant="h5">{og === '' ? t('searchRoomAddOns.genericTitle') : og}</Typography>
                </Stack>
                <Spacer1 />
                <Typography variant="body1">{t('searchRoomAddOns.summary')}</Typography>

                {!isBelowMD ? <Spacer3 /> : <Spacer1 />}
                {isBelowMD && <Divider orientation="horizontal" sx={{ my: 2 }} />}

                <Box sx={{ maxWidth: '640px', margin: '0 auto' }}>
                  {itemsToRender.map((o, idx) => {
                    return (
                      <Fragment key={o.offer!.id!}>
                        <AddOnItem addOn={o} groupIdx={currentTabIndex} kind="roomAddon" />
                        {idx < itemsToRender.length - 1 && <Divider orientation="horizontal" sx={{ my: 2 }} />}
                      </Fragment>
                    )
                  })}
                </Box>
              </>
            </Paper>
          </Box>
        ) : null
      })
      .filter((a) => !!a)
  }, [
    addonGroups.items,
    addonGroups.order,
    bookingCtx.state.cart.groups,
    currentTabIndex,
    isBelowMD,
    theme.shape.borderRadius,
  ])

  const tabs = buildPetTabGroups(bookingCtx.state.cart.groups)

  useEffect(() => {
    document.title = t('title.addRoom')
  }, [])

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <>
        <Typography variant="h1" sx={{ px: 2 }}>
          {t('searchRoomAddOns.title')}
        </Typography>
        {tabs.length > 1 && (
          <>
            <Spacer3 />
            <Tabs tabIndex={currentTabIndex} tabs={tabs} onChange={onChangeTab} />
          </>
        )}

        <Spacer3 />

        {addOnsToRender?.length ? (
          addOnsToRender
        ) : (
          <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
            <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
              <Typography variant="body1">
                {t('booking.roomAddOns.emptyMessage', { petNames: tabs[currentTabIndex].label })}
              </Typography>
            </Paper>
          </Box>
        )}
        <Spacer height={STICKY_FOOTER_HEIGHT} />
        <BackContinue />
      </>
    </Box>
  )
}
