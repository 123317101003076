import { Types } from 'service-api'

export const petRequiresTemperTest = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(available && condition?.reason?.type === 'TEMPER_TEST' && !unavailableReason && affectedByPets?.length)
}

export const petFailedTemperTest = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(
    !available &&
      !condition?.reason &&
      unavailableReason?.type === 'TEMPER_TEST' &&
      unavailableReason.contingent &&
      affectedByPets?.length
  )
}

export const petPassedTemperTest = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(available && !condition?.reason && !unavailableReason && !affectedByPets?.length)
}

export const petHasTemperTestScheduled = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(
    available &&
      condition?.reason?.type === 'TEMPER_TEST' &&
      condition.dependOnDate &&
      !unavailableReason &&
      affectedByPets?.length
  )
}

export const petCannotBookOnline = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(
    !available &&
      !condition?.reason &&
      unavailableReason?.type === 'SALES_CHANNEL' &&
      unavailableReason.text?.toLowerCase() === 'client' &&
      affectedByPets?.length
  )
}

export const allNonTestedPetsCannotBookOnline = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(
    !available &&
      !condition?.reason &&
      unavailableReason?.type === 'TEMPER_TEST' &&
      !unavailableReason.contingent &&
      affectedByPets?.length
  )
}

export const tooSoonToBook = (availabilityGroup: Types.AvailabilityGroupClientDto | undefined) => {
  const { available, condition, unavailableReason, affectedByPets } = availabilityGroup?.availabilities?.[0] || {}
  return Boolean(
    !available &&
      condition?.reason?.type === 'TEMPER_TEST' &&
      unavailableReason?.type === 'TEMPER_TEST' &&
      affectedByPets?.length
  )
}
