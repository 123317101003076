import { Typography, Stack, useTheme } from '@mui/material'
import { i18WithDefault as t } from '@shared/locales'
import { formatDate, formatAmPm, dayToJSDate, getDOWFromStr } from '@shared/dates'
import {
  displayHoursInPeriodRestrictions,
  isSelectedDayInPeriodRestrictions,
  isSelectedDateInPeriodRestrictionsRelation,
} from '@shared/restrictions'
import { FeeStructure } from './fee_structure'
import { PaymentSettingAdminDto } from 'service-api/src/types'
import { Types } from 'service-api'

export interface EarlyArrivalDetailsProps {
  startDate: string
  startTime?: string | null
  reservation: Types.BookingReservationClientDto
}

export const EarlyArrivalDetails = ({ startDate, startTime, reservation }: EarlyArrivalDetailsProps) => {
  const { order, locationBookingConf, checkInHour, operationHour } = reservation
  const theme = useTheme()
  const checkInDay = startDate && getDOWFromStr(startDate)

  const earlyArrival = (order?.data as Record<string, []>).surchargeSettings?.filter(
    (fee: PaymentSettingAdminDto) =>
      fee?.before &&
      checkInDay &&
      isSelectedDateInPeriodRestrictionsRelation(fee.restrictionRelations!, checkInDay, startDate)
  ) as PaymentSettingAdminDto[]

  const isCheckInEnabled =
    checkInHour &&
    checkInDay &&
    isSelectedDayInPeriodRestrictions(checkInHour, checkInDay) &&
    (locationBookingConf?.surchargeStrategy === 'SUGGESTED' || locationBookingConf?.surchargeStrategy === 'ENFORCED')

  return (
    <Stack>
      {startDate && (
        <Typography variant="body1" style={{ fontWeight: 700 }} mt={1} mb={1}>
          {t('surchargePolicy.arrival')}: {formatDate(dayToJSDate(startDate))} {startTime && formatAmPm(startTime)}
        </Typography>
      )}
      {checkInDay && operationHour && (
        <Typography variant="caption" mt={1} mb={1}>
          {t('surchargePolicy.open')}: {displayHoursInPeriodRestrictions(operationHour, checkInDay)}
        </Typography>
      )}
      {isCheckInEnabled && checkInDay && (
        <Typography variant="body2" sx={{ backgroundColor: theme.palette.action.selected, p: 1 }} mt={1} mb={1}>
          {t('surchargePolicy.checkInHours')}: {displayHoursInPeriodRestrictions(checkInHour!, checkInDay)}
        </Typography>
      )}
      {locationBookingConf?.surchargeStrategy === 'ENFORCED' && !!earlyArrival.length && (
        <FeeStructure type="earlyArrival" title={t('feeStructure.earlyArrival.title')} fees={earlyArrival} />
      )}
    </Stack>
  )
}
