import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_AGREEMENTS_PATH, CLIENT_CONTRACTS_AGREEMENTS_PATH } from '../types/constants'
import { AgreementClientDto, AgreementCreateInput, APIAgreementClientDtoDtoResponse } from '../types/api'

export const ClientAgreementsService = {
  get: (locationId: string, contractId: string): Promise<AgreementClientDto | null> => {
    return requestGooseAPI<APIAgreementClientDtoDtoResponse>({
      url: getAPIPath(CLIENT_AGREEMENTS_PATH, { locationId, contractId }),
      method: 'GET',
    }).then((res) => {
      return res?.results?.[0] ?? null
    })
  },
  sign: (locationId: string, contractId: string, data: AgreementCreateInput): Promise<AgreementClientDto> => {
    return requestGooseAPI<AgreementClientDto>({
      url: getAPIPath(CLIENT_CONTRACTS_AGREEMENTS_PATH, { locationId, contractId }),
      method: 'POST',
      data,
    })
  },
}
