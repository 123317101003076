import { Box, Stack, Typography } from '@mui/material'
import { useSessionContext } from '@providers/session'
import { isUserLoggedIn } from '@providers/session/helpers'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { i18WithDefault as t } from '@shared/locale'
import { Intake } from '@views/intake/intake'
import { useRoutes } from 'app/use_routes'
import {
  FlexCardDisplay,
  isIntakeEnabled,
  InvoiceCard,
  PurchaseReceipt,
  Spacer3,
  allowNoPayments,
  getPoliciesData,
} from 'components-ui'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Hooks, Services, Types } from 'service-api'
import { PurchaseSummarySkeleton } from './purchase_summary_skeleton'

export const PurchaseSummary = () => {
  const sessionCtx = useSessionContext()
  const { navigateRoute } = useRoutes()
  const { accountName = '', bookingId = '' } = useParams()
  const [order, setOrder] = useState<Types.OrderClientDto | undefined>()
  const [isOrderLinkingLoading, setIsOrderLinkingLoading] = useState<boolean>(false)
  const {
    data: orderResponse,
    isFetching: isOrderLoading,
    isSuccess: isOrderSuccess,
    refetch: refetchOrder,
    error: orderError,
  } = Hooks.useClientOrder(accountName, bookingId)
  const {
    data: receipt,
    isFetching: isReceiptLoading,
    refetch: refetchReceipt,
  } = Hooks.useClientReceipt(accountName, bookingId)
  const { data: bookingConfs, isFetching: isBookingConfsLoading } = Hooks.useClientBookingConfigs(
    accountName,
    order?.primaryServiceType?.serviceType?.name || '',
    { enabled: !!(order?.ownerId || order?.owner?.id) }
  )
  const isPackage = order?.primaryServiceType?.serviceType?.name === 'packages'
  const noPaymentsAllowed = allowNoPayments(order?.primaryServiceType?.locationBookingConf?.[0])
  const policyCardsData = useMemo(
    () => (receipt ? getPoliciesData(receipt, noPaymentsAllowed) : []),
    [receipt, noPaymentsAllowed]
  )
  const loggedIn = isUserLoggedIn(sessionCtx.state.user)

  useEffect(() => {
    document.title = t('title.purchaseSummary')
  }, [])

  useEffect(() => {
    if (!isOrderSuccess) {
      return
    }

    if (!loggedIn) {
      setTimeout(
        () =>
          navigateRoute(
            'signIn',
            {},
            {
              state: {
                redirect: {
                  route: 'purchaseSummary',
                  params: { bookingId },
                },
                email: orderResponse?.userData?.[0]?.email,
                userExists: !!(orderResponse.ownerId || orderResponse.owner?.id),
              },
            }
          ),
        0
      )
      return
    }

    if (!orderResponse.ownerId && !orderResponse.owner?.id) {
      if (orderResponse.userData?.[0]?.email !== sessionCtx.state.user?.email) {
        setTimeout(() => navigateRoute('notFound', {}), 0)
      } else {
        setIsOrderLinkingLoading(true)
        Services.ClientOrderService.linkOrderToUser(bookingId, accountName)
          .then((result) => {
            setOrder(result)
          })
          .catch(() => {
            setTimeout(() => navigateRoute('notFound', {}), 0)
          })
          .finally(() => {
            setIsOrderLinkingLoading(false)
          })
      }
      return
    }

    setOrder(orderResponse)
  }, [orderResponse, isOrderSuccess, loggedIn, sessionCtx.state.user?.email])

  useEffect(() => {
    if (orderError) {
      setTimeout(() => navigateRoute('notFound', {}), 0)
    }
  }, [orderError])

  if (isOrderLoading || isReceiptLoading || isBookingConfsLoading || isOrderLinkingLoading) {
    return <PurchaseSummarySkeleton />
  }

  if (!order?.invoices) {
    return null
  }

  return (
    <Stack spacing={3} sx={{ maxWidth: MAX_WIDGET_SIZE, p: 3, pb: '130px', margin: '0 auto' }}>
      <Box>
        <Spacer3 />
        <Typography variant="h4">{t('purchaseSummary.title')}</Typography>
      </Box>
      <Stack spacing={3}>
        {order.invoices.map((invoice) => (
          <InvoiceCard
            key={invoice.id}
            invoice={invoice}
            order={order}
            isPackage={isPackage}
            onCancelInvoice={() => {
              refetchOrder()
              refetchReceipt()
            }}
          />
        ))}
      </Stack>
      {isIntakeEnabled(bookingConfs?.locationBookingConf) && (
        <Intake
          flexBookingConfs={bookingConfs?.flexBookingConfs || []}
          order={order || {}}
          onSave={(newOrder) => {
            setOrder(newOrder)
          }}
        />
      )}
      <Stack direction="row" flexWrap="wrap" gap={1}>
        <Stack spacing={3} sx={{ flex: '1 0 49%' }}>
          {policyCardsData.map((group, index) => (
            <FlexCardDisplay key={index} cardData={group} />
          ))}
        </Stack>
        <Box sx={{ flex: '1 0 50%', height: 'fit-content' }}>
          <PurchaseReceipt receipt={receipt} isPackage={isPackage} />
        </Box>
      </Stack>
    </Stack>
  )
}
