import * as Sentry from '@sentry/browser'

export const setTag = (key: string, value: string) => {
  Sentry.setTag(key, value)
}

export const setUser = (id: string, email: string) => {
  Sentry.setUser({ id, email })
}

export const captureError = (error: Error) => {
  Sentry.captureException(error)
}
