import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { NotFound } from '@views/not_found'
import { AppLayout } from '@layouts/app_layout'
import { OfferLayout } from '@layouts/offer_layout'
import { routes, pageKeys, offerKeys, noHeaderPageKeys } from './routes_definitions'
import { wrapCreateBrowserRouter, ErrorBoundary } from '@sentry/react'
import type { TRouteNames } from './app.d'
import { PageLayout } from '@layouts/page_layout'
import TagManager from 'react-gtm-module'
import { useLocationContext } from '@providers/location'

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

export const AppRoutes = () => {
  const { location } = useLocationContext()
  const renderRoute = (k: TRouteNames) => {
    const Element = routes[k].component
    return <Route key={k} element={<Element />} path={routes[k].path} />
  }

  if (location) {
    TagManager.dataLayer({
      dataLayer: {
        location: {
          name: location?.name,
        },
      },
    })
  }

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<AppLayout />}>
          <Route element={<PageLayout />}>
            <Route>{pageKeys.map(renderRoute)}</Route>
            <Route element={<OfferLayout />}>{offerKeys.map(renderRoute)}</Route>
          </Route>
          <Route>{noHeaderPageKeys.map(renderRoute)}</Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </>
    )
  )

  return (
    <ErrorBoundary fallback={<NotFound />}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  )
}
