import { useMemo, useCallback } from 'react'
import { generatePath, NavigateOptions, useNavigate, useParams } from 'react-router-dom'

import type { RoutesPathsType, NavigateToFx, TRouteNames, BuildPathFx } from './app.d'
import { routes, routeKeys } from './routes_definitions'
import { useSessionContext } from '@providers/session'

export const useRoutes = (): {
  routes: RoutesPathsType
  navigateTo: NavigateToFx
  navigateRoute: typeof navigateRoute
  buildPath: BuildPathFx
} => {
  const { accountName = '' } = useParams()
  const { state } = useSessionContext()
  const navigate = useNavigate()
  const _getPath = (routeName: TRouteNames) =>
    generatePath(routes[routeName].path, {
      accountName: state.currentLocation.name,
    })

  //
  // This will memoise the resolved routes using the current facilityName
  // ex:
  //   calendar = '/:facilityName/calendar' => '/the-kennel/calendar'
  //
  const resolvedRoutes = useMemo<Record<TRouteNames, string>>(
    () =>
      routeKeys.reduce((acc, key) => {
        if (routes[key].preResolve) {
          acc[key] = _getPath(key)
        }
        return acc
      }, {} as RoutesPathsType),
    // the helper function _getPath only has facilityName as dependency
    // every time it change is ok to recalculate all the paths again
    // react-hooks infers that _getPath is a dependency too
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  const navigateTo = useCallback(
    (routeName: TRouteNames, options?: NavigateOptions) => {
      navigate(resolvedRoutes[routeName], options)
    },
    [navigate, resolvedRoutes]
  )

  const navigateRoute = useCallback(
    (route: TRouteNames, options: GenericSimpleBag, navigateOptions?: NavigateOptions) => {
      const opts = Object.assign({}, { accountName }, options)
      const path = generatePath(routes[route].path, opts)
      navigate(path, navigateOptions)
    },
    [accountName, navigate]
  )

  const buildPath = useCallback(
    (route: TRouteNames, options: GenericSimpleBag) => {
      const opts = Object.assign({}, { accountName }, options)
      return generatePath(routes[route].path, opts)
    },
    [accountName]
  )

  return {
    routes: resolvedRoutes,
    navigateTo,
    navigateRoute,
    buildPath,
  }
}
