import { Box, Button, Checkbox, Modal, Stack, Typography } from '@mui/material'
import { formatNumberAsPrice } from '@shared/common'
import { i18WithParams as t } from '@shared/locales'
import { isInvoiceCanceled } from '@shared/invoices'
import { ButtonActions } from 'button_actions'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Types } from 'service-api'
import { useAdminCancelInvoice, useClientCancelInvoice } from 'service-api/src/hooks'
import { ClientInvoiceService, AdminInvoiceService } from 'service-api/src/services'
import { getInvoiceDates, capitalize } from 'shared'
import { TextInputMoney } from 'textinput_money'
import { getRefundRestrictions } from './helper'
import { getPrimaryServiceTypeName } from '@shared/invoices'
import { captureError } from 'service-api/src/shared'

type Props = {
  invoice: Types.CustomInvoiceAdminDto
  order: Types.CustomOrderAdminDto
  canOverrideRefund?: boolean
  open: boolean
  onClose: () => void
  onCancelInvoice: () => void
}
export const CancelInvoiceModal = ({ invoice, order, canOverrideRefund, open, onClose, onCancelInvoice }: Props) => {
  const { accountName = '' } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const primaryType = getPrimaryServiceTypeName(invoice)
  const [refundMode, setRefundMode] = useState(false)
  const [overrideRefundAmount, setOverrideRefundAmount] = useState('')
  const [alsoCancelGroomingAppointment, setAlsoCancelGroomingAppointment] = useState<boolean>(true)

  const { startDate: invoiceStartDate } = useMemo(() => getInvoiceDates(invoice), [invoice])
  // refundSettingToApply tells us what deposit refund rate to use at this particular time
  // maxCancelBy is the latest time the invoice can be canceled
  const { refundSettingToApply, maxCancelBy } = useMemo(
    () => getRefundRestrictions(order, invoiceStartDate),
    [order, invoiceStartDate]
  )

  const refundEligibleAmount = (invoice.netPaid || 0) * parseFloat(refundSettingToApply?.rate || '0')

  const { refetch: clientCancelInvoice } = useClientCancelInvoice(accountName, order.id!, invoice.id!)
  const { refetch: adminCancelInvoice } = useAdminCancelInvoice(
    accountName,
    order.id!,
    invoice.id!,
    overrideRefundAmount ? parseFloat(overrideRefundAmount) : undefined,
    invoice.locationServiceType?.serviceType?.name === 'grooming' ? 'CANCELED' : undefined
  )

  const applicableGroomsInOrder = useMemo(() => {
    return order.invoices?.filter(
      (invoice) =>
        invoice.items?.[0].locationServiceType?.serviceType?.name === 'grooming' &&
        invoice.isInStay &&
        !isInvoiceCanceled(invoice)
    )
  }, [order.invoices])

  useEffect(() => {
    if (!open) {
      setRefundMode(false)
    }
  }, [open])

  useEffect(() => {
    setOverrideRefundAmount(refundMode ? refundEligibleAmount.toFixed(2) : '')
  }, [refundMode, refundEligibleAmount])

  const renderRefundMode = () => (
    <>
      <Typography variant="h4" mb={3}>
        {t('cancelInvoice.overrideRefund.title')}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="body1">{t('global.label.amountPaid')}</Typography>
          <Typography variant="h6">{formatNumberAsPrice(invoice.netPaid || 0)}</Typography>
        </Stack>
        <TextInputMoney
          autoFocus={true}
          id="refund-amount"
          label={t('cancelInvoice.refundAmount')}
          value={overrideRefundAmount}
          onChange={(e) => setOverrideRefundAmount(e.target.value)}
        />
      </Stack>
    </>
  )

  const handleSubmit = async () => {
    if (accountName && order.id && invoice.id) {
      // cancel all grooms in applicableGroomsInOrder as well if the invoice we're canceling isnt a groom
      if (alsoCancelGroomingAppointment && invoice.items?.[0].locationServiceType?.serviceType?.name !== 'grooming') {
        applicableGroomsInOrder?.map((associatedGroom): void => {
          if (order.id && associatedGroom.id) {
            canOverrideRefund
              ? AdminInvoiceService.cancelInvoice(accountName, order.id, associatedGroom.id, {
                  amount: overrideRefundAmount ? parseFloat(overrideRefundAmount) : undefined,

                  invoiceSubStatus:
                    associatedGroom.locationServiceType?.serviceType?.name === 'grooming' ? 'CANCELED' : undefined,
                }).catch((err) => {
                  const errorMsg = err?.response?.data?.error ?? t('global.label.unexpectedError')
                  enqueueSnackbar(errorMsg, { variant: 'warning' })
                })
              : ClientInvoiceService.cancelInvoice(accountName, order.id, associatedGroom.id).catch((err) => {
                  const errorMsg = err?.response?.data?.error ?? t('global.label.unexpectedError')
                  enqueueSnackbar(errorMsg, { variant: 'warning' })
                })
          }
        })
      }
      const cancelInvoice = canOverrideRefund ? adminCancelInvoice : clientCancelInvoice
      await cancelInvoice()
        .then(({ data, error }) => {
          if (data) {
            onCancelInvoice()
            enqueueSnackbar(
              t(
                alsoCancelGroomingAppointment
                  ? 'cancelInvoice.enqueue.multi.success'
                  : 'cancelInvoice.enqueue.single.success',
                { primary: capitalize(primaryType) }
              ),
              {
                variant: 'info',
              }
            )
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const errorMsg = error?.response?.data?.error ?? t('global.label.unexpectedError')
            enqueueSnackbar(errorMsg, { variant: 'warning' })
          }
        })
        .catch((error: Error) => {
          enqueueSnackbar(t('global.label.unexpectedError'), { variant: 'error' })
          captureError(error as Error)
        })
        .finally(() => {
          onClose()
        })
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-cancel-invoice"
      aria-describedby="modal-cancel-invoice-for-order"
    >
      <Box sx={styledBox}>
        {refundMode ? (
          renderRefundMode()
        ) : (
          <>
            <Typography variant="h4" mb={3}>
              {t('cancelInvoice.confirmCancel')}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('cancelInvoice.cancelDescription', {
                  amount: formatNumberAsPrice(refundEligibleAmount),
                }),
              }}
            />
            {applicableGroomsInOrder &&
              applicableGroomsInOrder?.length > 0 &&
              invoice.items?.[0].locationServiceType?.serviceType?.name !== 'grooming' && (
                <>
                  <Typography variant="body1" mt={1}>
                    {t('cancelInvoice.grooms.description', {
                      count: (applicableGroomsInOrder?.length || 0).toString(),
                    })}
                  </Typography>
                  <Stack direction={'row'} spacing={1} mt={1}>
                    <Checkbox
                      style={{ padding: 0 }}
                      checked={alsoCancelGroomingAppointment}
                      onClick={() => setAlsoCancelGroomingAppointment(!alsoCancelGroomingAppointment)}
                    />
                    <Typography variant="body1">{t('cancelInvoice.grooms.notice')}</Typography>
                  </Stack>
                </>
              )}
            {canOverrideRefund && (
              <Box>
                <Button variant="text" onClick={() => setRefundMode(true)}>
                  {t('cancelInvoice.overrideRefund.button')}
                </Button>
              </Box>
            )}
          </>
        )}
        <Stack direction="row" justifyContent="flex-end" mt={2} mr={-2}>
          <ButtonActions
            actionEnabled={true}
            onActionLabel={t('global.label.continue')}
            onAction={handleSubmit}
            onCancel={async () => onClose()}
            onCancelLabel={t('global.label.goBack')}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

const styledBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  width: 'min(456px, 100%)',
  borderRadius: 1,
  display: 'flex',
  flexDirection: 'column',
  outline: 0,
  pt: 2,
  pr: 3,
  pl: 3,
  pb: 1,
}
