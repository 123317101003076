import { TChangePasswordProps } from './index.d'
import { AuthHeader } from './auth_header'
import { ChangePasswordForm } from './change_password_form'
import { i18WithDefault as t } from '../shared/locales'

export const ChangePassword = ({ switchModalType, user }: TChangePasswordProps) => {
  return (
    <>
      <AuthHeader value={t('confirmResetPassword.submitButton')} />
      <ChangePasswordForm switchModalType={switchModalType} user={user} />
    </>
  )
}
