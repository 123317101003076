import { getAPIPath, requestGooseAPI } from '../shared/request'
import {
  APIBookingSearchClientDtoResponse,
  APIBookingSearchByDaysClientDtoResponse,
  CLIENT_BOOKING_SEARCH_PATH,
  CLIENT_BOOKING_SEARCH_DAYS_PATH,
} from '../types'
import { GenericSimpleBag, TBookingSearchOptions } from '../types/general'

export const ClientBookingSearchService = {
  search: (
    locationId: string,
    serviceTypeName: string,
    options: TBookingSearchOptions
  ): Promise<APIBookingSearchClientDtoResponse> => {
    return requestGooseAPI<APIBookingSearchClientDtoResponse>({
      url: getAPIPath(CLIENT_BOOKING_SEARCH_PATH, { locationId, serviceTypeName }),
      method: 'POST',
      params: {
        start: options.startDate,
        end: options.endDate,
        type: options.offersType,
      },
      data: {
        petGroups: options.petGroups,
      },
    })
  },

  searchByDays: (
    locationId: string,
    serviceTypeName: string,
    options: TBookingSearchOptions
  ): Promise<APIBookingSearchByDaysClientDtoResponse> => {
    const params = {
      start: options.startDate,
      end: options.endDate,
      type: options.offersType,
    } as Record<string, string>
    if (options.offerName) {
      params['name'] = options.offerName
    }

    return requestGooseAPI<APIBookingSearchByDaysClientDtoResponse>({
      url: getAPIPath(CLIENT_BOOKING_SEARCH_DAYS_PATH, { locationId, serviceTypeName }),
      method: 'POST',
      params,
      data: {
        petGroups: options.petGroups,
      },
    })
  },
}
