import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Grid, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { i18WithParams as t } from '@shared/locale'
import { Spacer2, Spacer4, TextInputNumber, TextInputPhone } from 'components-ui'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClientUserProfileService } from 'service-api/src/services'
import { TState, states } from 'service-api/src/shared/states'
import { UserClientDto, UserProfileClientDto } from 'service-api/src/types'
import { accountHolderSchema, buildRequestBody, setupInitialValues } from './schema'
import { captureError } from 'service-api/src/shared'

type AccountHolderFormProps = {
  user: UserClientDto
  onSave: (userProfile: UserProfileClientDto) => void
  onCancel: () => void
}

export const AccountHolderForm = ({ user, onSave, onCancel }: AccountHolderFormProps) => {
  const { accountName = '' } = useParams()
  const [submitting, setSubmitting] = useState(false)

  const formik = useFormik({
    initialValues: setupInitialValues(user),
    validationSchema: accountHolderSchema,
    onSubmit: (values) => {
      setSubmitting(true)
      ClientUserProfileService.put(accountName, user.userProfile?.[0].id || '', buildRequestBody(values))
        .then((res) => {
          onSave(res)
          enqueueSnackbar(t('editAccountHolder.success'), {
            variant: 'success',
          })
        })
        .catch((error) => {
          enqueueSnackbar(t('global.label.unexpectedError'), { variant: 'error' })
          captureError(error as Error)
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  const statesByCode: { [key: string]: TState } = useMemo(
    () =>
      states.reduce(
        (res, state) => ({
          ...res,
          [state.code]: state,
        }),
        {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [states]
  )

  const baseFormikProps = useCallback(
    (fieldName: string) => ({
      onChange: formik.handleChange,
      value: formik.values[fieldName],
      error: formik.touched[fieldName] && Boolean(formik.errors[fieldName]),
      helperText: formik.touched[fieldName] && formik.errors[fieldName] ? t('global.label.required') : null,
    }),
    [formik]
  )

  return (
    <Paper sx={{ px: 2, pt: 2, pb: 3, width: '100%' }}>
      <Typography variant="h5">{t('manageAccount.people.accountHolder')}</Typography>
      <Spacer4 />

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            id="firstName"
            label={t('contactForm.firstName')}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ flexBasis: 0, flexGrow: 1, mr: { xs: 0, sm: 1 } }}
            {...baseFormikProps('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="lastName"
            label={t('contactForm.lastName')}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ flexBasis: 0, flexGrow: 1, mt: { xs: 2, sm: 0 } }}
            {...baseFormikProps('lastName')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputPhone
            id="phone"
            name="phone"
            label={t('contactForm.phone')}
            size="small"
            fullWidth
            variant="outlined"
            {...baseFormikProps('phone')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            label={t('contactForm.email')}
            variant="outlined"
            size="small"
            fullWidth
            disabled
            {...baseFormikProps('email')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="streetAddress"
            label={t('contactForm.streetLine1')}
            variant="outlined"
            size="small"
            fullWidth
            {...baseFormikProps('streetAddress')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="streetAddress2"
            label={t('contactForm.streetLine2')}
            variant="outlined"
            size="small"
            fullWidth
            {...baseFormikProps('streetAddress2')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="city"
            label={t('contactForm.city')}
            variant="outlined"
            size="small"
            fullWidth
            sx={{ flexBasis: 0, flexGrow: 0, minWidth: '100px' }}
            {...baseFormikProps('city')}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="state"
            sx={{ flexBasis: 0, flexGrow: 1, minWidth: '62px' }}
            includeInputInList
            filterSelectedOptions
            size="small"
            clearIcon={null}
            options={states}
            value={statesByCode?.[formik.values.state] ?? null}
            onChange={(_, value) => formik.setFieldValue('state', value?.code ?? '')}
            onBlur={formik.handleBlur}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={(option) => (statesByCode?.[formik.values.state] === option ? option.code : option.label)}
            renderInput={(params) => (
              <TextField
                {...params}
                id="state"
                name="state"
                label={t('contactForm.state')}
                variant="outlined"
                fullWidth
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={baseFormikProps('state').helperText}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInputNumber
            id="zipCode"
            label={t('contactForm.zipCode')}
            variant="outlined"
            fullWidth
            size="small"
            allowLeadingZeros={true}
            sx={{ flexBasis: 0, flexGrow: 0, minWidth: '80px' }}
            {...baseFormikProps('zipCode')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="country"
            name="country"
            label={t('contactForm.country')}
            variant="outlined"
            size="small"
            select
            fullWidth
            {...baseFormikProps('country')}
          >
            <MenuItem value="US">{t('global.select.USA')}</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Spacer2 />
      <Stack direction="row" spacing={2}>
        <LoadingButton
          loading={submitting}
          variant="contained"
          onClick={() => {
            if (formik.dirty) {
              formik.handleSubmit()
            }
          }}
        >
          {t('global.label.save')}
        </LoadingButton>
        <Button onClick={() => onCancel()}>{t('global.label.cancel')}</Button>
      </Stack>
    </Paper>
  )
}
