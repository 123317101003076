import { useQuery } from '@tanstack/react-query'
import { ClientPetFamiliesService } from '../services/client_pet_families.service'
import { APIPetFamilyClientDtoResponse, APIPetProfileClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'

export const useClientPetFamilies = (locationName: string) => {
  return useQuery<APIPetFamilyClientDtoResponse>({
    queryKey: ['clientPetFamilies', locationName],
    queryFn: () => ClientPetFamiliesService.families(locationName),
    ...defaultQueryOptions,
  })
}

export const useClientPetProfiles = (locationName: string, makeRequest: boolean) => {
  return useQuery<APIPetProfileClientDtoResponse | null>({
    queryKey: ['clientPetProfiles', locationName],
    queryFn: () => ClientPetFamiliesService.familyPets(locationName),
    ...defaultQueryOptions,
    enabled: makeRequest,
  })
}
