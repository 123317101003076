import { CognitoUser } from '@aws-amplify/auth'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ChangePassword } from './change_password'
import { ConfirmResetPassword } from './confirm_reset_password'
import { ForgotPassword } from './forgot_password'
import { ModalType } from './index.d'
import { LogIn } from './log_in'
import { MFA_Code } from './mfa_code'
import { SignUp } from './sign_up_form'

export const AuthDialog = ({ modalStep, email: emailFromProps }: { modalStep?: string; email?: string }) => {
  const { accountName = '' } = useParams()
  const navigate = useNavigate()
  const [modalType, setModalType] = useState(modalStep === 'signUp' ? ModalType.signUp : ModalType.signIn)
  const [email, setEmail] = useState('')
  const [user, saveUser] = useState<CognitoUser | null>(null)

  useEffect(() => {
    if (emailFromProps?.length) {
      setEmail(emailFromProps)
    }
  }, [emailFromProps])

  const switchModalType = (modal: ModalType) => setModalType(modal)
  const saveEmail = (savedEmail: string) => setEmail(savedEmail)

  const redirectHome = () => {
    navigate(`/${accountName}`)
  }

  const getAuthForm = () => {
    switch (modalType) {
      case ModalType.signIn:
        return <LogIn switchModalType={switchModalType} saveUser={saveUser} />
      case ModalType.forgotPassword:
        return <ForgotPassword switchModalType={switchModalType} saveEmail={saveEmail} />
      case ModalType.confirmResetPassword:
        return <ConfirmResetPassword switchModalType={switchModalType} email={email} />
      case ModalType.changePassword:
        return <ChangePassword switchModalType={switchModalType} user={user} />
      case ModalType.signUp:
        return <SignUp switchModalType={switchModalType} saveUser={saveUser} email={email} />
      case ModalType.mfaCode:
        return <MFA_Code onSuccess={redirectHome} user={user} />

      default:
        return null
    }
  }
  return getAuthForm()
}
