import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

export const DaysSkeleton = () => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Skeleton variant="text" height={22} width={50} />
      <Skeleton variant="text" height={22} width={20} />
      <Skeleton variant="text" height={22} width={50} />
    </Stack>
  )
}
