import { ReactNode } from 'react'
import { useImmerReducer } from 'use-immer'
import { TBookingProviderProps, TBookingContext } from './types'

import { BookingContext, initialState } from './booking_context'
import { bookingReducer } from './booking_reducer'

export const BookingProvider = ({ children }: TBookingProviderProps) => {
  const [state, dispatch] = useImmerReducer(bookingReducer, initialState)
  const { Provider } = BookingContext

  return <Provider value={{ state, dispatch }}> {children} </Provider>
}

export type TTestBookingProviderProps = {
  children: ReactNode
  mockState?: TBookingContext
}

export const TestBookingProvider = ({ children, mockState }: TTestBookingProviderProps) => {
  const [state, dispatch] = useImmerReducer(bookingReducer, mockState || initialState)
  const { Provider } = BookingContext

  return <Provider value={{ state, dispatch }}> {children} </Provider>
}
