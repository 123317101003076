import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useCallback } from 'react'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { formatNumberAsPrice } from 'components-ui'
import type { TPetItemProps } from './types'

export const PetItem = ({ groupIdx, selectedIdx, showPricePerPet = false }: TPetItemProps) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const addOn = bookingCtx.state.cart.groups[groupIdx]['petsAddOn']![selectedIdx]

  const onAddOnFromPet = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget: t } = ev
      bookingCtx.dispatch({
        type: 'INCREASE_PET_ADDON',
        payload: { addOnId: addOn.offer!.id, groupIdx: t.dataset.group, petId: t.dataset.pet },
      })
    },
    [addOn.offer, bookingCtx]
  )

  const onRemoveAddOnFromPet = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget: t } = ev

      bookingCtx.dispatch({
        type: 'DECREASE_PET_ADDON',
        payload: { addOnId: addOn.offer!.id, groupIdx: t.dataset.group, petId: t.dataset.pet },
      })
    },
    [addOn.offer, bookingCtx]
  )

  const items = bookingCtx.state.cart.groups[groupIdx].pets.map((pet, petIdx) => {
    const petAvail = addOn?.availability?.availabilities?.[petIdx]
    if (!petAvail?.available) {
      return null
    }
    const addOnPet = addOn.pets.find((p) => p.id === pet.id)

    if (!addOnPet) return null

    return (
      <Stack
        key={`pet-${pet.id}`}
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{
          maxWidth: '640px',
          px: 2,
          py: 1.25,
          margin: '0 auto',
          width: '100%',
          backgroundColor: theme.palette!.primary?.shade5,
        }}
      >
        <Typography variant="body2" flexGrow={1}>
          {pet.name}
          {showPricePerPet ? ` (${formatNumberAsPrice(petAvail.price?.rate)})` : ''}
        </Typography>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Tooltip title={t('addonItemPicker.removeItem')} disableInteractive>
            <IconButton onClick={onRemoveAddOnFromPet} sx={{ p: '6px' }} data-group={groupIdx} data-pet={addOnPet.id}>
              <RemoveCircleOutlineIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Box component="span" sx={{ width: '35px', textAlign: 'center' }}>
            {addOnPet.qty}
          </Box>
          <Tooltip title={t('addonItemPicker.addItem')} disableInteractive>
            <IconButton onClick={onAddOnFromPet} sx={{ p: '6px' }} data-group={groupIdx} data-pet={addOnPet.id}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    )
  })

  return <Box>{items}</Box>
}
