import { SvgIcon, SvgIconProps } from '@mui/material'

/* eslint-disable max-len */
export const GroomingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} data-testid="GroomingIcon">
      <path d="M7.03268 5.36602C7.22435 4.94935 7.33268 4.49102 7.33268 3.99935C7.33268 2.15768 5.84102 0.666016 3.99935 0.666016C2.15768 0.666016 0.666016 2.15768 0.666016 3.99935C0.666016 5.84102 2.15768 7.33268 3.99935 7.33268C4.49102 7.33268 4.94935 7.22435 5.36602 7.03268L7.33268 8.99935L5.36602 10.966C4.94935 10.7743 4.49102 10.666 3.99935 10.666C2.15768 10.666 0.666016 12.1577 0.666016 13.9993C0.666016 15.841 2.15768 17.3327 3.99935 17.3327C5.84102 17.3327 7.33268 15.841 7.33268 13.9993C7.33268 13.5077 7.22435 13.0493 7.03268 12.6327L8.99935 10.666L14.8327 16.4993H17.3327V15.666L7.03268 5.36602ZM3.99935 5.66602C3.08268 5.66602 2.33268 4.92435 2.33268 3.99935C2.33268 3.07435 3.08268 2.33268 3.99935 2.33268C4.91602 2.33268 5.66602 3.07435 5.66602 3.99935C5.66602 4.92435 4.91602 5.66602 3.99935 5.66602ZM3.99935 15.666C3.08268 15.666 2.33268 14.9243 2.33268 13.9993C2.33268 13.0743 3.08268 12.3327 3.99935 12.3327C4.91602 12.3327 5.66602 13.0743 5.66602 13.9993C5.66602 14.9243 4.91602 15.666 3.99935 15.666ZM8.99935 9.41602C8.76602 9.41602 8.58268 9.23268 8.58268 8.99935C8.58268 8.76602 8.76602 8.58268 8.99935 8.58268C9.23268 8.58268 9.41602 8.76602 9.41602 8.99935C9.41602 9.23268 9.23268 9.41602 8.99935 9.41602ZM14.8327 1.49935L9.83268 6.49935L11.4993 8.16602L17.3327 2.33268V1.49935H14.8327Z" />
    </SvgIcon>
  )
}
