import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { useRoutes } from 'app/use_routes'

export const useRedirectNoData = () => {
  const { serviceTypeName = 'boarding' } = useParams()
  const bookingCtx = useBookingContext()
  const { navigateRoute } = useRoutes()

  // redirect to home if the state has invalid data
  useEffect(() => {
    if (!bookingCtx.state.startDate) {
      navigateRoute('searchServiceType', { serviceTypeName })
    }
  })
}
