import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'

import { AppRoutes } from './routes'
import { SessionProvider } from 'providers'
import { BookingProvider } from '@providers/booking'
import { useAppThemeContext, AppThemeProvider } from '@providers/app_theme'
import { CustomSnackbar } from 'components-ui'
import { LocationProvider } from '@providers/location'

const queryClient = new QueryClient()

const AppInner = () => {
  const appThemeCtx = useAppThemeContext()
  return (
    <ThemeProvider theme={appThemeCtx!.theme}>
      <CssBaseline />
      <SessionProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider
            Components={{
              success: CustomSnackbar,
              info: CustomSnackbar,
              warning: CustomSnackbar,
              error: CustomSnackbar,
            }}
          >
            <LocationProvider>
              <BookingProvider>
                {/* <UserSettingsProvider> */}
                {/* <IntercomProvider appId={intercomAppId}> */}
                <AppRoutes />
                {/* </IntercomProvider> */}
                {/* </UserSettingsProvider> */}
              </BookingProvider>
            </LocationProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </SessionProvider>
    </ThemeProvider>
  )
}

export const App = () => {
  return (
    <AppThemeProvider>
      <AppInner />
    </AppThemeProvider>
  )
}
