import parseJSON from 'date-fns/parseJSON'
import add from 'date-fns/add'

import { Types } from 'service-api'
import type { TCheckInOutOptions, TIntervalTime } from './location.d'
import { getHoursByDate } from './dates'

const baseDateOperation = '2022-10-20'

const toAmPmStr = (date: Date) =>
  date
    .toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hourCycle: 'h12', timeZone: 'UTC' })
    .replace(/\s?AM/, 'am')
    .replace(/\s?PM/, 'pm')

const toTimeZ = (date: Date) =>
  date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hourCycle: 'h23', timeZone: 'UTC' })

/**
 * Generates time intervals using the OPERATION_HOURS's periods as range
 *
 */
export const checkInOutTimeIntervals = (
  date: undefined | string | Date,
  data: Types.RestrictionClientDto[],
  options?: TCheckInOutOptions
) => {
  if (!date || !data) {
    return []
  }

  const defaults = Object.assign({}, { interval: 30 }, options)
  const rc = getHoursByDate(date, data).reduce(
    (acc, hour) => acc.concat(generateInterval(hour.startTime, hour.endTime, defaults.interval)),
    [] as TIntervalTime[]
  )
  return rc
}

const generateInterval = (startTime: string, endTime: string, interval: number) => {
  let startDate = parseJSON(`${baseDateOperation}T${startTime}:00Z`)
  const endDate = parseJSON(`${baseDateOperation}T${endTime}:00Z`)
  const intervals = [] as TIntervalTime[]

  while (startDate < endDate) {
    intervals.push({
      ampm: toAmPmStr(startDate),
      timez: toTimeZ(startDate),
    })
    startDate = add(startDate, { minutes: interval })
  }

  return intervals
}

export const isCheckInTimeRequired = (conf: Types.LocationBookingConfClientDto) =>
  conf.checkInTimeRequired === 'REQUIRED'

export const isCheckInTimeDisabled = (conf: Types.LocationBookingConfClientDto) =>
  conf.checkInTimeRequired === 'DISABLED'

export const isCheckInTimeEnabled = (conf: Types.LocationBookingConfClientDto) =>
  conf.checkInTimeRequired !== 'DISABLED'

export const isCheckOutTimeRequired = (conf: Types.LocationBookingConfClientDto) =>
  conf.checkOutTimeRequired === 'REQUIRED'

export const isCheckOutTimeDisabled = (conf: Types.LocationBookingConfClientDto) =>
  conf.checkOutTimeRequired === 'DISABLED'

export const isCheckOutTimeEnabled = (conf: Types.LocationBookingConfClientDto) =>
  conf.checkOutTimeRequired !== 'DISABLED'

export const isCheckInOutTimeDisabled = (conf: Types.LocationBookingConfClientDto) =>
  isCheckInTimeDisabled(conf) && isCheckOutTimeDisabled(conf)

export const isCheckInOutTimeEnabled = (conf: Types.LocationBookingConfClientDto) =>
  isCheckInTimeEnabled(conf) || isCheckOutTimeEnabled(conf)

export const isVetRequired = (conf: Types.LocationBookingConfClientDto) => conf.vetRequired === 'REQUIRED'
export const isVetDisabled = (conf: Types.LocationBookingConfClientDto) => conf.vetRequired === 'DISABLED'
export const isVetEnabled = (conf: Types.LocationBookingConfClientDto) => conf.vetRequired !== 'DISABLED'
export const isIntakeEnabled = (conf?: Types.LocationBookingConfClientDto | Types.LocationBookingConfAdminDto) =>
  !!conf?.intakeEnabled && conf.intakeEnabled !== 'DISABLED'
export const isVaccinationEnabled = (conf: Types.LocationBookingConfClientDto) =>
  conf.vaccinationRequired !== 'DISABLED'
export const isVaccinationRequired = (conf: Types.LocationBookingConfClientDto) =>
  conf.vaccinationRequired === 'REQUIRED'

export const allowCardOnly = (conf?: Types.LocationBookingConfClientDto | Types.LocationBookingConfAdminDto) =>
  conf?.allowOfflinePackages === 'DISABLED' && conf?.allowCardPayments === 'REQUIRED'

export const offlinePackageDisabled = (conf?: Types.LocationBookingConfClientDto | Types.LocationBookingConfAdminDto) =>
  !conf?.allowOfflinePackages || conf.allowOfflinePackages === 'DISABLED'

export const allowCardAndPrepaidPayments = (
  conf?: Types.LocationBookingConfClientDto | Types.LocationBookingConfAdminDto
) => conf?.allowOfflinePackages === 'OPTIONAL' && conf?.allowCardPayments === 'REQUIRED'

export const allowNoPayments = (conf?: Types.LocationBookingConfClientDto | Types.LocationBookingConfAdminDto) =>
  conf?.allowOfflinePackages === 'REQUIRED' && conf?.allowCardPayments === 'DISABLED'
