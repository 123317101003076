import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useParams } from 'react-router-dom'

import { MAX_PET_GROUPS, useBookingContext } from '@providers/booking/booking_context'
import { i18WithParams as t } from '@shared/locale'
import { capitalize, getNthOfThis } from 'components-ui/src/shared/common'

export const RoomSelector = ({
  id,
  groupIdx,
  petIdx,
  prefix,
}: {
  id: string
  groupIdx: number
  petIdx: number
  prefix: string
}) => {
  const ctx = useBookingContext()
  const { serviceTypeName = '' } = useParams()
  const isBoarding = serviceTypeName === 'boarding'
  const onChangeHandler = (event: SelectChangeEvent<number>) => {
    ctx.dispatch({ type: 'SET_PET_IN_ROOM', payload: { groupIdx, petIdx, value: event.target.value } })
  }
  const group = ctx.state.groups[groupIdx]
  const totPets = Math.min(group.pets.length, group.maxPets || MAX_PET_GROUPS)

  return (
    <FormControl sx={{ minWidth: 140, mb: 0 }}>
      <InputLabel id={`${id}-label`}>
        {isBoarding ? capitalize(t('global.label.room')) : t('searchBar.petSelect.attending')}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`pet-room-select-${petIdx}`}
        value={ctx.state.groups[groupIdx].pets[petIdx].room}
        onChange={onChangeHandler}
        label={isBoarding ? t('global.label.room') : t('searchBar.petSelect.attending')}
        autoWidth
      >
        {isBoarding ? (
          getNthOfThis(totPets, (i) => (
            <MenuItem key={i} value={i + 1}>
              <span data-testid={`pet-room-option-${i}`}>{`${prefix} Room ${i + 1}`}</span>
            </MenuItem>
          ))
        ) : (
          <MenuItem value={1}>
            <span>{t('global.label.yes')}</span>
          </MenuItem>
        )}
        <MenuItem value={0}>{isBoarding ? t('global.label.notStaying') : t('global.label.no')}</MenuItem>
      </Select>
    </FormControl>
  )
}
