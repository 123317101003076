import { ClickAwayListener, Tooltip, useTheme } from '@mui/material'
import React from 'react'
import { SignInSignUpTooltip } from './signin_signup_tooltip'
import { DataLayerArgs, TagManagerArgs } from 'react-gtm-module'

type SignInTooltipProps = {
  children: React.ReactElement
  onClose?: (event: Event | React.SyntheticEvent<Element, Event>) => void
  onError?: (error: Error) => void
  open?: boolean
  onLogin?: () => void
  TagManager?: {
    dataLayer: (dataLayerArgs: DataLayerArgs) => void
    initialize: (tagManagerArgs: TagManagerArgs) => void
  }
}

export const SignInTooltip = ({ children, onClose, onError, open, onLogin, TagManager }: SignInTooltipProps) => {
  const theme = useTheme()

  return (
    <ClickAwayListener onClickAway={(event) => onClose?.(event)}>
      <Tooltip
        componentsProps={{
          popper: { sx: { px: 2 } },
          arrow: { style: { color: theme.palette.common.white } },
          tooltip: { style: { padding: 0 } },
        }}
        onClose={onClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<SignInSignUpTooltip TagManager={TagManager} onError={onError} onSuccessfulSignIn={onLogin} />}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  )
}
