import { TBookingContext } from '@providers/booking/types'
import add from 'date-fns/add'
import endOfMonth from 'date-fns/endOfMonth'
import startOfDay from 'date-fns/startOfDay'
import sub from 'date-fns/sub'

export const findIndexDate = (dates: Date[], date: Date) => {
  const dateTime = date.getTime()
  return dates.findIndex((item) => item.getTime() === dateTime)
}

export const getDateRange = (newStartDate: Date, startDate: Date, endDate: Date) => {
  const fromDate = newStartDate.getMonth() === startDate.getMonth() ? startDate : newStartDate
  const endMonth = endOfMonth(fromDate)
  if (endMonth.getMonth() === endDate.getMonth()) {
    return [fromDate, endDate]
  } else {
    return [fromDate, add(endMonth, { days: 1 })]
  }
}

export const isDataInCache = (startDate: Date, endDate: Date, state: TBookingContext) => {
  const fromDate = startOfDay(startDate)
  const toDate = startOfDay(sub(endDate, { days: 1 }))

  const rc =
    state.data.offerAvailability?.reduce((acc, item) => {
      if (item.id === fromDate.getTime() || item.id === toDate.getTime()) {
        acc++
      }
      return acc
    }, 0) || 0

  return rc == 2
}
