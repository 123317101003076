import type { TDrawersProviderProps } from './types'
import { useImmerReducer } from 'use-immer'

import { drawersReducer } from './drawers_reducer'
import { DrawersContext, initialState } from './drawers_context'

export const DrawersProvider = ({ children }: TDrawersProviderProps) => {
  const [state, dispatch] = useImmerReducer(drawersReducer, initialState)
  const { Provider } = DrawersContext

  return <Provider value={{ state, dispatch }}> {children} </Provider>
}
