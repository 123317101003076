import { Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Grid, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { i18WithParams as t } from '@shared/locale'
import { MIN_DATE, DatePicker, Spacer2, Spacer4, SpeciesButtons, TextInputNumber, isEmpty } from 'components-ui'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  APISpeciesClientDtoResponse,
  BreedClientDto,
  PetClientDto,
  PetProfileClientDto,
  SpeciesClientDto,
} from 'service-api/src/types'
import { TFormikValues, createPet, deletePet, petSchema, setupInitialValues, updatePet } from './helper'
import { captureError } from 'service-api/src/shared'

type PetFormProps = {
  pet?: PetClientDto
  petProfile?: PetProfileClientDto
  onSave: (pet: PetClientDto) => void
  onCancel: () => void
  onDelete?: (petId: string) => void
  petFamilyId: string
  speciesResponse?: APISpeciesClientDtoResponse | SpeciesClientDto[]
  isLoadingSpeciesResponse?: boolean
  isEditing?: boolean
}

export const PetForm = ({
  pet,
  petProfile,
  petFamilyId,
  onSave,
  onCancel,
  onDelete,
  isLoadingSpeciesResponse,
  speciesResponse,
  isEditing = false,
}: PetFormProps) => {
  const { accountName = '' } = useParams()
  const [selectedSpecies, setSelectedSpecies] = useState<SpeciesClientDto>({
    id: '',
    name: '',
  })
  const [saving, setSaving] = useState(false)
  const [deleting, setDeleting] = useState(false)

  // Workaround till API returns order field https://goose-pet.atlassian.net/browse/GA-1288
  const species = useMemo(() => {
    let speciesData: SpeciesClientDto[] = []
    if (speciesResponse && 'results' in speciesResponse) {
      speciesData = speciesResponse.results
    } else if (speciesResponse && Array.isArray(speciesResponse)) {
      speciesData = speciesResponse
    }

    return speciesData.reduce<SpeciesClientDto[]>((res: SpeciesClientDto[], specie: SpeciesClientDto) => {
      return specie.name === 'dog' ? [specie, ...res] : [...res, specie]
    }, [])
  }, [speciesResponse])

  const selectSpecies = useCallback(() => {
    if (species.length) {
      let speciesToSelect = species[0]
      if (pet) {
        speciesToSelect = species.find(({ id }) => id === pet.petProfile?.[0].breed?.species?.id) || species[0]
      } else if (petProfile) {
        speciesToSelect = species.find(({ id }) => id === petProfile.breed?.speciesId) || species[0]
      }
      setSelectedSpecies(speciesToSelect)
    }
  }, [pet, petProfile, species])

  useEffect(() => {
    selectSpecies()
  }, [selectSpecies])

  const breedsPerSpecies = useMemo(() => {
    return species.reduce<Record<string, BreedClientDto[]>>((res, { id = '', breeds = [] }) => {
      return {
        ...res,
        [id]: breeds,
      }
    }, {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [species.length])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = useCallback((submitFn: Promise<any>, feedbackMsg: string) => {
    return submitFn
      .then((res) => {
        if (res?.id) {
          enqueueSnackbar(feedbackMsg, { variant: 'success' })
        } else {
          enqueueSnackbar(res?.response?.data?.error || t('global.label.unexpectedError'), { variant: 'error' })
        }
        return res
      })
      .catch((error) => {
        enqueueSnackbar(t('global.label.unexpectedError'), {
          variant: 'error',
        })
        captureError(error as Error)
      })
  }, [])

  const formik = useFormik({
    initialValues: setupInitialValues({ pet, petProfile }),
    validationSchema: petSchema,
    onSubmit: (values) => {
      setSaving(true)
      handleSubmit(
        values.id ? updatePet(values, accountName, petFamilyId) : createPet(values, accountName, petFamilyId),
        values.id ? t('petForm.edit.success') : t('petForm.create.success')
      )
        .then((res) => res.id && onSave(res))
        .finally(() => setSaving(false))
    },
  })

  const baseFormikProps = useCallback(
    (fieldName: keyof TFormikValues) => ({
      id: fieldName,
      name: fieldName,
      onChange: formik.handleChange,
      value: formik.values[fieldName],
      error: formik.touched[fieldName] && Boolean(formik.errors[fieldName]),
      helperText: formik.touched[fieldName] && formik.errors[fieldName] ? t('global.label.required') : null,
    }),
    [formik]
  )

  const onSelectSpecies = useCallback(
    (newSpecies: SpeciesClientDto) => {
      if (newSpecies.id !== selectedSpecies.id) {
        const petData = newSpecies.id === pet?.petProfile?.[0].breed?.species?.id ? pet : {}
        formik.resetForm({
          values: setupInitialValues({
            pet: petData,
          }),
        })
        setSelectedSpecies(newSpecies)
      }
    },
    [selectedSpecies, formik, pet]
  )

  if (!species.length) {
    return null
  }

  return (
    <Paper sx={{ px: 2, pt: 2, pb: 3, width: '100%' }}>
      {pet || petProfile ? (
        <Typography variant="h5">{t('petForm.title.edit')}</Typography>
      ) : (
        <Typography variant="h5">{t('petForm.title.add')}</Typography>
      )}

      <Spacer4 />
      <Grid container spacing={1}>
        {!isEditing && (
          <Grid item xs={12}>
            <SpeciesButtons
              species={species}
              selected={selectedSpecies.id || ''}
              onSelect={(val) => onSelectSpecies(val as SpeciesClientDto)}
              size="small"
            />
            <Spacer2 />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label={t('global.label.name')} size="small" fullWidth {...baseFormikProps('displayName')} />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="breed"
            includeInputInList
            filterSelectedOptions
            size="small"
            clearIcon={null}
            loading={isLoadingSpeciesResponse}
            loadingText={t('global.label.loading')}
            options={breedsPerSpecies?.[selectedSpecies.id!] ?? []}
            getOptionLabel={(option) => option.displayName || option.name || ''}
            value={isEmpty(formik.values.breed) ? null : formik.values.breed}
            onChange={(_, value) =>
              formik.setFieldValue('breed', value ? { ...value, species: selectedSpecies } : null)
            }
            onBlur={formik.handleBlur}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                id="breed"
                name="breed"
                label={t('global.label.breed')}
                fullWidth
                error={formik.touched.breed && Boolean(formik.errors.breed)}
                helperText={baseFormikProps('breed').helperText}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInputNumber
            label={t('global.label.weight')}
            size="small"
            fullWidth
            {...baseFormikProps('weight')}
            value={formik.values.weight}
          />
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={formik.values.birthdate}
              onChange={(date) => formik.setFieldValue('birthdate', date)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="birthdate"
                  name="birthdate"
                  label={t('global.label.dateOfBirth_optional')}
                  size="small"
                  fullWidth
                  error={
                    (formik.touched.birthdate && Boolean(formik.errors.birthdate)) ||
                    (!!formik.values.birthdate && new Date(formik.values.birthdate) < MIN_DATE)
                  }
                  helperText={baseFormikProps('birthdate').helperText}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('global.label.sex_optional')} size="small" select fullWidth {...baseFormikProps('sex')}>
            <MenuItem value="MALE">{t('global.label.male')}</MenuItem>
            <MenuItem value="FEMALE">{t('global.label.female')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t('petForm.isAltered', {
              petSpecies: selectedSpecies.name!,
            })}
            size="small"
            select
            fullWidth
            {...baseFormikProps('altered')}
          >
            <MenuItem value="yes">{t('global.label.yes')}</MenuItem>
            <MenuItem value="no">{t('global.label.no')}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>

      <Spacer2 />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={saving}
            variant="contained"
            onClick={() => {
              if (formik.dirty) {
                formik.handleSubmit()
              }
            }}
          >
            {t('global.label.save')}
          </LoadingButton>
          <Button onClick={() => onCancel()}>{t('global.label.cancel')}</Button>
        </Stack>
        {(pet?.id || petProfile?.pet?.id) && onDelete && (
          <LoadingButton
            loading={deleting}
            startIcon={<Delete />}
            onClick={() => {
              const petId = pet?.id || petProfile?.pet?.id
              if (petId) {
                setDeleting(true)
                handleSubmit(deletePet(petId, accountName, petFamilyId), t('petForm.delete.success'))
                  .then((res) => res.id && onDelete(res.id))
                  .finally(() => setDeleting(false))
              }
            }}
          >
            {t('global.label.delete')}
          </LoadingButton>
        )}
      </Stack>
    </Paper>
  )
}
