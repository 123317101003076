import { LoadingButton } from '@mui/lab'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { STICKY_FOOTER_HEIGHT_PX, STICKY_FOOTER_MOBILE_HEIGHT_PX } from '@shared/constants'
import { continueNavigation } from '@shared/header_navigation'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { TBackContinueProps } from './types'

export const BackContinue = ({
  backLabel,
  continueLabel,
  onContinue,
  onBack,
  showBack = true,
  continueEnabled = true,
}: TBackContinueProps) => {
  const { accountName = '', serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const { navigateRoute } = useRoutes()
  const defaultBack = t('global.label.back')
  const defaultContinue = t('global.label.continue')
  const theme = useTheme()
  const isBellowMD = useMediaQuery(theme.breakpoints.down('md'))

  const onBackHandler = useCallback(() => {
    if (typeof onBack === 'function') {
      onBack()
    } else {
      history.back()
    }
  }, [onBack])

  const moveToNextPage = useCallback(async () => {
    const rc = await continueNavigation(bookingCtx, accountName)
    if (rc) {
      navigateRoute(rc, { serviceTypeName })
    }
  }, [accountName, bookingCtx, navigateRoute, serviceTypeName])
  const onClickContinue = useCallback(async () => {
    try {
      bookingCtx.dispatch({
        type: 'SET_ASYNC_ACTION',
        payload: { value: true },
      })
      if (onContinue) {
        const rc = await onContinue()
        if (rc) {
          await moveToNextPage()
        }
      } else {
        await moveToNextPage()
      }
    } finally {
      bookingCtx.dispatch({
        type: 'SET_ASYNC_ACTION',
        payload: { value: false },
      })
    }
  }, [bookingCtx, onContinue, moveToNextPage])

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: `${isBellowMD ? STICKY_FOOTER_MOBILE_HEIGHT_PX : STICKY_FOOTER_HEIGHT_PX}px`,
        background: theme.palette.background.paper,
        boxShadow: '0px -3px 6px rgba(0, 0, 0, 0.06)',
        zIndex: 1000,
      }}
    >
      {showBack && (
        <Button variant="text" onClick={onBackHandler} size="large" disabled={bookingCtx.state.asyncAction}>
          {backLabel || defaultBack}
        </Button>
      )}
      <LoadingButton
        variant="contained"
        onClick={onClickContinue}
        size="large"
        loading={bookingCtx.state.asyncAction}
        disabled={!continueEnabled}
      >
        {continueLabel || defaultContinue}
      </LoadingButton>
    </Stack>
  )
}
