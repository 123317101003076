import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

export const DatePickerSkeleton = () => {
  return (
    <Box sx={{ width: '320px', height: '340px' }}>
      <Skeleton variant="text" height={50} />
      <Skeleton variant="rounded" width={320} height={280} />
    </Box>
  )
}
