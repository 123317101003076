import LanguageIcon from '@mui/icons-material/Language'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PlaceIcon from '@mui/icons-material/Place'
import { useTheme } from '@mui/material'
import Link from '@mui/material/Link'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo } from 'react'

import { useLocationContext } from '@providers/location'
import { isEmpty } from '@shared/common'
import { generatePhoneFromString } from 'components-ui'

// this breakpoint is contrived and is ok. The idea is to show only the icons on mobile
// and the full address on large screens. The rationale is to minimize the wrapping of
// the content on small screens.
const MOBILE_BREAKPOINT = 'lg'

export const Website = ({ responsive = false }: { responsive?: boolean }) => {
  const { location } = useLocationContext()
  const theme = useTheme()

  const isDesktop = (useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT)) ? 'mobile' : 'desktop') === 'desktop'
  const mdActive = responsive ? isDesktop : true
  const website = useMemo(() => location?.communications?.find((c) => c.type === 'WEBSITE'), [location])

  return (
    <Link href={website?.value} target="_blank" sx={{ lineHeight: '1.4', color: 'inherit' }}>
      <Stack direction="row" component="div" spacing={1} alignItems="center">
        <LanguageIcon />
        {mdActive &&
          (website ? (
            <Typography variant="body2" component="span">
              {website.value}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ minWidth: '150px' }} />
          ))}
      </Stack>
    </Link>
  )
}

export const AddressLocation = ({ responsive = false }: { responsive?: boolean }) => {
  const { location } = useLocationContext()
  const theme = useTheme()
  const isDesktop = (useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT)) ? 'mobile' : 'desktop') === 'desktop'
  const mdActive = responsive ? isDesktop : true

  const address = useMemo(() => {
    if (isEmpty(location?.address)) {
      return
    }

    const address = location!.address![0]
    const data = [
      address?.streetAddress,
      address?.streetAddress2,
      address?.city,
      `${address?.state || ''} ${address?.zipCode || ''}`.trim(),
    ]
      .filter(Boolean)
      .join(', ')
    return data
  }, [location])

  return (
    <Link
      href={`https://www.google.com/maps/place/${encodeURIComponent(address || '')}`}
      target="_blank"
      sx={{ lineHeight: '1.4', color: 'inherit' }}
    >
      <Stack direction="row" component="div" spacing={1} alignItems="center">
        <PlaceIcon />
        {mdActive &&
          (location && !isEmpty(location) ? (
            <Typography variant="body2" component="span">
              {address}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ minWidth: '150px' }} />
          ))}
      </Stack>
    </Link>
  )
}

export const Phone = ({ responsive = false }: { responsive?: boolean }) => {
  const { location } = useLocationContext()
  const theme = useTheme()

  const phone = useMemo(() => location?.communications?.find((c) => c.type === 'PHONE'), [location])
  const isDesktop = (useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT)) ? 'mobile' : 'desktop') === 'desktop'
  const mdActive = responsive ? isDesktop : true

  return (
    <Link href={`tel:${phone?.value}`} sx={{ lineHeight: '1.4', color: 'inherit' }}>
      <Stack direction="row" component="div" spacing={1} alignItems="center">
        <LocalPhoneIcon />
        {mdActive &&
          (phone ? (
            <Typography variant="body2" component="span">
              {generatePhoneFromString(phone.value)}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ minWidth: '150px' }} />
          ))}
      </Stack>
    </Link>
  )
}
