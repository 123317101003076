import { Outlet } from 'react-router-dom'

import { BookingHeader } from 'components/booking_header'
import { useMonitoringData } from 'hooks/use_monitoring_data'

export const OfferLayout = () => {
  useMonitoringData()

  return (
    <>
      <BookingHeader />
      <Outlet />
    </>
  )
}
