import { useBookingContext } from '@providers/booking'
import { AdyenPayment } from './adyen_payment/adyen_payment'
import { TiledPayment } from './tiled_payment/tiled_payment'
import { i18WithDefault as t } from '@shared/locale'
import { useEffect } from 'react'

export const Payment = () => {
  const bookingCtx = useBookingContext()

  useEffect(() => {
    document.title = t('title.addPayment')
  }, [])

  if (bookingCtx.state.data?.reservation?.order?.location?.payFacConfig?.type === 'ADYEN') {
    return <AdyenPayment />
  }

  return <TiledPayment />
}
