import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { lighten } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import React, { useCallback } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { useLocationContext } from '@providers/location'
import { useSessionContext } from '@providers/session'
import { i18WithDefault as t } from '@shared/locale'
import { routes } from 'app/routes_definitions'
import { useRoutes } from 'app/use_routes'
import { ButtonMenu } from 'components-ui'
import { SignInTooltip } from 'components-ui/src/signin_tooltip/signin_tooltip'
import { AddressLocation, Phone, Website } from 'components/address'
import { useUserStatus } from 'hooks/use_userstatus'
import { Services } from 'service-api'
import { HeaderSkeleton } from './header_skeleton'
import TagManager from 'react-gtm-module'

export const SiteHeader = () => {
  const sessionCtx = useSessionContext()
  const { isLoading: isLoadingLocation, location } = useLocationContext()
  const bookingCtx = useBookingContext()
  const { accountName: locationName = '' } = useParams()
  const { navigateRoute } = useRoutes()
  const { isLoggedIn } = useUserStatus()
  const [loginTooltipOpen, setLoginTooltipOpen] = React.useState(false)

  const theme = useTheme()
  const softPrimary = lighten(theme.palette.primary.main, 0.15)
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const onMenuItemHandler = useCallback(
    (handler: string) => {
      switch (handler) {
        case 'manageAccount': {
          navigateRoute('manageAccount', { accountName: locationName })
          return
        }
        case 'myBookings': {
          navigateRoute('purchaseHistory', { accountName: locationName })
          return
        }
        case 'logOut': {
          sessionCtx.dispatch({ type: 'SIGNOUT' })
          bookingCtx.dispatch({ type: 'RESET_STATE_FULL' })
          return
        }
        default: {
          return
        }
      }
    },
    [navigateRoute, locationName, sessionCtx, bookingCtx]
  )

  const menuItems = [
    { handle: 'manageAccount', label: t('loginMenu.manageAccount') },
    { handle: 'myBookings', label: t('loginMenu.myBookings') },
    { handle: 'logOut', label: t('loginMenu.logOut') },
  ]

  if (isLoadingLocation) {
    return <HeaderSkeleton />
  }

  const contrastColor = theme.palette.getContrastText(theme.palette.primary.main)

  const handleLoginTooltipOpen = () => {
    setLoginTooltipOpen(true)
    TagManager.dataLayer({
      dataLayer: {
        event: 'LOGIN_START_HEADER',
      },
    })
  }

  const handleLoginTooltipClose = () => {
    setLoginTooltipOpen(false)
  }

  const handleLoginTooltipError = (error: Error) => {
    if (error.message === 'User does not exist.') {
      enqueueSnackbar(t('global.createAccountAlert'), {
        action: (key) => (
          <Button
            color="primary"
            size="large"
            onClick={() => {
              navigateRoute('signIn', { accountName: locationName })
              closeSnackbar(key)
            }}
          >
            {t('global.createAccount')}
          </Button>
        ),
        style: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
        },
      })
      return
    }
  }

  const onSuccessLogin = async () => {
    const petProfiles = await Services.ClientPetFamiliesService.familyPets(locationName)
    bookingCtx.dispatch({ type: 'SET_PET_PROFILES', payload: { value: petProfiles?.results || [] } })
  }

  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 1,
        background: `linear-gradient(180deg, ${theme.palette.primary.main} 25%, ${softPrimary} 100%)`,
        borderBottom: `1px solid ${contrastColor}`,
        color: contrastColor,
        height: '100%',
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Link
        sx={{ textDecoration: 'none', color: 'inherit' }}
        href={generatePath(routes.locationHome.path, { accountName: locationName })}
      >
        <Typography variant="h5" color={contrastColor}>
          <strong>{location?.displayName}</strong>
        </Typography>
      </Link>
      <Stack direction="row" spacing={3} alignItems="center">
        {!isBelowMD && (
          <Stack
            direction="row"
            spacing={2}
            justifyContent={{ xs: 'left', md: 'center' }}
            sx={{ color: contrastColor, marginLeft: 2, '& a': { textDecorationColor: 'transparent' } }}
          >
            <Website responsive />
            <AddressLocation responsive />
            <Phone responsive />
          </Stack>
        )}
        {isLoggedIn ? (
          <ButtonMenu
            menuItems={menuItems}
            onClickMenuItem={onMenuItemHandler}
            startIcon={<AccountCircleIcon sx={{ fill: contrastColor }} />}
            sx={{
              padding: 0,
              color: contrastColor,
              ['& .MuiButton-startIcon']: {
                marginRight: isBelowMD ? 0 : '8px',
              },
              ['& svg']: {
                width: 24,
                height: 24,
              },
            }}
            label={isBelowMD ? '' : t('header.loggedInButton')}
          />
        ) : (
          <SignInTooltip
            onClose={handleLoginTooltipClose}
            onError={handleLoginTooltipError}
            open={loginTooltipOpen}
            onLogin={onSuccessLogin}
            TagManager={TagManager}
          >
            <Button
              sx={{
                color: contrastColor,
                borderColor: contrastColor,
                '&:hover': { borderColor: contrastColor },
              }}
              variant="outlined"
              onClick={handleLoginTooltipOpen}
            >
              {t('global.label.login')}
            </Button>
          </SignInTooltip>
        )}
      </Stack>
    </Stack>
  )
}
