export const MOBILE_BREAKPOINT = 'md'

export const PRIVACY_POLICY_URL = 'https://www.goose.pet/privacy-policy'
export const TOS_URL = 'https://www.goose.pet/terms-conditions'

export const OFFER_IMAGE_MAX_WIDTH = 460
export const OFFER_UPLOAD_IMAGE_MAX_WIDTH = 900

// Supported types: https://github.com/Goose-Holdings/goose-api/blob/main/src/services/assetService.ts#L95
export const SUPPORTED_FILE_TYPES = {
  IMAGE: {
    'image/jpeg': [],
    'image/png': [],
    'image/gif': [],
    'image/heic': [],
    'image/heif': [],
  },
  RECORD: {
    'image/jpeg': [],
    'image/png': [],
    'application/pdf': [],
    'image/heic': [],
  },
  FILE: {
    // TODO::Fill this when it's used
  },
  VIDEO: {
    // TODO::Fill this when it's used
  },
}

export const INTAKE_INSTRUCTIONS = {
  feeding: 'intake-instructions-field-0',
  medication: 'intake-instructions-field-1',
  care: 'intake-instructions-field-2',
}

export const MIN_DATE = new Date('1900-01-01')
