import { Alert, Box } from '@mui/material'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import { i18WithParams as t } from '@shared/locales'

export const GroomNotice = ({ isPast = false }: { isPast?: boolean }) => {
  return (
    <Box>
      <Alert icon={<ContentCutIcon />} severity="info">
        {isPast ? t('groomNotice.pastText') : t('groomNotice.text')}
      </Alert>
    </Box>
  )
}
