import differenceInCalendarDays from 'date-fns/esm/differenceInCalendarDays'
import isFuture from 'date-fns/isFuture'

import { Types } from 'service-api'
import { DEFAULT_TIMEZONE, getTzDateInUtc } from './dates'
import { i18WithDefault as t } from './locales'
import { formatInTimeZone } from 'date-fns-tz'
import { subDays } from 'date-fns'
import { isEmpty } from '@shared/common'

type InvoiceAllowedAction = 'EDIT' | 'CANCEL' | 'CHECK_IN' | 'CHECK_OUT' | 'LATE_CHECK_IN' | 'LATE_CHECK_OUT' | 'VIEW'

type TPetEmojiOptions = {
  showMemorializeEmoji?: boolean
  showDeletedPetProfileEmoji?: boolean
}

const defaultPetEmojiOptions: TPetEmojiOptions = {
  showMemorializeEmoji: true,
  showDeletedPetProfileEmoji: true,
} as const

export const getInvoiceDates = (invoice: Types.InvoiceClientDto) => {
  const { startDate, startTime, endDate, endTime } = invoice.period?.[0] ?? {}
  return {
    startDate: getTzDateInUtc(startDate, startTime, invoice.location?.timezone),
    endDate: getTzDateInUtc(endDate, endTime, invoice.location?.timezone),
  }
}

export const getInvoiceSchedule = (invoice: Types.InvoiceClientDto) => {
  const { startDate, startTime, endDate, endTime } = invoice.resourceUsages?.[0] ?? {}
  if (!startDate) return
  return {
    startDate: getTzDateInUtc(startDate, startTime, invoice.location?.timezone),
    endDate: getTzDateInUtc(endDate, endTime, invoice.location?.timezone),
    duration: invoice.resourceUsages?.[0]?.duration || 0,
  }
}

export const getInvoiceActualDates = (invoice?: Types.InvoiceAdminDto) => {
  const timezone = invoice?.location?.timezone || DEFAULT_TIMEZONE,
    period = invoice?.period?.[0],
    activity = invoice?.activities?.[0]
  const periodEndDate = isOvernight(invoice)
    ? getTzDateInUtc(period?.endDate, period?.endTime, timezone)
    : subDays(getTzDateInUtc(period?.endDate, period?.endTime, timezone), 1)
  return {
    startDate: activity?.startedAt
      ? new Date(activity?.startedAt)
      : getTzDateInUtc(period?.startDate, period?.startTime, timezone) || '',
    endDate: activity?.endedAt ? new Date(activity?.endedAt) : periodEndDate || '',
    hasStartTime: !!(activity?.startedAt || period?.startTime),
    hasEndTime: !!(activity?.endedAt || period?.endTime),
  }
}

export const getOfferAddons = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto[] => {
  return invoice?.items?.filter((item) => item.offerType === 'SERVICE') || []
}

export const getPetAddons = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto[] => {
  return invoice?.items?.filter((item) => item.offerType === 'PET') || []
}

export const getCompCreditItems = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto[] => {
  return invoice?.items?.filter((item) => item.type === 'CREDIT') || []
}

export const getAdditionItems = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto[] => {
  return invoice?.items?.filter((item) => item.type === 'ADDITION') || []
}

export const getGroomingItems = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto[] => {
  return (
    invoice?.items?.filter(
      (item) => item.type === 'OFFER' && item.locationServiceType?.serviceType?.name === 'grooming'
    ) || []
  )
}

export const getCancellationFee = (invoice?: Types.CustomInvoiceAdminDto): number => {
  const netPaid = invoice?.netPaid || 0
  return netPaid > 0 ? netPaid : 0
}

export type TInvoiceItemAddons = {
  id: string
  displayName: string
}

export const formatPetAddons = (invoiceItems?: Types.InvoiceItemAdminDto[]) => {
  return (
    invoiceItems?.reduce((acc, item) => {
      const display = `${item.displayName} (${formatPetNames(getPetsInInvoiceItem(item), false, false)})`
      acc.push({
        id: item.id!,
        displayName: `${display}${(item.qty ?? 1) > 1 ? `x${item.qty}` : ''}`,
      })
      return acc
    }, [] as TInvoiceItemAddons[]) || []
  )
}

export const formatRunCardPetAddsOns = (petId: string, addOns?: Types.InvoiceItemAdminDto[]) => {
  return addOns
    ?.filter((item) => {
      return item.offerType === 'PET' && item.petRelations?.[0].invoicePet?.id === petId
    })
    .map((item) => {
      return `${item.displayName} ${item.qty === 1 ? '' : `(${item.qty}x)`}`
    })
}

// checks if invoice is ready for checkin
export const isCheckInState = (invoice?: Types.CustomInvoiceAdminDto) =>
  ['CHECK_IN', 'LATE_CHECK_IN'].some((action) => invoice?.allowedActions?.includes(action as InvoiceAllowedAction))

// checks if invoice is ready for checkout
export const isCheckOutState = (invoice?: Types.CustomInvoiceAdminDto) =>
  ['CHECK_OUT', 'LATE_CHECK_OUT'].some((action) => invoice?.allowedActions?.includes(action as InvoiceAllowedAction))

export const isViewState = (invoice?: Types.CustomInvoiceAdminDto) => !!invoice?.allowedActions?.includes('VIEW')

export const isCancelState = (invoice?: Types.CustomInvoiceAdminDto) => !!invoice?.allowedActions?.includes('CANCEL')

export const isInvoiceBooked = (invoice?: Types.CustomInvoiceAdminDto) =>
  invoice?.activities?.length === 0 || invoice?.activities?.[0]?.activityStatus === 'PLANNED'

// checks if invoice has been checked in
export const hasUserCheckedIn = (invoice?: Types.CustomInvoiceAdminDto) =>
  ['IN_PROGRESS', 'COMPLETED'].includes(invoice?.activities?.[0]?.activityStatus || '')

export const getPrimaryOffer = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto | undefined => {
  return invoice?.items?.find((item) => item.offerType === 'PRIMARY')
}

export const getPrimaryServiceType = (invoice?: Types.InvoiceAdminDto) => {
  return invoice?.locationServiceType?.serviceType
}

export const getPrimaryServiceTypeName = (invoice?: Types.InvoiceAdminDto) => {
  return invoice?.locationServiceType?.serviceType?.name
}

export const isTemperTestInvoice = (invoice?: Types.InvoiceAdminDto) => {
  return invoice?.locationServiceType?.serviceType?.name === 'temper-test'
}

export const getOtherOffers = (invoice?: Types.InvoiceAdminDto): Types.InvoiceItemAdminDto[] | undefined => {
  return invoice?.items?.reduce((acc, item) => {
    if (item.offerType !== 'PRIMARY') {
      acc.push(item)
    }
    return acc
  }, [] as Types.InvoiceItemAdminDto[])
}

export const getPetsInInvoiceItem = (invoiceItem?: Types.InvoiceItemAdminDto) => {
  return (
    invoiceItem?.petRelations?.map((r) => ({
      ...r.invoicePet!,
    })) || []
  )
}

export const getItemsPetsInvoiced = (invoice?: Types.InvoiceAdminDto): Types.InvoicePetAdminDto[] => {
  const orderPetById = (invoice?.order?.pets || []).reduce(
    (acc, pet) => {
      if (pet.id) {
        acc[pet.id] = acc[pet.id] || pet
      }
      return acc
    },
    {} as Record<string, Types.InvoicePetAdminDto>
  )
  return (
    getPrimaryOffer(invoice)?.petRelations?.map(({ invoicePet }) => {
      const orderPet = orderPetById[invoicePet?.id || ''] || {}
      return {
        ...invoicePet,
        locationPetProfile: {
          ...invoicePet?.locationPetProfile,
          petInstructions: orderPet.locationPetProfile?.petInstructions,
        },
      }
    }) || []
  )
}

export const getPetsInvoiced = (invoice?: Types.InvoiceAdminDto): Types.InvoicePetAdminDto[] => {
  const orderPetById = (invoice?.order?.pets || []).reduce(
    (acc, pet) => {
      if (pet.id) {
        acc[pet.id] = acc[pet.id] || pet
      }
      return acc
    },
    {} as Record<string, Types.InvoicePetAdminDto>
  )

  return (getPrimaryOffer(invoice)?.petRelations || []).map(({ invoicePet }) => {
    const orderPet = orderPetById[invoicePet?.id || ''] || {}
    const tags = orderPet.locationPetProfile?.tags?.[0]?.tag?.id
      ? orderPet.locationPetProfile.tags
      : invoicePet?.locationPetProfile?.tags
    return {
      ...orderPet,
      locationPetProfile: {
        ...orderPet.locationPetProfile,
        profileImage: invoicePet?.locationPetProfile?.profileImage || orderPet.locationPetProfile?.profileImage,
        locationSpecies: orderPet.locationSpecies,
        tags,
      },
    }
  })
}

export const isOvernight = (invoice?: Types.InvoiceAdminDto) => !!invoice?.locationServiceType?.serviceType?.overnight

export const formatInvoicePeriod = (invoice?: Types.InvoiceAdminDto) => {
  const timezone = invoice?.location?.timezone || DEFAULT_TIMEZONE
  if (!invoice?.period?.[0]) {
    return ''
  }

  const { startDate, endDate } = getInvoiceDates(invoice)
  const primaryServiceType = getPrimaryServiceTypeName(invoice)

  const overNight = isOvernight(invoice) || (primaryServiceType === 'grooming' && !!startDate && !!endDate)

  return `${formatInTimeZone(startDate, timezone, 'MMM d')}${
    overNight ? ` - ${formatInTimeZone(endDate, timezone, 'MMM d')}` : ''
  }`
}

export const primaryOfferName = (invoice?: Types.InvoiceAdminDto) => {
  return getPrimaryOffer(invoice)?.displayName || ''
}

const isLocationPetProfileMemorialized = (locationPetProfile?: Types.LocationPetProfileAdminDto): boolean => {
  return locationPetProfile?.status === 'INACTIVE'
}

const isLocationPetProfileDeleted = (locationPetProfile?: Types.LocationPetProfileAdminDto): boolean => {
  return isEmpty(locationPetProfile?.id) || isEmpty(locationPetProfile?.displayName)
}

export const getPetStatusEmoji = (
  locationPetProfile?: Types.LocationPetProfileAdminDto,
  options?: TPetEmojiOptions
): string => {
  const config = { ...defaultPetEmojiOptions, ...options } as TPetEmojiOptions
  if (config.showMemorializeEmoji && isLocationPetProfileMemorialized(locationPetProfile)) return '🌈 '
  if (config.showDeletedPetProfileEmoji && isLocationPetProfileDeleted(locationPetProfile)) return '❌ '
  return ''
}

export const formatPetNames = (pets?: Types.InvoicePetAdminDto[], showSex = true, showEmoji = true) => {
  const getLocalizedSexLabel = (sex: string) => {
    const localeSex = t(`global.label.${sex}`) || ''
    return localeSex ? ` (${localeSex[0]})` : ''
  }

  if (isEmpty(pets)) return ''

  return (
    pets
      ?.map((pet) => {
        if (isEmpty(pet)) return ''
        const sexStr = showSex ? getLocalizedSexLabel(pet?.locationPetProfile?.sex || '') : ''
        const emoji = showEmoji ? getPetStatusEmoji(pet?.locationPetProfile) : ''
        return `${emoji}${pet.locationPetProfile?.displayName || pet?.displayName || ''}${sexStr}`
      })
      .join(', ') || ''
  )
}

export const formatInvoiceSummaryTitle = (invoice: Types.CustomInvoiceAdminDto) => {
  const timezone = invoice.location?.timezone || DEFAULT_TIMEZONE
  const offer = getPrimaryOffer(invoice),
    { startDate, endDate } = getInvoiceDates(invoice),
    overnight = isOvernight(invoice),
    pets = getPetsInvoiced(invoice)

  const date = overnight
    ? `${formatInTimeZone(startDate, timezone, 'M/d')} - ${formatInTimeZone(endDate, timezone, 'M/d')}`
    : formatInTimeZone(startDate, timezone, 'M/d')

  return `${offer?.displayName} | ${date} | ${formatPetNames(pets, false, false)}`
}

export const getInvoiceItemsForDisplayName = (invoice: Types.CustomInvoiceAdminDto) => {
  return (invoice.items || []).map((item) => item.offer?.displayName).join(', ')
}

export const formatInvoicePeriodSimple = (invoice?: Types.InvoiceAdminDto, overNight = true) => {
  const timezone = invoice?.location?.timezone || DEFAULT_TIMEZONE
  if (!invoice?.period?.[0]) {
    return ''
  }
  const { startDate, endDate } = getInvoiceDates(invoice)
  return `${formatInTimeZone(startDate, timezone, 'M/d')}${
    overNight ? ` - ${formatInTimeZone(endDate, timezone, 'M/d')}` : ''
  }`
}

export const daysOfStay = (periods?: Types.InvoicePeriodAdminDto[], pricingStrategy?: 'NIGHTLY' | 'DAILY' | null) => {
  if (!periods?.length || !pricingStrategy) {
    return null
  }

  const periodLength = differenceInCalendarDays(
    getTzDateInUtc(periods[0].endDate),
    getTzDateInUtc(periods[0].startDate)
  )
  return pricingStrategy === 'NIGHTLY' ? periodLength : periodLength + 1
}

export const hasInvoiceInFuture = (invoices?: Types.InvoiceAdminDto[]) => {
  if (!invoices) {
    return false
  }

  return invoices.some((invoice) => isFuture(getInvoiceDates(invoice).startDate))
}

export const getServiceDatesAsText = (
  invoice: Types.InvoiceAdminDto,
  options?: { includeTime?: boolean; useResourceUsage?: boolean }
) => {
  const timezone = invoice?.location?.timezone || DEFAULT_TIMEZONE
  let datesObj: { startDate: Date; endDate: Date; duration?: number } | undefined = undefined
  if (options?.useResourceUsage) {
    datesObj = getInvoiceSchedule(invoice)
  } else {
    datesObj = getInvoiceDates(invoice)
  }
  if (datesObj === undefined) return t('global.label.unscheduled')
  const serviceType = getPrimaryServiceTypeName(invoice)
  const format = options?.includeTime ? 'MMM d, yyyy h:mm aaa' : 'MMM d, yyyy'

  if (serviceType === 'daycare' || serviceType === 'grooming') {
    return formatInTimeZone(datesObj.startDate, timezone, format)
  }

  return `${formatInTimeZone(datesObj.startDate, timezone, format)} - ${formatInTimeZone(
    datesObj.endDate,
    timezone,
    format
  )}`
}

export const isInvoiceCanceled = (invoice?: Types.CustomInvoiceAdminDto) => {
  return invoice?.invoiceStatus === 'CANCELED' || invoice?.invoiceSubStatus === 'CANCELED'
}

export const isInvoiceCompleted = (invoice?: Types.CustomInvoiceAdminDto) => {
  return invoice?.activities?.[0]?.activityStatus === 'COMPLETED' || invoice?.invoiceSubStatus === 'COMPLETED'
}

export const getInvoiceSubStatus = (invoice?: Types.CustomInvoiceAdminDto) => {
  return {
    isCanceled: invoice?.invoiceSubStatus === 'CANCELED',
    isRequested:
      invoice?.invoiceSubStatus === 'REQUESTED' || invoice?.invoiceSubStatus === null || !invoice?.invoiceSubStatus,
    isDeclined: invoice?.invoiceSubStatus === 'DECLINED',
    isScheduled: invoice?.invoiceSubStatus === 'SCHEDULED',
    isCheckedOut: invoice?.invoiceSubStatus === 'COMPLETED',
  }
}

export const getScheduleDate = (invoice?: Types.CustomInvoiceAdminDto) => {
  if (!invoice) {
    return null
  }
  const { isRequested, isDeclined, isScheduled } = getInvoiceSubStatus(invoice)
  if (isRequested || isDeclined) {
    return null
  }

  const { startTime } = invoice.period?.[0] ?? {}
  if (!isScheduled && !startTime) {
    return null
  }

  const { startDate } = getInvoiceDates(invoice)
  const timezone = invoice?.location?.timezone || DEFAULT_TIMEZONE

  return formatInTimeZone(startDate, timezone, 'MMM d, yyyy h:mm aaa')
}
