import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { ClientBookingReservationService } from '../services/client_booking_reservation.service'
import { BookingReservationClientDto, CreateBookingOrderClientInput } from '../types'
import { defaultQueryOptions } from './common'

export const useClientBookingReservation = (
  locationName: string,
  bookingOrder?: CreateBookingOrderClientInput
): UseQueryResult<BookingReservationClientDto | null> => {
  return useQuery<BookingReservationClientDto | null>({
    queryKey: ['clientBookingReservation', locationName],
    queryFn: () => ClientBookingReservationService.makeReservation(locationName, bookingOrder!),
    enabled: Boolean(bookingOrder),
    ...defaultQueryOptions,
  })
}
