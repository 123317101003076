import { SvgIcon, SvgIconProps } from '@mui/material'

/* eslint-disable max-len */
export const BoardingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} data-testid="BoardingIcon">
      <path d="M1.66699 9.00974L10.0003 3.23728L18.3337 9.00974V6.97364L10.0003 1.22217L1.66699 6.93166V9.00974Z" />
      <path d="M16.8852 9.63952V17.595H12.8969V12.8931C12.8969 11.2978 11.6165 9.99636 10.0002 9.99636C8.38392 9.99636 7.12447 11.2768 7.12447 12.8931V17.595H3.11523V9.63952L10.0002 4.81165L16.8852 9.63952Z" />
    </SvgIcon>
    // <SvgIcon {...props} data-testid="BoardingIcon">
    //   <path d="M4.87427 11.659L11.9999 6.72307L19.1255 11.659V9.91794L11.9999 5L4.87427 9.88205V11.659Z" />
    //   <path d="M17.8854 12.1966V18.9991H14.4752V14.9786C14.4752 13.6145 13.3803 12.5017 11.9983 12.5017C10.6162 12.5017 9.53928 13.5966 9.53928 14.9786V18.9991H6.11108V12.1966L11.9983 8.06836L17.8854 12.1966Z" />
    // </SvgIcon>
  )
}
