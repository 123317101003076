import { SvgIcon, SvgIconProps } from '@mui/material'

/* eslint-disable max-len */
export const VisaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="32" height="21" viewBox="0 0 32 21" fill="none" data-testid="VisaIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
        fill="#F4F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V5H0V1.5Z"
        fill="#4095DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16H32V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V16Z"
        fill="#F4B662"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1405 7.28462C19.8291 7.14852 19.3394 7 18.7294 7C17.1725 7 16.0766 7.92035 16.0675 9.23809C16.0576 10.2127 16.8495 10.7564 17.4466 11.0804C18.0602 11.4125 18.2664 11.625 18.2639 11.9219C18.2597 12.3761 17.7741 12.5842 17.3214 12.5842C16.6909 12.5842 16.356 12.4818 15.8381 12.2286L15.6355 12.1206L15.4153 13.6386C15.7825 13.8274 16.4632 13.9914 17.1697 14C18.8243 14 19.8995 13.0909 19.9121 11.6837C19.9177 10.9108 19.4981 10.3248 18.5893 9.84108C18.039 9.52672 17.7013 9.31841 17.7052 9.00014C17.7055 8.71823 17.9905 8.41658 18.6073 8.41658C19.1224 8.40737 19.4952 8.53927 19.7858 8.67707L19.9272 8.75468L20.1405 7.28462ZM12.8536 13.8995L13.8388 7.11817H15.4135L14.4284 13.8995H12.8536ZM22.8646 9.59983C22.8646 9.59983 22.3678 11.1054 22.2373 11.4952H23.5446C23.4824 11.1562 23.1802 9.53763 23.1802 9.53763L23.0739 8.95277C23.0319 9.08717 22.9732 9.26533 22.9286 9.40077C22.8889 9.52147 22.8603 9.60823 22.8646 9.59983ZM24.1801 7.12508H22.9631C22.5856 7.12508 22.3031 7.24506 22.1377 7.68721L19.7991 13.9019H21.4531C21.4531 13.9019 21.7229 13.0658 21.7842 12.8824C21.869 12.8824 22.2689 12.8831 22.6929 12.884C23.171 12.8849 23.6796 12.8859 23.8011 12.8859C23.8483 13.1225 23.9929 13.9019 23.9929 13.9019H25.4545L24.1801 7.12508ZM11.5325 7.12388L9.99122 11.7471L9.82612 10.8073L9.27428 7.69142C9.17908 7.26169 8.9024 7.1343 8.56042 7.11928H6.02099L6 7.25978C6.61781 7.4354 7.17026 7.68865 7.65441 8.00364L9.05479 13.8935L10.7214 13.8924L13.2013 7.12388H11.5325Z"
        fill="#0071CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
    </SvgIcon>
  )
}
