const ADMIN_BASE_PATH = '/admin'
const CLIENT_BASE_PATH = '/client'

export const ADMIN_ACCOUNT_LOCATION_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/locations`
export const ADMIN_ACCOUNT_LOCATION_ID_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/locations/{{id}}`
export const ADMIN_ACCOUNT_ROLES_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/account-roles`
export const ADMIN_ACCOUNT_ROLES_ID_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/account-roles/{{id}}`
export const ADMIN_ACCOUNT_USER_INVITATIONS_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/user-account-invitations`
export const ADMIN_ACCOUNT_USER_INVITATIONS_ID_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/user-account-invitations/{{id}}`
export const ADMIN_ACTIVITY_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/activities`
export const ADMIN_ACTIVITY_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/activities/{{id}}`
export const ADMIN_ACTIVITY_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/activities/batch`
export const ADMIN_ADDRESS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/addresses`
export const ADMIN_ADDRESS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/addresses/{{id}}`
export const ADMIN_ASSET_OWNERSHIP_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/assets/{{ownership}}/{{id}}`
export const ADMIN_ASSET_OWNERSHIP_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/assets/{{ownership}}`
export const ADMIN_ASSET_USAGES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/asset-usages/{{id}}`
export const ADMIN_ASSET_USAGES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/asset-usages`
export const ADMIN_BOOKING_CONFIGS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/configs/{{serviceTypeName}}`
export const ADMIN_BOOKING_SEARCH_CONFIGS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/search/configs/{{serviceTypeName}}`
export const ADMIN_BOOKING_FIELD_CONFS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking-field-confs`
export const ADMIN_BOOKING_RESERVATION_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/reservation`
export const ADMIN_BOOKING_RESERVATION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/reservation/{{id}}`
export const ADMIN_BOOKING_RESERVATION_CANCEL_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/reservation/{{orderId}}/payment/{{paymentId}}/cancel`
export const ADMIN_BOOKING_RESERVATION_CONFIRM_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/reservation/{{id}}/confirm`
export const ADMIN_BOOKING_RESERVATION_PAYMENT_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/reservation/{{id}}/payment`
export const ADMIN_BOOKING_SEARCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/search/{{serviceTypeName}}`
export const ADMIN_BOOKING_SEARCH_DAYS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/booking/search/{{serviceTypeName}}/days`
export const ADMIN_CANCEL_INVOICE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/invoices/{{invoiceId}}/cancel`
export const ADMIN_CHARGEBEEPORTAL_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/chargebeeportal`
export const ADMIN_COLOR_SCHEMAS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/color-schemas`
export const ADMIN_COMMUNICATIONS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/communications`
export const ADMIN_COMMUNICATIONS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/communications/{{id}}`
export const ADMIN_CONTRACTS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/contracts`
export const ADMIN_CONTRACTS_AGREEMENTS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/contracts/{{contractId}}/agreements`
export const ADMIN_CONVERSATION_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/conversations`
export const ADMIN_CONVERSATION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/conversations/{{conversationId}}`
export const ADMIN_CONVERSATION_ID_MESSAGES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/conversations/{{conversationId}}/messages`
export const ADMIN_CONVERSATION_ID_MMS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/conversations/{{conversationId}}/mms`
export const ADMIN_DIVISION_ID_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/divisions/{{id}}`
export const ADMIN_DIVISION_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/divisions`
export const ADMIN_DIVISION_USERS_ID_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/division-users/{{id}}`
export const ADMIN_DIVISION_USERS_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/division-users`
export const ADMIN_INSTRUCTION_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}/instructions`
export const ADMIN_INSTRUCTION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}/instructions/{{id}}`
export const ADMIN_INSTRUCTION_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}/instructions/batch`
export const ADMIN_INVOICE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/invoices/{{invoiceId}}`
export const ADMIN_INVOICE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/invoices`
export const ADMIN_LOCATION_ASSOCIATED_CONTACTS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/associated-contacts`
export const ADMIN_LOCATION_ASSOCIATED_CONTACTS_PATH_ID = `${ADMIN_BASE_PATH}/{{locationId}}/associated-contacts/{{id}}`
export const ADMIN_LOCATION_BOOKING_CONF_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-booking-confs`
export const ADMIN_LOCATION_BOOKING_CONF_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-booking-confs/{{id}}`
export const ADMIN_LOCATION_INVOICES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}`
export const ADMIN_LOCATION_INVOICES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices`
export const ADMIN_LOCATION_NOTES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/notes`
export const ADMIN_LOCATION_NOTES_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/notes/batch`
export const ADMIN_LOCATION_NOTES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/notes/{{id}}`
export const ADMIN_LOCATION_INVOICE_NOTES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}/notes`
export const ADMIN_LOCATION_INVOICE_NOTES_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}/notes/batch`
export const ADMIN_LOCATION_INVOICE_NOTES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/invoices/{{invoiceId}}/notes/{{id}}`
export const ADMIN_LOCATION_PAYMENT_DEVICES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationName}}/payment-devices/{{id}}`
export const ADMIN_LOCATION_PAYMENT_DEVICES_PATH = `${ADMIN_BASE_PATH}/{{locationName}}/payment-devices`
export const ADMIN_LOCATION_PET_PROFILE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationName}}/location-pet-profiles/{{petId}}`
export const ADMIN_LOCATION_PET_PROFILE_PATH = `${ADMIN_BASE_PATH}/{{locationName}}/location-pet-profiles`
export const ADMIN_LOCATION_SERVICE_TYPE = `${ADMIN_BASE_PATH}/{{locationId}}/location-service-types`
export const ADMIN_LOCATION_SERVICE_TYPE_MESSAGE_TEMPLATE = `${ADMIN_BASE_PATH}/{{locationId}}/location-service-type-message-templates`
export const ADMIN_LOCATION_SERVICE_TYPE_MESSAGE_TEMPLATE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-service-type-message-templates/{{id}}`
export const ADMIN_LOCATION_SERVICE_TYPE_MESSAGE_TEMPLATE_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-service-type-message-templates/batch`
export const ADMIN_LOCATION_SPECIES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-species`
export const ADMIN_LOCATION_TAG_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/tags`
export const ADMIN_LOCATION_TAG_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/tags/{{tagId}}`
export const ADMIN_LOCATION_TAG_RELATIONS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/tag-relations`
export const ADMIN_LOCATION_TAG_RELATIONS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/tag-relations/{{id}}`
export const ADMIN_LOCATION_TAG_RELATIONS_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/tag-relations/batch`
export const ADMIN_LOCATION_THEMES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-themes`
export const ADMIN_LOCATION_THEMES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-themes/{{id}}`
export const ADMIN_LOCATION_USERPROFILE_SEARCH_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/location-user-profiles`
export const ADMIN_LOCATION_USERPROFILE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-user-profiles`
export const ADMIN_LOCATION_USERPROFILE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-user-profiles/{{id}}`
export const ADMIN_LOCATION_VACCINE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-vaccines`
export const ADMIN_LOCATION_VACCINE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/location-vaccines/{{id}}`
export const ADMIN_VACCINE_TYPES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/vaccine-types`
export const ADMIN_VACCINATION_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/vaccinations`
export const ADMIN_PET_INSTRUCTION_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/pet-instructions`
export const ADMIN_PET_INSTRUCTION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/pet-instructions/{{id}}`
export const ADMIN_CONSUMABLE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/consumables`
export const ADMIN_CONSUMABLE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/consumables/{{id}}`
export const ADMIN_CONSUMABLE_BATCH = `${ADMIN_BASE_PATH}/{{locationId}}/consumables/batch`
export const ADMIN_VACCINATION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/vaccinations/{{id}}`
export const ADMIN_OFFERS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offers`
export const ADMIN_OFFERS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offers/{{offerId}}`
export const ADMIN_OFFER_OFFERS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offer-offers`
export const ADMIN_OFFER_OFFERS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offer-offers/{{id}}`
export const ADMIN_OFFER_GROUPS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offer-groups`
export const ADMIN_OFFER_PRICE_ADJUSTMENT_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offer-price-adjustments/{{id}}`
export const ADMIN_OFFER_PRICE_ADJUSTMENT_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/offer-price-adjustments`
export const ADMIN_ORDER_SEARCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders`
export const ADMIN_ORDER_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{id}}`
export const ADMIN_ORDER_PAYMENT_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/order-payments/{{id}}`
export const ADMIN_ORDER_PAYMENT_INIT_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/payments`
export const ADMIN_ORDER_PAYMENT_UPDATE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/payments/{{id}}`
export const ADMIN_ORDER_PAYMENT_CONFIRM_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/payments/{{id}}/confirm`
export const ADMIN_ORDER_PAYMENT_CANCEL_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/payments/{{id}}/cancel`
export const ADMIN_ORDER_PAYMENT_REFUND_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/orders/{{orderId}}/invoices/{{invoiceId}}/payments/{{id}}/refund`
export const ADMIN_PAYMENT_SETTING_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/payment-settings/{{id}}`
export const ADMIN_PAYMENT_SETTING_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/payment-settings`
export const ADMIN_PERIOD_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/periods/{{periodId}}`
export const ADMIN_PERIODS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/periods`
export const ADMIN_PRICE_ADJUSTMENT_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/price-adjustments/{{id}}`
export const ADMIN_PRICE_ADJUSTMENT_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/price-adjustments`
export const ADMIN_PRICES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/prices`
export const ADMIN_BATCH_PRICES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/prices/batch`
export const ADMIN_PRIMARY_RESOURCE_UNITS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/primary-resource-units`
export const ADMIN_RECEIPT_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/receipt/{{id}}`
export const ADMIN_REPORTS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/reports/{{reportName}}`
export const ADMIN_REPORT_TYPES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/report-types`
export const ADMIN_REPORT_TYPES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/report-types`
export const ADMIN_RESOURCE_USAGES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resource-usages`
export const ADMIN_RESOURCE_USAGES_DATA_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/reports/services-by-resource-usage-date-export`
export const ADMIN_RESOURCE_USAGE_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resource-usages/batch`
export const ADMIN_RESOURCE_USAGE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resource-usages/{{id}}`
export const ADMIN_RESTRICTION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/restrictions/{{id}}`
export const ADMIN_RESTRICTION_RELATION_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/restriction-relations/{{id}}`
export const ADMIN_RESTRICTION_RELATIONS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/restriction-relations`
export const ADMIN_RESTRICTIONS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/restrictions`
export const ADMIN_SERVICES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/services/{{id}}`
export const ADMIN_SERVICES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/services`
export const ADMIN_RESOURCE_USAGE_TYPES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/reports/services-by-resource-usage-date`
export const ADMIN_SERVICE_OFFERS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/service-offers/{{id}}`
export const ADMIN_SERVICE_OFFERS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/service-offers`
export const ADMIN_SERVICE_RESOURCES_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/service-resources/batch`
export const ADMIN_SERVICE_TYPES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/service-types`
export const ADMIN_SEND_EMAIL_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/receipt/{{id}}/send-email`
export const ADMIN_SPECIES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/species`
export const ADMIN_STORED_PAYMENT_METHODS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/stored-payment-methods`
export const ADMIN_STORED_PAYMENT_METHODS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/stored-payment-methods/{{id}}`
export const ADMIN_VOUCHERS_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/vouchers`
export const ADMIN_VOUCHER_BULK_UPDATE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/vouchers/bulk`
export const ADMIN_TAXES_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/taxes`
export const ADMIN_TAXES_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/taxes/{{id}}`
export const ADMIN_UNIT_ACTIVITY_RELATIONS_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/unit-activity-relations/{{id}}`
export const ADMIN_UNIT_ACTIVITY_RELATIONS_BATCH_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/unit-activity-relations/batch`
export const ADMIN_USER_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/users/{{id}}`
export const ADMIN_USERPROFILE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/user-profiles/{{id}}`

export const CLIENT_AGREEMENTS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/contracts/{{contractId}}/agreements`
export const CLIENT_ASSET_OWNERSHIP_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/assets/{{ownership}}/{{id}}`
export const CLIENT_ASSET_OWNERSHIP_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/assets/{{ownership}}`
export const CLIENT_ASSET_USAGES_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/asset-usages/{{id}}`
export const CLIENT_ASSET_USAGES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/asset-usages`
export const CLIENT_BOOKING_CONFIGS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/configs/{{serviceTypeName}}`
export const CLIENT_BOOKING_RESERVATION_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/reservation`
export const CLIENT_BOOKING_RESERVATION_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/reservation/{{id}}`
export const CLIENT_BOOKING_PAYMENT_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/reservation/{{orderId}}/payment`
export const CLIENT_BOOKING_CONFIRM_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/reservation/{{orderId}}/confirm`
export const CLIENT_BOOKING_SEARCH_CONFIGS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/search/configs/{{serviceTypeName}}`
export const CLIENT_BOOKING_SEARCH_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/search/{{serviceTypeName}}`
export const CLIENT_BOOKING_SEARCH_DAYS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/booking/search/{{serviceTypeName}}/days`
export const CLIENT_CANCEL_INVOICE_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/orders/{{orderId}}/invoices/{{invoiceId}}/cancel`
export const CLIENT_CONTRACTS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/contracts`
export const CLIENT_CONTRACTS_AGREEMENTS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/contracts/{{contractId}}/agreements`
export const CLIENT_LOCATION_BOOKING_CONF_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/location-booking-confs`
export const CLIENT_LOCATION_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/locations/{{id}}`
export const CLIENT_LOCATION_SERVICE_TYPE_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/location-service-types`
export const CLIENT_LOCATION_SPECIES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/location-species`
export const CLIENT_LOCATION_THEMES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/location-themes`
export const CLIENT_LOCATION_VACCINES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/location-vaccines`
export const CLIENT_LINK_ORDER_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/link-orders/{{id}}`
export const CLIENT_ORDER_SEARCH_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/orders`
export const CLIENT_ORDER_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/orders/{{id}}`
export const CLIENT_PET_FAMILIES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/pet-families`
export const CLIENT_PET_PROFILES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/pet-profiles`
export const CLIENT_PET_PROFILES_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/pet-profiles/{{id}}`
export const CLIENT_PETS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/pets`
export const CLIENT_PETS_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/pets/{{id}}`
export const CLIENT_RECEIPT_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/receipt/{{id}}`
export const CLIENT_SPECIES_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/species`
export const CLIENT_STORED_PAYMENT_METHODS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/stored-payment-methods`
export const CLIENT_STORED_PAYMENT_METHODS_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/stored-payment-methods/{{id}}`
export const CLIENT_USER_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/users/{{id}}`
export const CLIENT_USER_PETS_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/user/{{id}}/pets`
export const CLIENT_USERPROFILE_ID_PATH = `${CLIENT_BASE_PATH}/{{locationId}}/user-profiles/{{id}}`
export const ADMIN_EXTERNAL_ACCOUNT_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/external-accounts`
export const ADMIN_ADYEN_ONBOARDING_PATH = `${ADMIN_BASE_PATH}/account/{{accountId}}/adyen/onboarding`
export const ADMIN_PHONE_NUMBER_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/phone-number`
export const ADMIN_MESSAGE_TEMPLATES = `${ADMIN_BASE_PATH}/{{locationId}}/message-templates`
export const ADMIN_MESSAGE_TEMPLATES_ID = `${ADMIN_BASE_PATH}/{{locationId}}/message-templates/{{id}}`
export const ADMIN_RESOURCE_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resources`
export const ADMIN_RESOURCE_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resources/{{id}}`
export const ADMIN_RESOURCE_CALENDAR_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resource/calendar`
export const ADMIN_RESOURCE_CALENDAR_ID_PATH = `${ADMIN_BASE_PATH}/{{locationId}}/resource/calendar/{{resourceId}}`
export const ADMIN_REACTIVE_MESSAGES = `${ADMIN_BASE_PATH}/{{locationId}}/reactive-messages`
export const ADMIN_RESOURCE_AVAILABILITY = `${ADMIN_BASE_PATH}/{{locationId}}/resource/availability`
