import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import { useCallback, useEffect, useRef, useState } from 'react'

import type { TButtonMenuProps } from './button_menu.d'

export const ButtonMenu = ({ menuItems, onClickMenuItem, label, itemsContainerSx, ...rest }: TButtonMenuProps) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), [])
  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }, [])

  const handleMenuItemClick = useCallback(
    (ev: React.MouseEvent<HTMLLIElement>) => {
      onClickMenuItem?.(ev.currentTarget.id)
      setOpen(false)
    },
    [onClickMenuItem]
  )

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Stack direction="row" spacing={2} sx={{ zIndex: theme.zIndex.fab, marginLeft: '16px !important' }}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          {...rest}
        >
          {label}
        </Button>
        <Popper
          sx={{ zIndex: theme.zIndex.tooltip }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={itemsContainerSx}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {menuItems.map((item) => (
                      <MenuItem key={item.handle} id={item.handle} onClick={handleMenuItemClick}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}
