import { useQuery } from '@tanstack/react-query'
import { ClientReceiptService } from '../services'
import { ReceiptDto, TBookingStep } from '../types'
import { defaultQueryOptions } from './common'
import { AxiosError } from 'axios'

export const useClientReceipt = (locationName: string, orderId: string, step: TBookingStep = 'CONFIRMATION') => {
  return useQuery<ReceiptDto, AxiosError<{ error: string }>>({
    queryKey: ['clientReceipt', locationName, orderId],
    queryFn: () => ClientReceiptService.getReceipt(locationName, orderId, step),
    enabled: !!locationName && !!orderId,
    ...defaultQueryOptions,
  })
}
