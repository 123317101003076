import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Alert, AutocompleteChangeReason, useTheme } from '@mui/material'
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ChangeEvent, Fragment, MouseEvent, SyntheticEvent, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import FormControl from '@mui/material/FormControl'
import { useBookingContext } from '@providers/booking'
import { TBookingPet } from '@providers/booking/types'
import { useLocationContext } from '@providers/location'
import { i18WithParams as t } from '@shared/locale'
import { requiresWeightForSpecie } from '@shared/service_types'
import { Spacer2, TextInputNumber, generatePhoneFromString } from 'components-ui'
import { Types } from 'service-api'
import { AddButton } from './add_button'
import { ErrorMessages } from './error_messages'
import { PetThresholds } from './pet_thresholds'
import { BookingSpeciesButtons } from './species_button'
import { FootstepIcon } from '@shared/icons/footstep_icon'

export const SearchModalContents = ({ onDone }: { onDone?: () => void }) => {
  const bookingCtx = useBookingContext()
  const { location } = useLocationContext()
  const { serviceTypeName = '' } = useParams()
  const theme = useTheme()
  const isNotDaycare = serviceTypeName !== 'daycare'

  const onChangePetData = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { target: t } = ev
      bookingCtx.dispatch({
        type: 'SET_PET_SEARCH_FIELD',
        payload: {
          fieldName: t.name,
          value: t.value,
          groupIdx: t.dataset.group,
          petIdx: t.dataset.pos,
        },
      })
    },
    [bookingCtx]
  )
  const onBlurField = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      const { currentTarget: t } = ev
      const nodeAttrs = ev.currentTarget.attributes
      bookingCtx.dispatch({
        type: 'BLUR_FIELD_VALIDATION',
        payload: {
          fieldName: nodeAttrs.getNamedItem('name')?.value,
          groupIdx: t.dataset.group,
          petIdx: t.dataset.pos,
        },
      })
    },
    [bookingCtx]
  )

  const onSelectBreed = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ev: SyntheticEvent, value: Types.BreedClientDto | null, reason: AutocompleteChangeReason) => {
      const { currentTarget: t } = ev as SyntheticEvent<HTMLInputElement>
      if (reason === 'clear') {
        const input = t.closest('.MuiAutocomplete-root') as HTMLElement
        if (input) {
          bookingCtx.dispatch({
            type: 'CLEAR_PET_SEARCH_BREED_FIELD',
            payload: {
              groupIdx: input.dataset.group,
              petIdx: input.dataset.pos,
            },
          })
        }
        return
      }

      if (!value || typeof value === 'string') {
        return
      }

      bookingCtx.dispatch({
        type: 'SET_PET_SEARCH_BREED_FIELD',
        payload: {
          breed: value.displayName,
          breedId: value.id,
          groupIdx: t.dataset.group,
          petIdx: t.dataset.pos,
        },
      })
    },
    [bookingCtx]
  )
  const onChangeBreedInput = useCallback(
    (ev: SyntheticEvent, newValue: string, reason: AutocompleteInputChangeReason) => {
      if (!ev || (reason === 'reset' && !newValue)) {
        return
      }
      let { currentTarget: t } = ev as SyntheticEvent<HTMLInputElement>
      if (!t.dataset.group) {
        t = t.closest('.MuiAutocomplete-root')!
      }

      bookingCtx.dispatch({
        type: 'SET_BREED_INPUT_FIELD',
        payload: {
          groupIdx: t.dataset.group,
          petIdx: t.dataset.pos,
          value: newValue,
        },
      })
    },
    [bookingCtx]
  )

  const onRemovePetRecord = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      const { dataset } = ev.currentTarget
      bookingCtx.dispatch({
        type: 'REMOVE_PET_SEARCH_FIELD',
        payload: { groupIdx: dataset.group, petIdx: dataset.pet },
      })
    },
    [bookingCtx]
  )
  const onRemoveRoom = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      const { dataset } = ev.currentTarget
      bookingCtx.dispatch({
        type: 'REMOVE_ROOM_SEARCH_FIELD',
        payload: { roomIdx: dataset.group },
      })
    },
    [bookingCtx]
  )

  const onDoneClick = useCallback(
    (_ev: MouseEvent<HTMLButtonElement>) => {
      bookingCtx.dispatch({ type: 'SET_DONE_FORM_VALIDATION' })
      // onDone?.()
    },
    [bookingCtx]
  )

  const { config } = bookingCtx.state
  const { groups } = bookingCtx.state

  const requiresWeight = useMemo(
    () => requiresWeightForSpecie(bookingCtx.state.config.booking),
    [bookingCtx.state.config.booking]
  )
  const showTemperTestWarning = Boolean(
    bookingCtx.state.config.booking?.temperTest?.length &&
      bookingCtx.state.config.booking.temperTest[0].contingent === false
  )
  return (
    <Paper elevation={0}>
      {showTemperTestWarning && (
        <Alert
          severity="warning"
          icon={<FootstepIcon sx={{ fill: 'white !important' }} />}
          sx={{ borderRadius: 0, mx: -3, mb: 2 }}
        >
          <Typography variant="body1">
            {t('searchBar.actionPrompt.temperTestBanner.main', {
              temperTestName: bookingCtx.state.data.temperTest?.displayName || t('temperTest.defaultName'),
            })}
          </Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: t('searchBar.actionPrompt.temperTestBanner.sub', {
                phoneNumber: generatePhoneFromString(location?.communications?.find((c) => c.type === 'PHONE')?.value),
              }),
            }}
          />
        </Alert>
      )}
      {groups.map((group, groupIdx) => {
        const groupWithError = bookingCtx.state.groups[groupIdx].errors.length > 0
        return (
          <Box key={group.id}>
            {isNotDaycare && (
              <>
                <Stack direction="column" alignItems="start">
                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="h6" component="span">
                      <strong style={{ textTransform: 'capitalize' }}>
                        {`${t('global.label.room')} ${groupIdx + 1}`}
                      </strong>
                    </Typography>
                    <Stack direction="row">
                      <BookingSpeciesButtons group={groupIdx} />
                      {groups.length > 1 && (
                        <Box sx={{ minWidth: '38px', marginLeft: 1 }}>
                          <Tooltip title={t('global.label.remove')} disableInteractive>
                            <IconButton onClick={onRemoveRoom} sx={{ p: '6px' }} data-group={groupIdx}>
                              <DeleteOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                  <PetThresholds specieId={group.specieId} />
                </Stack>
              </>
            )}
            <Spacer2 />

            {groupWithError && (
              <>
                <ErrorMessages groupIdx={groupIdx} />
                <Spacer2 />
              </>
            )}

            {group.pets.map((_pet, petIdx) => {
              return (
                <Fragment key={`${group.id}_${petIdx}`}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1 }} alignItems="start">
                    <FormControl fullWidth>
                      <TextField
                        name="name"
                        label={t('global.label.name')}
                        size="small"
                        value={group.pets[petIdx].name}
                        error={Boolean(group.pets[petIdx].nameErrorMsg)}
                        helperText={group.pets[petIdx].nameErrorMsg}
                        onChange={onChangePetData}
                        onBlur={onBlurField}
                        inputProps={{
                          ['data-group']: groupIdx,
                          ['data-pos']: petIdx,
                        }}
                      />
                    </FormControl>
                    <Autocomplete
                      name="breed"
                      sx={{ minWidth: '200px' }}
                      fullWidth
                      freeSolo
                      size="small"
                      options={(config.breedsBySpecie?.[group.specieId] || []) as Types.BreedClientDto[]}
                      value={group.pets[petIdx] as TBookingPet}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      onChange={onSelectBreed}
                      onBlur={onBlurField}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('global.label.breed')}
                          error={Boolean(group.pets[petIdx].breedErrorMsg)}
                          helperText={group.pets[petIdx].breedErrorMsg}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                      inputValue={group.pets[petIdx].breedInput}
                      onInputChange={onChangeBreedInput}
                      renderOption={(props, option) => (
                        <li {...props} data-group={groupIdx} data-pos={petIdx}>
                          {option.displayName}
                        </li>
                      )}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option
                        }
                        return option.displayName || ''
                      }}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      isOptionEqualToValue={(option: Types.BreedClientDto, value: TBookingPet) =>
                        option.id === value.breedId
                      }
                      data-group={groupIdx}
                      data-pos={petIdx}
                    />
                    {requiresWeight[group.specieId] && (
                      <FormControl
                        sx={{
                          maxWidth: '100px',
                          flexShrink: 0,
                        }}
                      >
                        <TextInputNumber
                          name="weight"
                          label={t('global.label.weight')}
                          size="small"
                          value={group.pets[petIdx].weight}
                          onChange={onChangePetData}
                          onBlur={onBlurField}
                          error={Boolean(group.pets[petIdx].weightErrorMsg)}
                          helperText={group.pets[petIdx].weightErrorMsg}
                          inputProps={{
                            ['data-group']: groupIdx,
                            ['data-pos']: petIdx,
                          }}
                        />
                      </FormControl>
                    )}
                    {group.pets.length > 1 && (
                      <Box sx={{ minWidth: '38px' }}>
                        <Tooltip title={t('global.label.remove')} disableInteractive>
                          <IconButton
                            onClick={onRemovePetRecord}
                            sx={{ p: '6px' }}
                            data-group={groupIdx}
                            data-pet={petIdx}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Stack>

                  <Spacer2 />
                </Fragment>
              )
            })}
            <Divider orientation="horizontal" />
            <Spacer2 />
          </Box>
        )
      })}

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          py: 1,
          position: 'sticky',
          bottom: 0,
          background: theme.palette.background.paper,
          zIndex: theme.zIndex.appBar,
        }}
      >
        <AddButton />
        <Button variant="text" onClick={onDoneClick} size="small">
          {t('global.label.done')}
        </Button>
      </Stack>
    </Paper>
  )
}
