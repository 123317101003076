import add from 'date-fns/add'
import startOfDay from 'date-fns/startOfDay'
import { current, original } from 'immer'

import {
  buildClosedDates,
  buildOpenDays,
  daycareStartEndDates,
  isWeightRequiredForSpecie,
  minimumSelectableDate,
} from '@shared/service_types'
import { Types } from 'service-api'
import { OrderClientDto } from 'service-api/src/types'
import type {
  TBookingCart,
  TBookingPet,
  TBookingContext,
  TBreedsBySpecie,
  TCartVet,
  TOfferAvailability,
  TReducerActions,
} from './types'
import { newCart, newStateData } from './booking_context'
import { newCartGroup } from './helper'
import {
  calculateCartPrice,
  checkFormValid,
  checkPetWeight,
  formHasErrors,
  newPetGroup,
  newEmptyPetGroup,
  newPetRecord,
  updatePetAddonQty,
  validateForm,
  petProfilesToGroups,
  regroupPets,
  reorderPets,
  removeFieldRequiredError,
  petProfileToPetRecord,
  speciePlatformToLocation,
  getSpecieRestrictionThresholds,
  checkTemperTestRequirement,
} from './helper'
import { isEmpty } from '@shared/common'
import { selectedDaysSorted } from '../../shared/pet_addons'
import { millisecondsToDays } from 'components-ui'

const actions: TReducerActions = {
  LOGIN: (state: TBookingContext, _?: GenericSimpleBag) => {
    state.isValid = false
  },
  SET_BOOKING_CONFIG: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const mustUpdateDates = state.serviceTypeName !== payload!.serviceTypeName
    const baseDate = add(new Date(), { days: 1 })
    state.isNewCustomer = !!payload!.isNewCustomer
    state.serviceTypeName = payload!.serviceTypeName
    state.config.booking = payload!.value
    if (!state.startDate || mustUpdateDates) {
      if (state.serviceTypeName === 'daycare') {
        const { startDate, endDate } = daycareStartEndDates(baseDate, state.config.booking)
        state.startDate = startDate
        state.endDate = endDate
      } else {
        state.startDate = minimumSelectableDate(baseDate, state.config.booking)
        state.endDate = add(state.startDate, { days: 1 })
      }
    }
    state.closedDates = buildClosedDates(state.config.booking)
    state.openDays = buildOpenDays(state.config.booking)
  },
  RESET_STATE: (state: TBookingContext) => {
    resetState(state)
  },
  RESET_STATE_FULL: (state: TBookingContext) => {
    resetState(state)
    state.groups = [newPetGroup()]
    state.currentState = 'search'
    state.confirmation = { groups: [] }
    state.data.petProfiles = undefined
    state.isNewCustomer = true
  },
  SET_LOCATION_CONFIG: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.config.location = payload!.value
  },
  SET_SERVICE_TYPE_CONFIG: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.config.serviceType = payload!.value
  },
  SET_SERVICE_TYPE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.serviceType = payload!.value
  },
  SET_SPECIES_CONFIG: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const species: Types.LocationSpeciesClientDto[] = payload!.value
    state.config.species = species
    state.config.breedsBySpecie = species.reduce((acc, specie) => {
      acc[specie.id!] = specie.species?.breeds || []
      return acc
    }, {} as TBreedsBySpecie)
    const specieId = state.groups?.[0]?.specieId || species[0].id
    actions['SET_PET_GROUP_SPECIE'](state, { specieId, groupIdx: 0 })
  },
  SET_TEMPER_TEST_DATA: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.temperTest = payload!.value
  },
  SET_BOOKING_DATES: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.startDate = payload!.value[0]
    state.endDate = payload!.value[1]
    state.isValid = checkFormValid(state)
  },
  SET_PET_SEARCH_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof state.groups?.[val.groupIdx]?.pets?.[val.petIdx]?.[val.fieldName as string] !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.groups[val.groupIdx].pets[val.petIdx][val.fieldName as string] = val!.value as string
    }
  },
  SET_PET_CART_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    const groupIdx = val.groupIdx as number
    const petIdx = val.petIdx as number
    const fieldName = val.fieldName as keyof TBookingPet
    const value = val.value as string

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof state.cart.groups?.[groupIdx]?.pets?.[petIdx]?.[fieldName] !== 'undefined') {
      if (fieldName === 'id') {
        // look for petAddons and update the pet id too
        state.cart.groups[groupIdx].petsAddOn?.forEach((petAddOn) => {
          petAddOn.pets.forEach((pet) => {
            if (pet.id === state.cart.groups[groupIdx].pets[petIdx][fieldName]) {
              pet.id = value
            }
          })
        })
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.cart.groups[groupIdx].pets[petIdx][fieldName] = value
    }
  },
  CLEAR_PET_SEARCH_PROFILE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.groups[val.groupIdx].pets[val.petIdx] = Object.assign({}, newPetRecord())
  },
  SET_PET_SEARCH_BREED_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    if (!val.breed || !val.breedId) {
      return
    }
    state.groups[val.groupIdx].pets[val.petIdx].breed = val.breed
    state.groups[val.groupIdx].pets[val.petIdx].breedId = val.breedId
    state.groups[val.groupIdx].pets[val.petIdx].breedInput = val.breed
  },
  CLEAR_ROOM: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    state.groups[val.groupIdx].pets = []
  },
  CLEAR_PET_SEARCH_BREED_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    state.groups[val.groupIdx].pets[val.petIdx].breed = ''
    state.groups[val.groupIdx].pets[val.petIdx].breedId = ''
    state.groups[val.groupIdx].pets[val.petIdx].breedInput = ''
  },
  SET_BREED_INPUT_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    state.groups[val.groupIdx].pets[val.petIdx].breedInput = val.value
  },
  ADD_PET_SEARCH_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const newPet = newPetRecord()
    newPet.room = parseInt(payload!.groupIdx, 10) + 1
    state.groups[payload!.groupIdx].pets = state.groups[payload!.groupIdx].pets.concat(newPet)
    state.isValid = false
  },
  ADD_ROOM_SEARCH_FIELD: (state: TBookingContext, _payload?: GenericSimpleBag) => {
    state.groups.push(newPetGroup())
    const totRooms = state.groups.length
    state.groups[totRooms - 1].pets[0].room = totRooms
    state.isValid = false
    actions['SET_PET_GROUP_SPECIE'](state, {
      specieId: state.config.species![0].id,
      groupIdx: state.groups.length - 1,
    })
  },
  ADD_EMPTY_ROOM_SEARCH_FIELD: (state: TBookingContext, _payload?: GenericSimpleBag) => {
    state.groups.push(newEmptyPetGroup())
    state.isValid = false
    actions['SET_PET_GROUP_SPECIE'](state, {
      specieId: state.config.species![0].id,
      groupIdx: state.groups.length - 1,
    })
  },
  REMOVE_PET_SEARCH_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.groups[payload!.groupIdx].pets.splice(payload!.petIdx, 1)
    validateForm(state)
  },
  REMOVE_ROOM_SEARCH_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.groups.splice(payload!.roomIdx, 1)
    reorderPets(state)
  },
  ADD_CONFIRMATION_GROUPS: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    const receipt: Types.ReceiptDto = val!.receipt
    state.confirmation.groups = []

    receipt?.pets?.forEach((petInReceipt) => {
      const selectedPet = Object.assign({}, newPetRecord(), {
        name: petInReceipt?.displayName,
      })
      const groupIdx = (receipt?.invoices || [])?.findIndex((invoice) => invoice?.pets?.includes(selectedPet.name))

      if (groupIdx === -1) {
        return
      }

      if (state.confirmation?.groups && !state.confirmation?.groups?.[groupIdx]) {
        state.confirmation.groups.push({ ...newPetGroup(), pets: [] })
      }
      state.confirmation?.groups?.[groupIdx]?.pets.push(Object.assign({}, selectedPet) as TBookingPet)
    })
  },
  SET_PET_GROUP_SPECIE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    if (state.groups.length === 0) {
      return
    }
    const spc = state.config!.species!.find((s) => s.id === payload!.specieId)
    const { totalWeight, petWeight, petCount } = getSpecieRestrictionThresholds(state, payload!.specieId)
    const group = state.groups[payload!.groupIdx]

    group.specieId = payload!.specieId
    group.specieName = spc?.species?.name || spc?.name || ''
    group.maxWeight = isEmpty(totalWeight?.max) ? undefined : parseInt(String(totalWeight?.max), 0)
    group.maxPets = isEmpty(petCount?.max) ? undefined : parseInt(String(petCount?.max), 0)
    group.maxPetWeight = isEmpty(petWeight?.max) ? undefined : parseInt(String(petWeight?.max), 0)
    group.isWeightRequired = isWeightRequiredForSpecie(state, payload!.specieId)
    group.pets.forEach((pet) => {
      group.errors = []
      pet.nameErrorMsg = undefined
      pet.breedErrorMsg = undefined
      pet.weightErrorMsg = undefined
    })
  },
  CHECK_ROOM_WEIGHT: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const group = state.groups[payload!.groupIdx]
    checkPetWeight(group)
  },
  ADD_ERROR_MSG: (state: TBookingContext, payload?: GenericSimpleBag) => {
    // if the message already exists, do not load it again
    if (state.groups[payload!.group].errors.findIndex((e) => e.kind === payload!.error.kind) < 0) {
      state.groups[payload!.group].errors.push(payload!.error)
    }
  },
  BLUR_FIELD_VALIDATION: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    const group = state.groups[val.groupIdx]
    const pet = group.pets[val.petIdx]
    // isFieldEmpty(group, pet, val.fieldName)
    removeFieldRequiredError(pet, val.fieldName)
  },
  SET_DONE_FORM_VALIDATION: (state: TBookingContext, _payload?: GenericSimpleBag) => {
    validateForm(state)
    if (!formHasErrors(state)) {
      state.searchBarOpen = false
    }
  },
  TOGGLE_SEARCH_BAR: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.searchBarOpen = !state.searchBarOpen
    if (payload?.action === 'close') {
      validateForm(state)
    }
  },
  SET_SEARCH_STATE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.currentState = payload!.value
    state.data.formHandler = payload!.formHandler
  },
  ADD_CART_GROUP: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const { petGroup, specieId, specieName, pets } = payload!
    state.cart.groups[petGroup] = {
      ...newCartGroup(),
      specieId: specieId,
      specieName: specieName,
      pets: pets,
    }
  },
  REMOVE_CART_GROUP: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const { groomOfferId, locationPetId } = payload!
    state.cart.groups = state.cart.groups.filter(
      (g) => !(g.offerSelected?.offer.id === groomOfferId && g.pets[0].id === locationPetId)
    )
  },
  SET_PET_GROUP: (state, payload?) => {
    state.currentGroup = payload!.value
  },
  SELECT_OFFER: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const { offer, petGroup, availability } = payload!
    const data = { offer, availability, qty: [1] }

    state.cart.groups[petGroup].offerSelected = data
    state.cart.groups[petGroup].daycareSelected = []
    state.data.offerAvailability = undefined

    checkTemperTestRequirement(state, petGroup)
    calculateCartPrice(state.serviceTypeName, state)
  },
  CLEAR_OFFERS_SELECTION: (state: TBookingContext) => {
    state.cart.groups.forEach((group) => {
      group.offerSelected = undefined
      group.daycareSelected = []
    })
    state.data.offerAvailability = undefined

    calculateCartPrice(state.serviceTypeName, state)
  },
  LOAD_OFFERS_SEARCH_RESULTS: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.offersSearchResults = (state.data.offersSearchResults || []).concat(payload!.value || [])
  },
  CLEAR_OFFERS_SEARCH_RESULTS: (state: TBookingContext) => {
    state.data.offersSearchResults = undefined
  },
  LOAD_OFFERS_ADDONS: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.offersAddOns = payload!.value
    if (payload!.searchState) {
      state.currentState = payload!.searchState
    }
  },
  LOAD_PET_ADDONS: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.petsAddOns = payload!.value
    state.data.grooms = payload!.grooms
    if (payload!.searchState) {
      state.currentState = payload!.searchState
    }
  },
  TOGGLE_ROOM_ADDON: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const groupIdx = payload!.groupIdx
    const offer: Types.BookingSearchClientDto = payload!.value
    state.cart.groups[groupIdx].roomsAddOn = state.cart.groups[groupIdx].roomsAddOn || []

    const idx = state.cart.groups[groupIdx].roomsAddOn!.findIndex((o) => o.offer!.id === offer.offer!.id)

    if (idx < 0) {
      state.cart.groups[groupIdx].roomsAddOn!.push({
        offer: offer.offer!,
        availability: offer.availabilityGroups![groupIdx],
      })
    } else {
      state.cart.groups[groupIdx].roomsAddOn!.splice(idx, 1)
    }
    calculateCartPrice(state.serviceTypeName, state)
  },
  TOGGLE_PET_ADDON: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const groupIdx = payload!.groupIdx
    const offer: Types.BookingSearchClientDto = payload!.value
    state.cart.groups[groupIdx].petsAddOn = state.cart.groups[groupIdx].petsAddOn || []

    const idx = state.cart.groups[groupIdx].petsAddOn!.findIndex((o) => o.offer!.id === offer.offer!.id)

    if (idx < 0) {
      state.cart.groups[groupIdx].petsAddOn!.push({
        offer: offer.offer!,
        availability: offer.availabilityGroups![groupIdx],
        pets: state.cart.groups[groupIdx].pets.map((pet, petIdx) => ({
          id: pet.id,
          qty: offer.availabilityGroups?.[groupIdx].availabilities?.[petIdx]?.available ? 1 : 0,
        })),
      })
    } else {
      state.cart.groups[groupIdx].petsAddOn!.splice(idx, 1)
    }
    calculateCartPrice(state.serviceTypeName, state)
  },
  TOGGLE_PET_ADDON_BY_DAY: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const groupIdx = payload!.groupIdx
    const offer: Types.BookingSearchByDaysClientDto = payload!.value
    state.cart.groups[groupIdx].petsAddOnDaycare = state.cart.groups[groupIdx].petsAddOnDaycare || []

    const selectedDays = selectedDaysSorted(state) || []
    const firstDay = selectedDays[0]

    const idx = state.cart.groups[groupIdx].petsAddOnDaycare!.findIndex((o) => o.offer!.id === offer.offer!.id)
    if (idx < 0) {
      state.cart.groups[groupIdx].petsAddOnDaycare!.push({
        offer: offer.offer!,
        pets: state.cart.groups[groupIdx].pets.map((pet, petIdx) => ({
          id: pet.id,
          dates: selectedDays.map((selectedDay) => {
            const dateSelectedIdx = millisecondsToDays(selectedDay - firstDay)
            const availability = offer.availabilityGroupsByDays![dateSelectedIdx]?.[0] || {}
            return {
              availability,
              qty: availability.availabilities?.[petIdx]?.available ? 1 : 0,
              dateSelected: selectedDay,
            }
          }),
        })),
      })
    } else {
      state.cart.groups[groupIdx].petsAddOnDaycare!.splice(idx, 1)
    }
    calculateCartPrice(state.serviceTypeName, state)
  },
  INCREASE_PET_ADDON: (state: TBookingContext, payload?: GenericSimpleBag) => {
    updatePetAddonQty(1, state, payload)
    calculateCartPrice(state.serviceTypeName, state)
  },
  DECREASE_PET_ADDON: (state: TBookingContext, payload?: GenericSimpleBag) => {
    updatePetAddonQty(-1, state, payload)
    calculateCartPrice(state.serviceTypeName, state)
  },
  REMOVE_PET_ADDON: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const { groupIdx, petAddOnId, petId } = payload!
    const petsAddOn = state.cart.groups[groupIdx].petsAddOn!
    const petAddOnIdx = petsAddOn.findIndex((p) => p.offer.id === petAddOnId)
    const petIdx = petsAddOn[petAddOnIdx].pets.findIndex((p) => p.id === petId)

    // if there is only one pet in the addon, remove the whole addon
    // otherwise, remove only the pet
    if (petsAddOn[petAddOnIdx].pets.length === 1) {
      petsAddOn.splice(petAddOnIdx, 1)
    } else {
      petsAddOn[petAddOnIdx].pets.splice(petIdx, 1)
    }
  },
  TOGGLE_GROOM_IN_PET_GROOM: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const groupIdx = payload!.groupIdx
    const petIdx = payload!.petIdx
    const offer: Types.OfferClientDto = payload!.value.offer
    state.cart.groups[groupIdx].grooms[petIdx] = state.cart.groups[groupIdx].grooms[petIdx] || []
    const petGrooms = state.cart.groups[groupIdx].grooms[petIdx]
    const idx = petGrooms.findIndex((g) => g.offer!.id === offer!.id)
    if (idx < 0) {
      state.cart.groups[groupIdx].grooms[petIdx].push({
        offer: offer!,
        availabilityGroups: payload!.value.availabilityGroups,
      })
    } else {
      state.cart.groups[groupIdx].grooms[petIdx].splice(idx, 1)
    }
    calculateCartPrice(state.serviceTypeName, state)
  },
  LOAD_OFFER_AVAILABILITY: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const availabilities: Types.AvailabilityGroupClientDto[][] = payload!.value
    const start = state.startDate!
    state.data.offerAvailability = availabilities.reduce((acc, avail, idx) => {
      const offerDate = startOfDay(add(start, { days: idx }))
      acc.push({
        id: offerDate.getTime(),
        date: offerDate,
        availability: avail[0]!,
      })

      return acc
    }, [] as TOfferAvailability[])
  },
  SET_OFFER_AVAILABILITY: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const availabilityByDays: Types.AvailabilityGroupClientDto[][] = payload!.availability
    const startDate = payload!.startDate

    const offerAvailability =
      availabilityByDays?.reduce((acc, avail, idx) => {
        const offerDate = startOfDay(add(startDate, { days: idx }))
        acc.push({
          id: offerDate.getTime(),
          date: offerDate,
          availability: avail[0]!,
        })
        return acc
      }, [] as TOfferAvailability[]) || []

    state.data.offerAvailability = state.data.offerAvailability || []
    state.data.offerAvailability = state.data.offerAvailability.concat(offerAvailability)
    state.data.offerAvailability.sort((a, b) => a.id - b.id)
  },
  SET_DAYCARE_DATES: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const selectedDate: Date = payload!.value
    const availability: TOfferAvailability = payload!.availability
    if (selectedDate === null) {
      state.cart.groups[0].daycareSelected = []
      state.cart.groups[0].petsAddOnDaycare = []
      calculateCartPrice(state.serviceTypeName, state)
      return
    }

    if (availability) {
      state.cart.groups[0].petsAddOnDaycare = []
      if (!state.cart.groups[0].daycareSelected) {
        state.cart.groups[0].daycareSelected = [availability]
        calculateCartPrice(state.serviceTypeName, state)
        return
      }

      const idx = state.cart.groups[0].daycareSelected.findIndex((dc) => dc.id === availability.id)
      if (idx < 0) {
        state.cart.groups[0].daycareSelected.push(availability)
      } else {
        state.cart.groups[0].daycareSelected.splice(idx, 1)
      }
    } else {
      const idx = state.cart.groups[0].daycareSelected?.findIndex((dc) => dc.id === selectedDate.getTime()) ?? -1
      if (idx < 0) {
        return
      }
      state.cart.groups[0].daycareSelected?.splice(idx, 1)
    }

    checkTemperTestRequirement(state, 0)
    calculateCartPrice(state.serviceTypeName, state)
  },
  SET_TEMPER_TEST_DATE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    if (!state.cart.temperTest) {
      state.cart.temperTest = {
        date: undefined,
        time: undefined,
        price: undefined,
      }
    }

    state.cart.temperTest!.date = payload!.value
    state.cart.temperTest!.time = undefined
    state.cart.temperTest!.timez = undefined
    state.cart.temperTest!.price = payload!.price
  },
  SET_TEMPER_TEST_TIME: (state: TBookingContext, payload?: GenericSimpleBag) => {
    if (!state.cart.temperTest) {
      state.cart.temperTest = {
        date: undefined,
        time: undefined,
        timez: undefined,
      }
    }

    state.cart.temperTest!.time = payload!.value
    state.cart.temperTest!.timez = payload!.timez
  },
  SET_TEMPER_TEST_OFFER: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.temperTestOffer = payload!.value
  },
  SET_PAYMENT_CONTACT: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const c = payload!.value

    state.cart.contact = {
      firstName: c.firstName,
      lastName: c.lastName,
      phone: c.phone,
      email: c.email,
      useMailingAddress: true,
      mailingAddress: {
        streetLine1: c.streetLine1,
        streetLine2: c.streetLine2,
        city: c.city,
        state: c.state,
        zipCode: c.zipCode,
        country: c.country,
      },
      billingAddress: {
        streetLine1: '',
        streetLine2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
    }
  },
  SET_PAYMENT_BILLING_ADDRESS: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const c = payload!.value
    if (state.cart.contact) {
      state.cart.contact.billingAddress = {
        streetLine1: c.streetLine1,
        streetLine2: c.streetLine2,
        city: c.city,
        state: c.state,
        zipCode: c.zipCode,
        country: c.country,
      }
    }
  },
  SET_RESERVATION_DATA: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.data.reservation = payload!.value
  },
  SET_PAYMENT_METHOD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.cart.payment.method = payload!.value
  },
  SET_CONTACT_FIELD: (state: TBookingContext, payload?: GenericSimpleBag) => {
    if (payload!.value.fieldName) {
      const { nspace, fieldName, value } = payload!.value || {}
      if (nspace) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.cart.contact[nspace][fieldName] = value
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.cart.contact[fieldName] = value
      }
    }
  },
  SET_CHECK_IN_OUT_FORM: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const field = payload!.value.fieldName as keyof TBookingCart
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.cart[field] = payload!.value.value as string
  },
  SET_VET_FORM: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const field = payload!.value.fieldName as keyof TCartVet
    state.cart.vet[field] = payload!.value.value as string
  },
  SET_ASYNC_ACTION: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.asyncAction = payload!.value
  },
  SET_FIRE_PURCHASE_EVENT: (state: TBookingContext, payload?: GenericSimpleBag) => {
    state.shouldFireGAPurchaseEvent = payload!.value
  },
  SET_CART_TOTAL: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const order: OrderClientDto = payload!.value
    state.cart.total = (order.invoices || []).reduce(
      (total, invoice) =>
        total +
        (invoice.items || []).reduce((sum, { price = 0, qty = 0, tax = 0 }) => sum + (price * qty + (tax || 0)), 0),
      0
    )
  },
  SET_PET_PROFILES: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const petProfiles = payload!.value as Types.PetProfileClientDto[]
    if (petProfiles.length > 0) {
      state.groups = Object.values(petProfilesToGroups(state, petProfiles))
      state.groups.forEach((group, groupIdx) => {
        actions['SET_PET_GROUP_SPECIE'](state, {
          specieId: group.specieId,
          groupIdx,
        })
      })
      actions['SET_DONE_FORM_VALIDATION'](state)
    } else {
      state.groups = []
      state.isValid = false
    }

    state.data.petProfiles = payload!.value as Types.PetProfileClientDto[]
  },

  SET_PET_PROFILE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const petProfile = payload!.value as Types.PetProfileClientDto
    let groupIdx = payload!.groupIdx

    const locationSpecie = speciePlatformToLocation(state, petProfile.breed?.speciesId || '')
    if (!locationSpecie) {
      return
    }

    if (groupIdx === -1) {
      const { totalWeight, petWeight, petCount } = getSpecieRestrictionThresholds(state, locationSpecie.id || '')
      const group = newPetGroup()
      group.specieId = locationSpecie.id!
      group.specieName = locationSpecie.species?.name || locationSpecie.name || ''
      group.maxWeight = isEmpty(totalWeight?.max) ? undefined : parseInt(String(totalWeight?.max), 0)
      group.maxPets = isEmpty(petCount?.max) ? undefined : parseInt(String(petCount?.max), 0)
      group.maxPetWeight = isEmpty(petWeight?.max) ? undefined : parseInt(String(petWeight?.max), 0)
      group.pets = []
      state.groups.push(group)
      groupIdx = state.groups.length - 1
    }

    state.groups[groupIdx].pets.push(petProfileToPetRecord(state, petProfile))
    state.data.petProfiles?.push(petProfile)
    validateForm(state)
  },

  UPDATE_PET_PROFILE: (state: TBookingContext, payload?: GenericSimpleBag) => {
    const val = payload!
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.groups[val.groupIdx].pets[val.petIdx] = petProfileToPetRecord(state, val!.value)
    const profileIdx = state.data.petProfiles?.findIndex((profile) => profile.id === val!.value.id)

    if (profileIdx !== -1 && state.data.petProfiles) {
      state.data.petProfiles[profileIdx!] = val!.value
    }
    validateForm(state)
  },

  SET_PET_IN_ROOM: (state, payload?) => {
    const pet = state.groups[payload!.groupIdx].pets[payload!.petIdx]
    pet.room = payload!.value
    if (payload!.value === 0) {
      pet.nameErrorMsg = undefined
      pet.breedErrorMsg = undefined
      pet.weightErrorMsg = undefined
    }
    state.isValid = checkFormValid(state)
  },

  REGROUP_PETS: (state, _) => {
    state.cart.groups = regroupPets(state)
  },
}

/* eslint-disable no-console */
export const bookingReducer = (state: TBookingContext, action: TReducerAction) => {
  const fx = actions[action.type]
  fx?.(state, action.payload)

  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(action.type)
    if (!fx) {
      console.log('%c action not found ', 'background: #FF0000; color: #fff')
    }
    console.log('start', original(state))
    console.log('payload', action.payload)
    console.log('after', current(state))
    console.groupEnd()
  }
}
/* eslint-enable no-console */

const resetState = (state: TBookingContext) => {
  state.cart = newCart()
  state.data = {
    ...newStateData(),
    petProfiles: state.data.petProfiles,
    temperTest: state.data.temperTest,
  }
  state.startDate = undefined
  state.endDate = undefined
  state.isValid = false

  if (state.config.species) {
    actions['SET_PET_GROUP_SPECIE'](state, {
      specieId: state.config.species?.[0]?.id || '',
      groupIdx: 0,
    })
  }
}
