import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_ASSET_OWNERSHIP_PATH, CLIENT_ASSET_OWNERSHIP_ID_PATH } from '../types/constants'
import { AssetClientDto, AssetUsageUpdateInput } from '../types/api'

export const ClientAssetService = {
  create: async (
    locationName: string,
    ownership: string,
    type: 'IMAGE' | 'FILE' | 'RECORD',
    file: File,
    usages: AssetUsageUpdateInput[] = []
  ) => {
    const data = new FormData()
    data.append('type', type)
    data.append('displayName', file.name)
    data.append('file', file, file.name)
    if (usages.length) {
      data.append('assetUsage', JSON.stringify({ create: usages }))
    }

    return requestGooseAPI<AssetClientDto>({
      url: getAPIPath(CLIENT_ASSET_OWNERSHIP_PATH, { locationId: locationName, ownership }),
      method: 'POST',
      data,
    })
  },

  remove: async (account: string, ownership: string, assetId: string) => {
    return requestGooseAPI<AssetClientDto>({
      url: getAPIPath(CLIENT_ASSET_OWNERSHIP_ID_PATH, { locationId: account, ownership, id: assetId }),
      method: 'DELETE',
    })
  },
}
