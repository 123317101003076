import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LOCATION_SPECIES_PATH } from '../types/constants'
import { APILocationSpeciesClientDtoResponse } from '../types/api'

export const ClientLocationSpeciesService = {
  getSpecies: async (locationName: string, includeBreeds = false): Promise<APILocationSpeciesClientDtoResponse> => {
    const includes: string[] = []
    if (includeBreeds) {
      includes.push('species.breeds')
    }

    return requestGooseAPI<APILocationSpeciesClientDtoResponse>({
      url: getAPIPath(CLIENT_LOCATION_SPECIES_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        includes,
      },
    })
  },
}
