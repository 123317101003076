import { Stack, Paper, Box, Typography, Link } from '@mui/material'
import { Spacer2 } from '../spacer'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { isHTML } from '@shared/common'

export type TCardEntry = {
  displayName?: string | null
  content?: string | string[] | null
  description?: string | null
  type?: string
} & Record<string, unknown>

export const FlexCardDisplay = ({ cardData }: { cardData: TCardEntry[] }) => {
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Paper sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
      <Stack spacing={2}>
        {cardData.map(({ displayName, content, type, description }, index) => (
          <Box key={index}>
            <Typography variant={index === 0 ? 'h5' : 'h6'}>{displayName}</Typography>
            {renderContent(content, type, description)}
            {index === 0 && <Spacer2 />}
          </Box>
        ))}
      </Stack>
    </Paper>
  )
}

function renderContent(content?: string | string[] | null, type?: string, description?: string | null) {
  if ((typeof content === 'string' && !content?.trim()) || !content?.length) {
    return null
  }

  switch (type) {
    case 'URL': {
      return (
        <Link href={content as string} target="_blank">
          {description || content || ''}
        </Link>
      )
    }
    default: {
      if (typeof content === 'string') {
        return (
          <Typography variant="body1" data-testid="flex-content">
            {content}
          </Typography>
        )
      }
      return (
        <Box data-testid="flex-content" mt={-2}>
          {content.map((row, idx) =>
            isHTML(row) ? (
              <div key={idx} dangerouslySetInnerHTML={{ __html: row }} />
            ) : (
              <Typography key={idx} variant="body1">
                {row}
              </Typography>
            )
          )}
        </Box>
      )
    }
  }
}
