import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { SxProps, Theme } from '@mui/material/styles'
import { BoardingIcon, DaycareIcon, OfferAddOnIcon, PetAddOnIcon } from '../asset'

import { TChipIconProps, TColorChipProps } from './types'

export const ColorChip = ({
  label,
  icon,
  bgColor,
  variant = 'filled',
  onDelete,
  textColor,
  size,
  sxOverrides = {},
}: TColorChipProps) => {
  let chipCss: SxProps<Theme> = {
    '& .MuiChip-label': {
      color: textColor || '',
      whiteSpace: 'normal',
    },
  }
  if (variant === 'filled') {
    chipCss.backgroundColor = bgColor || ''
  }
  if (size === 'medium') {
    chipCss = {
      ...chipCss,
      padding: '25px 10px',
      fontSize: '16px',
      lineHeight: '26px',
    }
  }
  if (size === 'small') {
    chipCss = {
      ...chipCss,
      padding: '3px 4px',
      height: 'auto',
      fontSize: '13px',
      lineHeight: '18px',
    }
  }

  return (
    <Chip
      icon={icon}
      sx={{
        ...chipCss,
        ...sxOverrides,
      }}
      label={label}
      variant={variant}
      onDelete={onDelete}
    />
  )
}

const ChipIcon = ({ Icon, bgColor, size }: TChipIconProps) => {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        borderRadius: '50%',
        width: size === 'medium' ? '30px' : '24px',
        height: size === 'medium' ? '30px' : '24px',
        marginLeft: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '1px',
        '& .MuiSvgIcon-root': {
          fill: 'white',
          width: size === 'medium' ? '24px' : '20px',
          height: size === 'medium' ? '24px' : '20px',
        },
      }}
    >
      {Icon}
    </Box>
  )
}

export const ChipBoarding = (props: TColorChipProps) => {
  if (!props.label) {
    return null
  }

  const iconSize = props.size === 'medium' ? '-1 -1 24 24' : '-2 -2 26 26'

  return (
    <ColorChip
      data-testid="color-chip-boarding"
      {...props}
      icon={<ChipIcon Icon={<BoardingIcon viewBox={iconSize} />} bgColor={props.iconColor} size={props.size} />}
    />
  )
}

export const ChipDaycare = (props: TColorChipProps) => {
  if (!props.label) {
    return null
  }

  const iconSize = props.size === 'medium' ? '-1 -1 30 30' : '-1 -1 28 28'

  return (
    <ColorChip
      data-testid="color-chip-boarding"
      {...props}
      icon={<ChipIcon Icon={<DaycareIcon viewBox={iconSize} />} bgColor={props.iconColor} size={props.size} />}
    />
  )
}

export const ChipPetAddOn = (props: TColorChipProps) => {
  if (!props.label) {
    return null
  }

  const iconSize = props.size === 'medium' ? '-1 -1 30 30' : '-1 -1 28 28'

  return (
    <ColorChip
      data-testid="color-chip-pet-addon"
      {...props}
      icon={<ChipIcon Icon={<PetAddOnIcon viewBox={iconSize} />} bgColor={props.iconColor} size={props.size} />}
    />
  )
}

export const ChipOffersAddOn = (props: TColorChipProps) => {
  if (!props.label) {
    return null
  }

  return (
    <ColorChip
      data-testid="color-chip-offer-addon"
      {...props}
      icon={
        <ChipIcon
          Icon={<OfferAddOnIcon viewBox="-2 -1 28 28" fontSize="small" />}
          bgColor={props.iconColor}
          size={props.size}
        />
      }
    />
  )
}
