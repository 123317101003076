import { add, addDays, format, startOfDay } from 'date-fns'
import { useMemo } from 'react'
import { UseQueryResult } from '@tanstack/react-query'

import { useBookingContext } from '@providers/booking'
import type { TBookingContext, TCartGroup } from '@providers/booking/types'
import { Types } from 'service-api'
import { useClientBookingReservation } from 'service-api/src/hooks'
import { completeISODate, toISODate, dayToJSDate } from 'components-ui/src/shared/dates'

const DATE_TIME_FORMAT = 'yyyy-MM-dd'

interface TPetAddons {
  id: string
  qty: number
  petIndex: number
}

export const useBookingReservation = (
  locationName: string
): UseQueryResult<Types.BookingReservationClientDto | null> => {
  const bookingCtx = useBookingContext()

  const bookingOrder: Types.CreateBookingOrderClientInput | undefined = useMemo(() => {
    if (typeof bookingCtx.state.startDate === 'undefined') {
      return
    }
    const serviceTypeName = bookingCtx.state.data.serviceType?.serviceType?.name || 'boarding'

    return {
      serviceTypeName: serviceTypeName!,
      pets: buildPets(bookingCtx.state.cart.groups),
      reservations: [
        ...buildReservation(bookingCtx.state, serviceTypeName!),
        ...buildGroomingReservation(bookingCtx.state),
        ...buildTemperTestReservations(bookingCtx.state),
      ],
    }
  }, [bookingCtx.state])

  return useClientBookingReservation(locationName, bookingOrder)
}

const buildPets = (groups: TCartGroup[]) =>
  groups
    .map((group) =>
      group.pets.map((p) => ({
        // if the pet.id length is less than 12, is a local generated id,
        // we don't need to send this one
        petId: p.id.length < 12 ? undefined : p.id,
        displayName: p.name,
        locationSpeciesId: group.specieId,
        breedId: p.breedId,
        weight: p.weight ? parseFloat(p.weight) : undefined,
        birthdate: p.birthdate,
        sex: p.sex,
        altered: p.altered,
      }))
    )
    .flat()

const getDateRanges = (state: TBookingContext) => {
  if (!state.startDate) {
    return []
  }
  if (state.serviceTypeName === 'daycare') {
    return state.cart.groups
      .map((group) => {
        return group.daycareSelected!.map(({ date }) => ({
          startDate: format(date, DATE_TIME_FORMAT),
          endDate: format(add(date, { days: 1 }), DATE_TIME_FORMAT),
        }))
      })
      .flat()
  }
  return [
    {
      startDate: format(state.startDate!, DATE_TIME_FORMAT),
      endDate: format(state.endDate!, DATE_TIME_FORMAT),
    },
  ]
}

export const buildDaycareAddons = (startDate: string, group: TCartGroup, petIndexes: number[]) => {
  const daycareDate = startOfDay(Date.parse(completeISODate(startDate))).getTime()
  return (
    group.petsAddOnDaycare
      ?.map((addOn) => {
        return addOn.pets.reduce((acc, pet) => {
          pet.dates.forEach((date) => {
            if (date.dateSelected == daycareDate && date.qty > 0) {
              acc.push({
                id: addOn.offer.id!,
                qty: date.qty,
                petIndex: petIndexes?.[group.pets.findIndex((p) => p.id === pet.id)],
              })
            }
          })
          return acc
        }, [] as TPetAddons[]) as TPetAddons[]
      })
      ?.flat() || ([] as TPetAddons[])
  )
}

export const buildReservation = (state: TBookingContext, serviceTypeName: string) => {
  const dateRanges = getDateRanges(state)
  return dateRanges
    .map((dr) => {
      let counter = 0
      return state.cart.groups.map((group) => {
        const groupPets = group.pets
        const petLength = groupPets.length
        const petIndexes = Array.from<number, number>(new Array(petLength).fill(0), (v, i) => v + i + counter)
        counter += petLength

        const petAddOns: TPetAddons[] =
          group.petsAddOn
            ?.map((addOn) =>
              addOn.pets.map((pet) => ({
                id: addOn.offer.id!,
                qty: pet.qty,
                petIndex: petIndexes?.[groupPets.findIndex((p) => p.id === pet.id)],
              }))
            )
            ?.flat() || []

        const petDaycareAddOns =
          serviceTypeName === 'daycare' ? buildDaycareAddons(dr.startDate, group, petIndexes) : []

        return {
          serviceTypeName,
          petIndexes,
          startDate: dr.startDate,
          endDate: dr.endDate,
          primaryOffers: [
            {
              id: group.offerSelected!.offer.id!,
              qty: 1,
              serviceOffers: group.roomsAddOn?.map((addOn) => ({ id: addOn.offer.id!, qty: 1 })) || [],
              petOffers: petAddOns.concat(petDaycareAddOns).flat(),
            },
          ],
        }
      })
    })
    .flat()
}
export const buildGroomingReservation = (state: TBookingContext) => {
  const dateRanges = getDateRanges(state)
  // for daycare, we'll use first day, and for boarding, we'll use last date of the date range. Either way we only want the first dateRange object
  const dr = dateRanges[0]
  let counter = 0
  // invoice for each petGroom inside grooms on each group
  return state.cart.groups
    .map((group) => {
      const petLength = group.pets.length
      const obj = group.grooms
        .map((petGrooms, petIdx) => {
          const primaryOffersPerPet: { id: string; qty: number }[] = []
          petGrooms.map((groom) => {
            primaryOffersPerPet.push({ id: groom.offer.id!, qty: 1 })
          })

          return (
            primaryOffersPerPet.length > 0 && {
              serviceTypeName: 'grooming',
              // add up all pets so far to get current pet's index
              petIndexes: [counter + petIdx],
              startDate: state.serviceTypeName === 'daycare' ? dr.startDate : dr.endDate,
              endDate:
                state.serviceTypeName === 'daycare' ? dr.endDate : toISODate(addDays(dayToJSDate(dr.endDate), 1)),
              primaryOffers: primaryOffersPerPet,
            }
          )
        })
        .filter((i) => i !== false)
        .flat()
      counter += petLength
      return obj
    })
    .flat()
}

// We can not filter group by temperTest because we need to keep the index to build
// the petIndex, we filter at the end by null
export const buildTemperTestReservations = (state: TBookingContext) => {
  let ctr = 0
  const petIndexes: number[] = state.cart.groups.reduce((acc, group) => {
    group.pets.forEach((pet) => {
      if (!pet.temperTested) {
        acc.push(ctr)
      }
      ctr++
    })
    return acc
  }, [] as number[])

  if (petIndexes.length === 0) {
    return []
  }

  const temperTest = state.cart.temperTest!
  return [
    {
      serviceTypeName: state.data.temperTest?.serviceType?.name,
      petIndexes,
      startDate: format(temperTest.date!, DATE_TIME_FORMAT),
      endDate: format(add(temperTest.date!, { days: 1 }), DATE_TIME_FORMAT),
      startTime: temperTest.timez!,
      primaryOffers: [
        {
          id: state.data.temperTestOffer?.id || '',
          qty: 1,
          serviceOffers: [],
          petOffers: [],
        },
      ],
    },
  ]
}
