import { useState, useEffect } from 'react'

export const useUserStatus = () => {
  const userInStorage = localStorage.getItem('BookingUserState')
  const [userData] = useState(userInStorage)
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(userInStorage))

  useEffect(() => {
    setIsLoggedIn(Boolean(userInStorage))
  }, [userInStorage])

  return { userData, isLoggedIn }
}
