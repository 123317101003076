import { createContext, useContext } from 'react'

import type { TDrawersContext, TDrawersContextDef } from './types'

export const initialState: TDrawersContext = {
  openDrawerId: null,
  isOpenDrawerDirty: false,
}

export const DrawersContext = createContext<TDrawersContextDef | null>(null)

export const useDrawersContext = () => {
  const ctx = useContext(DrawersContext)
  if (ctx === null) {
    throw new Error('useDrawersContext must be used within a DrawersProvider')
  }
  return ctx
}
