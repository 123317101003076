import { Box, Divider, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Fragment, useEffect, useState } from 'react'

import { useBookingContext } from '@providers/booking'
import { TBookingPet } from '@providers/booking/types'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { i18WithDefault as t } from '@shared/locale'
import { GroomingIcon, Spacer1, Spacer3 } from 'components-ui'
import { GroomingSelector } from './grooming_selector'

export const Grooms = ({ currentTabIndex }: { currentTabIndex: number }) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const [petsInGroup, setPetsInGroup] = useState<TBookingPet[]>([])
  useEffect(() => {
    const petsFromGroups: TBookingPet[] = []
    bookingCtx.state.cart.groups[currentTabIndex].pets?.map((pet): void => {
      if (!petsFromGroups.some((p) => p.id === pet.id)) petsFromGroups.push({ ...pet })
      setPetsInGroup(petsFromGroups)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabIndex])

  const searchResult = bookingCtx.state.data.grooms

  return (
    <>
      {searchResult && searchResult.length !== 0 && (
        <>
          <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
            <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <GroomingIcon />
                <Typography variant="h5">{t('searchPetAddOns.grooming')}</Typography>
              </Stack>
              <Spacer1 />
              <Typography variant="body1">{t('searchPetAddOns.summary')}</Typography>

              <Divider orientation="horizontal" sx={{ my: 2, maxWidth: '640px', marginX: 'auto' }} />
              {searchResult.map((o, idx) => {
                const isAvailableForAnyPet = o.availabilityGroups?.some(
                  (ag) => ag.availabilities?.some((a) => a.available)
                )
                return (
                  <Fragment key={o.offer!.id!}>
                    {isAvailableForAnyPet && (
                      <>
                        <GroomingSelector groom={o} groupIdx={currentTabIndex} petsInGroup={petsInGroup} />
                        {idx < searchResult.length - 1 && (
                          <Divider orientation="horizontal" sx={{ my: 2, maxWidth: '640px', marginX: 'auto' }} />
                        )}
                      </>
                    )}
                  </Fragment>
                )
              })}
            </Paper>
          </Box>
          <Spacer3 />
        </>
      )}
    </>
  )
}
