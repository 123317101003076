import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { buildSearchParams } from '@shared/header_navigation'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { OfferCard, Spacer, Spacer3 } from 'components-ui'
import { Hooks, Types } from 'service-api'
import { SearchResultsSkeleton } from './search_result_skeleton'
import { sortOffers } from './helper'
import { useSessionContext } from '@providers/session'
import { isUserLoggedIn } from '@providers/session/helpers'

export const SearchOffersByDay = () => {
  const { accountName = '', serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const { navigateRoute } = useRoutes()
  const { state: s } = bookingCtx
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const searchParams = useMemo(() => buildSearchParams(bookingCtx), [bookingCtx])
  const searchResult = Hooks.useClientBookingSearchByDays(accountName, serviceTypeName, {
    ...searchParams,
    offersType: 'PRIMARY',
  })

  useEffect(() => {
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'searchResults' } })
  })

  useEffect(() => {
    if (searchResult.isSuccess) {
      bookingCtx.dispatch({ type: 'LOAD_OFFERS_SEARCH_RESULTS', payload: { value: searchResult.data?.results || [] } })
    }
  }, [searchResult.isSuccess, searchResult.data?.results])

  // redirect to home if the state has invalid data
  useEffect(() => {
    if (!s.startDate) {
      navigateRoute('searchServiceType', { serviceTypeName })
    }
  })

  const onSelectOffer = useCallback(
    async (offer: Types.OfferClientDto, availability: Types.AvailabilityGroupClientDto, petGroup: number) => {
      bookingCtx.dispatch({ type: 'SELECT_OFFER', payload: { offer, availability, petGroup } })
      if (!isUserLoggedIn(sessionCtx.state.user)) {
        navigateRoute('loginCheck', { accountName, serviceTypeName })
      } else {
        navigateRoute('datesSelection', { serviceTypeName })
      }
    },
    [bookingCtx, navigateRoute, serviceTypeName]
  )

  const totalPets = useMemo(
    () => bookingCtx.state.cart.groups.reduce((acc, g) => acc + g.pets.length, 0),
    [bookingCtx.state.cart.groups]
  )

  const sortedOffers = useMemo(() => {
    if (searchResult.isSuccess) {
      return sortOffers(searchResult.data?.results, serviceTypeName)
    } else {
      return []
    }
  }, [searchResult.data?.results, searchResult.isSuccess])

  const renderResult = () =>
    sortedOffers.map((result) => (
      <Fragment key={result.offer!.id}>
        <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
          <OfferCard
            key={result.offer!.id}
            searchResult={result}
            petGroup={0} // for daycare there is always 1 pet group
            onSelectOffer={onSelectOffer}
            totalPets={totalPets}
            serviceTypeName={serviceTypeName}
            specieTypeName={s.cart.groups[0].specieName}
            selected={bookingCtx.state.cart.groups[0]?.offerSelected?.offer?.id === result.offer?.id}
          />
        </Paper>
        <Spacer3 />
      </Fragment>
    ))

  const pageTitle =
    bookingCtx.state.serviceTypeName === 'boarding' ? 'searchResults.title.boarding' : 'searchResults.title.daycare'

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <Typography variant="h1" sx={{ px: 2 }}>
        {t(pageTitle)}
      </Typography>
      <Spacer3 />
      {searchResult.isLoading ? <SearchResultsSkeleton /> : renderResult()}
    </Box>
  )
}
