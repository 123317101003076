import { LocalizationProvider, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CustomPickersDay } from '@components/multiple_date_picker/custom_picker_day'
import { useBookingContext } from '@providers/booking'
import { buildPetGroups } from '@shared/booking_groups'
import { buildErrorMessage } from '@shared/error'
import { maximumSelectableDate } from '@shared/service_types'
import { DayCalendarSkeleton } from 'components-ui/src/date_picker_skeleton/date_picker_skeleton'
import { Services, Types } from 'service-api'
import { getDateRange, isDataInCache } from './helper'
import { captureError } from 'service-api/src/shared'

export const DatePicker = () => {
  const bookingCtx = useBookingContext()
  const { state } = bookingCtx
  const serviceTypeName = state.serviceTypeName
  const { accountName: locationName = '' } = useParams()
  const endDate = maximumSelectableDate(state.startDate!, state)
  const isFetching = useRef<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const [requestDates, setRequestDates] = useState<Date[]>(() => {
    return getDateRange(state.startDate!, state.startDate!, endDate)
  })

  const searchParams = useMemo(() => {
    return {
      petGroups: buildPetGroups(state, false),
      offersType: 'PRIMARY',
      offerName: state.cart.groups[0].offerSelected!.offer!.name,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cart.groups[0].offerSelected!.offer!.name])

  useEffect(() => {
    if (isFetching.current) {
      return
    }

    if (isDataInCache(requestDates[0], requestDates[1], state)) {
      setIsLoading(false)
      return
    }

    isFetching.current = true
    ;(async () => {
      const params = {
        ...searchParams,
        startDate: format(requestDates[0], 'yyyy-MM-dd'),
        endDate: format(requestDates[1], 'yyyy-MM-dd'),
      } as unknown as Types.TBookingSearchOptions
      try {
        const response = await Services.ClientBookingSearchService.searchByDays(locationName, serviceTypeName, params)
        bookingCtx.dispatch({
          type: 'SET_OFFER_AVAILABILITY',
          payload: {
            availability: response.results[0]?.availabilityGroupsByDays,
            startDate: requestDates[0],
          },
        })
        bookingCtx.dispatch({ type: 'LOAD_OFFERS_SEARCH_RESULTS', payload: { value: response.results || [] } })

        setIsLoading(false)
        isFetching.current = false
      } catch (error) {
        enqueueSnackbar(buildErrorMessage(error), { variant: 'error' })
        captureError(error as Error)
        isFetching.current = false
        setIsLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationName, requestDates, serviceTypeName])

  const shouldDisableDate = (day: Date) => {
    const idx = state.data.offerAvailability?.findIndex(({ id }) => id === day.getTime()) ?? -1
    if (idx < 0) {
      return true
    }
    return !state.data.offerAvailability?.[idx]?.availability?.availabilities?.[0]?.available
  }

  const onChangeDateValue = (selectedDate: Date | null) => {
    if (!selectedDate) {
      return
    }

    const date = startOfDay(selectedDate)
    const dateTime = date.getTime()
    const idx = state.data.offerAvailability?.findIndex(({ id }) => id === dateTime) ?? -1
    if (idx < 0) {
      return
    }

    bookingCtx.dispatch({
      type: 'SET_DAYCARE_DATES',
      payload: { value: date, availability: state.data.offerAvailability?.[idx] },
    })
  }

  const dates = useMemo(
    () => state.cart.groups[0].daycareSelected?.map(({ date }) => date) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.cart.groups[0].daycareSelected]
  )

  const renderPickerDay = useCallback(
    (date: Date, _selectedDates: Date[], pickersDayProps: PickersDayProps<Date>) => {
      const idx = dates.findIndex((dc) => dc.getTime() === date.getTime()) ?? -1
      return <CustomPickersDay {...pickersDayProps} selected={idx >= 0} />
    },
    [dates]
  )

  const handleMonthChange = (date: Date) => {
    const [fromDate, toDate] = getDateRange(date, state.startDate!, endDate)
    setRequestDates([fromDate, toDate])
    if (isDataInCache(fromDate, toDate, state)) {
      return
    }
    setIsLoading(true)
  }

  const monthButtons = isLoading
    ? {
        LeftArrowButton: () => <></>,
        RightArrowButton: () => <></>,
      }
    : {}

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        showToolbar={false}
        value={dates}
        minDate={state.startDate}
        maxDate={endDate}
        shouldDisableDate={shouldDisableDate}
        onChange={onChangeDateValue}
        renderDay={renderPickerDay}
        renderInput={(_props) => <></>}
        views={['day']}
        loading={isLoading}
        renderLoading={() => <DayCalendarSkeleton />}
        onMonthChange={handleMonthChange}
        components={monthButtons}
      />
    </LocalizationProvider>
  )
}
