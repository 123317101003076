import { useTheme } from '@mui/material'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useMemo } from 'react'

import { Autocomplete } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { Spacer3, TextInputNumber, TextInputPhone } from 'components-ui'
import { TState, states } from 'service-api/src/shared/states'
import { TContactInformationProps } from './details_policies.d'

export const ContactInformation = ({ formik }: TContactInformationProps) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ev: React.ChangeEvent<any>) => {
      const { name, id, dataset, value } = ev.target
      const nspace = name === 'state' ? 'mailingAddress' : dataset?.nspace
      bookingCtx.dispatch({
        type: 'SET_CONTACT_FIELD',
        payload: {
          value: {
            nspace,
            fieldName: name || id,
            value,
          },
        },
      })

      formik.handleChange(ev)
    },
    [bookingCtx, formik]
  )

  const onStateSelect = useCallback(
    (value: string) => {
      bookingCtx.dispatch({
        type: 'SET_CONTACT_FIELD',
        payload: {
          value: {
            nspace: 'mailingAddress',
            fieldName: 'state',
            value,
          },
        },
      })
      formik.setFieldValue('state', value)
    },
    [bookingCtx, formik]
  )

  const statesByCode: { [key: string]: TState } = useMemo(
    () =>
      states.reduce(
        (res, state) => ({
          ...res,
          [state.code]: state,
        }),
        {}
      ),
    []
  )

  return (
    <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
      <Typography variant="h5">{t('detailsPolicies.contactInformation')}</Typography>
      <Spacer3 />

      <Stack direction={{ xs: 'column', sm: 'row' }}>
        <TextField
          id="firstName"
          label={t('contactForm.firstName')}
          variant="outlined"
          size="small"
          sx={{ flexBasis: 0, flexGrow: 1, mr: { xs: 0, sm: 1 } }}
          value={formik.values.firstName}
          onChange={onChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          id="lastName"
          label={t('contactForm.lastName')}
          variant="outlined"
          size="small"
          sx={{ flexBasis: 0, flexGrow: 1, mt: { xs: 2, sm: 0 } }}
          value={formik.values.lastName}
          onChange={onChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mt: 2 }}>
        <TextField
          id="email"
          label={t('contactForm.email')}
          variant="outlined"
          size="small"
          sx={{ flexBasis: 0, flexGrow: 1, mr: { xs: 0, sm: 1 } }}
          value={formik.values.email}
          onChange={onChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          disabled
        />
        <TextInputPhone
          id="phone"
          name="phone"
          label={t('contactForm.phone')}
          size="small"
          variant="outlined"
          sx={{ flexBasis: 0, flexGrow: 1, mt: { xs: 2, sm: 0 } }}
          value={formik.values.phone}
          onChange={onChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </Stack>

      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        {t('contactForm.mailingAddress')}
      </Typography>

      <TextField
        id="streetLine1"
        label={t('contactForm.streetLine1')}
        variant="outlined"
        size="small"
        fullWidth
        value={formik.values.streetLine1}
        onChange={onChange}
        error={formik.touched.streetLine1 && Boolean(formik.errors.streetLine1)}
        helperText={formik.touched.streetLine1 && formik.errors.streetLine1}
        inputProps={{ 'data-nspace': 'mailingAddress' }}
      />
      <TextField
        id="streetLine2"
        label={t('contactForm.streetLine2')}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ mt: 2 }}
        value={formik.values.streetLine2}
        onChange={onChange}
        error={formik.touched.streetLine2 && Boolean(formik.errors.streetLine2)}
        helperText={formik.touched.streetLine2 && formik.errors.streetLine2}
        inputProps={{ 'data-nspace': 'mailingAddress' }}
      />

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={2}>
        <TextField
          id="city"
          label={t('contactForm.city')}
          variant="outlined"
          size="small"
          sx={{ flexBasis: 0, flexGrow: 0, minWidth: '120px' }}
          value={formik.values.city}
          onChange={onChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          inputProps={{ 'data-nspace': 'mailingAddress' }}
        />
        <Autocomplete
          id="state"
          sx={{ flexBasis: 0, flexGrow: 1 }}
          includeInputInList
          size="small"
          clearIcon={null}
          options={states}
          value={statesByCode?.[formik.values.state] ?? null}
          onChange={(_, value) => onStateSelect(value?.code || '')}
          onBlur={formik.handleBlur}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          renderInput={(params) => (
            <TextField
              {...params}
              id="state"
              name="state"
              label={t('contactForm.state')}
              variant="outlined"
              fullWidth
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              inputProps={{
                ...params.inputProps,
                'data-nspace': 'mailingAddress',
              }}
              data-nspace="mailingAddress"
            />
          )}
        />
        <TextInputNumber
          id="zipCode"
          label={t('contactForm.zipCode')}
          variant="outlined"
          size="small"
          allowLeadingZeros={true}
          sx={{ flexBasis: 0, flexGrow: 0, minWidth: '120px' }}
          value={formik.values.zipCode}
          onChange={onChange}
          error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
          helperText={formik.touched.zipCode && formik.errors.zipCode}
          inputProps={{ 'data-nspace': 'mailingAddress' }}
        />
      </Stack>
      <TextField
        id="country"
        name="country"
        label={t('contactForm.country')}
        variant="outlined"
        size="small"
        select
        fullWidth
        sx={{ mt: 2 }}
        value={formik.values.country}
        onChange={onChange}
        error={formik.touched.country && Boolean(formik.errors.country)}
        helperText={formik.touched.country && formik.errors.country}
        inputProps={{ 'data-nspace': 'mailingAddress' }}
      >
        <MenuItem value="US">{t('global.select.USA')}</MenuItem>
      </TextField>
    </Paper>
  )
}
