import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LINK_ORDER_ID_PATH, CLIENT_ORDER_ID_PATH } from '../types/constants'
import {
  BookingReservationClientDto,
  CustomOrderClientDto,
  OrderClientDto,
  UpdateBookingOrderClientInput,
} from '../types/api'

const baseIncludes = [
  'invoices.location',
  'invoices.period',
  'invoices.transactions',
  'invoices.locationServiceType.serviceType',
  'owner.userProfile',
  'payments.transactions',
  'primaryServiceType.serviceType',
  'primaryServiceType.locationBookingConf',
  'pets.pet.petProfile',
  'pets.breed',
  'pets.locationPetProfile.profileImage',
  'pets.attachments.asset',
  'userData',
  'invoices.items.offer.locationServiceType',
  'invoices.items.locationServiceType.serviceType',
  'invoices.items.petRelations.invoicePet',
  'invoices.items.locationSpecies.species',
]

export const ClientOrderService = {
  getOrder: async (locationName: string, orderId: string): Promise<OrderClientDto> => {
    return requestGooseAPI<OrderClientDto>({
      url: getAPIPath(CLIENT_ORDER_ID_PATH, { locationId: locationName, id: orderId }),
      method: 'GET',
      params: {
        includes: baseIncludes,
      },
    })
  },

  update: (
    orderId: string,
    locationName: string,
    data: UpdateBookingOrderClientInput
  ): Promise<BookingReservationClientDto> => {
    return requestGooseAPI<BookingReservationClientDto>({
      url: getAPIPath(CLIENT_ORDER_ID_PATH, { locationId: locationName, id: orderId }),
      method: 'PUT',
      data,
      params: {
        includes: baseIncludes,
      },
    })
  },

  linkOrderToUser: (orderId: string, locationName: string): Promise<CustomOrderClientDto> => {
    return requestGooseAPI<CustomOrderClientDto>({
      url: getAPIPath(CLIENT_LINK_ORDER_ID_PATH, { locationId: locationName, id: orderId }),
      method: 'PUT',
      params: {
        includes: baseIncludes,
      },
    })
  },
}
