import { useMemo } from 'react'
import { Navigate, Params, To, useParams } from 'react-router-dom'

type RedirectWithParamsProps = {
  to: To | ((params: Readonly<Params<string>>) => To)
  replace?: boolean
}
export const RedirectWithParams = ({ to, replace = true }: RedirectWithParamsProps) => {
  const params = useParams()
  const newRoute = useMemo(() => (typeof to === 'function' ? to(params) : to), [to, params])
  return <Navigate to={newRoute} replace={replace} />
}
