import { Button, Typography } from '@mui/material'
import { NotFoundIcon } from './NotFoundIcon'
import { i18WithDefault as t } from '@shared/locale'

export const NotFound = () => {
  return (
    <NotFoundIcon>
      <Typography variant="h4">{t('global.notFound')}</Typography>
      <Button variant="contained" color="success" onClick={() => location.reload()}>
        {t('global.notFound.tryAgain')}
      </Button>
    </NotFoundIcon>
  )
}
