import * as yup from 'yup'
import { i18WithDefault as t } from '@shared/locale'
import { Types } from 'service-api'
import {
  isCheckInTimeEnabled,
  isCheckInTimeRequired,
  isCheckOutTimeEnabled,
  isCheckOutTimeRequired,
  isVetEnabled,
  isVetRequired,
} from 'components-ui/src/shared'
import { TBookingCart } from '@providers/booking/types'

export const detailsPoliciesSchema = (
  reservation: Types.BookingReservationClientDto,
  inputCardGroups: Types.FlexBookingConfClientDto[][]
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = {
    // contact information
    firstName: yup.string().max(200).required().label(t('contactForm.firstName')),
    lastName: yup.string().max(200).required().label(t('contactForm.lastName')),
    email: yup.string().email().required().label(t('contactForm.email')),
    phone: yup.string().length(10).required().label(t('contactForm.phone')),
    streetLine1: yup.string().max(200).required().label(t('contactForm.streetLine1')),
    streetLine2: yup.string().max(200).label(t('contactForm.streetLine2')),
    city: yup.string().max(50).required(t('global.label.required')).label(t('contactForm.city')),
    state: yup.string().max(2).required(t('global.label.required')).label(t('contactForm.state')),
    zipCode: yup.string().max(5).required(t('global.label.required')).label(t('contactForm.zipCode')),
    country: yup.string().length(2).required().label(t('contactForm.country')),
    // special instructions
    careDetails: yup.string().max(200).label(t('specialInstructions.careDetails')),
  }

  if (isVetEnabled(reservation.locationBookingConf!)) {
    schema.vetName = yup.string().max(200).label(t('veterinarianInfoForm.vetName'))
    schema.vetPhone = yup.string().length(10).label(t('veterinarianInfoForm.vetPhone'))
    if (isVetRequired(reservation.locationBookingConf!)) {
      schema.vetName = schema.vetName.required()
      schema.vetPhone = schema.vetPhone.required()
    } else {
      schema.vetName = schema.vetName.when('vetPhone', {
        is: (phone: string) => !!phone,
        then: (field: yup.StringSchema) => field.required(),
        otherwise: (field: yup.StringSchema) => field.optional(),
      })
      schema.vetPhone = schema.vetPhone.when('vetName', {
        is: (name: string) => !!name,
        then: (field: yup.StringSchema) => field.required(),
        otherwise: (field: yup.StringSchema) => field.optional(),
      })
    }
  }

  if (isCheckInTimeEnabled(reservation.locationBookingConf!)) {
    schema.checkInTime = yup.string().label(t('checkInOutForm.checkIn'))
    if (isCheckInTimeRequired(reservation.locationBookingConf!)) {
      schema.checkInTime = schema.checkInTime.required()
    }
  }
  if (isCheckOutTimeEnabled(reservation.locationBookingConf!)) {
    schema.checkOutTime = yup.string().label(t('checkInOutForm.checkOut'))

    if (isCheckOutTimeRequired(reservation.locationBookingConf!)) {
      schema.checkOutTime = schema.checkOutTime.required()
    }
  }

  inputCardGroups.forEach((groups) => {
    groups.forEach((field) => {
      const fieldName = field.name!
      schema[fieldName] = yup.string()
      if (field.fieldRequired) {
        schema[fieldName] = schema[fieldName].required(t('detailsPolicies.requiredField'))
      }
    })
  })

  return yup.object().shape(schema, [['vetName', 'vetPhone']])
}

export const setupInitialValues = (cart?: TBookingCart, userProfile?: Types.UserClientDto) => {
  const profile = userProfile?.userProfile?.[0]
  return {
    // contact information
    firstName: cart?.contact?.firstName || profile?.firstName || '',
    lastName: cart?.contact?.lastName || profile?.lastName || '',
    email: cart?.contact?.email || userProfile?.email || '',
    phone: cart?.contact?.phone || profile?.phone || '',
    streetLine1: cart?.contact?.mailingAddress?.streetLine1 || profile?.streetAddress || '',
    streetLine2: cart?.contact?.mailingAddress?.streetLine2 || profile?.streetAddress2 || '',
    city: cart?.contact?.mailingAddress?.city || profile?.city || '',
    state: cart?.contact?.mailingAddress?.state || profile?.state || '',
    zipCode: cart?.contact?.mailingAddress?.zipCode || profile?.zipCode || '',
    country: cart?.contact?.mailingAddress?.country || 'US',
    // checkIn - checkOut
    checkInTime: cart?.checkInTime || '',
    checkOutTime: cart?.checkOutTime || '',
    // veterinarian
    vetName: cart?.vet.name || '',
    vetPhone: cart?.vet.phone || '',
    // vaccination records
    // special instructions
    careDetails: '',
  }
}

export const getProfileValues = (formValues: Record<string, string>) => {
  return {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    // email: formValues.email,
    phone: formValues.phone,
    streetAddress: formValues.streetLine1,
    streetAddress2: formValues.streetLine2 || null,
    city: formValues.city,
    state: formValues.state,
    zipCode: formValues.zipCode,
    // country: formValues.country,
  }
}
