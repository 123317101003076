import { Box, Paper, Popover, useMediaQuery, useTheme } from '@mui/material'
import { MouseEvent, ReactNode, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { speciePlatformToLocation } from '@providers/booking/helper'
import { TBookingPet } from '@providers/booking/types'
import { useClientPetFamilies } from 'service-api/src/hooks'
import { PetAdminDto, PetProfileClientDto, SpeciesAdminDto } from 'service-api/src/types'
import { PetForm } from '../../pet_form/pet_form'
import { PetSelector } from './pet_selector'

export const PetSelect = ({ children }: { children: ReactNode }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [addNewPet, setAddNewPet] = useState<boolean>(false)
  const [editPet, setEditPet] = useState<boolean>(false)
  const [selectedPet, setSelectedPet] = useState<PetProfileClientDto | null>(null)
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))
  const { accountName = '' } = useParams()
  const [editGroups, setEditGroups] = useState<{
    groupIdx: number
    petIdx: number
  } | null>(null)

  const petFamily = useClientPetFamilies(accountName)

  const bookingCtx = useBookingContext()

  const onClick = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      bookingCtx.dispatch({
        type: 'TOGGLE_SEARCH_BAR',
        payload: { action: 'open' },
      })
      setAnchorEl(ev.currentTarget)
    },
    [bookingCtx]
  )

  const handleCancel = useCallback(() => {
    setAddNewPet(false)
    setEditPet(false)
    setSelectedPet(null)
  }, [])

  const closeModal = useCallback(() => {
    bookingCtx.dispatch({ type: 'SET_DONE_FORM_VALIDATION' })
    bookingCtx.dispatch({
      type: 'TOGGLE_SEARCH_BAR',
      payload: { action: 'close' },
    })
    setAnchorEl(null)
    handleCancel()
  }, [bookingCtx, handleCancel])

  const petGroups = bookingCtx.state.groups
  const modalSize = () => (addNewPet ? '444px' : '600px')

  const handleSaveUpdatePet = useCallback(
    (pet: PetAdminDto) => {
      const { petProfile } = pet
      if (petProfile && petProfile?.length > 0) {
        if (editPet && editGroups) {
          const { groupIdx, petIdx } = editGroups
          bookingCtx.dispatch({
            type: 'UPDATE_PET_PROFILE',
            payload: {
              value: { ...petProfile[0], pet: { ...pet } },
              groupIdx,
              petIdx,
            },
          })
        } else {
          const locationSpecie = speciePlatformToLocation(bookingCtx.state, petProfile[0].breed?.species?.id || '')
          if (!locationSpecie) {
            return
          }
          const groupIdx = petGroups.findIndex((g) => g.specieId === locationSpecie.id)
          bookingCtx.dispatch({
            type: 'SET_PET_PROFILE',
            payload: { value: { ...petProfile[0], pet: { ...pet } }, groupIdx },
          })
        }
        setAddNewPet(false)
        setEditPet(false)
        setEditGroups(null)
        setSelectedPet(null)
      }
    },
    [bookingCtx, petGroups, editPet, editGroups]
  )

  const handleEdit = useCallback(
    (pet: TBookingPet, groupIdx: number, petIdx: number) => {
      const petData = bookingCtx?.state?.data?.petProfiles?.find((profile) => profile?.pet?.id === pet.id)
      if (petData) {
        setEditPet(true)
        setEditGroups({ groupIdx, petIdx })
        setSelectedPet(petData as PetProfileClientDto)
      }
    },
    [bookingCtx?.state?.data?.petProfiles]
  )

  const handleDeletePet = useCallback(() => {
    if (editGroups) {
      bookingCtx.dispatch({
        type: 'REMOVE_PET_SEARCH_FIELD',
        payload: editGroups,
      })
      setEditPet(false)
      setEditGroups(null)
      setSelectedPet(null)
    }
  }, [bookingCtx, editGroups])

  const handleAddNewPet = useCallback(() => {
    setAddNewPet(true)
  }, [])

  const isAddingOrEditing = addNewPet || editPet
  const allowedSpecies =
    bookingCtx?.state?.config?.booking?.species?.map((specie) => specie?.available && specie.text) || []
  const petFormSpecies = bookingCtx?.state?.config?.species
    ?.map((item) => allowedSpecies.includes(item.id) && item.species)
    .filter(Boolean)

  return (
    <Box>
      <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Box>
      {anchorEl && (
        <Popover
          open={bookingCtx.state.searchBarOpen}
          onClose={closeModal}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={anchorEl}
          slotProps={{
            paper: {
              style: {
                width: `${isBelowMD ? 'calc(100vw - 32px)' : modalSize()}`,
                marginTop: '20px',
              },
            },
          }}
        >
          <Paper elevation={0}>
            {!isAddingOrEditing && <PetSelector onEditAction={handleEdit} addNewPetAction={handleAddNewPet} />}
            {isAddingOrEditing && (
              <PetForm
                petProfile={selectedPet as PetProfileClientDto}
                isEditing={editPet}
                petFamilyId={petFamily.data?.results[0].id || ''}
                onCancel={handleCancel}
                onSave={handleSaveUpdatePet}
                speciesResponse={petFormSpecies as SpeciesAdminDto[]}
                onDelete={handleDeletePet}
              />
            )}
          </Paper>
        </Popover>
      )}
    </Box>
  )
}
