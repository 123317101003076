import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import format from 'date-fns/format'
import { useMemo, useCallback } from 'react'

import { i18WithDefault as t } from '@shared/locale'
import { useBookingContext } from '@providers/booking'
import { formatNumberAsPrice } from '@shared/common'
import { formatAmPm, getHoursByDate } from 'components-ui/src/shared'

export const DatesViewer = ({ totalPets }: { totalPets: number }) => {
  const bookingCtx = useBookingContext()
  const daycare = useMemo(() => bookingCtx.state.cart.groups[0].daycareSelected ?? [], [bookingCtx.state.cart.groups])

  const onRemoveItem = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget: t } = ev
      if (t.dataset.idx) {
        bookingCtx.dispatch({ type: 'SET_DAYCARE_DATES', payload: { value: daycare[Number(t.dataset.idx)].date } })
      }
    },
    [bookingCtx, daycare]
  )

  const getOperationHours = useCallback(
    (date: Date) => {
      const hours = getHoursByDate(date, bookingCtx.state.config.booking?.operationHour || [])
      return hours.map(({ startTime, endTime }) => `${formatAmPm(startTime)} - ${formatAmPm(endTime)}`).join(' / ')
    },
    [bookingCtx.state.config.booking?.operationHour]
  )

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={10}>{daycare.length} Days Selected</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {daycare.map((dc, idx) => {
          return (
            <TableRow key={dc.id}>
              <TableCell width="100%">
                <Typography variant="body1" component="span">
                  {format(dc.date, 'MMM dd')}
                </Typography>
                <Typography variant="caption" component="span" sx={{ pl: 1 }}>
                  {`(${t('global.label.open')} ${getOperationHours(dc.date)})`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" component="span">
                  {formatNumberAsPrice((dc.availability.availabilities![0].price!.value ?? 0) / totalPets)}
                </Typography>
                <Typography variant="caption" component="span">
                  /{bookingCtx.state.groups[0].specieName}
                </Typography>
              </TableCell>
              <TableCell align="center" width={90}>
                <Tooltip title={t('addonDatePicker.removeDay')} disableInteractive>
                  <IconButton onClick={onRemoveItem} data-idx={idx}>
                    <RemoveCircleOutlineIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
