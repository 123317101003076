import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback, useMemo } from 'react'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { Spacer1, Spacer2 } from 'components-ui'
import { formatNumberAsPrice } from 'components-ui/src/shared'
import { Types } from 'service-api'
import { getPriceRangeForOffers, getPriceRangeForOffersByDay } from './helper'
import { PetItem } from './pet_item'
import { PetItemByDay } from './pet_item_byday'
import type { TAddOnItemProps } from './types'

export const AddOnItem = ({ addOn, groupIdx, kind }: TAddOnItemProps) => {
  const bookingCtx = useBookingContext()
  const offer = addOn.offer!
  const isDaycare = bookingCtx.state.serviceTypeName === 'daycare'
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const strategy = useMemo(() => {
    if (isDaycare) {
      return (addOn as Types.BookingSearchByDaysClientDto).availabilityGroupsByDays?.[0]?.[groupIdx].pricingStrategy
    } else {
      return (addOn as Types.BookingSearchClientDto).availabilityGroups?.[groupIdx].pricingStrategy
    }
  }, [addOn, groupIdx, isDaycare])

  const { minRate, maxRate } = useMemo(() => {
    if (isDaycare) {
      return getPriceRangeForOffersByDay((addOn as Types.BookingSearchByDaysClientDto).availabilityGroupsByDays)
    } else {
      return getPriceRangeForOffers(groupIdx, (addOn as Types.BookingSearchClientDto).availabilityGroups)
    }
  }, [addOn, groupIdx, isDaycare])

  const onSelectItem = useCallback(
    (_ev: React.MouseEvent<HTMLButtonElement>) => {
      const msg =
        kind === 'petAddOn' ? (isDaycare ? 'TOGGLE_PET_ADDON_BY_DAY' : 'TOGGLE_PET_ADDON') : 'TOGGLE_ROOM_ADDON'
      bookingCtx.dispatch({ type: msg, payload: { value: addOn, groupIdx, qty: 1 } })
    },
    [kind, bookingCtx, addOn, groupIdx, isDaycare]
  )

  const selectedIdx = useMemo(() => {
    const keyName = isDaycare ? 'petsAddOnDaycare' : 'petsAddOn'
    const addOn =
      kind === 'petAddOn'
        ? bookingCtx.state.cart.groups[groupIdx][keyName]
        : bookingCtx.state.cart.groups[groupIdx]['roomsAddOn']

    if (!addOn) {
      return -1
    }
    return addOn.findIndex((o) => o.offer!.id === offer.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupIdx, bookingCtx.state.serviceTypeName, bookingCtx.state.cart.groups[groupIdx]])

  const BtnIcon = useMemo(() => (selectedIdx < 0 ? AddIcon : CheckIcon), [selectedIdx])
  const priceLabel = useMemo(() => {
    if (kind === 'petAddOn') {
      return t('global.label.each')
    }
    return t(strategy === 'NIGHTLY' ? 'global.label.night' : 'global.label.day')
  }, [kind, strategy])
  const priceVaries = offer?.priceVaries

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        sx={{ maxWidth: '640px', margin: '0 auto', width: '100%' }}
      >
        <Stack direction="column" width="100%" spacing={1}>
          <Stack direction="row" width="100%" sx={{ mb: 1 }}>
            <Typography flexGrow={1} variant="h6">
              {offer.displayName}
            </Typography>
            <Box>
              <Typography variant="body2" component="span" sx={{ paddingRight: '4px' }}>
                {priceVaries && t('offers.startingAt')}
              </Typography>
              <Typography variant="body2" component="span">
                <strong>
                  {minRate === maxRate
                    ? formatNumberAsPrice(minRate)
                    : `${formatNumberAsPrice(minRate)} - ${formatNumberAsPrice(maxRate)}`}
                </strong>
              </Typography>
              <Typography variant="body2" component="span">
                /{priceLabel}
              </Typography>
            </Box>
          </Stack>
          <Stack direction={{ sx: 'column', md: 'row' }} width="100%" alignItems="start">
            <Typography flexGrow={1} variant="body2">
              {offer.description}
            </Typography>
            {isBelowMD && <Spacer1 />}
            <Button
              variant={selectedIdx < 0 ? 'outlined' : 'contained'}
              size="small"
              startIcon={<BtnIcon />}
              onClick={onSelectItem}
              sx={{
                marginLeft: isBelowMD ? 0 : '32px',
                minWidth: '120px',
                py: 1,
              }}
            >
              {selectedIdx < 0 ? t('global.label.add') : t('global.label.added')}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Spacer2 />

      {kind === 'petAddOn' &&
        selectedIdx >= 0 &&
        (isDaycare ? (
          <PetItemByDay groupIdx={groupIdx} selectedIdx={selectedIdx} showPricePerPet={minRate !== maxRate} />
        ) : (
          <PetItem groupIdx={groupIdx} selectedIdx={selectedIdx} showPricePerPet={minRate !== maxRate} />
        ))}
    </Box>
  )
}
