import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { LocationClientDto, LocationThemeClientDto } from 'service-api/src/types'

export const LocationContext = createContext<{
  location?: LocationClientDto
  locationTheme?: LocationThemeClientDto
  update: (location: LocationClientDto, locationTheme: LocationThemeClientDto) => void
  isLoading: boolean
  setLoading: (val: boolean) => void
} | null>(null)

export const LocationProvider = ({ children }: { children: ReactNode }) => {
  const { Provider } = LocationContext
  const [data, setData] = useState<{ location: LocationClientDto; locationTheme: LocationThemeClientDto }>()
  const [isLoading, setLoading] = useState(true)

  const update = useCallback((location: LocationClientDto, locationTheme: LocationThemeClientDto) => {
    setData({ location, locationTheme })
  }, [])

  return (
    <Provider value={{ location: data?.location, locationTheme: data?.locationTheme, update, isLoading, setLoading }}>
      {children}
    </Provider>
  )
}

export const useLocationContext = () => {
  const ctx = useContext(LocationContext)
  if (ctx === null) {
    throw new Error('useLocationContext must be used within a LocationProvider')
  }
  return ctx
}
