import { SvgIcon, SvgIconProps } from '@mui/material'

/* eslint-disable max-len */
export const DiscoverIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="32" height="21" viewBox="0 0 32 21" fill="none" data-testid="DiscoverIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
        fill="url(#paint0_linear_3341_28032)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H30.5C31.3284 0 32 0.671573 32 1.5V19.5C32 20.3284 31.3284 21 30.5 21H1.5C0.671573 21 0 20.3284 0 19.5V1.5Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01632 11.3331C6.80583 11.5245 6.5323 11.608 6.09938 11.608H5.91955V9.32215H6.09938C6.5323 9.32215 6.79508 9.40013 7.01632 9.60178C7.24804 9.8093 7.38739 10.1311 7.38739 10.4622C7.38739 10.794 7.24804 11.1256 7.01632 11.3331ZM6.23356 8.73657H5.25V12.193H6.22838C6.74863 12.193 7.12435 12.0696 7.45402 11.794C7.84593 11.4677 8.07765 10.9759 8.07765 10.4673C8.07765 9.44714 7.32024 8.73657 6.23356 8.73657Z"
        fill="#212D34"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.38672 12.1931H9.0568V8.73657H8.38672V12.1931Z" fill="#212D34" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6947 10.0629C10.2926 9.91322 10.1746 9.81454 10.1746 9.62771C10.1746 9.41004 10.3849 9.24459 10.6737 9.24459C10.8744 9.24459 11.0395 9.32765 11.214 9.52449L11.5646 9.06257C11.2765 8.80898 10.9318 8.67944 10.5552 8.67944C9.94736 8.67944 9.48378 9.1041 9.48378 9.66978C9.48378 10.146 9.69958 10.3898 10.3289 10.6176C10.5913 10.7109 10.7248 10.7728 10.7921 10.8145C10.926 10.9025 10.993 11.0271 10.993 11.1722C10.993 11.4523 10.7718 11.6598 10.4728 11.6598C10.1532 11.6598 9.89573 11.4989 9.74151 11.1987L9.30859 11.6181C9.61729 12.0742 9.9881 12.2762 10.498 12.2762C11.1942 12.2762 11.6828 11.8103 11.6828 11.1413C11.6828 10.592 11.4569 10.3435 10.6947 10.0629Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8945 10.4673C11.8945 11.4832 12.6874 12.271 13.7077 12.271C13.9961 12.271 14.2432 12.214 14.5478 12.0696V11.276C14.28 11.5457 14.0427 11.6546 13.7389 11.6546C13.064 11.6546 12.5851 11.1622 12.5851 10.4622C12.5851 9.79861 13.0792 9.27514 13.7077 9.27514C14.0273 9.27514 14.2692 9.38985 14.5478 9.66401V8.87078C14.2537 8.72068 14.0119 8.65845 13.7235 8.65845C12.7083 8.65845 11.8945 9.46223 11.8945 10.4673Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.859 11.0583L18.943 8.73657H18.2109L19.6687 12.2818H20.0293L21.5133 8.73657H20.787L19.859 11.0583Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8164 12.1929H23.7166V11.6077H22.486V10.6747H23.6714V10.0892H22.486V9.32204H23.7166V8.73633H21.8164V12.1929Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0252 10.3277H24.8294V9.28091H25.0358C25.4532 9.28091 25.68 9.45678 25.68 9.79317C25.68 10.1406 25.4532 10.3277 25.0252 10.3277ZM26.3695 9.75684C26.3695 9.10971 25.9265 8.73633 25.1538 8.73633H24.1602V12.1929H24.8294V10.8043H24.9169L25.8443 12.1929H26.6684L25.5869 10.7368C26.0916 10.6334 26.3695 10.2861 26.3695 9.75684Z"
        fill="#212D34"
      />
      <g filter="url(#filter0_i_3341_28032)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4205 10.4767C18.4205 11.4922 17.6023 12.3155 16.593 12.3155C15.5837 12.3155 14.7656 11.4922 14.7656 10.4767C14.7656 9.46108 15.5837 8.63794 16.593 8.63794C17.6023 8.63794 18.4205 9.46108 18.4205 10.4767Z"
          fill="url(#paint1_linear_3341_28032)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_3341_28032"
          x="14.7656"
          y="8.63794"
          width="3.65625"
          height="3.67749"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3341_28032" />
        </filter>
        <linearGradient
          id="paint0_linear_3341_28032"
          x1="-16"
          y1="10.5"
          x2="3.26553"
          y2="39.857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF1F3" />
          <stop offset="1" stopColor="#D3DADF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3341_28032"
          x1="18.8316"
          y1="10.8282"
          x2="16.6631"
          y2="8.26346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA700" />
          <stop offset="1" stopColor="#E75A10" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
