import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_CONTRACTS_PATH } from '../types/constants'
import { ContractClientDto, APIContractClientDtoResponse } from '../types/api'
import { TContractType } from '../types/general'

export const ClientContractsService = {
  get: (locationId: string, contractType?: TContractType): Promise<ContractClientDto | null> => {
    return requestGooseAPI<APIContractClientDtoResponse>({
      url: getAPIPath(CLIENT_CONTRACTS_PATH, { locationId }),
      method: 'GET',
      params: { type: contractType },
    }).then((res) => res?.results?.[0] ?? null)
  },
}
