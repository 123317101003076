import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { buildPetTabGroups, pendingOfferSelection } from '@shared/booking_groups'
import { scrollToTop } from '@shared/common'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { buildSearchParams, continueNavigation } from '@shared/header_navigation'
import { i18WithDefault as t } from '@shared/locale'
import { captureError } from '@shared/monitoring'
import { useRoutes } from 'app/use_routes'
import { OfferCard, Spacer, Tabs } from 'components-ui'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'
import { Hooks, Types } from 'service-api'
import { SearchResultsSkeleton } from './search_result_skeleton'
import { sortOffers } from './helper'
import { isUserLoggedIn } from '@providers/session/helpers'
import { useSessionContext } from '@providers/session'

const SearchOfferResults = ({ offers }: { offers: Types.BookingSearchClientDto[] }) => {
  const { accountName = '', serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const { navigateRoute } = useRoutes()
  const [currentTabIndex, setTabIndex] = useState(0)
  const [canContinueNavigation, setCanContinueNavigation] = useState(false)
  const theme = useTheme()
  const tabs = buildPetTabGroups(bookingCtx.state.cart.groups)
  const onChangeTab = useCallback((_label: string, index: number) => {
    setTabIndex(index)
    bookingCtx.dispatch({ type: 'SET_PET_GROUP', payload: { value: index } })
  }, [])

  const sortedOffers = useMemo(() => {
    if (offers) {
      return sortOffers(offers, serviceTypeName, currentTabIndex)
    } else {
      return []
    }
  }, [offers])

  // keeps the offers selected by the user for each group
  const [groupOffer, setGroupOffer] = useState<Record<number, Types.BookingSearchClientDto>>(() => {
    return bookingCtx.state.cart.groups.reduce((acc, g, groupIdx) => {
      const selected = sortedOffers.find((r) => r.offer?.id === g.offerSelected?.offer?.id)
      if (selected) {
        acc[groupIdx] = selected
      }
      return acc
    }, {} as GenericSimpleBag)
  })
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const cartGroups = bookingCtx.state.cart?.groups
    if (!canContinueNavigation) {
      return
    }
    if (!isUserLoggedIn(sessionCtx.state.user)) {
      navigateRoute('loginCheck', { accountName, serviceTypeName })
      return
    }
    if (cartGroups.every((group) => !!group?.offerSelected)) {
      continueNavigation(bookingCtx, accountName).then((nextRoute) => {
        navigateRoute(nextRoute!, { serviceTypeName })
      })
    }
  }, [
    bookingCtx.state,
    canContinueNavigation,
    accountName,
    serviceTypeName,
    bookingCtx,
    sessionCtx.state.user,
    navigateRoute,
  ])

  const onSelectOffer = useCallback(
    (offer: Types.OfferClientDto, availability: Types.AvailabilityGroupClientDto, petGroupIdx: number) => {
      bookingCtx.dispatch({ type: 'SELECT_OFFER', payload: { offer, availability, petGroup: petGroupIdx } })
      setGroupOffer({ ...groupOffer, [petGroupIdx]: sortedOffers.find((r) => r.offer?.id === offer.id)! })
      setCanContinueNavigation(true)

      const tabIdx = pendingOfferSelection(bookingCtx.state.cart.groups, petGroupIdx)
      if (tabIdx >= 0) {
        scrollToTop()
        setTabIndex(tabIdx)
        return
      }
    },
    [bookingCtx, groupOffer, sortedOffers]
  )

  const renderCard = (result: Types.BookingSearchClientDto) => {
    const isSelected = bookingCtx.state.cart.groups[currentTabIndex]?.offerSelected?.offer?.id === result.offer?.id
    return (
      <Fragment key={result.offer!.id}>
        <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
          <OfferCard
            key={result.offer!.id}
            searchResult={result}
            petGroup={currentTabIndex}
            onSelectOffer={onSelectOffer}
            serviceTypeName={serviceTypeName}
            specieTypeName={bookingCtx.state.cart.groups[0].specieName}
            selected={isSelected}
          />
        </Paper>
        <Spacer height={3} />
      </Fragment>
    )
  }

  const renderResult = () => {
    const petGroups = bookingCtx.state.cart.groups
    const offerSelected = groupOffer[currentTabIndex]
    const renderItems: ReactNode[] = []
    if (offerSelected) {
      renderItems.push(renderCard(offerSelected))
    }
    return renderItems.concat(
      sortedOffers.map((result) => {
        if (
          result.offer?.locationSpecies?.id !== petGroups?.[currentTabIndex]?.specieId ||
          offerSelected?.offer?.id === result.offer?.id
        ) {
          return null
        }
        return renderCard(result)
      })
    )
  }

  return (
    <Box>
      {tabs.length > 1 && (
        <>
          <Tabs tabIndex={currentTabIndex} tabs={tabs} onChange={onChangeTab} />
          <Spacer height={3} />
        </>
      )}
      {renderResult()}
    </Box>
  )
}

export const SearchOffersGeneral = () => {
  const { accountName = '', serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const searchParams = useMemo(() => buildSearchParams(bookingCtx), [bookingCtx])

  const searchResult = Hooks.useClientBookingSearch(accountName, serviceTypeName, {
    ...searchParams,
    offersType: 'PRIMARY',
  })

  useEffect(() => {
    if (searchResult.isError) {
      captureError(searchResult.error as Error)
    }
  }, [searchResult.error, searchResult.isError])

  useEffect(() => {
    if (searchResult.isSuccess) {
      bookingCtx.dispatch({ type: 'LOAD_OFFERS_SEARCH_RESULTS', payload: { value: searchResult.data?.results || [] } })
    }
  }, [searchResult.isSuccess, searchResult.data?.results])

  useEffect(() => {
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'searchResults' } })
  })

  // redirect to home if the state has invalid data
  useRedirectNoData()

  const pageTitle =
    bookingCtx.state.serviceTypeName === 'boarding' ? 'searchResults.title.boarding' : 'searchResults.title.daycare'

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <Typography variant="h1" sx={{ px: 2 }}>
        {t(pageTitle)}
      </Typography>
      <Spacer height={3} />
      {searchResult.isLoading ? (
        <SearchResultsSkeleton />
      ) : (
        <SearchOfferResults offers={searchResult.data?.results || []} />
      )}
    </Box>
  )
}
