import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import { Spacer2 } from '../spacer'

export const OfferCardSkeleton = () => {
  return (
    <Box sx={{ maxWidth: '1000px' }}>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Skeleton variant="text" height={50} />
        <Spacer2 />
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4 }}>
          <Skeleton variant="rounded" width={320} height={200} />

          <Stack direction="column" width="100%">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />

            <Spacer2 />

            <Stack direction="row" spacing={3} justifyContent="end" sx={{ marginTop: 'auto' }} alignItems="center">
              <Skeleton variant="rounded" width={100} height={35} />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}
