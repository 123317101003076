import cloneDeep from 'lodash.clonedeep'
import { isEmpty, genId } from '@shared/common'
import { toISODate } from 'components-ui'
import { i18WithDefault as t } from '@shared/locale'
import { Types } from 'service-api'
import type {
  TBookingContext,
  TBookingPet,
  TCartGroom,
  TCartGroupOffer,
  TCartGroupPetAddon,
  TCartGroupPetAddonDaycare,
  TCartGroupPetAddonQty,
  TCartGroupPetAddonDaycareDates,
  TOfferAvailability,
  TPetFields,
  TBookingGroup,
  TServiceTypeNames,
  TCartGroup,
} from './types'
import { getThresholds } from '@shared/service_types'

export const newPetRecord = () => {
  return {
    id: genId(),
    name: '',
    nameErrorMsg: undefined,
    nameInput: '',
    breedId: '',
    breed: '',
    breedInput: '',
    breedErrorMsg: undefined,
    weight: '',
    weightErrorMsg: undefined,
    temperTested: true,
    birthdate: undefined,
    sex: undefined,
    altered: undefined,
    petId: undefined,
    room: 1,
  }
}

export const newPetGroup = () => {
  return {
    id: genId(),
    specieId: '',
    specieName: '',
    maxPets: undefined,
    maxWeight: undefined,
    maxPetWeight: undefined,
    isWeightRequired: false,
    errors: [],
    pets: [newPetRecord()] as TBookingPet[],
  } as TBookingGroup
}

export const newCartGroup = (): TCartGroup => ({
  offerSelected: undefined,
  roomsAddOn: [],
  petsAddOn: [],
  daycareSelected: [],
  grooms: [],
  pets: [],
  specieId: '',
  specieName: '',
})

export const newEmptyPetGroup = () => {
  return {
    id: genId(),
    specieId: '',
    specieName: '',
    maxPets: undefined,
    maxWeight: undefined,
    maxPetWeight: undefined,
    isWeightRequired: false,
    errors: [],
    pets: [] as TBookingPet[],
  }
}

export const newContact = () => {
  return {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    useMailingAddress: true,
    mailingAddress: {
      streetLine1: '',
      streetLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US',
    },
    billingAddress: {
      streetLine1: '',
      streetLine2: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US',
    },
  }
}

export const validateForm = (state: TBookingContext) => {
  if (state.groups.length === 0) {
    state.isValid = false
    return
  }

  state.groups.forEach(checkGroupForm)
  state.isValid = checkFormValid(state)
}

export const checkFormValid = (state: TBookingContext): boolean => {
  let stCheck =
    state.groups?.[0]?.pets?.[0]?.name !== '' &&
    !formHasErrors(state) &&
    !isEmpty(state.startDate) &&
    !isEmpty(state.endDate)

  if (stCheck && state.serviceTypeName === 'boarding') {
    // check that boarding has different start and end dates
    stCheck = toISODate(state.startDate) !== toISODate(state.endDate)
  }

  return stCheck
}

export const checkPetWeight = (group: TBookingGroup) => {
  if (isEmpty(group.maxWeight) && isEmpty(group.maxPetWeight) && isEmpty(group.maxPets)) {
    return
  }

  const rooms = group.pets.reduce(
    (acc, pet) => {
      if (pet.room > 0) {
        const id = String(pet.room)
        acc[id] = acc[id] || { weight: 0, pets: 0 }
        acc[id].weight += parseInt(pet.weight) || 0
        acc[id].pets += 1
      }
      return acc
    },
    {} as Record<string, GenericSimpleBag>
  )

  group.errors = []
  const totalWeightInRoom = Object.keys(rooms).some((roomId) => rooms[roomId].weight > group.maxWeight!)
  const petWeightInRoom = isEmpty(group.maxPetWeight)
    ? null
    : group.pets.some((pet) => pet.room > 0 && parseInt(pet.weight) > (group.maxPetWeight ?? 0))
  const totalPetsInRoom = Object.keys(rooms).some((roomId) => rooms[roomId].pets > group.maxPets!)

  if (totalWeightInRoom) {
    group.errors.push({ kind: 'petWeightGroup', message: t('searchBar.errors.petWeightGroup') })
  }

  if (petWeightInRoom) {
    group.errors.push({ kind: 'petWeightMax', message: t('searchBar.errors.petWeightMax') })
  }

  if (totalPetsInRoom) {
    group.errors.push({ kind: 'petCountMax', message: t('searchBar.errors.petCountMax') })
  }
}

export const checkGroupForm = (group: TBookingGroup) => {
  checkPetWeight(group)

  const fields: TPetFields[] = ['name', 'breed']
  if (group.isWeightRequired) {
    fields.push('weight')
  }
  group.pets.forEach((p) => {
    fields.forEach((field) => isFieldEmpty(group, p, field))
  })
}

export const removeFieldRequiredError = (pet: TBookingPet, fieldName: TPetFields) => {
  if (pet[fieldName]?.trim() !== '') {
    pet[`${fieldName}ErrorMsg`] = undefined
  }
}

export const isFieldEmpty = (group: TBookingGroup, pet: TBookingPet, fieldName: TPetFields) => {
  if (pet.room === 0) {
    return
  }
  if (pet[fieldName]?.trim() === '') {
    pet[`${fieldName}ErrorMsg`] = t('searchBar.errors.required')
  } else {
    pet[`${fieldName}ErrorMsg`] = undefined
  }

  if (fieldName === 'weight' && !isEmpty(group.maxPetWeight)) {
    const weight = parseInt(pet.weight)
    if (weight > group.maxPetWeight!) {
      pet.weightErrorMsg = t('searchBar.errors.maxWeight')
    }
  }
}

export const calculateCartPrice = (serviceTypeName: TServiceTypeNames, state: TBookingContext) => {
  // const totalPets = state.groups.reduce((acc, g) => acc + g.pets.length, 0)
  const calcGroup = (data?: TCartGroupOffer[]) => {
    if (!data) {
      return 0
    }
    return data.reduce((acc, g) => acc + (g?.availability?.availabilities?.[0]?.price?.value || 0), 0)
  }
  const calcGroupAddOn = (data?: TCartGroupPetAddon[]) => {
    return (data || []).reduce(
      (acc, group) =>
        acc +
        group?.pets?.reduce(
          (tot, p, index) => tot + p.qty * (group?.availability?.availabilities?.[index]?.price?.value || 0),
          0
        ),
      0
    )
  }
  const calcDayCare = (data?: TOfferAvailability[]) => {
    if (!data) {
      return 0
    }
    return data.reduce((acc, g) => acc + (g?.availability?.availabilities?.[0]?.price?.value ?? 0), 0)
  }

  const calcDayCareAddOn = (data?: TCartGroupPetAddonDaycare[]) => {
    return (data || []).reduce(
      (acc, group) =>
        acc +
        group?.pets?.reduce(
          (tot, p, index) =>
            tot +
            p.dates.reduce((tot, d) => tot + d.qty * (d.availability?.availabilities?.[index]?.price?.value || 0), 0),
          0
        ),
      0
    )
  }

  const calcGrooms = (data?: TCartGroom[][]) => {
    const totalArray = (data || []).map((petGrooms, petIdx) => {
      return petGrooms.reduce(
        (acc, petGroom) => acc + (petGroom.availabilityGroups[petIdx].availabilities?.[0].price?.value ?? 0),
        0
      )
    })
    return totalArray.reduce((acc, tot) => acc + tot, 0)
  }

  state.cart.total = Number(
    state.cart.groups
      .reduce((acc, g) => {
        let offerValue = 0
        if (serviceTypeName !== 'daycare') {
          offerValue = g.offerSelected?.availability?.availabilities?.[0]?.price?.value || 0
        }

        acc +=
          offerValue +
          calcGroup(g.roomsAddOn) +
          calcGroupAddOn(g.petsAddOn) +
          calcDayCare(g.daycareSelected) +
          calcDayCareAddOn(g.petsAddOnDaycare) +
          calcGrooms(g.grooms)
        return acc
      }, 0)
      .toFixed(2)
  )
}

export const updatePetAddonQty = (value: number, state: TBookingContext, payload?: GenericSimpleBag) => {
  const groupIdx = payload!.groupIdx
  const addOnId = payload!.addOnId
  const petId = payload!.petId
  const date = payload!.date
  const field = (payload!.field || 'petsAddOn') as 'petsAddOn' | 'petsAddOnDaycare'

  const addOn = state.cart.groups[groupIdx]?.[field] as TCartGroupPetAddon[] | TCartGroupPetAddonDaycare[]
  const addonIdx = addOn?.findIndex((o) => o.offer!.id === addOnId) ?? -1
  if (addonIdx < 0) {
    return
  }

  const petIdx = addOn[addonIdx].pets?.findIndex((p) => p.id === petId) ?? -1
  if (petIdx < 0) {
    return
  }

  if (field == 'petsAddOn') {
    const petData = addOn[addonIdx].pets[petIdx] as TCartGroupPetAddonQty
    if (value < 0 && petData?.qty === 0) {
      return
    }
    petData.qty += value
  } else if (field == 'petsAddOnDaycare') {
    const petData = addOn[addonIdx].pets[petIdx] as TCartGroupPetAddonDaycareDates
    const selectedDate = petData.dates.findIndex((d) => String(d.dateSelected) === date) ?? -1
    if (selectedDate < 0 || (value < 0 && petData.dates[selectedDate].qty === 0)) {
      return
    }
    petData.dates[selectedDate].qty += value
  }
}

export const petProfilesToGroups = (state: TBookingContext, petProfiles: Types.PetProfileClientDto[]) => {
  const newGroup = (id: string, specie?: Types.LocationSpeciesClientDto): TBookingGroup => {
    const group = newPetGroup()
    group.specieId = id
    group.specieName = specie?.species?.name || specie?.name || ''
    group.pets = []
    return group
  }

  return (
    petProfiles.reduce(
      (acc, petProfile) => {
        const specie = speciePlatformToLocation(state, petProfile.breed?.speciesId || '')
        // if the pet's specie is not available at the location, skip it
        if (!specie) {
          return acc
        }
        const locationSpecieId = specie!.id!
        acc[locationSpecieId] ||= newGroup(locationSpecieId, specie)
        acc[locationSpecieId].pets.push(petProfileToPetRecord(state, petProfile))
        return acc
      },
      {} as Record<string, TBookingGroup>
    ) || {}
  )
}

export const petProfileToPetRecord = (state: TBookingContext, petProfile: Types.PetProfileClientDto): TBookingPet => {
  const oldPetData = {
    petInRoom: 1,
    temperTested: true,
  }
  state.groups.some((g) =>
    g.pets.some((p) => {
      if (p.id === petProfile.pet?.id) {
        oldPetData.petInRoom = p.room
        oldPetData.temperTested = p.temperTested
        return true
      }
      return false
    })
  )

  return {
    ...newPetRecord(),
    id: petProfile.pet!.id!,
    name: petProfile.pet!.displayName!,
    breed: petProfile.breed?.displayName || '',
    breedId: petProfile.breed?.id || petProfile.breedId || '',
    petId: petProfile.petId || undefined,
    weight: String(petProfile.weight || ''),
    birthdate: petProfile.birthdate || undefined,
    sex: petProfile.sex || undefined,
    altered: isEmpty(petProfile.altered) ? undefined : Boolean(petProfile.altered),
    temperTested: oldPetData.temperTested,
    room: oldPetData.petInRoom,
  }
}

export const speciePlatformToLocation = (
  state: TBookingContext,
  platformSpecieId: string
): Types.LocationSpeciesClientDto | undefined => {
  const locationSpecie = state.config.species?.find((s) => s.species?.id === platformSpecieId)
  if (!locationSpecie) {
    return
  }
  return state.config.booking?.species?.some((s) => s.text === locationSpecie.id && s.available)
    ? locationSpecie
    : undefined
}

export const formHasErrors = (state: TBookingContext) => {
  return (
    state.groups.filter(
      (g) =>
        g.errors.length > 0 ||
        g.pets.filter((p) => Boolean(p.nameErrorMsg) || Boolean(p.breedErrorMsg) || Boolean(p.weightErrorMsg)).length >
          0
    ).length > 0
  )
}

export const regroupPets = (ctx: TBookingContext) => {
  const groups = cloneDeep(ctx.groups)
  const newGroups = groups.reduce(
    (acc, group) => {
      const { pets, ...rest } = group

      pets.forEach((pet) => {
        if (pet.room === 0) {
          return
        }
        const key = `${rest.specieId}-${pet.room}`
        acc[key] = acc[key] || {
          ...newCartGroup(),
          id: genId(),
          pets: [],
          specieId: rest.specieId,
          specieName: rest.specieName,
        }
        acc[key].pets.push(pet)
      })

      return acc
    },
    {} as Record<string, TCartGroup>
  )

  return Object.values(newGroups)
}

export const reorderPets = (ctx: TBookingContext) => {
  ctx.groups.forEach((groups, groupIdx) => {
    groups.pets.forEach((pet) => (pet.room = groupIdx + 1))
  })
}

export const getSpecieRestrictionThresholds = (ctx: TBookingContext, specieId: string) => {
  return {
    totalWeight: getThresholds(ctx.config?.booking?.totalWeight, specieId),
    petWeight: getThresholds(ctx.config?.booking?.weight, specieId),
    petCount: getThresholds(ctx.config?.booking?.petCount, specieId),
  }
}

export const getSelectedOffer = (ctx: TBookingContext, offerId: string): Types.OfferClientDto | undefined => {
  let offer: Types.OfferClientDto | undefined = undefined
  ctx.cart.groups.some((g) => {
    if (g.offerSelected?.offer?.id === offerId) {
      offer = g.offerSelected.offer
      return true
    }
    return false
  })
  return offer
}

export const getSelectedGroom = (
  ctx: TBookingContext,
  groupIdx: number,
  offerId: string
): Types.OfferClientDto | undefined => {
  let offer: Types.OfferClientDto | undefined = undefined
  ctx.cart.groups[groupIdx].grooms?.some((petGrooms) => {
    return petGrooms.some((petGroom) => {
      if (petGroom.offer?.id === offerId) {
        offer = petGroom.offer
        return true
      }
      return false
    })
  })
  return offer
}

export const checkTemperTestRequirement = (ctx: TBookingContext, petGroup: number): void => {
  const group = ctx.cart.groups[petGroup]
  let availability = undefined
  if (group.offerSelected?.availability.availabilities?.[0]?.condition?.reason?.type === 'TEMPER_TEST') {
    availability = group.offerSelected.availability.availabilities[0]
  } else {
    availability = group.daycareSelected?.find(
      (day) => day.availability?.availabilities?.[0]?.condition?.reason?.type === 'TEMPER_TEST'
    )?.availability.availabilities?.[0]
  }

  if (!availability) {
    return
  }

  const petsNeedTemper = availability.affectedByPets?.map((pet) => pet.petId) || []
  group.pets.forEach((pet) => {
    pet.temperTested = !petsNeedTemper.includes(pet.id)
  })
}
