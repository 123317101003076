import { getTzDateInUtc } from '@shared/dates'
import { addMinutes, isBefore } from 'date-fns'
import { InvoiceClientDto, OrderClientDto } from 'service-api/src/types'
import { RefundSetting } from './invoice_card'
import { isCancelState } from 'shared'

export const canCancelInvoice = (invoice: InvoiceClientDto, order: OrderClientDto, isAdmin: boolean) => {
  if (invoice.invoiceStatus !== 'CONFIRMED') {
    return false
  }
  const now = Date.now()
  if (isAdmin) {
    return isCancelState(invoice)
  } else {
    // To be replaced with invoice.allowedActions.includes('CANCEL')
    // pending BE change: https://goose-pet.atlassian.net/browse/GA-1804
    const startDateAt12AM = getTzDateInUtc(invoice.period?.[0]?.startDate, null, invoice.location?.timezone)
    return isBefore(now, startDateAt12AM)
  }
}

export const getRefundRestrictions = (order: OrderClientDto, invoiceStartDate: Date) => {
  let refundSettingToApply: RefundSetting | undefined,
    maxCancelBy = 0
  const today = new Date().getTime(),
    refundSettings: RefundSetting[] = order.data?.refundSettings || []
  refundSettings.forEach((setting) => {
    if (parseFloat(setting.rate)) {
      const cancelBy = addMinutes(invoiceStartDate, -setting.before).getTime()
      maxCancelBy = maxCancelBy ? Math.max(maxCancelBy, cancelBy) : cancelBy
      if (!refundSettingToApply && today < cancelBy) {
        refundSettingToApply = setting
      }
    }
  })
  return { refundSettingToApply, maxCancelBy }
}
