import { LoadingButton } from '@mui/lab'
import { Divider, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import format from 'date-fns/format'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import type { TBookingGroup } from '@providers/booking/types'
import { formatNumberAsPrice, getPetGroupSummary, getPetNames } from '@shared/common'
import { STICKY_HEADER_HEIGHT_PX } from '@shared/constants'
import { continueNavigation } from '@shared/header_navigation'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { WarningBanner } from './warning_banner'

const HeaderButton = (props: { hasOrderSelected: boolean }) => {
  const theme = useTheme()
  const isBellowMD = useMediaQuery(theme.breakpoints.down('md'))
  const ctx = useBookingContext()
  const { navigateRoute } = useRoutes()
  const { accountName = '', serviceTypeName = '' } = useParams()
  const { hasOrderSelected } = props
  const onEditSearch = useCallback(() => {
    navigateRoute('searchServiceType', { serviceTypeName })
  }, [navigateRoute, serviceTypeName])
  const isButtonContinueDisabled = (() => {
    if (!ctx.state.currentState) {
      return true
    }

    if (ctx.state.currentState === 'temperTest') {
      return Boolean(
        typeof ctx.state.cart.temperTest?.date === 'undefined' || typeof ctx.state.cart.temperTest?.time === 'undefined'
      )
    }
    return false
  })()

  const onContinueClick = useCallback(async () => {
    const rc = await continueNavigation(ctx, accountName)
    if (rc) {
      navigateRoute(rc, { serviceTypeName })
    }
  }, [ctx, accountName, navigateRoute, serviceTypeName])

  if (ctx.state.currentState === 'payment' || ctx.state.currentState === 'paymentSummary') {
    return null
  }

  const formProps: GenericSimpleBag = {}

  if (ctx.state.data.formHandler) {
    formProps.form = ctx.state.data.formHandler
    formProps.type = 'submit'
  } else {
    formProps.onClick = onContinueClick
  }

  return hasOrderSelected ? (
    !isBellowMD ? (
      <LoadingButton
        size="large"
        variant="contained"
        {...formProps}
        loading={ctx.state.asyncAction}
        disabled={isButtonContinueDisabled}
      >
        {t('global.label.continue')}
      </LoadingButton>
    ) : null
  ) : (
    <Button size="large" variant="outlined" onClick={onEditSearch}>
      {t('global.label.edit')}
    </Button>
  )
}

export const BookingHeader = () => {
  const ctx = useBookingContext()
  const { serviceTypeName = '' } = useParams()
  const theme = useTheme()

  const { state: bookingState } = ctx
  const isDaycare = serviceTypeName === 'daycare'
  let hasOrderSelected = false
  if (bookingState.currentState !== 'searchResults') {
    if (isDaycare) {
      hasOrderSelected = (bookingState.cart.groups[0]?.daycareSelected?.length ?? 0) > 0
    } else {
      hasOrderSelected = bookingState.cart.groups.length > 0
    }
  }
  const displayProps = hasOrderSelected ? { display: { xs: 'none', md: 'block' } } : {}

  return (
    <Box
      sx={{
        position: 'sticky',
        width: '100%',
        top: `${STICKY_HEADER_HEIGHT_PX}px`,
        zIndex: theme.zIndex.appBar,
      }}
    >
      <Paper>
        <Stack
          direction="row"
          spacing={{ xs: 0, md: 2 }}
          px={2}
          sx={{ height: '70px', background: 'white' }}
          alignItems="center"
        >
          {serviceTypeName === 'boarding' && (
            <>
              <Box flexGrow={1} {...displayProps}>
                <Typography variant="caption" component="p">
                  {t('global.label.dates')}
                </Typography>
                <Typography variant="h6" component="span">
                  {format(bookingState.startDate || 0, 'MMM d')}
                </Typography>
                <Typography variant="body1" component="span" sx={{ mx: 1 }}>
                  {t('global.label.to')}
                </Typography>
                <Typography variant="h6" component="span">
                  {format(bookingState.endDate || 0, 'MMM d')}
                </Typography>
              </Box>

              <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
            </>
          )}
          <Box flexGrow={1} display={{ xs: 'none', md: 'block' }}>
            <Typography variant="caption" component="p">
              {getPetGroupSummary(
                bookingState.cart.groups[0]?.pets?.[0]?.name
                  ? bookingState.cart.groups
                  : (bookingState.confirmation.groups as TBookingGroup[]),
                serviceTypeName
              )}
            </Typography>
            <Typography variant="h6" component="span">
              {getPetNames(
                bookingState.cart.groups[0]?.pets?.[0]?.name
                  ? bookingState.cart.groups
                  : (bookingState.confirmation.groups as TBookingGroup[])
              )}
            </Typography>
          </Box>

          {hasOrderSelected && (
            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
          )}

          <Stack
            direction="row"
            flexShrink={0}
            flexGrow={{ xs: hasOrderSelected ? 1 : 0, md: 0 }}
            spacing={4}
            justifyContent={{ xs: 'space-between', md: 'end' }}
            marginLeft={{ xs: 0, md: 2 }}
          >
            {hasOrderSelected && (
              <Box sx={{ minWidth: '90px' }}>
                <Typography variant="caption" component="p">
                  {t('global.label.subtotal')}
                </Typography>
                <Typography variant="h6" component="span">
                  {formatNumberAsPrice(bookingState.cart.total)}
                </Typography>
              </Box>
            )}
            <HeaderButton hasOrderSelected={hasOrderSelected} />
          </Stack>
        </Stack>
      </Paper>
      <WarningBanner />
    </Box>
  )
}
