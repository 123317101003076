import { TBookingContext } from '@providers/booking/types'
import { Types } from 'service-api'
import cloneDeep from 'lodash.clonedeep'

export const firstSelectedDate = (bookingCtx: TBookingContext) => {
  if (bookingCtx.serviceTypeName === 'daycare') {
    if (!bookingCtx.cart.groups[0].daycareSelected) {
      return new Date()
    }
    const _cloned = cloneDeep(bookingCtx.cart.groups[0].daycareSelected!)
    return _cloned.sort(({ id: idA }, { id: idB }) => idA - idB)?.[0].date
  } else {
    return bookingCtx.startDate!
  }
}

export const getOfferServiceInterval = (temperTest: Types.OfferClientDto, defaultInterval = 30) => {
  if (!temperTest) {
    return defaultInterval
  }

  const so = temperTest.serviceOffers?.filter((so) => so.offerFrequency === 'DAILY') || []
  if (so.length == 0) {
    return defaultInterval
  }

  const sr = so[0].service?.serviceResources?.filter((sr) => sr.shareableType === 'PER_BOOKING') || []
  if (sr.length == 0) {
    return defaultInterval
  }

  return sr[0].minutesPerCycle || defaultInterval
}
