// import { forwardRef } from 'react'
// import { PatternFormat, PatternFormatProps } from 'react-number-format'
import { PatternFormat, PatternFormatProps } from 'react-number-format'
import TextField from '@mui/material/TextField'
import { ReadonlyTextField, TextFieldProps } from 'text_field'

export type TextInputPhoneProps = Omit<TextFieldProps, 'defaultValue' | 'type'> &
  Omit<PatternFormatProps, 'format' | 'size'>

export const TextInputPhone = (props: TextInputPhoneProps) => {
  const { readOnly, renderEmptyReadOnly, onChange, ...baseProps } = props
  if (readOnly) {
    return (
      <ReadonlyTextField
        sx={baseProps.sx}
        label={baseProps.label}
        value={baseProps.value as string}
        renderIfEmpty={renderEmptyReadOnly}
      />
    )
  }

  return (
    <PatternFormat
      {...baseProps}
      value={baseProps.value as string}
      format="(###) ###-####"
      type="text"
      customInput={TextField}
      onValueChange={({ value }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange?.({ target: { name: baseProps.name || '', value } })
      }}
      valueIsNumericString
    />
  )
}
