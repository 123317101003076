import * as Sentry from '@sentry/browser'

export const setTag = (key: string, value: string) => Sentry.setTag(key, value)
export const setUser = (id: string, email: string) => Sentry.setUser({ id, email })
export const captureError = (error: Error) => Sentry.captureException(error)

interface TSentryTag {
  key: string
  value: string
}

export interface TCaptureException {
  tags?: TSentryTag[]
  user?: Record<string, string>
}

export const captureException = (error: Error, options?: TCaptureException) => {
  if (options) {
    if (options.tags) {
      options.tags.forEach((tag) => {
        Sentry.setTag(tag.key, tag.value)
      })
    }
    if (options.user?.id && options.user?.email) {
      Sentry.setUser({ id: options.user.id, email: options.user.email })
    }
  }

  Sentry.captureException(error)
}
