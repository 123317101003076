import * as yup from 'yup'
import { i18WithDefault as t } from '../shared'
import YupPassword from 'yup-password'

YupPassword(yup)

export const logInSchema = yup.object({
  email: yup.string().email(t('password.email.error')).required(t('password.emailRequired.error')),
  password: yup.string().min(8, t('password.minLength.error')).required(t('password.required.error')),
})
