import { getAPIPath, requestGooseAPI } from '../shared/request'
import {
  CreateBookingOrderInput,
  BookingReservationClientDto,
  UpdateBookingOrderInput,
  CLIENT_BOOKING_RESERVATION_PATH,
  CLIENT_BOOKING_RESERVATION_ID_PATH,
} from '../types'

export const ClientBookingReservationService = {
  makeReservation: (
    locationName: string,
    data: CreateBookingOrderInput
  ): Promise<BookingReservationClientDto | null> => {
    if (data.reservations.length === 0) {
      return Promise.resolve(null)
    }

    return requestGooseAPI<BookingReservationClientDto>({
      url: getAPIPath(CLIENT_BOOKING_RESERVATION_PATH, { locationId: locationName }),
      method: 'POST',
      data,
    })
  },

  updateReservation: (
    orderId: string,
    locationName: string,
    data: UpdateBookingOrderInput
  ): Promise<BookingReservationClientDto> => {
    return requestGooseAPI<BookingReservationClientDto>({
      url: getAPIPath(CLIENT_BOOKING_RESERVATION_ID_PATH, { locationId: locationName, id: orderId }),
      method: 'PUT',
      data,
    })
  },
}
