import { Box, Chip, Link, Typography } from '@mui/material'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { i18WithDefault as t } from '@shared/locale'
import { HeadCell, ROWS_PER_PAGE_OPTIONS, Row, SharedTable, Spacer3 } from 'components-ui'
import { getServiceDatesText } from 'components-ui/src/shared'
import format from 'date-fns/format'
import { useEffect, useMemo, useState } from 'react'
import { Link as NavLink, useParams } from 'react-router-dom'
import { Hooks } from 'service-api'
import { OrderClientDto, TOrderSearchOptions } from 'service-api/src/types'

const PURCHASE_DATE = 'updatedAt',
  SERVICE = 'service',
  SERVICE_DATES = 'serviceDates',
  ACTIONS = 'actions'

const headCells: HeadCell[] = [
  { id: PURCHASE_DATE, label: t('global.label.purchaseDate'), sortable: true },
  { id: SERVICE, label: t('global.label.service') },
  { id: SERVICE_DATES, label: t('global.label.serviceDates') },
  { id: ACTIONS, label: t('global.label.actions') },
]

export const PurchaseHistory = () => {
  const { accountName = '' } = useParams()
  const [orders, setOrders] = useState<OrderClientDto[]>([])
  const [requestOptions, setRequestOptions] = useState<TOrderSearchOptions>({
    limit: ROWS_PER_PAGE_OPTIONS[0],
    sort: PURCHASE_DATE,
    order: 'desc',
  })
  const { data, isLoading } = Hooks.useClientOrderSearch(accountName, requestOptions)

  useEffect(() => {
    if (data?.results) {
      setOrders((prevOrders) => [...prevOrders, ...data.results])
    }
  }, [data])

  useEffect(() => {
    document.title = t('title.purchases')
  }, [])

  const rows: Row[] = useMemo(
    () =>
      orders.map((order) => {
        const purchaseDate = new Date(order.updatedAt || 0)
        const service =
          order.primaryServiceType?.displayName ||
          order.primaryServiceType?.serviceType?.displayName ||
          order.primaryServiceType?.name
        return {
          [PURCHASE_DATE]: format(purchaseDate, 'MMM d, yyyy'),
          [SERVICE]: service ? <Chip label={service} /> : null,
          [SERVICE_DATES]: getServiceDatesText(order),
          [ACTIONS]: (
            <Link component={NavLink} relative="path" to={`../${order.id}`}>
              {t('purchaseHistory.viewSummary')}
            </Link>
          ),
        }
      }),
    [orders]
  )

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, pb: '130px', margin: '0 auto' }}>
      <Spacer3 />
      <Typography variant="h4" sx={{ mb: 3, px: 2 }}>
        {t('global.label.mybookings')}
      </Typography>
      <SharedTable
        title={t('purchaseHistory.title')}
        rows={rows}
        head={headCells}
        defaultOrder="desc"
        defaultOrderBy={PURCHASE_DATE}
        isLoading={isLoading}
        emptyTableMessage={t('purchaseHistory.emptyTable')}
        hasMore={!!data?.nextToken}
        loadMore={(limit) => {
          if (data?.nextToken) {
            setRequestOptions((prevOptions) => ({ ...prevOptions, limit, nextToken: data.nextToken! }))
          }
        }}
        onSort={(sort, order, limit) => {
          setOrders([])
          setRequestOptions((prevOptions) => ({ ...prevOptions, sort, order, limit, nextToken: undefined }))
        }}
      />
    </Box>
  )
}
