import { Skeleton } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { useSessionContext } from '@providers/session'
import { isUserLoggedIn } from '@providers/session/helpers'
import { getPetGroupSummary } from '@shared/common'
import { i18WithDefault as t } from '@shared/locale'
import { CatIcon, DogIcon, capitalizeFirstLetter } from 'components-ui'
import { PetSearchSimple } from '../pet_search_simple/pet_search_simple'
import { PetSelect } from '../pet_select/pet_select'

const buttons: Record<string, React.ReactNode> = {
  dog: <DogIcon viewBox="0 -2 28 28" />,
  cat: <CatIcon viewBox="0 -2 28 28" />,
}

const PetGroups = () => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const groups = bookingCtx.state.groups.filter(
    (g) => g.pets.filter((p) => p.name.trim() != '' && p.room > 0).length > 0
  )
  const strategy = bookingCtx.state.config.location?.pricingStrategy

  if (!strategy) {
    return <Skeleton variant="text" width="100px" height={22} />
  }

  if (groups.length === 0) {
    return (
      <Typography variant="h6" color="primary.main">
        {t('searchBar.defaultSearchText')}
      </Typography>
    )
  }

  return (
    <Stack direction="row" alignItems="start" width="100%" spacing={1} flexWrap="wrap">
      {groups.map((group, idx) => {
        const pets = group.pets
          .filter((p) => p && p.room > 0)
          .map(({ name }) => capitalizeFirstLetter(name) || null)
          .filter(Boolean)
          .join(', ')
        return (
          <Stack
            key={idx}
            direction="row"
            alignItems="center"
            sx={{ '& svg': { fill: theme.palette.primary.main }, color: 'primary.main' }}
          >
            {buttons[group.specieName]} &nbsp; <strong>{pets}</strong>
          </Stack>
        )
      })}
    </Stack>
  )
}

export const RoomsAndPets = () => {
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const { serviceTypeName = '' } = useParams()

  const legend = t(`searchBar.rooms.title.${serviceTypeName}`)

  return (
    <Stack direction="column" alignItems="start" justifyContent="center" sx={{ px: { xs: 0, md: 2 }, flexGrow: 2 }}>
      <Typography variant="subtitle2">
        {legend} {getPetGroupSummary(bookingCtx.state.groups, serviceTypeName)}
      </Typography>
      {isUserLoggedIn(sessionCtx.state.user) ? (
        <PetSelect>
          <PetGroups />
        </PetSelect>
      ) : (
        <PetSearchSimple>
          <PetGroups />
        </PetSearchSimple>
      )}
    </Stack>
  )
}
