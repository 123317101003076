import { useQuery } from '@tanstack/react-query'
import { ClientInvoiceService } from '../services'
import { OrderClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientCancelInvoice = (locationName: string, orderId: string, invoiceId: string) => {
  return useQuery<OrderClientDto>({
    queryKey: ['clientCancelInvoice', locationName, orderId, invoiceId],
    queryFn: () => ClientInvoiceService.cancelInvoice(locationName, orderId, invoiceId),
    ...defaultQueryOptions,
    enabled: false,
  })
}
