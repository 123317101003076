import { useQuery } from '@tanstack/react-query'
import { ClientUserService } from '../services'
import { UserClientDto, APIPetClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'

export const useClientUser = (locationId: string, userId: string) => {
  return useQuery<UserClientDto>({
    queryKey: ['clientUser', locationId, userId],
    queryFn: () => {
      if (!userId) {
        return Promise.resolve({
          isLoading: false,
          isSuccess: true,
          data: null,
        } as unknown as Promise<UserClientDto>)
      }
      return ClientUserService.get(locationId, userId)
    },
    enabled: !!locationId,
    ...defaultQueryOptions,
  })
}

export const useClientUserPets = (locationName: string, id: string) => {
  return useQuery<APIPetClientDtoResponse>({
    queryKey: ['clientUserPets', locationName, id],
    queryFn: () => ClientUserService.getPets(locationName, id),
    ...defaultQueryOptions,
  })
}
