import { Typography, Stack } from '@mui/material'
import { i18WithParams as t } from '@shared/locales'

export const CheckInOut = ({ checkIn, checkOut }: { checkIn?: string[]; checkOut?: string[] }) => {
  if (!checkIn?.length || !checkOut?.length) {
    return null
  }
  const checkInDateAndTime = checkIn[0]
  const checkOutDateAndTime = checkOut[0]

  return (
    <Stack spacing={3} sx={{ px: 1, pt: 2, pb: 0 }}>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" width={75}>
          <strong>{t('global.label.checkIn')}</strong>
        </Typography>
        <Stack>{checkInDateAndTime}</Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" width={75}>
          <strong>{t('global.label.checkOut')}</strong>
        </Typography>
        <Stack>{checkOutDateAndTime}</Stack>
      </Stack>
    </Stack>
  )
}
