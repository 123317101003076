import { SvgIcon } from '@mui/material'

type Props = {
  style?: React.CSSProperties
}

export const LaserSvg = ({ style }: Props) => (
  <SvgIcon width="308" height="290" viewBox="0 0 308 290" fill="none" style={{ height: 308, width: 308, ...style }}>
    <g filter="url(#filter0_ddd_22302_21175)">
      <circle cx="304" cy="287" r="5" fill="#CE2E2E" />
    </g>
    <path d="M138.5 7L0.5 0L300 290L307.5 284.5L138.5 7Z" fill="url(#paint0_linear_22302_21175)" fillOpacity="0.3" />
    <defs>
      <filter
        id="filter0_ddd_22302_21175"
        x="296"
        y="280"
        width="16"
        height="16"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22302_21175" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_22302_21175" result="effect2_dropShadow_22302_21175" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_22302_21175" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.168627 0 0 0 0 0.196078 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_22302_21175" result="effect3_dropShadow_22302_21175" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_22302_21175" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_22302_21175"
        x1="402"
        y1="172.5"
        x2="48"
        y2="-18.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CE2E2E" stopOpacity="0.5" />
        <stop offset="1" stopColor="#CE2E2E" stopOpacity="0" />
      </linearGradient>
    </defs>
  </SvgIcon>
)
