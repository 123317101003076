import { useRoutes } from 'app/use_routes'
import { useLocation } from 'react-router-dom'
import { SignInSignUpTooltip } from 'components-ui/src/signin_tooltip/signin_signup_tooltip'
import SignUp from './signup'

export const SignIn = () => {
  const { state } = useLocation()
  const { navigateRoute } = useRoutes()

  if (state?.email) {
    return (
      <SignUp
        email={state?.email}
        userExists={state?.userExists}
        onSuccess={() => setTimeout(() => navigateRoute(state.redirect.route, state.redirect.params), 0)}
      />
    )
  }

  return (
    <SignInSignUpTooltip
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      onSuccessfulSignIn={() => {
        if (state.redirect) {
          navigateRoute(state.redirect.route, state.redirect.params)
        }
      }}
      onFailedSignIn={() => {}}
    />
  )
}
