import { useQuery } from '@tanstack/react-query'
import { ClientLocationThemesService } from '../services'
import { LocationThemeClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientLocationTheme = (locationName: string) => {
  return useQuery<LocationThemeClientDto>({
    queryKey: ['clientLocationTheme', locationName],
    queryFn: () => ClientLocationThemesService.getTheme(locationName),
    ...defaultQueryOptions,
  })
}
