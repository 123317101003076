import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

export const PanelSkeleton = () => {
  return (
    <Box sx={{ width: '100%', height: '340px' }}>
      <Skeleton variant="text" height={50} />
      <Skeleton variant="rounded" height={280} />
    </Box>
  )
}
