import { Box, Modal, SxProps, Theme } from '@mui/material'
import { ReactNode } from 'react'

const defaultSx: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  width: 408,
  maxWidth: '100%',
  borderRadius: 1,
  outline: 0,
}

type Props = {
  open: boolean
  onClose?: (event?: unknown, reason?: string) => void
  children: ReactNode
  sx?: SxProps<Theme>
}

export const SimpleModal = ({ open, onClose, children, sx }: Props) => {
  return open ? (
    <Modal open={true} onClose={onClose}>
      <Box sx={{ ...defaultSx, ...sx }}>{children}</Box>
    </Modal>
  ) : null
}
