import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_CANCEL_INVOICE_PATH } from '../types/constants'
import { OrderClientDto } from '../types/api'

export const ClientInvoiceService = {
  cancelInvoice: (locationId: string, orderId: string, invoiceId: string): Promise<OrderClientDto> => {
    return requestGooseAPI<OrderClientDto>({
      url: getAPIPath(CLIENT_CANCEL_INVOICE_PATH, { locationId, orderId, invoiceId }),
      method: 'PUT',
    })
  },
}
