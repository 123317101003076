import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { useParams } from 'react-router-dom'
import { capitalize } from 'components-ui'

interface TMenuOption {
  handle: 'addPet' | 'newRoom'
  groupIdx: number
  label: string
}

export const AddButton = () => {
  const bookingCtx = useBookingContext()
  const { serviceTypeName } = useParams()
  const isBoarding = serviceTypeName === 'boarding'

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const idx = bookingCtx.state.groups.findIndex((g) => !g.specieId)
    if (idx >= 0) {
      bookingCtx.dispatch({
        type: 'ADD_ERROR_MSG',
        payload: {
          group: idx,
          error: {
            kind: 'unselectedSpecie',
            message: t('searchBar.errors.unselectedSpecie'),
          },
        },
      })
      return
    }

    setAnchorEl(event.currentTarget)
  }
  const handleClose = (ev: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(null)

    const { currentTarget: t } = ev

    const group = t.dataset.group
    const handle = t.dataset.handle

    switch (handle) {
      case 'addPet':
        bookingCtx.dispatch({ type: 'ADD_PET_SEARCH_FIELD', payload: { groupIdx: group } })
        break
      case 'newRoom':
        bookingCtx.dispatch({ type: 'ADD_ROOM_SEARCH_FIELD' })
        break
    }
  }

  const menuOptions = bookingCtx.state.groups.reduce((acc, group, idx) => {
    const withRoom = isBoarding ? `to Room ${idx + 1}` : ''
    const label = `Add ${capitalize(group.specieName)} ${withRoom}`.trim()

    if (typeof group.maxPets !== 'undefined' && group.pets.length < group.maxPets) {
      acc.push({
        handle: 'addPet',
        groupIdx: idx,
        label,
      })
    }
    return acc
  }, [] as TMenuOption[])

  if (serviceTypeName !== 'daycare') {
    if (bookingCtx.state.groups.length < bookingCtx.state.maxGroups) {
      menuOptions.push({
        handle: 'newRoom',
        groupIdx: 0,
        label: 'Add Another Room',
      })
    }
  }

  return (
    <div>
      <Button
        id="add-pet-room"
        onClick={handleClick}
        startIcon={<AddIcon fontSize="small" />}
        disabled={menuOptions.length === 0}
        variant="outlined"
        sx={{
          p: 1,
          pl: 2,
          pr: 2,
        }}
      >
        {t('global.label.add')}
      </Button>
      <Menu
        id="add-pet-room"
        aria-labelledby="add-pet-room"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuOptions.map((menu) => (
          <MenuItem
            key={`${menu.handle}-${menu.groupIdx}`}
            onClick={handleClose}
            data-group={menu.groupIdx}
            data-handle={menu.handle}
          >
            {menu.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
