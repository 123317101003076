import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_SPECIES_PATH } from '../types/constants'
import { APISpeciesClientDtoResponse } from '../types/api'

// This gets all species in all location. locationName needs to be passed but it is not used.
export const ClientSpeciesService = {
  getSpecies: async (locationName: string, includeBreeds = false): Promise<APISpeciesClientDtoResponse> => {
    return requestGooseAPI<APISpeciesClientDtoResponse>({
      url: getAPIPath(CLIENT_SPECIES_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        includes: [...(includeBreeds ? ['breeds'] : [])],
      },
    })
  },
}
