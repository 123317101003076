import { ClientLocationVaccinesService } from 'services'
import { useQuery } from '@tanstack/react-query'
import { APILocationVaccineClientDtoResponse } from '../types/api'
import { defaultQueryOptions, DEFAULT_CACHE_TIME } from './common'

export const useClientLocationVaccines = (
  locationName: string,
  options?: { cacheEnabled?: boolean; enabled?: boolean }
) => {
  const { cacheEnabled = false, enabled = true } = options || {}
  return useQuery<APILocationVaccineClientDtoResponse>({
    queryKey: ['clientLocationVaccines', locationName],
    queryFn: () => ClientLocationVaccinesService.list(locationName),
    ...defaultQueryOptions,
    ...(cacheEnabled ? { staleTime: DEFAULT_CACHE_TIME, cacheTime: DEFAULT_CACHE_TIME } : {}),
  })
}
