import { ReactNode, useEffect } from 'react'
import { Hub } from 'aws-amplify'
import type { HubCapsule } from '@aws-amplify/core'
import { useImmerReducer } from 'use-immer'
import { useParams } from 'react-router-dom'

import { sessionReducer } from './session_reducer'
import { SessionContext, initialState } from './session_context'

export type SessionProviderProps = {
  children: ReactNode
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [state, dispatch] = useImmerReducer(sessionReducer, initialState)
  const { locationName } = useParams()
  const { Provider } = SessionContext

  useEffect(() => {
    let cancel = false
    const authHandler = async (data: HubCapsule) => {
      if (cancel) return
      switch (data.payload.event) {
        case 'signIn':
          dispatch({ type: 'AUTHENTICATE', payload: { user: data.payload.data, currentLocation: locationName } })
          break
        case 'signOut':
          dispatch({ type: 'DEAUTHENTICATE' })
          break
      }
    }
    const removeListener = Hub.listen('auth', authHandler)

    return () => {
      cancel = true
      removeListener()
    }
  })

  return <Provider value={{ state, dispatch }}> {children} </Provider>
}
