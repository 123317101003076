import { Types } from 'service-api'
import { generatePhoneFromString } from './common'

export const renderFlexFieldValue = (data?: {
  conf: Types.FlexBookingConfClientDto | Types.FlexBookingConfAdminDto
  value: string
}) => {
  const { conf = {}, value = '' } = data || {}
  switch (conf.subtype) {
    case 'PHONE': {
      return generatePhoneFromString(value)
    }
    default: {
      return value
    }
  }
}

export const parseSelectOptions = (conf: Types.FlexBookingConfClientDto | Types.FlexBookingConfAdminDto) => {
  const { match } = conf
  if (!match || !match.startsWith('^(') || !match.endsWith(')$')) {
    // match format is guaranteed to only be ^(option1|option2|option3)$ if subtype is SELECT
    // This is defensive code
    return []
  }

  return match.substring(2, match.length - 2).split('|')
}
