import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LOCATION_BOOKING_CONF_PATH } from '../types/constants'
import { APILocationBookingConfClientDtoResponse } from '../types/api'

export const ClientLocationBookingConfService = {
  getConfiguration: (locationName: string, serviceTypeId: string): Promise<APILocationBookingConfClientDtoResponse> => {
    return requestGooseAPI<APILocationBookingConfClientDtoResponse>({
      url: getAPIPath(CLIENT_LOCATION_BOOKING_CONF_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        locationServiceType: serviceTypeId,
      },
    })
  },
}
