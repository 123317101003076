import { Box } from '@mui/material'
import { useBookingContext } from '@providers/booking'
import { SpeciesButtons } from 'components-ui'
import { useCallback } from 'react'
import { LocationSpeciesClientDto } from 'service-api/src/types'

export const BookingSpeciesButtons = ({ group }: { group: number }) => {
  const bookingCtx = useBookingContext()
  const onSelectSpecie = useCallback(
    (specie: LocationSpeciesClientDto) =>
      bookingCtx.dispatch({
        type: 'SET_PET_GROUP_SPECIE',
        payload: { groupIdx: group, specieId: specie.id },
      }),
    [bookingCtx, group]
  )

  const { groups } = bookingCtx.state
  const { species, booking } = bookingCtx.state.config

  if (!species) {
    return null
  }

  const speciesAvailable =
    (booking?.species?.length || 0) > 0
      ? species.filter((s) => booking!.species!.findIndex((sp) => sp.text === s.id && sp.available) >= 0)
      : species

  if (speciesAvailable.length === 1) {
    return null
  }

  return (
    <Box>
      <SpeciesButtons species={speciesAvailable} selected={groups[group]?.specieId} onSelect={onSelectSpecie} />
    </Box>
  )
}
