import { TCartContact } from '@providers/booking/types'

export const removeDecimal = (value?: string | number | null) => {
  if (!value) return 0
  const tmpN = Number(value)
  if (Number.isNaN(tmpN)) return 0
  return tmpN * 100
}

export const getBillingAddress = (cartContact: TCartContact) => {
  return {
    street: `${cartContact.mailingAddress.streetLine1} ${cartContact.mailingAddress.streetLine2}`.trim(),
    postalCode: cartContact.mailingAddress.zipCode,
    city: cartContact.mailingAddress.city,
    country: cartContact.mailingAddress.country,
    stateOrProvince: cartContact.mailingAddress.state,
    houseNumberOrName: '',
  }
}
