import format from 'date-fns/format'
import add from 'date-fns/add'
import sub from 'date-fns/sub'

import { Types, Services } from 'service-api'
import { TBookingContextDef } from '@providers/booking/types'
import { sortOrders, buildPetGroups, buildPetGroupsTempered } from '@shared/booking_groups'
import { TRouteNames } from 'app/app.d'
import { daycareSelectionPeriod } from './pet_addons'
import { dayToJSDate } from 'components-ui'

export const continueNavigation = async (
  bookingCtx: TBookingContextDef,
  accountName: string
): Promise<TRouteNames | undefined> => {
  switch (bookingCtx.state.currentState) {
    // if we are on search results, we need to check if there are offers or petAddOns to know
    // where to jump next, to keep browser history clean
    case 'searchResults': {
      let rc = await checkOfferAddOns(bookingCtx, accountName)
      if (rc) {
        return rc
      }
      rc = await checkPetAddOns(bookingCtx, accountName)
      if (rc) {
        return rc
      }
      if (isOfferWithTemperTest(bookingCtx)) {
        return 'temperTest'
      }
      return 'detailsPolicies'
    }
    // if we are on offerAddOns, we need to check if there are petAddOns to jump to that page
    // or skip it
    case 'roomAddOns': {
      const rc = await checkPetAddOns(bookingCtx, accountName)
      if (rc) {
        return rc
      }
      // if there are no petAddOns check if the offer requires temper test
      if (isOfferWithTemperTest(bookingCtx)) {
        return 'temperTest'
      }
      // if not move to details
      return 'detailsPolicies'
    }
    case 'petAddOns': {
      removePerksWithZeroQuantity(bookingCtx)
      // check if the offer requires temper test
      if (isOfferWithTemperTest(bookingCtx)) {
        return 'temperTest'
      }
      // if not move to details
      return 'detailsPolicies'
    }
    // dates selection for DayCare
    case 'datesSelection': {
      const rc = await checkPetAddOnsDaycare(bookingCtx, accountName)
      if (rc) {
        return rc
      }
      // if (!bookingCtx.state.groups?.[0]?.pets?.[0].temperTested) {
      if (isOfferWithTemperTest(bookingCtx)) {
        return 'temperTest'
      }
      return 'detailsPolicies'
    }
    case 'temperTest': {
      return 'detailsPolicies'
    }
    case 'detailsPolicies': {
      return 'paymentForm'
    }
  }

  return
}

export const removePerksWithZeroQuantity = (bookingCtx: TBookingContextDef) => {
  bookingCtx.state.cart.groups.forEach((group, groupIdx) => {
    group.petsAddOn?.forEach((petAddOn) => {
      petAddOn.pets.forEach((pet) => {
        if (pet.qty === 0) {
          bookingCtx.dispatch({
            type: 'REMOVE_PET_ADDON',
            payload: {
              groupIdx,
              petAddOnId: petAddOn.offer.id,
              petId: pet.id,
            },
          })
        }
      })
    })
  })
}

const isOfferWithTemperTest = (bookingCtx: TBookingContextDef) => {
  return bookingCtx.state.cart.groups.some((g) => {
    return (
      g.offerSelected?.availability.availabilities?.[0].condition?.reason?.type === 'TEMPER_TEST' ||
      g.daycareSelected?.some((day) => day.availability.availabilities?.[0].condition?.reason?.type === 'TEMPER_TEST')
    )
  })
}

const filterAvailableAddOns = (results: Types.BookingSearchClientDto[]) =>
  results.filter(
    (addOn) => addOn.availabilityGroups?.some((group) => group.availabilities?.some((av) => !!av.available))
  )

const someAddOnsAvailable = (results: Types.BookingSearchByDaysAdminDto[]) =>
  results.some(
    (addOn) => addOn.availabilityGroupsByDays?.some((group) => group[0].availabilities?.some((av) => av.available))
  )

const checkOfferAddOns = async (
  bookingCtx: TBookingContextDef,
  accountName: string
): Promise<TRouteNames | undefined> => {
  const serviceTypeName = bookingCtx.state.serviceTypeName
  const services = await Services.ClientBookingSearchService.search(accountName, serviceTypeName, {
    ...buildSearchParams(bookingCtx, true),
    offersType: 'SERVICE',
  })
  const availableAddOns = filterAvailableAddOns(services.results)
  if (availableAddOns.length > 0) {
    bookingCtx.dispatch({
      type: 'LOAD_OFFERS_ADDONS',
      payload: { value: sortOrders(availableAddOns), searchState: 'roomAddOns' },
    })
    return 'searchRoomAddOns'
  }
  return
}

const checkPetAddOns = async (
  bookingCtx: TBookingContextDef,
  accountName: string
): Promise<TRouteNames | undefined> => {
  const serviceTypeName = bookingCtx.state.serviceTypeName

  const [promise1, promise2] = await Promise.allSettled([
    Services.ClientBookingSearchService.search(accountName, serviceTypeName, {
      ...buildSearchParams(bookingCtx, true),
      offersType: 'PET',
    }),
    Services.ClientBookingSearchService.search(accountName, 'grooming', {
      ...buildSearchParams(bookingCtx, false, true),
      offersType: 'PRIMARY',
    }),
  ])

  if (promise1.status === 'fulfilled') {
    const availableAddOns = filterAvailableAddOns(promise1.value.results)

    if (availableAddOns.length > 0 || (promise2.status === 'fulfilled' && promise2.value.results.length > 0)) {
      bookingCtx.dispatch({
        type: 'LOAD_PET_ADDONS',
        payload: {
          value: sortOrders(availableAddOns),
          searchState: 'petAddOns',
          grooms: promise2.status === 'fulfilled' ? promise2.value.results : [],
        },
      })
      return 'searchPetAddOns'
    }
  }

  return undefined
}

const checkPetAddOnsDaycare = async (
  bookingCtx: TBookingContextDef,
  accountName: string
): Promise<TRouteNames | undefined> => {
  const serviceTypeName = bookingCtx.state.serviceTypeName

  const params = {
    petGroups: buildPetGroups(bookingCtx.state, true),
    offersType: 'PET',
  } as Types.TBookingSearchOptions

  const dayPeriod = daycareSelectionPeriod(bookingCtx)
  if (!dayPeriod) {
    return
  }

  params.startDate = dayPeriod.startDate
  params.endDate = dayPeriod.endDate

  const groomStartDate = sub(dayToJSDate(dayPeriod.endDate) || 0, { days: 1 })

  const [promise1, promise2] = await Promise.allSettled([
    Services.ClientBookingSearchService.searchByDays(accountName, serviceTypeName, params),
    Services.ClientBookingSearchService.search(accountName, 'grooming', {
      ...params,
      startDate: format(groomStartDate, 'yyyy-MM-dd'),
      petGroups: buildPetGroups(bookingCtx.state, false, true),
      offersType: 'PRIMARY',
    }),
  ])

  if (promise1.status === 'fulfilled') {
    const availableAddOns = someAddOnsAvailable(promise1.value.results)
    if (availableAddOns || (promise2.status === 'fulfilled' && promise2.value.results.length > 0)) {
      bookingCtx.dispatch({
        type: 'LOAD_PET_ADDONS',
        payload: {
          value: sortOrders(promise1.value.results),
          searchState: 'petAddOns',
          grooms: promise2.status === 'fulfilled' ? promise2.value.results : [],
        },
      })
      return 'searchPetAddOns'
    }
  }
  return undefined
}

export const buildSearchParams = (bookingCtx: TBookingContextDef, isAddOnSearch?: boolean, isGroom?: boolean) => {
  const startDate =
    isGroom && bookingCtx.state.endDate ? sub(bookingCtx.state.endDate, { days: 1 }) : bookingCtx.state.startDate
  return {
    startDate: format(startDate || 0, 'yyyy-MM-dd'),
    endDate: format(bookingCtx.state.endDate || 0, 'yyyy-MM-dd'),
    petGroups: buildPetGroups(bookingCtx.state, isAddOnSearch, isGroom),
  }
}
export const buildSearchParamsForTemperTest = (
  bookingCtx: TBookingContextDef,
  maxDate: Date
): Types.TBookingSearchOptions => {
  const searchParams = {
    startDate: format(bookingCtx.state.startDate || 0, 'yyyy-MM-dd'),
    endDate: format(bookingCtx.state.endDate || 0, 'yyyy-MM-dd'),
    petGroups: buildPetGroupsTempered(bookingCtx.state),
  }

  // Lookup the TemperTest offer to get the price, using the same payload as a regular offer search
  const startDateStr =
    bookingCtx.state.cart.groups?.[0]?.offerSelected?.availability.availabilities?.[0].condition?.dependOnDate
  const startDate = startDateStr ? new Date(`${startDateStr}T00:00:00.000`) : new Date()
  const maxValue =
    bookingCtx.state.cart.groups?.[0]?.offerSelected?.availability.availabilities?.[0].condition?.reason?.max || 0
  const endDate = maxDate ? sub(add(maxDate, { days: 1 }), { days: Math.ceil(maxValue / 1440) }) : new Date()

  return {
    ...searchParams,
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
    offersType: 'PRIMARY',
  }
}
