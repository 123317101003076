import { Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'

import { i18WithDefault as t } from '@shared/locales'
import { Types } from 'service-api'
import { EarlyArrivalDetails } from './early_arrival_details'
import { LateDepartureDetails } from './late_departure_details'

export interface SurchargePolicyProps {
  reservation: Types.BookingReservationClientDto
}

export const SurchargePolicy = ({ reservation }: SurchargePolicyProps) => {
  const { order } = reservation
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const { startDate, endDate, startTime, endTime } = useMemo(() => {
    const invoice = order?.invoices?.find(({ items }) => {
      return items?.some(({ locationServiceTypeId }) => locationServiceTypeId === order?.primaryServiceType?.id)
    })

    return invoice?.period?.[0] || {}
  }, [order])

  const isDaycare = order?.primaryServiceType?.name === 'daycare'

  const generateSurchargeDetails = () => {
    if (isDaycare && endDate) {
      return order?.invoices
        ?.filter(({ isInStay }) => !isInStay)
        ?.map(({ items, period }, invoiceIdx) => {
          // format MM-dd-yyyy to MM/dd
          const selectedDate = period?.[0].startDate
          const daycareDateHeaderStr = selectedDate?.split('-').slice(1).join('/')
          const daycareHeader = items?.[0].displayName
            ? `${items?.[0].displayName} - ${daycareDateHeaderStr}`
            : daycareDateHeaderStr

          return (
            <LateDepartureDetails
              endDate={selectedDate}
              endTime={endTime}
              reservation={reservation}
              header={daycareHeader}
              key={invoiceIdx}
            />
          )
        })
    } else {
      return (
        <>
          {startDate && <EarlyArrivalDetails startDate={startDate} startTime={startTime} reservation={reservation} />}
          {endDate && <LateDepartureDetails endDate={endDate} endTime={endTime} reservation={reservation} />}
        </>
      )
    }
  }

  return (
    <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
      <Typography variant="h5">{t('surchargePolicy.title')}</Typography>
      <Stack mt={2}>{generateSurchargeDetails()}</Stack>
    </Paper>
  )
}
