import { Alert, Typography, Link, Stack, useTheme } from '@mui/material'
import { CustomContentProps, SnackbarContent } from 'notistack'
import React from 'react'

interface CustomVariantProps extends CustomContentProps {
  link?: {
    href: string
    text: string
  }
}

const Variant = (props: CustomVariantProps) => {
  const theme = useTheme()

  if (props.variant === 'default') {
    return props.message
  }

  if (props.link !== undefined) {
    return (
      <Alert severity={props.variant}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Typography variant="body1">{props.message}</Typography>
          <Link href={props.link.href} sx={{ textDecoration: 'none' }}>
            <Typography variant="body1bold" sx={{ color: theme.palette.text.white }}>
              {props.link.text}
            </Typography>
          </Link>
        </Stack>
      </Alert>
    )
  }

  return <Alert severity={props.variant}>{props.message}</Alert>
}

export const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomVariantProps>((props, ref) => {
  return (
    <SnackbarContent ref={ref} role="alert">
      <Variant {...props} />
    </SnackbarContent>
  )
})
CustomSnackbar.displayName = 'CustomSnackbar'
