import { useQuery } from '@tanstack/react-query'
import { ClientAgreementsService } from '../services'
import { AgreementClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientAgreements = (locationName: string, contractId?: string) => {
  return useQuery<AgreementClientDto | null>({
    queryKey: ['clientAgreements', locationName, contractId],
    queryFn: () => ClientAgreementsService.get(locationName, contractId!),
    ...defaultQueryOptions,
    enabled: typeof contractId === 'string' && contractId.length > 0,
  })
}
