import { LoadingButton } from '@mui/lab'
import { Button, useTheme } from '@mui/material'
import { i18WithDefault as t } from '@shared/locales'
import React from 'react'

type ContinueButtonProps = {
  loading?: boolean
  label?: string
} & React.ComponentProps<typeof Button>

export const ContinueButton = ({ loading, label, ...props }: ContinueButtonProps) => {
  const theme = useTheme()

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      sx={{
        backgroundColor: theme.palette.primary.main,
        '&:hover': { backgroundColor: theme.palette.primary.dark },
        color: theme.palette.getContrastText(theme.palette.primary.main),
      }}
      {...props}
    >
      {label ?? t('global.label.continue')}
    </LoadingButton>
  )
}
