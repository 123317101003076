import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { Spacer, formatNumberAsPrice } from 'components-ui'
import format from 'date-fns/format'
import type { TPetItemProps } from './types'

export const PetItemByDay = ({ groupIdx, selectedIdx }: TPetItemProps) => {
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const addOn = bookingCtx.state.cart.groups[groupIdx]?.petsAddOnDaycare?.[selectedIdx]
  const groups = bookingCtx.state.cart.groups[groupIdx]

  const onRemoveAddOnFromPet = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget: t } = ev
    bookingCtx.dispatch({
      type: 'DECREASE_PET_ADDON',
      payload: {
        addOnId: addOn?.offer?.id,
        groupIdx: t.dataset.group,
        petId: t.dataset.pet,
        field: 'petsAddOnDaycare',
        date: t.dataset.date,
      },
    })
  }

  const onAddOnFromPet = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget: t } = ev
    bookingCtx.dispatch({
      type: 'INCREASE_PET_ADDON',
      payload: {
        addOnId: addOn?.offer?.id,
        groupIdx: t.dataset.group,
        petId: t.dataset.pet,
        field: 'petsAddOnDaycare',
        date: t.dataset.date,
      },
    })
  }

  return (
    <>
      {groups.pets.map((pet, petIdx) => {
        const petAvail = addOn?.pets[petIdx].dates
        return (
          <Stack
            key={`pet-${pet.id}`}
            direction="column"
            alignItems="start"
            sx={{
              maxWidth: '640px',
              px: 2,
              py: 0.5,
              margin: '0 auto 16px',
              width: '100%',
              backgroundColor: theme.palette!.primary?.shade5,
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {pet.name}
            </Typography>
            <Spacer height={1} />

            {petAvail?.map((date) => {
              const isAvailable = date.availability?.availabilities?.[petIdx]?.available
              const price = date.availability?.availabilities?.[petIdx]?.price?.value
              return (
                <Stack
                  key={date.dateSelected}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%', mb: 1 }}
                >
                  <Typography variant="body2bold">{format(date.dateSelected, 'MMM d')}</Typography>

                  <Stack key={date.dateSelected} direction="row" justifyContent="center" alignItems="center">
                    {isAvailable ? (
                      <>
                        <Typography variant="body2bold">{price ? formatNumberAsPrice(price) : ''}</Typography>
                        <Tooltip title={t('addonItemPicker.removeItem')} disableInteractive>
                          <IconButton
                            onClick={onRemoveAddOnFromPet}
                            sx={{ p: '6px' }}
                            data-group={groupIdx}
                            data-pet={pet.id}
                            data-date={date.dateSelected}
                            disabled={!isAvailable}
                          >
                            <RemoveCircleOutlineIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        <Box component="span" sx={{ width: '35px', textAlign: 'center' }}>
                          {date.qty}
                        </Box>
                        <Tooltip title={t('addonItemPicker.addItem')} disableInteractive>
                          <IconButton
                            onClick={onAddOnFromPet}
                            sx={{ p: '6px' }}
                            data-group={groupIdx}
                            data-pet={pet.id}
                            data-date={date.dateSelected}
                            disabled={!isAvailable}
                          >
                            <AddCircleOutlineIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <Typography variant="caption" sx={{ fontStyle: 'italic', mr: 1 }}>
                        unavailable
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              )
            })}
          </Stack>
        )
      })}
    </>
  )
}
