import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance, AxiosHeaders } from 'axios'
import { Auth } from '@aws-amplify/auth'

const getBaseUrl = (baseUrl = '') => `https://${baseUrl}/api/v1`
const getClient = (url: string) => axios.create({ baseURL: url })

const setInterceptor = (client: AxiosInstance) => {
  client.interceptors.request.use((config: AxiosRequestConfig) => {
    return Auth.currentSession()
      .then((authConfig) => {
        const token = authConfig.getAccessToken()
        ;(config.headers as AxiosHeaders).set('Authorization', `Bearer ${token.getJwtToken()}`)
        return config
      })
      .catch((e) => {
        if (e === 'No current user') {
          ;(config.headers as AxiosHeaders).set('Authorization', 'OPTIONAL_AUTH')
        } else {
          // eslint-disable-next-line no-console
          console.error(e)
        }
        return config
      })
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getGooseAPIUrl = () => import.meta.env.VITE_GOOSEAPI_BASE_URL

export const requestGooseAPI = <T>(options: AxiosRequestConfig): Promise<T> => {
  const onSuccess = function S(response: AxiosResponse) {
    const { data } = response

    return data
  }

  const onError = function E(error: AxiosError) {
    return Promise.reject(error)
  }

  const client = getClient(getBaseUrl(getGooseAPIUrl()))
  setInterceptor(client)

  return client(options).then(onSuccess).catch(onError)
}

const VARIABLE_TEMPLATE = /\{\{(\w+)\}\}/gm

export const getAPIPath = (path?: string, options?: Record<string, string>) => {
  if (!path) {
    return ''
  }
  if (!options) {
    return path
  }

  const matches = path.matchAll(VARIABLE_TEMPLATE)
  for (const match of matches) {
    const [tpl, opt] = [...match]
    path = path.replace(tpl, options[opt])
  }
  return path
}

export const getAxiosErrorMessage = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const msg: string | null = error.response?.data?.error || null
    return msg ? msg.replaceAll('.', ' ') : msg
  }
  return null
}

export const isHttpGone = (error: unknown): boolean => {
  return axios.isAxiosError(error) && error.response?.status === 410
}

export const isNotFound = (error: unknown): boolean => {
  return axios.isAxiosError(error) && error.response?.status === 404
}
