import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

export const InvoiceCardSkeleton = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton variant="rounded" width={150} />
          <Skeleton variant="text" width={300} />
        </Stack>
        <Skeleton variant="rounded" width={100} />
      </Stack>
      <TableContainer sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <Skeleton variant="rectangular" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ verticalAlign: 'baseline' }}>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
