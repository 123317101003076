import { useAppThemeContext } from '@providers/app_theme'
import { useLocationContext } from '@providers/location'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useClientLocation, useClientLocationTheme } from 'service-api/src/hooks'

export const useLocationSetup = () => {
  const { accountName = '' } = useParams()
  const locationCtx = useLocationContext()
  const appThemeCtx = useAppThemeContext()

  const { data: locationTheme, isLoading: loadingLocationTheme } = useClientLocationTheme(accountName)
  const { data: location, isLoading: loadingLocation } = useClientLocation(accountName, locationTheme?.locationId ?? '')

  useEffect(() => {
    if (location?.id && locationTheme?.id) {
      locationCtx.update(location, locationTheme)
      appThemeCtx.updateTheme(locationTheme)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationTheme?.id, location?.id])

  useEffect(() => {
    locationCtx.setLoading(loadingLocationTheme || loadingLocation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingLocation, loadingLocationTheme])
}
