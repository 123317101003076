import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LOCATION_ID_PATH } from '../types/constants'
import { LocationClientDto } from '../types/api'

export const ClientLocationService = {
  getLocation: async (locationName: string, locationId: string): Promise<LocationClientDto> => {
    return requestGooseAPI<LocationClientDto>({
      url: getAPIPath(CLIENT_LOCATION_ID_PATH, { locationId: locationName, id: locationId }),
      method: 'GET',
      params: {
        includes: ['address', 'communications', 'subscriberGroup'],
      },
    })
  },
}
