import InfoIcon from '@mui/icons-material/Info'
import { Divider, Stack, Typography, useTheme } from '@mui/material'
import { i18WithParams as t } from '@shared/locales'
import { ReceiptDto } from 'service-api/src/types'

export const OffersSection = ({ receipt }: { receipt: ReceiptDto }) => {
  const { palette } = useTheme()

  return (
    <>
      <Stack spacing={2}>
        <Divider sx={{ ml: -1, mr: -1 }} />
        {receipt.invoices?.map((invoice, invoiceIndex) => {
          const isGroom = invoice.items?.[0].locationServiceType.serviceType.name === 'grooming'
          return (
            <Stack key={invoiceIndex}>
              <Stack direction="row" justifyContent="space-between" mb={2}>
                <Stack>
                  <Typography variant="h6">
                    {isGroom ? t('global.label.groomingRequest') : invoice.displayName}
                  </Typography>
                  {invoice.pets && <Typography variant="caption">{invoice.pets.join(', ')}</Typography>}
                </Stack>
                <Typography variant="h6">{invoice.subtotal}</Typography>
              </Stack>
              {invoice.items?.map((item, itemIndex) => (
                <Stack key={itemIndex} direction="row" justifyContent="space-between" ml={1} mb={1}>
                  <Typography variant="body1" flex={1.5}>
                    {isGroom ? item.title + (item.pets ? ` (${item.pets.join(', ')})` : '') : item.title}
                  </Typography>
                  {item.price && item.quantity && (
                    <Stack direction="row" spacing={0.5}>
                      <Typography variant="body1" sx={{ flex: 1 }} component="span">
                        {`${item.price} x ${item.quantity}`}
                      </Typography>
                      {item.priceVaries && (
                        <InfoIcon
                          sx={{
                            width: '20px',
                            height: '20px',
                            color: palette.action.active,
                          }}
                        />
                      )}
                    </Stack>
                  )}
                  <Typography variant="body1" sx={{ flex: 1, textAlign: 'right' }}>
                    {item.subtotal}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ textAlign: 'right', fontWeight: 'bold', marginLeft: 1 }}
                    color="text.secondary"
                  >
                    {item.taxLabel || ' '}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          )
        })}
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('global.label.subtotal')}</Typography>
          <Typography variant="h6">{receipt.subtotal}</Typography>
        </Stack>
        <Stack spacing={1}>
          {Object.entries(receipt.taxRates || {}).map(([rate, data], index) => (
            <Stack key={index} direction="row" justifyContent="space-between">
              <Typography variant="body1">
                {t('global.label.taxPercentage', { percentage: rate, label: data.label || '' })}
              </Typography>
              <Typography variant="body1">{data.value || ''}</Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, ml: -1, mr: -1 }} />
    </>
  )
}
