import { LoadingButton } from '@mui/lab'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'

import { enqueueSnackbar } from 'notistack'
import { i18WithDefault as t } from '../shared/locales'
import { TMfaProps } from './index.d'
import { initialValues, mfaSchema } from './mfa_schema'
import { captureError } from 'service-api/src/shared'

export const MFA_Code = ({ onSuccess, user }: TMfaProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [lastMsg, setLastMsg] = useState('')

  const onResendCode = useCallback(async () => {
    setLastMsg('')
    try {
      await Auth.resendSignUp(user?.getUsername() || '')
      enqueueSnackbar(t('mfaCode.resendCode.success'), { variant: 'success' })
    } catch (err) {
      setLastMsg((err as Error).message)
      enqueueSnackbar(t('mfaCode.genericError'), { variant: 'error' })
      captureError(err as Error)
      // eslint-disable-next-line no-console
      console.error('error resending code: ', err)
    }
  }, [user])

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: mfaSchema,
    onSubmit: async (values) => {
      setLastMsg('')
      setIsSubmitting(true)
      try {
        await Auth.confirmSignUp(user?.getUsername() || '', values.code)
        onSuccess(user)
      } catch (err) {
        enqueueSnackbar(t('mfaCode.genericError'), { variant: 'error' })
        captureError(err as Error)
        setLastMsg((err as Error).message)
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body2" textAlign="center">
            {t('mfaCode.title')}
          </Typography>

          <Typography variant="caption" textAlign="center">
            {t('mfaCode.subtitle')}
          </Typography>
        </Stack>
        <TextField
          fullWidth
          id="code"
          name="code"
          label={t('mfaCode.code')}
          value={formik.values.code}
          size="small"
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          sx={{ marginBottom: 3 }}
        />

        {lastMsg && (
          <Typography variant="body1" mb={3} color="error">
            {lastMsg}
          </Typography>
        )}

        <LoadingButton variant="contained" fullWidth type="submit" sx={{ mb: 3 }} loading={isSubmitting}>
          {t('global.label.continue')}
        </LoadingButton>

        <Link href="#" onClick={onResendCode}>
          {t('mfaCode.resendCode')}
        </Link>
      </Stack>
    </form>
  )
}
