import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { useCallback } from 'react'
import startOfDay from 'date-fns/startOfDay'

import { useBookingContext } from '@providers/booking'
import type { TDaysData } from './temper_test.d'

export const DatePicker = ({
  daysData,
  maxDate,
  startDate,
}: {
  daysData?: TDaysData
  startDate: Date
  maxDate: Date
}) => {
  const bookingCtx = useBookingContext()
  const { state: booking } = bookingCtx

  const shouldDisableDate = useCallback(
    (day: Date) => {
      if (day < startDate || day > maxDate) {
        return true
      }
      return !daysData?.[startOfDay(day).valueOf()]?.availabilities?.[0]?.available
    },
    [daysData, startDate, maxDate]
  )

  const onChangeDateValue = useCallback(
    (selectedDate: Date | null) => {
      if (!selectedDate) {
        return
      }
      const data = daysData?.[startOfDay(selectedDate).valueOf()]?.availabilities?.[0]
      bookingCtx.dispatch({
        type: 'SET_TEMPER_TEST_DATE',
        payload: { groupIdx: 0, value: selectedDate, price: data?.price?.value },
      })
    },
    [bookingCtx, daysData]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        showToolbar={false}
        value={booking.cart.temperTest?.date}
        minDate={startDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate}
        onChange={onChangeDateValue}
        renderInput={(_props) => <></>}
        views={['day']}
      />
    </LocalizationProvider>
  )
}
