import type { LoadingButtonOwnProps, LoadingButtonTypeMap } from '@mui/lab'
import { LoadingButton } from '@mui/lab'
import { ButtonProps, Stack } from '@mui/material'
import { useCallback, useState } from 'react'

import { i18WithDefault as t } from '@shared/locales'

type TButtonActionsProps = {
  actionEnabled?: boolean
  cancelEnabled?: boolean
  actionLabel?: string
  actionVariant?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  size?: 'small' | 'medium'
  onAction: () => Promise<void | unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel?: (...args: any[]) => Promise<void | unknown>
  onCancelLabel?: string
  onActionLabel?: string
  invertButtons?: boolean
  orientation?: 'horizontal' | 'vertical'
  actionButtonProps?: LoadingButtonOwnProps & ButtonProps
  cancelButtonProps?: LoadingButtonTypeMap & ButtonProps
}

export const ButtonActions = ({
  actionEnabled = true,
  cancelEnabled = true,
  onAction,
  onCancel,
  onCancelLabel,
  onActionLabel,
  actionVariant = 'primary',
  size = 'medium',
  orientation = 'horizontal',
  invertButtons = false,
  actionButtonProps,
  cancelButtonProps,
}: TButtonActionsProps) => {
  const [executingAction, setExecutingAction] = useState<boolean>(false)
  const [executingCancel, setExecutingCancel] = useState<boolean>(false)

  const localAction = useCallback(async () => {
    try {
      setExecutingAction(true)
      await onAction()
    } finally {
      setExecutingAction(false)
    }
  }, [onAction])
  const localCancel = useCallback(async () => {
    if (!onCancel) {
      return
    }

    try {
      setExecutingCancel(true)
      await onCancel()
    } finally {
      setExecutingCancel(false)
    }
  }, [onCancel])

  const buttons = [
    onCancel ? (
      <LoadingButton
        key="btn-cancel"
        variant="text"
        disabled={!cancelEnabled}
        loading={executingCancel}
        onClick={localCancel}
        {...cancelButtonProps}
      >
        {onCancelLabel || t('global.label.cancel')}
      </LoadingButton>
    ) : null,
    <LoadingButton
      key="btn-action"
      type="submit"
      variant="contained"
      data-testid="button-action"
      color={actionVariant}
      disabled={!actionEnabled}
      loading={executingAction}
      onClick={localAction}
      {...actionButtonProps}
    >
      {onActionLabel || t('global.label.save')}
    </LoadingButton>,
  ]

  if (invertButtons) {
    buttons.reverse()
  }

  return (
    <Stack direction={orientation === 'vertical' ? 'column' : 'row'} spacing={size === 'small' ? 1 : 2}>
      {buttons}
    </Stack>
  )
}
