import { Button, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { SearchModalContents } from '@components/search_bar/pet_search_simple/search_modal_contents'
import { i18WithDefault as t } from '@shared/locale'
import { SignInTooltipContent } from 'components-ui/src/signin_tooltip'
import { PrivacyPolicyTermsAgreement } from 'components-ui/src/privacy_policy_terms_agreement'
import TagManager from 'react-gtm-module'

export const ActionPrompt = ({
  onClose,
  enterPetsManually,
  onManualPetEntryClick,
}: {
  onClose: () => void
  enterPetsManually: boolean
  onManualPetEntryClick: () => void
}) => {
  const theme = useTheme()
  const [failedLogin, setFailedLogin] = useState<boolean>(false)
  const [showVerification, setShowVerification] = useState<boolean>(false)
  const [isRegistration, setIsRegistration] = useState<boolean>(false)
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Paper
      elevation={1}
      sx={{
        p: 3,
        borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px`,
      }}
    >
      <Stack direction={'column'} spacing={2} display={'block'}>
        {enterPetsManually ? (
          <Stack direction={'column'} spacing={2} width="100%">
            <Typography variant="h6">{t('searchBar.actionPrompt.manualEnterTitle')}</Typography>
            <SearchModalContents onDone={onClose} />
          </Stack>
        ) : (
          <Stack direction={'column'} spacing={2}>
            {isRegistration ? (
              <>
                <Typography variant="h6">{t('signin.verify.title')}</Typography>
              </>
            ) : !showVerification ? (
              <>
                <Typography variant="h6">{t('searchBar.actionPrompt.loginTitle')}</Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">{t('signin.verify.title')}</Typography>
                <Typography variant="body1">{t('forcedLogin.existingUser.info')}</Typography>
              </>
            )}
            <Stack direction={'column'} spacing={1}>
              <SignInTooltipContent
                simpleView={true}
                registerIfNeeded={true}
                onSuccessfulSignIn={onClose}
                onFailedSignIn={() => setFailedLogin(true)}
                showSignUpForm={failedLogin}
                continueLabel={t('global.label.next')}
                disableSubmit={failedLogin}
                onChange={() => setFailedLogin(false)}
                onCodeRequested={() => setShowVerification(true)}
                onRegistering={() => setIsRegistration(true)}
                onUseDifferentEmail={() => setShowVerification(false)}
                TagManager={TagManager}
              />
              {!showVerification && <PrivacyPolicyTermsAgreement sx={{ textAlign: 'center' }} />}
              {!showVerification && (
                <Button variant="text" onClick={() => onManualPetEntryClick()}>
                  {t('searchBar.actionPrompt.declineLogin')}
                </Button>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}
