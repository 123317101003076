import { useMemo } from 'react'
import { Typography, Stack } from '@mui/material'
import { i18WithParams as t } from '@shared/locales'
import { ReceiptDto } from 'service-api/src/types'
import { Spacer1 } from 'spacer'
import { formatNumberAsPrice, isEmpty } from '@shared/common'

export const Footer = ({ receipt, dueToday }: { receipt: ReceiptDto; dueToday?: number | null }) => {
  const dueTodayPercentage = useMemo(() => {
    if (!receipt.total || !dueToday) return ''
    const totalVal = parseFloat(receipt.total.substring(1))
    const percent = totalVal ? Math.round((dueToday / totalVal) * 100) : 0
    return `${percent}%`
  }, [dueToday, receipt.total])

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Typography variant="h5">{t('global.label.total')}</Typography>
        <Typography variant="h5">{receipt.total}</Typography>
      </Stack>
      {!isEmpty(dueToday) && (
        <Stack direction="row" justifyContent="space-between" mt={3}>
          <Typography variant="h5">{t('global.label.dueToday', { percentage: dueTodayPercentage })}</Typography>
          <Typography variant="h5">{formatNumberAsPrice(dueToday!)}</Typography>
        </Stack>
      )}
      {!!receipt.payments?.length && (
        <Stack mt={3}>
          <Typography variant="h5">{t('global.label.payments')}</Typography>
          <Spacer1 />
          {receipt.payments.map(({ displayName, amount }, idx) => (
            <Stack key={`${displayName}-${idx}`} direction="row" justifyContent="space-between" mt={1} ml={1} mr={-1}>
              <Typography variant="body1">{displayName}</Typography>
              <Typography variant="body1">{amount}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
      {!!receipt.adjustments?.length && (
        <Stack mt={3}>
          <Typography variant="h5">{t('global.label.adjustments')}</Typography>
          <Spacer1 />
          {receipt.adjustments.map(({ displayName, amount }, idx) => (
            <Stack key={`${displayName}-${idx}`} direction="row" justifyContent="space-between" mt={1} ml={1} mr={-1}>
              <Typography variant="body1">{displayName}</Typography>
              <Typography variant="body1">{amount}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </>
  )
}
