import { Box, Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useDrawersContext } from '@providers/drawers/drawers_context'
import { DrawersProvider } from '@providers/drawers/drawers_provider'
import { isFuture } from 'date-fns'
import { useParams } from 'react-router-dom'

import Skeleton from '@mui/material/Skeleton'
import { i18WithDefault as t } from '@shared/locale'
import { getOrderDates } from 'components-ui'
import { useEffect, useState } from 'react'
import { Hooks, Types } from 'service-api'
import { ContractDrawer } from './contract_drawer'
import { OwnerDrawer } from './owner_drawer'
import { PetDrawer } from './pet_drawer'

type Props = {
  flexBookingConfs: Types.FlexBookingConfClientDto[]
  order: Types.OrderClientDto
  onSave: (newOrder: Types.OrderClientDto) => void
}
const IntakeInner = ({ flexBookingConfs, order, onSave }: Props) => {
  const { accountName: locationName = '' } = useParams()
  const [isPressed, setIsPressed] = useState(false)
  const [isAgreementSigned, setAgreementSigned] = useState(false)
  const [allowExpandContract, setAllowExpandContract] = useState(true)
  const { palette } = useTheme()
  const ctx = useDrawersContext()
  const { maxDate: maxInvoiceStartDate } = getOrderDates(order)
  const contract = Hooks.useClientContracts(locationName, Types.ContractType.ServiceAgreement)
  const agreement = Hooks.useClientAgreements(locationName, contract?.data?.id)
  const isFutureOrder = !!maxInvoiceStartDate && !isFuture(maxInvoiceStartDate)

  useEffect(() => {
    if (agreement.isSuccess && agreement.data) {
      setAgreementSigned(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement.isSuccess])

  return (
    <Paper elevation={0} sx={{ backgroundColor: palette.action.hover, padding: 4 }}>
      <Stack direction="column" spacing={7} alignItems="center">
        <Stack direction="column" spacing={3} alignItems="center">
          <Box component="img" src="/assets/doggie.png" width="100px" height="100px" borderRadius="100%" />
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography variant="h2" textAlign="center">
              {t('intake.confirmation.title')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('intake.confirmation.content')}
            </Typography>
          </Stack>
        </Stack>
        {!isPressed ? (
          <Paper elevation={2} sx={{ width: { xs: '100%', sm: 'inherit' } }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => setIsPressed(true)}
              color="primary"
              sx={{
                width: { xs: '100%', sm: 'inherit' },
              }}
            >
              {t('intake.confirmation.button')}
            </Button>
          </Paper>
        ) : (
          <Stack spacing={2} sx={{ width: '100%' }}>
            {(order.pets || []).map((pet, index) => (
              <PetDrawer
                key={pet.id}
                invoicePet={pet}
                flexBookingConfs={flexBookingConfs}
                readOnly={isFutureOrder}
                shouldOpenByDefault={!index}
                onSave={(newOrder) => {
                  ctx.dispatch({ type: 'CLOSE_DRAWER' })
                  onSave(newOrder)
                }}
              />
            ))}
            <OwnerDrawer
              orderId={order.id!}
              ownerOrderData={order.userData?.[0]?.data}
              userProfile={order.owner?.userProfile?.[0]}
              flexBookingConfs={flexBookingConfs}
              readOnly={isFutureOrder}
              onSave={(newOrder) => {
                ctx.dispatch({ type: 'CLOSE_DRAWER' })
                onSave(newOrder)
              }}
            />
            {contract.data && agreement.isLoading ? (
              <Skeleton variant="rectangular" width="100%" height={76} />
            ) : (
              contract.isSuccess &&
              contract.data &&
              !isAgreementSigned && (
                <ContractDrawer
                  contract={contract.data}
                  allowExpand={allowExpandContract}
                  onSave={() => {
                    ctx.dispatch({ type: 'CLOSE_DRAWER' })
                    setAllowExpandContract(false)
                  }}
                />
              )
            )}
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}

export const Intake = (props: Props) => {
  return (
    <DrawersProvider>
      <IntakeInner {...props} />
    </DrawersProvider>
  )
}
