import { Link, SxProps, Theme, Typography, useTheme, Box } from '@mui/material'
import { i18WithDefault as t, i18WithComponent as tc } from '@shared/locales'
import { PRIVACY_POLICY_URL, TOS_URL } from '@shared/constants'

type PrivacyPolicyTermsAgreementProps = {
  sx?: SxProps<Theme>
}
export const PrivacyPolicyTermsAgreement = ({ sx }: PrivacyPolicyTermsAgreementProps) => {
  const theme = useTheme()

  return (
    <Box sx={sx}>
      <Typography variant="body2bold" style={{ fontSize: 12 }}>
        {tc('logInForm.privacyPolicyAndTerms', {
          terms: (
            <Link
              target="_blank"
              href={PRIVACY_POLICY_URL}
              sx={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
              }}
            >
              {t('logInForm.tos')}
            </Link>
          ),
          policy: (
            <Link
              target="_blank"
              href={TOS_URL}
              sx={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
              }}
            >
              {t('logInForm.privacyPolicy')}
            </Link>
          ),
        })}
      </Typography>
    </Box>
  )
}
