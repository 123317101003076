import { Header } from '@components/header'
import { useMonitoringData } from 'hooks/use_monitoring_data'
import { Outlet } from 'react-router-dom'

export const PageLayout = () => {
  useMonitoringData()

  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}
