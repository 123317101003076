import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_USERPROFILE_ID_PATH } from '../types/constants'
import { UserProfileClientDto, UserProfileUpdateInput } from '../types/api'

export const ClientUserProfileService = {
  get: async (locationId: string, userId: string): Promise<UserProfileClientDto> => {
    return requestGooseAPI<UserProfileClientDto>({
      url: getAPIPath(CLIENT_USERPROFILE_ID_PATH, { locationId, id: userId }),
      method: 'GET',
    })
  },

  put: async (locationId: string, userId: string, data: UserProfileUpdateInput): Promise<UserProfileClientDto> => {
    return requestGooseAPI<UserProfileClientDto>({
      url: getAPIPath(CLIENT_USERPROFILE_ID_PATH, { locationId, id: userId }),
      method: 'PUT',
      data,
    })
  },
}
