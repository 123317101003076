import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker'
import { MouseEvent, ReactNode, useCallback, useState } from 'react'

import { useBookingContext } from '@providers/booking'
import { maximumSelectableDate, minimumSelectableDate } from '@shared/service_types'
import { add, format } from 'date-fns'

export const StaticModalPicker = ({ children }: { children: ReactNode }) => {
  const bookingCtx = useBookingContext()
  const { config } = bookingCtx.state
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [value, setValue] = useState<DateRange<Date>>([
    bookingCtx.state.startDate || null,
    bookingCtx.state.endDate || null,
  ])
  const onClick = useCallback(
    (ev: MouseEvent<HTMLElement>) => {
      if (bookingCtx.state.startDate) {
        setAnchorEl(ev.currentTarget)
      }
    },
    [bookingCtx.state.startDate]
  )

  const onCloseModal = useCallback(() => setAnchorEl(null), [])
  const onChangeDateValue = useCallback((value: DateRange<Date>) => {
    setValue(value)
  }, [])
  const onAcceptValue = useCallback(
    (value: DateRange<Date>) => {
      bookingCtx.dispatch({ type: 'SET_BOOKING_DATES', payload: { value } })
      setAnchorEl(null)
    },
    [bookingCtx]
  )
  const onShouldDisableDate = useCallback(
    (day: Date) => {
      const strDay = format(day, 'yyyy-MM-dd')
      if (bookingCtx.state.closedDates.some((d) => d(strDay))) {
        return true
      }

      if (bookingCtx.state.openDays.some((d) => d(strDay))) {
        return false
      }

      return true
    },
    [bookingCtx]
  )

  const minSelectableDate = minimumSelectableDate(add(new Date(), { days: 1 }), config.booking)
  const maxSelectableDate = maximumSelectableDate(new Date(), bookingCtx.state)
  const renderStyle = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop'

  return (
    <Box>
      <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
        {children}
      </Box>
      {anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          onClose={onCloseModal}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={anchorEl}
          slotProps={{ paper: { style: { marginTop: '24px' } } }}
        >
          <Box>
            <Paper elevation={0} sx={{ p: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDateRangePicker
                  displayStaticWrapperAs={renderStyle}
                  showToolbar={false}
                  value={value}
                  minDate={minSelectableDate}
                  maxDate={maxSelectableDate}
                  shouldDisableDate={onShouldDisableDate}
                  onChange={onChangeDateValue}
                  onAccept={onAcceptValue}
                  renderInput={(_starProps, _endProps) => {
                    return <></>
                  }}
                />
              </LocalizationProvider>
            </Paper>
          </Box>
        </Popover>
      )}
    </Box>
  )
}
