import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
  Box,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect, useMemo, useState } from 'react'
import { Order, Row, TableProps } from './table.d'
import { ROWS_PER_PAGE_OPTIONS, usePagination } from './use_pagination'

export const SharedTable = ({
  rows,
  head,
  title,
  defaultOrder = 'desc',
  defaultOrderBy,
  isLoading,
  emptyTableMessage,
  shouldResetPage,
  hasMore = false,
  loadMore = (_: number) => void 0,
  onSort = (_: string, __: Order, ___: number) => void 0,
  onRowClick,
  onChangeRowsPerPage,
  component = Paper,
  showPagination = true,
  rowsPerPageConfig = ROWS_PER_PAGE_OPTIONS,
  dropdownFilters,
  isScrollable = false,
}: TableProps) => {
  const { page, resetPage, isLastPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(
    rows.length,
    loadMore,
    rowsPerPageConfig?.[0]
  )
  const [order, setOrder] = useState<Order>(defaultOrder)
  const [orderBy, setOrderBy] = useState<keyof Row | undefined>(defaultOrderBy)
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    onChangeRowsPerPage?.(rowsPerPage)
  }, [rowsPerPage])

  useEffect(() => {
    if (shouldResetPage || !rows.length) {
      resetPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length, shouldResetPage])

  const hasData = useMemo(() => rows.length > 0, [rows])
  const rowsToRender = useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, page, rowsPerPage]
  )
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0),
    [rows, page, rowsPerPage]
  )

  const handleSort = (cellId: string) => {
    const isAsc = orderBy === cellId && order === 'asc'
    const newOrder = isAsc ? 'desc' : 'asc'
    onSort(cellId, newOrder, rowsPerPage)
    resetPage()
    setOrder(newOrder)
    setOrderBy(cellId)
  }

  const renderRows = () => {
    if (isLoading) {
      return [1, 2, 3].map((num) => (
        <TableRow key={num}>
          <TableCell colSpan={head.length}>
            <Skeleton />
          </TableCell>
        </TableRow>
      ))
    }
    if (!hasData) {
      return (
        <TableRow>
          <TableCell colSpan={head.length} sx={{ textAlign: 'center' }}>
            {emptyTableMessage}
          </TableCell>
        </TableRow>
      )
    }
    return rowsToRender.map((row, index) => (
      <TableRow
        key={index}
        hover={!!onRowClick}
        sx={{ cursor: onRowClick ? 'pointer' : 'default' }}
        onClick={() => onRowClick && onRowClick(String(row.id))}
      >
        {head.map((headCell) => (
          <TableCell key={`${headCell.id} ${index}`} align={headCell.align || 'left'}>
            {row[headCell.id]}
          </TableCell>
        ))}
      </TableRow>
    ))
  }

  return (
    <TableContainer
      component={component}
      sx={{
        pl: 2,
        pr: 2,
        borderRadius: isBelowMD ? 0 : `${theme.shape.borderRadius}px`,
        overflow: isScrollable ? 'hidden' : 'auto',
      }}
    >
      {title && (
        <Typography sx={{ pt: 2, pb: 2.5 }} variant="h5">
          {title}
        </Typography>
      )}
      {dropdownFilters && <Box sx={{ background: `${theme.palette.background.default}` }}>{dropdownFilters}</Box>}
      <Table aria-label="table" sx={isScrollable ? { display: 'block', overflow: 'auto' } : {}}>
        <TableHead>
          <TableRow>
            {head.map(({ id, label, sortable, sortDisabled, align = 'left' }) => (
              <TableCell key={id} sortDirection={orderBy === id ? order : false} align={align}>
                {!sortable ? (
                  <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
                ) : (
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={() => (sortDisabled ? null : handleSort(id))}
                    disabled={sortDisabled}
                  >
                    <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows()}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={head.length} />
            </TableRow>
          )}
        </TableBody>
        {hasData && showPagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                page={page}
                rowsPerPageOptions={rowsPerPageConfig}
                rowsPerPage={rowsPerPage}
                count={-1}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to }) => `${from} - ${to}`}
                nextIconButtonProps={{ disabled: isLastPage && !hasMore }}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
