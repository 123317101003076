import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo } from 'react'
import CheckIcon from '@mui/icons-material/Check'

import { Types } from 'service-api'
import { BoardingDefaultImg } from '../asset'
import { AssetImage } from '../asset_image'
import { formatNumberAsPrice, isEmpty, i18WithDefault as t } from '../shared'
import { Spacer2 } from '../spacer'
import { getBaseGroup, isDaycareOfferUnavailable } from './helper'
import { TOfferCardProps } from './offer_card.d'
import { OfferIncludes } from './offer_includes'

export const OfferCard = ({
  searchResult,
  petGroup,
  onSelectOffer,
  showIncludes = true,
  serviceTypeName,
  specieTypeName,
  selected,
  totalPets,
}: TOfferCardProps) => {
  const isDayCare = serviceTypeName === 'daycare'
  const availabilityGroups: Types.AvailabilityGroupClientDto[] | Types.AvailabilityGroupClientDto[][] | undefined =
    useMemo(
      () =>
        (searchResult as Types.BookingSearchClientDto).availabilityGroups ||
        (searchResult as Types.BookingSearchByDaysClientDto).availabilityGroupsByDays ||
        [],
      [searchResult]
    )
  const { offer } = searchResult

  const group = useMemo(
    () => getBaseGroup(serviceTypeName, petGroup, availabilityGroups),
    [availabilityGroups, petGroup, serviceTypeName]
  )

  const room = group.availabilities?.[0] || {}
  const hasImage = Boolean(offer?.assetUsages?.[0]?.asset)

  const isOfferAvailable = useMemo(
    () =>
      isDayCare
        ? !isDaycareOfferUnavailable(availabilityGroups as Types.AvailabilityGroupClientDto[][] | undefined)
        : !!room.available,
    [isDayCare, room.available, availabilityGroups]
  )

  const onSelectOfferClick = useCallback(
    () => onSelectOffer(offer!, group, petGroup),
    [offer, group, onSelectOffer, petGroup]
  )

  const renderIncludes = () => {
    return (
      <Box>
        <Spacer2 />
        <Typography component="h6">
          <strong>{t('global.label.includes')}:</strong>
        </Typography>
        <Spacer2 />
        {offer?.serviceOffers?.map((service) => (
          <OfferIncludes
            key={service.id!}
            service={service}
            locationPricingStrategy={group.pricingStrategy || 'DAILY'}
            showStrategy={false}
          />
        ))}
      </Box>
    )
  }

  const strategyStr =
    group.pricingStrategy === 'NIGHTLY' ? t('global.label.strategy.night') : t('global.label.strategy.day')
  const price = isDayCare
    ? isEmpty(room.price?.value)
      ? undefined
      : room.price!.value! / (totalPets ?? 1)
    : room?.price?.rate
  const priceVaries = offer?.priceVaries

  return (
    <Box>
      <Typography variant="h2" mb={3}>
        {offer?.displayName}
      </Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4 }}>
        {hasImage ? <AssetImage asset={offer!.assetUsages![0].asset!} /> : <BoardingDefaultImg />}
        <Stack direction="column" width="100%">
          <>
            <Typography variant="body1" color="text.secondary">
              {offer?.description}
            </Typography>

            {showIncludes && renderIncludes()}
            <Divider sx={{ my: 2 }} />
            <Stack
              direction={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              }}
              spacing={3}
              justifyContent="end"
              alignItems="center"
            >
              {isOfferAvailable ? (
                <>
                  {!isEmpty(price) && (
                    <Typography variant="h5" data-testid="price-tag">
                      <Typography variant="body1" component="span" sx={{ paddingRight: '4px' }}>
                        {priceVaries && !isDayCare && t('offers.startsAt')}
                      </Typography>
                      <strong>
                        {isDayCare && <span>{t('global.label.from')}&nbsp;</span>}
                        {formatNumberAsPrice(price)}
                      </strong>
                      <Typography variant="body1" component="span">
                        /
                        {isDayCare && (
                          <span>
                            {specieTypeName} {t('global.label.per')}&nbsp;
                          </span>
                        )}
                        <span>{strategyStr}</span>
                      </Typography>
                    </Typography>
                  )}

                  <Button
                    size="large"
                    variant={selected ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={onSelectOfferClick}
                    startIcon={selected ? <CheckIcon /> : null}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'auto',
                        lg: 'auto',
                        xl: 'auto',
                      },
                    }}
                  >
                    {t(selected ? 'global.label.selected' : 'global.label.select')}
                  </Button>
                </>
              ) : (
                <Button
                  size="large"
                  disabled
                  variant="contained"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: 'auto',
                      lg: 'auto',
                      xl: 'auto',
                    },
                  }}
                >
                  {t('offerCard.noAvailable.callToBook')}
                </Button>
              )}
            </Stack>
          </>
        </Stack>
      </Stack>
    </Box>
  )
}
