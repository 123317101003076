import { useMemo } from 'react'
import { Alert, Paper, Typography } from '@mui/material'
import { useBookingContext } from '@providers/booking'
import { i18WithParams as t } from '@shared/locale'
import { FootstepIcon } from '@shared/icons/footstep_icon'
import { Types } from 'service-api'
import {
  allNonTestedPetsCannotBookOnline,
  petFailedTemperTest,
  tooSoonToBook,
} from 'components-ui/src/shared/availability'
import { useLocationContext } from '@providers/location'
import { formatListWithConjunction, generatePhoneFromString } from 'components-ui'

const WarningBannerInner = () => {
  const { state: bookingState } = useBookingContext()
  const { location } = useLocationContext()
  const isDaycare = bookingState.serviceTypeName === 'daycare'
  const petGroup = bookingState.currentGroup
  const phoneNumber = useMemo(
    () => generatePhoneFromString(location?.communications?.find((c) => c.type === 'PHONE')?.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  )

  let group: Types.AvailabilityGroupClientDto | undefined = undefined
  if (isDaycare) {
    ;(bookingState.data.offersSearchResults as Types.BookingSearchByDaysClientDto[])?.forEach((result) => {
      if (group) {
        return
      }
      result.availabilityGroupsByDays?.forEach((g) => {
        if (group) {
          return
        }
        if (
          g?.[0]?.availabilities?.[0]?.unavailableReason?.type === 'TEMPER_TEST' ||
          g?.[0]?.availabilities?.[0]?.condition?.reason?.type === 'TEMPER_TEST'
        ) {
          group = g?.[0]
        }
      })
    })
  } else {
    group = (bookingState.data.offersSearchResults as Types.BookingSearchClientDto[])?.[0]?.availabilityGroups?.[
      petGroup
    ]
  }

  const temperTestName = bookingState.data.temperTest?.displayName || t('temperTest.defaultName')
  const affectedPetsNames = (group?.availabilities?.[0]?.affectedByPets || []).map((pet) => pet.displayName)
  const baseAlertProps = {
    component: Paper,
    elevation: 1,
    square: true,
    severity: 'warning' as const,
    icon: <FootstepIcon sx={{ fill: 'white !important' }} />,
    sx: { borderRadius: 0 },
  }

  if (allNonTestedPetsCannotBookOnline(group)) {
    return (
      <Alert {...baseAlertProps}>
        {affectedPetsNames.length ? (
          <>
            <Typography variant="body1">
              {t(
                `searchResults.warning.allNonTestedPetsCannotBook.existingPet${
                  affectedPetsNames.length > 1 ? 's' : ''
                }.main`,
                { temperTestName, petNames: formatListWithConjunction(affectedPetsNames) }
              )}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('searchResults.warning.allNonTestedPetsCannotBook.existingPets.sub', { phoneNumber }),
              }}
            />
          </>
        ) : (
          <>
            <Typography variant="body1">
              {t('searchResults.warning.allNonTestedPetsCannotBook.newPets.main', { temperTestName })}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('searchResults.warning.allNonTestedPetsCannotBook.newPets.sub', { phoneNumber }),
              }}
            />
          </>
        )}
      </Alert>
    )
  }

  if (petFailedTemperTest(group)) {
    return (
      <Alert {...baseAlertProps}>
        <Typography variant="body1">
          {t('searchResults.warning.petFailed.main', {
            temperTestName,
            petNames: formatListWithConjunction(affectedPetsNames),
          })}
        </Typography>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: t('searchResults.warning.petFailed.sub', { phoneNumber }),
          }}
        />
      </Alert>
    )
  }

  if (!isDaycare && tooSoonToBook(group)) {
    return (
      <Alert {...baseAlertProps}>
        {affectedPetsNames.length ? (
          <>
            <Typography variant="body1">
              {t(`searchResults.warning.tooSoonToBook.existingPet${affectedPetsNames.length > 1 ? 's' : ''}.main`, {
                temperTestName,
                petNames: formatListWithConjunction(affectedPetsNames),
              })}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('searchResults.warning.tooSoonToBook.existingPets.sub', { phoneNumber }),
              }}
            />
          </>
        ) : (
          <>
            <Typography variant="body1">
              {t('searchResults.warning.tooSoonToBook.newPets.main', { temperTestName })}
            </Typography>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t('searchResults.warning.tooSoonToBook.newPets.sub', { phoneNumber }),
              }}
            />
          </>
        )}
      </Alert>
    )
  }

  return null
}

export const WarningBanner = () => {
  const {
    state: { currentState },
  } = useBookingContext()
  if (currentState === 'searchResults' || currentState === 'datesSelection') {
    return <WarningBannerInner />
  }
  return null
}
