import { PickersDay, PickersDayProps } from '@mui/x-date-pickers-pro'
import { styled } from '@mui/material/styles'

export const CustomPickersDay = styled(PickersDay, { shouldForwardProp: (prop) => prop !== 'selected' })<
  PickersDayProps<Date>
>(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<PickersDayProps<Date>>
