import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_ASSET_USAGES_PATH, CLIENT_ASSET_USAGES_ID_PATH } from '../types/constants'
import { AssetUsageClientDto, AssetUsageCreateInput, AssetUsageUpdateInput } from '../types/api'

export const ClientAssetUsageService = {
  create: async (locationName: string, assetId: string, data: Partial<AssetUsageCreateInput>) => {
    return requestGooseAPI<AssetUsageClientDto>({
      url: getAPIPath(CLIENT_ASSET_USAGES_PATH, { locationId: locationName }),
      method: 'POST',
      data: {
        assetId,
        ...data,
      },
    })
  },

  update: async (locationName: string, assetUsageId: string, data: AssetUsageUpdateInput) => {
    return requestGooseAPI<AssetUsageClientDto>({
      url: getAPIPath(CLIENT_ASSET_USAGES_ID_PATH, { locationId: locationName, id: assetUsageId }),
      method: 'PUT',
      data,
    })
  },

  remove: (locationName: string, assetUsageId: string) =>
    requestGooseAPI<AssetUsageClientDto>({
      url: getAPIPath(CLIENT_ASSET_USAGES_ID_PATH, { locationId: locationName, id: assetUsageId }),
      method: 'DELETE',
    }),
}
