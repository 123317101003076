import { current, original } from 'immer'

import type { TDrawersContext, TReducerActions } from './types'

const actions: TReducerActions = {
  OPEN_DRAWER: (state, payload) => {
    state.openDrawerId = payload?.value
  },
  CLOSE_DRAWER: (state, _) => {
    state.openDrawerId = null
    state.isOpenDrawerDirty = false
  },
  SET_IS_OPEN_DRAWER_DIRTY: (state, payload) => {
    state.isOpenDrawerDirty = payload?.value
  },
}

/* eslint-disable no-console */
export const drawersReducer = (state: TDrawersContext, action: TReducerAction) => {
  const fx = actions[action.type]
  fx?.(state, action.payload)

  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(action.type)
    if (!fx) {
      console.log('%c action not found ', 'background: #FF0000; color: #fff')
    }
    console.log('start', original(state))
    console.log('payload', action.payload)
    console.log('after', current(state))
    console.groupEnd()
  }
}
/* eslint-enable no-console */
