import { Paper, Divider, Stack, Skeleton, Box } from '@mui/material'
export const PurchaseReceiptSkeleton = () => {
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Skeleton variant="text" width={400} />
      <Skeleton variant="text" width="100%" />
      <Divider sx={{ borderWidth: 'unset', mb: 4, mt: 2 }} />
      <Skeleton variant="text" width={500} />
      <Skeleton variant="text" width={200} sx={{ mt: 2, mb: 2 }} />
      <Stack spacing={2}>
        <Divider sx={{ ml: -2, mr: -2 }} />
        {[1, 2].map((_, invoiceIndex) => (
          <Stack key={invoiceIndex}>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Stack>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={100} />
              </Stack>
              <Box>
                <Skeleton variant="text" width={100} />
              </Box>
            </Stack>
            {[1, 2].map((_, itemIndex) => (
              <Stack key={itemIndex} direction="row" justifyContent="space-between" ml={1} mb={1}>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={100} />
              </Stack>
            ))}
          </Stack>
        ))}
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Skeleton variant="text" width={300} />
            <Skeleton variant="text" width={100} />
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mt: 1, ml: -2, mr: -2 }} />
      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={100} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" mt={3}>
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={100} />
      </Stack>
    </Paper>
  )
}
