import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import useTheme from '@mui/material/styles/useTheme'
import { CatIcon, DogIcon } from 'asset'
import { LocationSpeciesClientDto } from 'service-api/src/types'

type Props = {
  species: LocationSpeciesClientDto[]
  selected: string
  onSelect: (id: LocationSpeciesClientDto) => void
  size?: 'small' | 'medium' | 'large' | undefined
}

const buttons: Record<string, React.ReactNode> = {
  dog: <DogIcon viewBox="0 -2 28 28" />,
  cat: <CatIcon viewBox="0 -2 28 28" />,
}

export const SpeciesButtons = ({ species, selected, onSelect, size = 'medium' }: Props) => {
  const { palette } = useTheme()

  if (!species?.length) {
    return null
  }

  return (
    <ButtonGroup>
      {species.map((specie) => {
        const btnProps: GenericSimpleBag = {
          id: specie.id,
          title: specie.displayName || specie.species?.displayName,
          onClick: () => onSelect(specie),
          variant: selected === specie.id ? 'contained' : 'outlined',
          size,
        }

        if (selected === specie.id) {
          btnProps.sx = {
            border: `1px solid ${palette.primary.main}`,
            backgroundColor: palette.primary.shade30,
            '& svg': { fill: palette.primary.main },
            '&:hover svg': { fill: 'white' },
            '&:hover': { boxShadow: 'none' },
            boxShadow: 'none',
          }
        }

        return (
          <Button key={specie.id} {...btnProps}>
            {buttons[specie.species?.name || specie.name || '']}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}
