import slug from 'slug'
import * as yup from 'yup'
import { format } from 'date-fns'
import { i18WithParams as t } from '@shared/locale'
import { BreedClientDto, PetClientDto, PetProfileClientDto } from 'service-api/src/types'
import { ClientPetProfilesService, ClientPetsService } from 'service-api/src/services'
import { dayToJSDate } from 'components-ui'

export type TFormikValues = {
  id: string
  profileId: string
  displayName: string
  breed: BreedClientDto
  weight: string
  birthdate: Date | string
  sex: string
  altered: string
}

export const petSchema = yup.object({
  displayName: yup.string().max(200).required().label(t('global.label.name')),
  breed: yup.object({
    id: yup.string().max(200).required().label(t('global.label.breed')),
  }),
  weight: yup.string().max(200).required().label(t('global.label.weight')),
})

export const setupInitialValues = ({
  pet,
  petProfile,
}: {
  pet?: PetClientDto
  petProfile?: PetProfileClientDto
}): TFormikValues => {
  const profile = pet ? pet?.petProfile?.[0] : petProfile
  return {
    id: pet?.id || profile?.pet?.id || '',
    profileId: profile?.id || '',
    displayName: pet?.displayName || profile?.displayName || '',
    breed: profile?.breed ?? {},
    weight: String(profile?.weight || ''),
    birthdate: dayToJSDate(profile?.birthdate) || '',
    sex: profile?.sex || '',
    altered: profile?.altered ? 'yes' : 'no',
  }
}

const buildRequestBody = (values: TFormikValues, petFamilyId: string) => {
  const name = slug(values.displayName)
  const petBody = {
    name,
    displayName: values.displayName.trim(),
    petFamilyId,
  }
  return {
    petBody,
    petProfileBody: {
      ...petBody,
      petId: values.id || undefined,
      breedId: values.breed.id!,
      weight: parseInt(values.weight || '0'),
      birthdate: values.birthdate ? format(new Date(values.birthdate), 'yyyy-MM-dd') : undefined,
      sex: values.sex ? <'MALE' | 'FEMALE'>values.sex : undefined,
      altered: values.altered === 'yes',
    },
  }
}

const buildPet = (petData: PetClientDto = {}, petProfileData: PetProfileClientDto = {}, values: TFormikValues) => ({
  ...petData,
  petProfile: [
    {
      breed: values.breed,
      ...petProfileData,
    },
  ],
})

export const createPet = async (values: TFormikValues, locationId: string, petFamilyId: string) => {
  const { petBody, petProfileBody } = buildRequestBody(values, petFamilyId)
  try {
    const petRes = await ClientPetsService.create(locationId, petFamilyId, {
      ...petBody,
      petProfile: {
        create: [petProfileBody],
      },
    })
    return buildPet(petRes, petRes.petProfile?.[0], values)
  } catch (e) {
    return e
  }
}

export const updatePet = (values: TFormikValues, locationId: string, petFamilyId: string) => {
  const { petBody, petProfileBody } = buildRequestBody(values, petFamilyId)
  return Promise.all([
    ClientPetsService.update(locationId, values.id, petFamilyId, petBody),
    ClientPetProfilesService.update(locationId, values.profileId, petFamilyId, petProfileBody),
  ])
    .then(([petRes, petProfileRes]) => buildPet(petRes, petProfileRes, values))
    .catch((e) => e)
}

export const deletePet = (petId: string, locationId: string, petFamilyId: string) => {
  return ClientPetsService.delete(locationId, petId!, petFamilyId)
    .then((res) => res)
    .catch((e) => e)
}
