import { Typography, Divider, useTheme, Stack } from '@mui/material'
import { Phone, Place } from '@mui/icons-material'
import { i18WithParams as t } from '@shared/locales'
import { DefaultThemeOptions } from '@themes/theme'

export const Header = ({ name, address, phone }: { name?: string; address?: string; phone?: string }) => {
  const palette = (useTheme() as DefaultThemeOptions).palette!
  return (
    <>
      <Typography variant="h4">
        <strong>{name}</strong>
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: 1, color: palette.text.secondaryShade70 }}
      >
        <Stack direction="row" alignItems="center">
          <Place />
          <Typography variant="body2">
            <strong>{address}</strong>
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" flexShrink={0}>
          <Phone />
          <Typography variant="body2">
            <strong>{phone}</strong>
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: palette.primary.main, borderWidth: 'unset', mb: 4 }} />
      <Typography variant="h5">{t('global.label.bookingSummary')}</Typography>
    </>
  )
}
