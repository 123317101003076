import { AlertTitle, useTheme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import add from 'date-fns/add'
import startOfDay from 'date-fns/startOfDay'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BackContinue } from '@components/back_continue/back_continue'
import { useBookingContext } from '@providers/booking'
import { MAX_WIDGET_SIZE, STICKY_FOOTER_HEIGHT } from '@shared/constants'
import { FootstepIcon } from '@shared/icons/footstep_icon'
import { buildSearchParamsForTemperTest } from '@shared/header_navigation'
import { i18WithParams as t } from '@shared/locale'
import { Spacer, Spacer2, dayToJSDate, formatListWithConjunction } from 'components-ui'
import { useRedirectNoData } from 'hooks/use_redirect_no_data'
import { Hooks, Types } from 'service-api'
import { DatePicker } from './date_picker'
import { DatePickerSkeleton } from './date_picker_skeleton'
import { DateViewer } from './date_viewer'
import { firstSelectedDate } from './helper'
import type { TDaysData } from './temper_test.d'
import { format, subDays } from 'date-fns'

export const TemperTest = () => {
  const { accountName = '', serviceTypeName = '' } = useParams()
  const bookingCtx = useBookingContext()
  const [tempTestData, setTempTestData] = useState<Types.BookingSearchByDaysClientDto>()
  const [daysData, setDaysData] = useState<TDaysData>()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  // redirect to home if the state has invalid data
  useRedirectNoData()

  useEffect(() => {
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'temperTest' } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.currentState])

  const maxDate = useMemo(() => firstSelectedDate(bookingCtx.state), [bookingCtx.state])
  const searchParams = useMemo(() => buildSearchParamsForTemperTest(bookingCtx, maxDate), [bookingCtx, maxDate])
  const temperTest = Hooks.useClientBookingSearchByDays(accountName, 'temper-test', searchParams)
  const startDate = useMemo(() => new Date(`${searchParams.startDate}T00:00:00.000`), [searchParams.startDate])

  useEffect(() => {
    document.title = t('title.scheduleEvaluation')
  }, [])

  useEffect(() => {
    if (temperTest.isSuccess && !tempTestData) {
      const results: Types.BookingSearchByDaysClientDto[] = temperTest.data!.results
      setTempTestData(results[0])
      bookingCtx.dispatch({ type: 'SET_TEMPER_TEST_OFFER', payload: { value: results?.[0]?.offer } })

      const data = results?.[0]?.availabilityGroupsByDays?.reduce((acc, dd, idx) => {
        const baseDate = startOfDay(add(startDate, { days: idx }))
        acc[baseDate.valueOf()] = { ...dd[0] } as Types.AvailabilityGroupClientDto
        return acc
      }, {} as TDaysData)

      setDaysData(data)
    }
  }, [tempTestData, temperTest.data, temperTest.data?.results, temperTest.isSuccess, startDate, bookingCtx])

  const temperTestName = useMemo(
    () => bookingCtx.state.data.temperTest?.displayName || t('temperTest.defaultName'),
    [bookingCtx.state.data.temperTest?.displayName]
  )
  const petNames = useMemo(
    () =>
      bookingCtx.state.cart.groups.flatMap((group) =>
        group.pets.filter((pet) => !pet.temperTested).map((pet) => pet.name)
      ),
    [bookingCtx.state.cart.groups]
  )

  const pageTitle = t('temperTest.title', { temperTestName })
  const pageSubtitle = t('temperTest.subtitle', { temperTestName, petNames: formatListWithConjunction(petNames) })
  const pageByline = useMemo(
    () =>
      t('temperTest.byline')
        .replaceAll('{{temperTestName}}', temperTestName)
        .replaceAll('{{serviceTypeName}}', serviceTypeName),
    [temperTestName, petNames, serviceTypeName]
  )

  const canContinue = useMemo(() => {
    return (
      typeof bookingCtx.state.cart.temperTest?.date !== 'undefined' &&
      typeof bookingCtx.state.cart.temperTest?.time !== 'undefined'
    )
  }, [bookingCtx.state.cart.temperTest?.date, bookingCtx.state.cart.temperTest?.time])

  if (!bookingCtx.state.startDate) {
    return null
  }

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto' }}>
      <Spacer height={3} />
      <Typography variant="h1" sx={{ px: 2 }}>
        {pageTitle}
      </Typography>
      <Spacer height={3} />

      <Alert severity="info" icon={<FootstepIcon stroke={theme.palette.info.main} sx={{ fill: 'white !important' }} />}>
        <AlertTitle
          dangerouslySetInnerHTML={{
            __html: t(`temperTest.information.title.${petNames.length === 1 ? 'singlePet' : 'multiplePets'}`, {
              petNames: formatListWithConjunction(petNames),
              temperTestName,
              date: format(subDays(dayToJSDate(searchParams.endDate)!, 1), 'MMM d'),
            }),
          }}
        />
        <Typography variant="body2">{t('temperTest.information.content')}</Typography>
      </Alert>
      <Spacer2 />

      <Paper elevation={1} sx={{ p: 2, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
        <Typography variant="h5">{pageSubtitle}</Typography>
        <Typography variant="caption">{pageByline}</Typography>

        <Spacer2 />

        <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={3}>
          {temperTest.isLoading ? (
            <DatePickerSkeleton />
          ) : (
            <DatePicker daysData={daysData} startDate={startDate} maxDate={maxDate} />
          )}
          <DateViewer />
        </Stack>
      </Paper>

      <Spacer height={STICKY_FOOTER_HEIGHT} />
      <BackContinue continueEnabled={canContinue} />
    </Box>
  )
}
