import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_PETS_ID_PATH, CLIENT_PETS_PATH } from '../types/constants'
import { APIPetClientDtoResponse, PetClientDto, PetCreateInput, PetUpdateInput } from '../types/api'

export const ClientPetsService = {
  get: (locationId: string, petFamily: string): Promise<APIPetClientDtoResponse> => {
    return requestGooseAPI<APIPetClientDtoResponse>({
      url: getAPIPath(CLIENT_PETS_PATH, { locationId }),
      method: 'GET',
      params: {
        petFamily,
        includes: ['petProfile.breed.species'],
      },
    })
  },

  create: async (locationId: string, petFamily: string, data: PetCreateInput): Promise<PetClientDto> => {
    return requestGooseAPI<PetClientDto>({
      url: getAPIPath(CLIENT_PETS_PATH, { locationId }),
      method: 'POST',
      params: {
        petFamily,
      },
      data,
    })
  },

  update: async (locationId: string, petId: string, petFamily: string, data: PetUpdateInput): Promise<PetClientDto> => {
    return requestGooseAPI<PetClientDto>({
      url: getAPIPath(CLIENT_PETS_ID_PATH, { locationId, id: petId }),
      method: 'PUT',
      params: {
        petFamily,
      },
      data,
    })
  },

  delete: async (locationId: string, petId: string, petFamily: string): Promise<PetClientDto> => {
    return requestGooseAPI<PetClientDto>({
      url: getAPIPath(CLIENT_PETS_ID_PATH, { locationId, id: petId }),
      method: 'DELETE',
      params: {
        petFamily,
      },
    })
  },
}
