import { getAPIPath, requestGooseAPI } from '../shared/request'
import { BookingConfigsClientDto, CLIENT_BOOKING_CONFIGS_PATH } from '../types'

export const ClientBookingConfigsService = {
  getData: (locationName: string, serviceTypeName: string): Promise<BookingConfigsClientDto> => {
    return requestGooseAPI<BookingConfigsClientDto>({
      url: getAPIPath(CLIENT_BOOKING_CONFIGS_PATH, { locationId: locationName, serviceTypeName }),
      method: 'GET',
    })
  },
}
