import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { i18WithParams as t } from '@shared/locale'
import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { Hooks } from 'service-api'
import { vaccinesForPet } from './helper'

export const PetVaccines = ({ speciesId }: { speciesId: string }) => {
  const { accountName: locationName = '' } = useParams()
  const { data: locationVaccinesResp, isFetching: loadingLocationVaccines } = Hooks.useClientLocationVaccines(
    locationName,
    { cacheEnabled: true }
  )
  const { requiredVax, optionalVax } = useMemo(
    () => vaccinesForPet(locationVaccinesResp?.results || [], speciesId),
    [locationVaccinesResp?.results, speciesId]
  )

  if (loadingLocationVaccines) {
    return <Skeleton variant="text" height={30} width="70%" />
  }

  if (!requiredVax.length && !optionalVax.length) {
    return null
  }

  return (
    <Stack>
      {!!requiredVax.length && (
        <Box>
          <Typography variant="h6" component="span">
            {`${t('global.label.required')}: `}
          </Typography>
          <Typography variant="body1" component="span">
            {requiredVax}
          </Typography>
        </Box>
      )}
      {!!optionalVax.length && (
        <Box>
          <Typography variant="h6" component="span" color="text.secondary">
            {`${t('global.label.optional')}: `}
          </Typography>
          <Typography variant="body1" component="span">
            {optionalVax}
          </Typography>
        </Box>
      )}
    </Stack>
  )
}
