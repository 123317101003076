import { original, current } from 'immer'
import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode'

import type { TSessionContextValues, TReducerActions, TIdToken, TGooseClaim } from './session'
import { getCognitoGroupId, getLocationData, USER_STORE_KEY } from './helpers'
import { Vault } from 'shared'
import TagManager from 'react-gtm-module'

const actions: TReducerActions = {
  // Is the initial application state when is loaded for first time
  // INITIALIZING

  LOGIN: (state: TSessionContextValues, _?: GenericSimpleBag) => {
    state.status = 'LOGIN'
  },
  NAVIGATE: (state: TSessionContextValues, payload?: GenericSimpleBag) => {
    const loc = getLocationData(state.user?.claims || [], payload!.locationName)
    if (loc) {
      state.currentLocation = loc
    } else {
      state.currentLocation = state.user!.claims[0].locations[0]
    }
    Vault.setObj(USER_STORE_KEY, { user: state.user, currentLocation: state.currentLocation })
    state.status = 'AUTHENTICATED'
  },

  // Message generated by SessionProvider when AWS Amplify send the `signIn` event after
  // a successfully login
  AUTHENTICATE: (state: TSessionContextValues, payload?: GenericSimpleBag) => {
    const accessToken = payload!.user.signInUserSession.accessToken.jwtToken
    const decodedIdToken = jwt_decode(payload!.user.signInUserSession.idToken.jwtToken) as TIdToken
    const claims = decodedIdToken.goose_api_claims
      ? (JSON.parse(decodedIdToken.goose_api_claims) as TGooseClaim[])
      : undefined
    const username = decodedIdToken.display_name || decodedIdToken.email
    const email = decodedIdToken.email
    const userId = getCognitoGroupId(payload!.user)
    TagManager.dataLayer({
      dataLayer: {
        event: 'LOGIN_SUCCESS',
        user: {
          id: userId,
        },
      },
    })
    // if (!claims || claims.length === 0) {
    //   state.user = undefined
    //   Vault.remove(USER_STORE_KEY)
    //   state.status = 'DEAUTHENTICATED'
    //   return
    // }

    // const landingLocation = getLocationData(claims, payload!.currentLocation) ?? claims[0].locations[0]

    state.user = {
      token: accessToken,
      id: decodedIdToken.sub,
      idHash: decodedIdToken.intercom_user_hash,
      claims: claims || [],
      username,
      email,
      userId,
    }
    // state.currentLocation = landingLocation

    Vault.setObj(USER_STORE_KEY, { user: state.user, currentLocation: state.currentLocation })
    state.status = 'AUTHENTICATED'
  },

  // Message sent when the app finished initializing and is ready
  READY: (state: TSessionContextValues, _?: GenericSimpleBag) => {
    state.status = 'READY'
  },

  // Message generated by SessionProvider when AWS Amplify send the `signOut` event after
  // a successfully logout
  DEAUTHENTICATE: (state: TSessionContextValues, _?: GenericSimpleBag) => {
    if (state.user?.userId) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'LOGOUT_HEADER',
          user: {
            id: state.user?.userId,
          },
        },
      })
    }

    state.user = undefined
    Vault.remove(USER_STORE_KEY)
    state.status = 'DEAUTHENTICATED'
  },

  // Send when the application request a logout
  SIGNOUT: (_state: TSessionContextValues, _?: GenericSimpleBag) => {
    // SessionProvider setup a handler with AWS Auth and will dispatch
    // a DEAUTHENTICATE event on the signOut event
    Auth.signOut()
      // eslint-disable-next-line no-console
      .catch((err: Error) => console.log(err))
  },
}

/* eslint-disable no-console */
export const sessionReducer = (state: TSessionContextValues, action: TReducerAction) => {
  const fx = actions[action.type]
  fx?.(state, action.payload)

  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development') {
    console.group(action.type)
    if (!fx) {
      console.log('%c action not found ', 'background: #FF0000; color: #fff')
    }
    console.log('start', original(state))
    console.log('payload', action.payload)
    console.log('after', current(state))
    console.groupEnd()
  }
}
/* eslint-enable no-console */
