import { useQuery } from '@tanstack/react-query'
import { ClientStoredPaymentMethodsService } from '../services'
import { StoredPaymentMethodClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientStoredPaymentMethods = (locationName: string) => {
  return useQuery<StoredPaymentMethodClientDto[]>({
    queryKey: ['clientStoredPaymentMethods', locationName],
    queryFn: () => ClientStoredPaymentMethodsService.list(locationName),
    ...defaultQueryOptions,
    enabled: !!locationName,
  })
}
