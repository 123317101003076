import { Stack, Paper, Box, Skeleton } from '@mui/material'

export const FlexCardDisplaySkeleton = () => (
  <Paper sx={{ p: 2 }}>
    <Stack spacing={2}>
      <Skeleton variant="text" width={400} />
      <Box>
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={200} />
      </Box>
      <Box>
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={200} />
      </Box>
    </Stack>
  </Paper>
)
