// import LightModeIcon from '@mui/icons-material/LightMode'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import { ServiceTypeIcon } from 'icon_avatar/icon_avatar'
import { useCallback } from 'react'
import { Types } from 'service-api'

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.shade30,
  borderRadius: '30px',
  padding: '16px 24px',
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.shade30,
    borderRadius: '30px',
  },
}))
const StyledUnselectedButton = styled(Button)(() => ({
  borderRadius: '30px',
  padding: '16px 24px',
}))

const availableServiceTypes = ['boarding', 'daycare', 'grooming', 'packages']

interface TProps {
  isLoading: boolean
  services?: Types.LocationServiceTypeClientDto[] | Types.LocationServiceTypeAdminDto[]
  currentServiceType: string
  onSelect: (serviceType: string) => void
}

export const ServiceTypeSelector = ({ isLoading, services, currentServiceType, onSelect }: TProps) => {
  const onClickButton = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const { currentTarget: t } = ev
      onSelect(t.id)
    },
    [onSelect]
  )

  const renderPlaceholders = () => {
    return (
      <>
        <Skeleton width={130} height={54} sx={{ transform: 'scale(1,1)', borderRadius: '30px' }} />
        <Skeleton width={130} height={54} sx={{ transform: 'scale(1,1)', borderRadius: '30px' }} />
      </>
    )
  }

  const renderButtons = () => {
    return services?.map((st) => {
      if (availableServiceTypes.includes(st.serviceType!.name!)) {
        const buttonProps: GenericSimpleBag = {
          id: st.serviceType?.name,
          'data-testid': `${st.serviceType?.name}-button`,
          startIcon: <ServiceTypeIcon serviceType={st.serviceType?.name} />,
          variant: currentServiceType === st.serviceType?.name ? 'contained' : 'text',
          size: 'large',
          onClick: onClickButton,
        }

        if (currentServiceType === st.serviceType?.name) {
          return (
            <StyledButton key={st.serviceType?.id} {...buttonProps}>
              {st.serviceType?.displayName}
            </StyledButton>
          )
        } else {
          return (
            <StyledUnselectedButton key={st.serviceType?.id} {...buttonProps} sx={{ px: 3, py: 2 }}>
              {st.serviceType?.displayName}
            </StyledUnselectedButton>
          )
        }
      }
      return null
    })
  }

  if (!isLoading && (services?.length ?? 0) < 2) {
    return null
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
      {isLoading ? renderPlaceholders() : renderButtons()}
    </Stack>
  )
}
