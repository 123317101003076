import { OfferCardSkeleton, Spacer3 } from 'components-ui'

export const SearchResultsSkeleton = () => {
  return (
    <>
      <OfferCardSkeleton />
      <Spacer3 />
      <OfferCardSkeleton />
      <Spacer3 />
      <OfferCardSkeleton />
    </>
  )
}
