import { Typography, Stack, useTheme } from '@mui/material'
import { i18WithDefault as t, i18WithParams as tp } from '@shared/locales'
import { formatDate, formatAmPm, dayToJSDate, getDOWFromStr } from '@shared/dates'
import {
  displayHoursInPeriodRestrictions,
  isSelectedDayInPeriodRestrictions,
  isSelectedDateInPeriodRestrictionsRelation,
} from '@shared/restrictions'
import { FeeStructure } from './fee_structure'
import { PaymentSettingAdminDto } from 'service-api/src/types'
import { Types } from 'service-api'

export interface LateDepartureDetailsProps {
  endDate?: string
  endTime?: string | null
  reservation: Types.BookingReservationClientDto
  header?: string
}

export const LateDepartureDetails = ({
  endDate,
  endTime,
  reservation,
  header: customHeader,
}: LateDepartureDetailsProps) => {
  const { order, locationBookingConf, checkOutHour, operationHour } = reservation
  const theme = useTheme()
  const checkOutDay = endDate && getDOWFromStr(endDate)

  const lateDeparture = (order?.data as Record<string, []>)?.surchargeSettings?.filter(
    (fee: PaymentSettingAdminDto) =>
      fee?.after &&
      checkOutDay &&
      isSelectedDateInPeriodRestrictionsRelation(fee.restrictionRelations!, checkOutDay, endDate) &&
      (locationBookingConf?.surchargeStrategy === 'ENFORCED' || locationBookingConf?.surchargeStrategy === 'DURATION')
  ) as PaymentSettingAdminDto[]
  const isCheckOutEnabled =
    checkOutHour &&
    checkOutDay &&
    isSelectedDayInPeriodRestrictions(checkOutHour, checkOutDay) &&
    (locationBookingConf?.surchargeStrategy === 'SUGGESTED' || locationBookingConf?.surchargeStrategy === 'ENFORCED')

  const defaultLateDepartureHeader = `${t('surchargePolicy.departure')}: ${formatDate(dayToJSDate(endDate))} ${
    endTime ? formatAmPm(endTime) : ''
  }`

  return (
    <Stack>
      {endDate && (
        <Typography variant="body1" style={{ fontWeight: 700 }} mt={1} mb={1}>
          {customHeader || defaultLateDepartureHeader}
        </Typography>
      )}
      {checkOutDay && operationHour && (
        <Typography variant="caption" mt={1} mb={1}>
          {t('surchargePolicy.open')}: {displayHoursInPeriodRestrictions(operationHour, checkOutDay)}
        </Typography>
      )}
      {isCheckOutEnabled && checkOutDay && (
        <Typography variant="body2" sx={{ backgroundColor: theme.palette.action.selected, p: 1 }} mt={1} mb={1}>
          {t('surchargePolicy.checkOutHours')}: {displayHoursInPeriodRestrictions(checkOutHour!, checkOutDay)}
        </Typography>
      )}
      {locationBookingConf?.surchargeStrategy === 'DURATION' && (
        <Typography variant="body2" sx={{ backgroundColor: theme.palette.action.selected, p: 1 }} mt={1} mb={1}>
          {t('surchargePolicy.duration')}
        </Typography>
      )}
      {(locationBookingConf?.surchargeStrategy === 'ENFORCED' ||
        locationBookingConf?.surchargeStrategy === 'DURATION') &&
        !!lateDeparture.length && (
          <FeeStructure type="lateDeparture" title={t('feeStructure.lateDeparture.title')} fees={lateDeparture} />
        )}
    </Stack>
  )
}
