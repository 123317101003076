import { useQuery } from '@tanstack/react-query'
import { ClientBookingSearchService } from '../services/client_booking_search.service'
import { APIBookingSearchClientDtoResponse, APIBookingSearchByDaysClientDtoResponse } from '../types'
import { TBookingSearchOptions } from '../types/general'
import { defaultQueryOptions } from './common'

export const useClientBookingSearch = (
  locationName: string,
  serviceTypeName: string,
  options: TBookingSearchOptions
) => {
  return useQuery<APIBookingSearchClientDtoResponse>({
    queryKey: ['clientBookingSearchRegular', locationName, serviceTypeName, options],
    queryFn: () => ClientBookingSearchService.search(locationName, serviceTypeName, options),
    // disable the call if the date is invalid
    enabled: options.startDate !== '1969-12-31',
    ...defaultQueryOptions,
  })
}

export const useClientBookingSearchByDays = (
  locationName: string,
  serviceTypeName: string,
  options: TBookingSearchOptions
) => {
  return useQuery<APIBookingSearchByDaysClientDtoResponse>({
    queryKey: ['clientBookingSearchByDays', locationName, serviceTypeName, options],
    queryFn: () => ClientBookingSearchService.searchByDays(locationName, serviceTypeName, options),
    // disable the call if the date is invalid
    enabled: options.startDate !== '1969-12-31',
    ...defaultQueryOptions,
  })
}
