import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { ClientBookingConfigsService } from '../services/client_booking_configs.service'
import { BookingConfigsClientDto } from '../types'
import { defaultQueryOptions } from './common'

export const useClientBookingConfigs = (
  locationName: string,
  serviceTypeName: string,
  options: { enabled: boolean } = { enabled: true }
): UseQueryResult<BookingConfigsClientDto> => {
  return useQuery<BookingConfigsClientDto>({
    queryKey: ['clientBookingConfigs', locationName, serviceTypeName],
    queryFn: () => ClientBookingConfigsService.getData(locationName, serviceTypeName),
    enabled: options.enabled && !!locationName && !!serviceTypeName,
    ...defaultQueryOptions,
  })
}
