import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
// import ButtonGroup from '@mui/material/ButtonGroup'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import { useState } from 'react'

import { enqueueSnackbar } from 'notistack'
import { generatePassword } from '../shared/common'
import { PRIVACY_POLICY_URL, TOS_URL } from '../shared/constants'
import { i18WithDefault as t } from '../shared/locales'
import { ModalType, TSignUpProps } from './index.d'
import { initialValues, signUpSchema } from './sign_up_schema'
import { captureError } from 'service-api/src/shared'

export const SignUp = ({ switchModalType, saveUser, email }: TSignUpProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  // const [isNewUser, setIsNewUser] = useState(true)
  const [lastMsg, setLastMsg] = useState('')

  const formik = useFormik({
    initialValues: initialValues(email),
    validationSchema: signUpSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLastMsg('')
      setIsSubmitting(true)
      try {
        const { user } = await Auth.signUp({
          username: values.email.toLowerCase().trim(),
          password: generatePassword(),
          attributes: {
            email: values.email.toLowerCase().trim(),
          },
          autoSignIn: {
            enabled: true,
          },
        })
        saveUser(user)
        switchModalType(ModalType.mfaCode)
      } catch (error) {
        enqueueSnackbar(t('signUpForm.genericError'), { variant: 'error' })
        captureError(error as Error)
        setLastMsg((error as Error).message)
        // eslint-disable-next-line no-console
        console.log('error signing up:', error)
      } finally {
        setIsSubmitting(false)
      }
    },
  })

  // const onToggleNewUser = useCallback(() => {
  //   setIsNewUser(!isNewUser)
  // }, [isNewUser])

  return (
    <Box>
      <Typography variant="body2" mb={4.5} textAlign="center">
        {t('signUpForm.newAccount')}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label={t('signin.enterEmail.email')}
          size="small"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        {/* <Typography variant="caption">{t('signUpForm.newUserQuestion')}</Typography> */}
        {/* <ButtonGroup variant="outlined" fullWidth sx={{ mb: 1 }}> */}
        {/*   <Button size="large" variant={isNewUser ? 'contained' : 'outlined'} onClick={onToggleNewUser}> */}
        {/*     {t('signUpForm.wereNew')} */}
        {/*   </Button> */}
        {/*   <Button size="large" variant={isNewUser ? 'outlined' : 'contained'} onClick={onToggleNewUser}> */}
        {/*     {t('signUpForm.oldUser')} */}
        {/*   </Button> */}
        {/* </ButtonGroup> */}
        {/* {!isNewUser && ( */}
        {/*   <> */}
        {/*     <Typography variant="body1">{t('signUpForm.oldUser.title')}</Typography> */}
        {/*     <Typography variant="body1">{t('signUpForm.oldUser.description')}</Typography> */}
        {/*   </> */}
        {/* )} */}
        {lastMsg && (
          <Typography variant="body1" mt={2} color="error">
            {lastMsg}
          </Typography>
        )}

        <LoadingButton
          variant="contained"
          fullWidth
          type="submit"
          sx={{ height: 33, fontSize: '14px' }}
          loading={isSubmitting}
          size="small"
        >
          {t('global.label.signUp')}
        </LoadingButton>

        {/* <Link href="#" onClick={() => switchModalType(ModalType.signIn)}>
          {t('signUpForm.alreadyHaveAccount')}
        </Link> */}

        <Typography variant="body2" mt={3} mb={1}>
          <strong>
            <span>{t('logInForm.disclaimer')}</span>{' '}
            <Link target="_blank" href={PRIVACY_POLICY_URL}>
              {t('logInForm.privacyPolicy')}
            </Link>
            <span>&nbsp;and&nbsp;</span>
            <Link target="_blank" href={TOS_URL}>
              {t('logInForm.tos')}
            </Link>
            .
          </strong>
        </Typography>
      </form>
    </Box>
  )
}
