import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'

import { ModalType, TForgotPasswordProps } from './index.d'
import { forgotPasswordSchema } from './forgot_password_schema'
import { SubmitError } from './submit_error'
import t from '../shared/locales'
import { sanitizeEmail } from './helper'

const initialValues = {
  email: '',
}

export const ForgotPasswordForm = ({ switchModalType, saveEmail }: TForgotPasswordProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const forgotPassword = async (email: string, setStatus: (status?: string) => void) => {
    setIsSubmitting(true)
    const sanitizedEmail = sanitizeEmail(email)
    try {
      await Auth.forgotPassword(sanitizedEmail)
      switchModalType(ModalType.confirmResetPassword)
      saveEmail(sanitizedEmail)
    } catch (err) {
      setStatus((err as Error).message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus }) => {
      await forgotPassword(values.email, setStatus)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="email"
        name="email"
        label={t('forgotPasswordForm.emailField')}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        FormHelperTextProps={{ style: { marginLeft: 0 } }}
      />
      <SubmitError value={formik.status} />
      <LoadingButton
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        sx={{ height: 42, my: 2 }}
        loading={isSubmitting}
      >
        {t('forgotPasswordForm.submitButton')}
      </LoadingButton>
    </form>
  )
}
