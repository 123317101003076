import { Types } from 'service-api'
import { getBaseGroup } from 'components-ui/src/offer_card/helper'

export const sortOffers = (
  offers: Types.BookingSearchClientDto[] | Types.BookingSearchByDaysClientDto[],
  serviceTypeName: string,
  petGroup?: number
): Types.BookingSearchClientDto[] | Types.BookingSearchByDaysClientDto[] => {
  const sortedOffers = offers.sort((a, b) => {
    const firstAvailabilityGroups =
      (a as Types.BookingSearchClientDto).availabilityGroups ||
      (a as Types.BookingSearchByDaysClientDto).availabilityGroupsByDays ||
      []
    const secondAvailabilityGroups =
      (b as Types.BookingSearchClientDto).availabilityGroups ||
      (b as Types.BookingSearchByDaysClientDto).availabilityGroupsByDays ||
      []
    const firstAvailability = getBaseGroup(serviceTypeName, petGroup || 0, firstAvailabilityGroups).availabilities?.[0]
      .available
    const secondAvailability = getBaseGroup(serviceTypeName, petGroup || 0, secondAvailabilityGroups)
      .availabilities?.[0].available

    if (firstAvailability === secondAvailability) {
      return (a.offer?.order || 0) - (b.offer?.order || 0)
    } else {
      return firstAvailability ? -1 : 1
    }
  })

  return sortedOffers
}
