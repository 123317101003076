import TextField from '@mui/material/TextField'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { ReadonlyTextField, TextFieldProps } from 'text_field'

export type TextInputNumberProps = TextFieldProps & Omit<NumericFormatProps, 'size'>

export const TextInputNumber = (props: TextInputNumberProps) => {
  const { readOnly, renderEmptyReadOnly, decimalScale, allowLeadingZeros, ...baseProps } = props
  if (readOnly) {
    return (
      <ReadonlyTextField
        sx={baseProps.sx}
        label={baseProps.label}
        value={baseProps.value as string}
        renderIfEmpty={renderEmptyReadOnly}
      />
    )
  }

  return (
    <NumericFormat
      isAllowed={(num) => num.floatValue === undefined || num.floatValue <= Number.MAX_SAFE_INTEGER}
      {...baseProps}
      decimalScale={decimalScale ?? 0}
      allowNegative={false}
      allowLeadingZeros={allowLeadingZeros ?? false}
      type="text"
      customInput={TextField}
      valueIsNumericString={true}
    />
  )
}
