import { requestGooseAPI, getAPIPath } from '../shared/request'
import { UserClientDto, APIPetClientDtoResponse } from '../types'
import { CLIENT_USER_PATH, CLIENT_USER_PETS_PATH } from '../types/constants'

export const ClientUserService = {
  get: async (locationId: string, userId: string) => {
    return requestGooseAPI<UserClientDto>({
      url: getAPIPath(CLIENT_USER_PATH, { locationId: locationId, id: userId }),
      method: 'GET',
      params: {
        includes: ['userProfile', 'petFamilyUser'],
      },
    })
  },
  getPets: async (locationId: string, userId: string) => {
    return requestGooseAPI<APIPetClientDtoResponse>({
      url: getAPIPath(CLIENT_USER_PETS_PATH, { locationId: locationId, id: userId }),
      method: 'GET',
      params: {
        includes: ['breed', 'pet'],
      },
    })
  },
}
