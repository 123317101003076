import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { DateSelector } from './date_selector'
import { RoomsAndPets } from './rooms_and_pets/rooms_and_pets'

export const SearchBar = ({ isLoading }: { isLoading: boolean }) => {
  const theme = useTheme()
  const bookingCtx = useBookingContext()
  const { serviceTypeName = '' } = useParams()
  const { navigateRoute } = useRoutes()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const doSearch = useCallback(() => {
    bookingCtx.dispatch({ type: 'REGROUP_PETS' })
    bookingCtx.dispatch({ type: 'SET_SEARCH_STATE', payload: { value: 'searchResults' } })
    navigateRoute('searchOffersResults', { serviceTypeName: serviceTypeName })
  }, [bookingCtx, navigateRoute, serviceTypeName])

  return (
    <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
      <Paper elevation={1} sx={{ pt: 2, pb: 2, px: 4, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
        <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
          {serviceTypeName !== 'daycare' && (
            <>
              <DateSelector />
              {isBelowMD ? (
                <Divider orientation="horizontal" flexItem sx={{ pt: 1, mb: 1 }} />
              ) : (
                <Divider orientation="vertical" flexItem />
              )}
            </>
          )}
          {isLoading ? <Skeleton variant="rounded" height={45} width="100%" /> : <RoomsAndPets />}
          <Stack
            direction="column"
            alignItems={{ xs: 'start', md: 'end' }}
            justifyContent="center"
            sx={{ marginRight: 'auto', flexShrink: 1, mt: { xs: 2, md: 0 } }}
          >
            <Button size="large" variant="contained" disabled={!bookingCtx.state.isValid} onClick={doSearch}>
              {t('global.label.Search')}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}
