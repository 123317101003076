import { Types } from 'service-api'

export const rename = (filenamePrefix: string, existingFiles: (Types.AssetClientDto | File)[], newFiles: File[]) => {
  const prefix = `${filenamePrefix}_`
  let maxFileNum = existingFiles.reduce((res, file) => {
    const name = (file as Types.AssetClientDto)?.id
      ? (file as Types.AssetClientDto)?.displayName || ''
      : (file as File)?.name || ''
    const strt = name.indexOf(prefix),
      end = name.lastIndexOf('.')
    const num =
      strt === 0 && end !== -1 ? name.substring(prefix.length, end) : strt === 0 ? name.substring(prefix.length) : ''
    return Math.max(res, parseInt(num) || 0)
  }, 0)

  return newFiles.map((file) => {
    const ext = file.type.split('/').pop()
    return new File([file], `${prefix}${++maxFileNum}${ext ? '.' : ''}${ext}`, { type: file.type })
  })
}
