import { Paper, Stack, Box, Typography } from '@mui/material'
import { ReceiptDto } from 'service-api/src/types'
import { CheckInOut, DetailsSection, Footer, Header, OffersSection } from './receipt_details'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { i18WithDefault as t } from '@shared/locales'
import InfoIcon from '@mui/icons-material/Info'

export const PurchaseReceipt = ({
  receipt,
  dueToday,
  isPackage,
}: {
  receipt?: ReceiptDto
  dueToday?: number | null
  isPackage?: boolean
}) => {
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  return receipt ? (
    <Box>
      <Paper sx={{ p: 2, height: '100%', borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
        <Header name={receipt.locationName} address={receipt.locationAddress} phone={receipt.locationPhone} />
        <CheckInOut checkIn={receipt.checkIn} checkOut={receipt.checkOut} />
        <DetailsSection receipt={receipt} isPackage={isPackage} />
        <Stack px={1}>
          <OffersSection receipt={receipt} />
          <Footer receipt={receipt} dueToday={dueToday} />
        </Stack>
      </Paper>
      {receipt.invoices?.some((invoice) => invoice.items?.some((item) => item.priceVaries)) && (
        <Stack direction="row" spacing={1} mt={1}>
          <InfoIcon
            sx={{
              width: '20px',
              height: '20px',
              color: theme.palette.action.active,
            }}
          />
          <Typography>{t('booking.startingAtPolicy')}</Typography>
        </Stack>
      )}
    </Box>
  ) : null
}
