import { CheckCircleOutline } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { enqueueSnackbar } from 'notistack'
import { ChangeEvent, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDrawersContext } from '@providers/drawers/drawers_context'
import { buildErrorMessage } from '@shared/error'
import { i18WithParams as t } from '@shared/locale'
import { captureError } from '@shared/monitoring'
import { ButtonActions } from 'components-ui/src/button_actions/button_actions'
import { Spacer } from 'components-ui/src/spacer/spacer'
import { Services, Types } from 'service-api'
import { Drawer } from './drawer'
import TagManager from 'react-gtm-module'

const ContractHeader = () => (
  <Stack direction="column">
    <Typography variant="h5">{t('serviceAgreement.title')}</Typography>
    <Typography variant="body2" color="text.secondaryShade60">
      {t('serviceAgreement.lead')}
    </Typography>
  </Stack>
)

const MINIMUM_SIGNATURE_LENGTH = 2

const ContractDrawerInner = ({
  contract,
  onSignature,
  onFormChange,
}: {
  contract: Types.ContractClientDto
  onSignature: () => void
  onFormChange: (dirty: boolean) => void
}) => {
  const { accountName: locationName = '' } = useParams()
  const [signature, setSignature] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [isContractSigned, contractSigned] = useState(false)
  const theme = useTheme()
  const hasSignatureMinLength = signature.length >= MINIMUM_SIGNATURE_LENGTH

  const onUpdateSignature = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target
    setSignature(value)
    onFormChange(true)
  }

  const onClearSignature = async () => {
    setSignature('')
    setAcceptTerms(false)
    onFormChange(false)
  }

  const onAcceptTerms = (ev: ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(ev.target.checked)
    onFormChange(ev.target.checked)
  }

  const submitSignature = async () => {
    setIsSubmitting(true)
    try {
      await Services.ClientAgreementsService.sign(locationName, contract.id || '', {
        customerName: signature,
      } as Types.AgreementCreateInput)
      contractSigned(true)
      onFormChange(false)
      onSignature?.()
    } catch (e) {
      captureError(e as Error)
      enqueueSnackbar(buildErrorMessage(e), { variant: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Box pt={2} pb={4} px={4}>
      <div dangerouslySetInnerHTML={{ __html: contract?.description || '' }}></div>
      <Divider sx={{ pt: 1, mb: 1 }} />

      <Box px={4} py={2} sx={{ backgroundColor: theme.palette.background.default, maxWidth: '500px' }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox disabled={isContractSigned} checked={acceptTerms} onChange={onAcceptTerms} />}
            label={t('serviceAgreement.form.accept')}
            sx={{ '& .MuiFormControlLabel-label': { fontWeight: 'bold' } }}
          />
          <Spacer height={4} />
          <TextField
            label="Signature"
            value={signature}
            disabled={isContractSigned}
            onChange={onUpdateSignature}
            helperText={t('serviceAgreement.form.sign.help')}
            inputProps={{ disabled: isContractSigned }}
            sx={{ marginBottom: 0, '& .MuiFormHelperText-root': { height: 'auto' } }}
          />
        </FormGroup>
      </Box>

      <Spacer height={3} />
      <ButtonActions
        invertButtons
        actionEnabled={!isSubmitting && hasSignatureMinLength && acceptTerms && !isContractSigned}
        onActionLabel={t('global.label.send')}
        onAction={submitSignature}
        onCancel={!isContractSigned && (hasSignatureMinLength || acceptTerms) ? onClearSignature : undefined}
        onCancelLabel={t('global.label.clearAllChanges')}
        cancelEnabled={!isSubmitting && !isContractSigned}
      />
    </Box>
  )
}

export const ContractDrawer = ({
  contract,
  onSave,
  allowExpand,
}: {
  contract: Types.ContractClientDto
  allowExpand: boolean
  onSave: () => void
}) => {
  const { dispatch } = useDrawersContext()
  const [isSigned, setSigned] = useState(false)
  const onSignature = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'INTAKE_SEND',
        type: 'AGREEMENT',
      },
    })
    setSigned(true)
    onSave()
  }

  const onFormChange = useCallback(
    (dirty: boolean) => {
      dispatch({ type: 'SET_IS_OPEN_DRAWER_DIRTY', payload: { value: dirty } })
    },
    [dispatch]
  )

  return (
    <Drawer
      id={`contract-${contract.id}`}
      label={<ContractHeader />}
      allowExpand={allowExpand}
      status={
        isSigned ? (
          <Stack direction="row" alignItems="center" spacing={0.5} color="success.main">
            <CheckCircleOutline />
            <Typography variant="body1">{t('global.label.sent')}</Typography>
          </Stack>
        ) : null
      }
    >
      <ContractDrawerInner contract={contract} onSignature={onSignature} onFormChange={onFormChange} />
    </Drawer>
  )
}
