import { useTheme } from '@mui/material'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material/'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { useSessionContext } from '@providers/session'
import { isUserLoggedIn } from '@providers/session/helpers'
import { MAX_WIDGET_SIZE } from '@shared/constants'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import {
  allowNoPayments,
  FlexCardDisplay,
  getPoliciesData,
  InvoiceCard,
  isIntakeEnabled,
  PurchaseReceipt,
  PurchaseReceiptSkeleton,
  Spacer,
} from 'components-ui'
import { Hooks, Services, Types } from 'service-api'
import { PurchaseSummarySkeleton } from '../purchase_summary/purchase_summary_skeleton'
import { Intake } from '@views/intake/intake'
import TagManager from 'react-gtm-module'
import { captureError } from 'service-api/src/shared'

export const BookingConfirmation = () => {
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const { navigateRoute } = useRoutes()
  const { accountName = '', orderId = '', serviceTypeName = 'boarding' } = useParams()
  const theme = useTheme()
  const renderStyle = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop'
  const [order, setOrder] = useState<Types.OrderClientDto | undefined>()
  const [isOrderLinkingLoading, setIsOrderLinkingLoading] = useState<boolean>(false)
  const isMobile = renderStyle === 'mobile'

  useEffect(() => {
    document.title = t('title.bookingConfirmation')
  }, [])

  const {
    data: receipt,
    isFetching: isReceiptLoading,
    refetch: refetchReceipt,
  } = Hooks.useClientReceipt(accountName, orderId)
  const {
    data: orderResponse,
    isFetching: isOrderLoading,
    isSuccess: isOrderSuccess,
    refetch: refetchOrder,
    error: orderError,
  } = Hooks.useClientOrder(accountName, orderId)
  const { data: bookingConfs, isFetching: isBookingConfsLoading } = Hooks.useClientBookingConfigs(
    accountName,
    order?.primaryServiceType?.serviceType?.name || '',
    { enabled: !!(order?.ownerId || order?.owner?.id) }
  )
  const noPaymentsAllowed = allowNoPayments(order?.primaryServiceType?.locationBookingConf?.[0])
  const policyCardsData = useMemo(
    () => (receipt ? getPoliciesData(receipt, noPaymentsAllowed) : []),
    [receipt, noPaymentsAllowed]
  )

  const configs = Hooks.useClientBookingSearchConfigs(accountName, serviceTypeName)
  const location = Hooks.useClientLocation(
    accountName,
    configs.data?.species?.[0]?.locationId ||
      configs.data?.petCount?.[0]?.locationId ||
      configs.data?.period?.[0]?.locationId ||
      ''
  )
  const loggedIn = isUserLoggedIn(sessionCtx.state.user)

  useEffect(() => {
    if (!isOrderSuccess) {
      return
    }

    if (!loggedIn) {
      setTimeout(
        () =>
          navigateRoute(
            'signIn',
            {},
            {
              state: {
                redirect: {
                  route: 'paymentSummary',
                  params: { orderId },
                },
                email: orderResponse?.userData?.[0]?.email,
                userExists: !!(orderResponse.ownerId || orderResponse.owner?.id),
              },
            }
          ),
        0
      )
      return
    }

    if (!orderResponse.ownerId && !orderResponse.owner?.id) {
      if (orderResponse.userData?.[0]?.email !== sessionCtx.state.user?.email) {
        setTimeout(() => navigateRoute('notFound', {}), 0)
      } else {
        setIsOrderLinkingLoading(true)
        Services.ClientOrderService.linkOrderToUser(orderId, accountName)
          .then((result) => {
            setOrder(result)
          })
          .catch(() => {
            setTimeout(() => navigateRoute('notFound', {}), 0)
          })
          .finally(() => {
            setIsOrderLinkingLoading(false)
          })
      }
      return
    }

    setOrder(orderResponse)
  }, [orderResponse, isOrderSuccess, loggedIn, sessionCtx.state.user?.email])

  useEffect(() => {
    if (orderError) {
      setTimeout(() => navigateRoute('notFound', {}), 0)
    }
  }, [orderError])

  useEffect(() => {
    bookingCtx.dispatch({
      type: 'SET_SEARCH_STATE',
      payload: { value: 'paymentSummary' },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.currentState])

  useEffect(() => {
    if (location.data) {
      bookingCtx.dispatch({
        type: 'SET_LOCATION_CONFIG',
        payload: { value: location.data },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingCtx.state.config.location, location.data])

  const isPetNameInCtx = !!bookingCtx.state?.groups[0]?.pets[0]?.name
  useEffect(() => {
    // confirmation reload - use pet values from receipt if there are no pet names defined in the booking state
    if (receipt && !isPetNameInCtx) {
      bookingCtx.dispatch({
        type: 'ADD_CONFIRMATION_GROUPS',
        payload: {
          receipt: receipt,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt, isPetNameInCtx])

  const firePurchaseEvent = (order: Types.OrderClientDto) => {
    const items =
      order.invoices?.flatMap(
        (invoice, index) =>
          invoice.items?.map((invoiceItem) => ({
            item_id: invoiceItem.offer?.id || invoiceItem.offerId || '',
            item_name: invoiceItem.displayName || '',
            affiliation: location.data?.displayName || '',
            index,
            item_category: invoice.locationServiceType?.serviceType?.name || '',
            item_category2: invoiceItem.locationSpecies?.species?.name || '',
            item_category3: invoiceItem.offerType,
            price: invoiceItem.subtotal || 0,
            quantity: invoiceItem.qty || 0,
          }))
      ) || []

    const purchaseData = {
      transaction_id: order.id || '',
      value: order.invoices?.reduce((sum, invoice) => sum + (invoice.total || 0), 0) || 0,
      tax: order.invoices?.reduce((sum, invoice) => sum + (invoice.tax || 0), 0) || 0,
      currency: order.payments?.[0].currency || '',
      items,
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ecommerce: purchaseData,
      },
    })

    if (location.data?.subscriberGroup?.everflowEnabled) {
      if (typeof EF !== 'undefined') {
        EF.conversion({
          offer_id: 1,
          // adv1: 'you can pass any custom data here ',
          amount: receipt?.total,
          order_id: order?.id,
        })
      } else {
        captureError({ cause: 'EF.conversion is not available' } as Error)
      }
    }
  }

  // Fire purchase event when order is successfully fetched
  useEffect(() => {
    if (orderResponse && isOrderSuccess && location.data && bookingCtx.state.shouldFireGAPurchaseEvent) {
      firePurchaseEvent(orderResponse)

      bookingCtx.dispatch({
        type: 'SET_FIRE_PURCHASE_EVENT',
        payload: { value: false },
      })
    }
  }, [orderResponse, isOrderSuccess, location.data])

  if (isOrderLoading || isReceiptLoading || isBookingConfsLoading || isOrderLinkingLoading) {
    return <PurchaseSummarySkeleton />
  }

  if (!order?.invoices) {
    return null
  }

  return (
    <Box sx={{ maxWidth: MAX_WIDGET_SIZE, margin: '0 auto', mb: 4 }}>
      <Spacer height={3} />
      <Stack alignItems={'center'}>
        <CheckCircleIcon fontSize="large" sx={{ color: theme.palette.success.light, mb: 1 }} />
        <Typography variant="h1">{t('paymentSummary.title')}</Typography>
      </Stack>

      <Spacer height={3} />

      <Stack direction="column" spacing={3} flex={1} width="100%">
        {order.invoices.map((invoice) => (
          <InvoiceCard
            key={invoice.id}
            invoice={invoice}
            order={order}
            onCancelInvoice={() => {
              refetchOrder()
              refetchReceipt()
            }}
          />
        ))}
      </Stack>

      {isIntakeEnabled(bookingConfs?.locationBookingConf) && (
        <>
          <Spacer height={3} />
          <Intake
            flexBookingConfs={bookingConfs?.flexBookingConfs || []}
            order={order || {}}
            onSave={(newOrder) => {
              setOrder(newOrder)
            }}
          />
        </>
      )}

      <Spacer height={3} />

      <Typography variant="h2">{t('paymentSummary.subtitle')}</Typography>
      <Spacer height={2} />
      <Stack direction={{ sx: 'column', md: 'row' }} alignItems="start" spacing={3} flex={1}>
        <Stack spacing={3} sx={{ flex: 1 }} width="100%">
          {policyCardsData.map((group, index) => (
            <FlexCardDisplay key={index} cardData={group} />
          ))}
        </Stack>
        <Stack direction="column" spacing={2} flex={1} mt={isMobile ? 3 : 0} width="100%">
          <Box sx={{ flex: 1 }}>
            {isReceiptLoading ? <PurchaseReceiptSkeleton /> : <PurchaseReceipt receipt={receipt} />}
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}
