import { createTheme, SimplePaletteColorOptions, Theme, ThemeOptions, PaletteOptions } from '@mui/material/styles'
import { darken, lighten, alpha } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true
  }
}

declare module 'notistack' {
  type VariantOverrideProps = {
    link?: {
      href: string
      text: string
    }
  }
  export interface VariantOverrides {
    default: true
    success: VariantOverrideProps
    error: VariantOverrideProps
    warning: VariantOverrideProps
    info: VariantOverrideProps
  }
}

declare module '@mui/material/styles' {
  export interface TypeBackground {
    default: string
    paper: string
    catOrange: string
    dogBlue: string
    grey: string
    darkGrey: string
  }

  export interface PaletteColor {
    default?: string
    shade5?: string
    shade8?: string
    shade10?: string
    shade12?: string
    shade30?: string
    shade50?: string
    shade60?: string
    darken60?: string
  }

  export interface PaletteColorOptions extends SimplePaletteColorOptions {
    default?: string
    shade5?: string
    shade8?: string
    shade10?: string
    shade12?: string
    shade30?: string
    shade50?: string
    shade60?: string
    darken60?: string
  }

  export interface TypeText {
    white: string
    secondaryShade18?: string
    secondaryShade50?: string
    secondaryShade60?: string
    secondaryShade70?: string
  }

  // export interface PaletteOptions {
  //   geese: PaletteOptions['primary']
  // }

  export interface TypographyVariants {
    body1bold: React.CSSProperties
    body2bold?: React.CSSProperties
    print: React.CSSProperties
  }

  export interface TypographyVariantsOptions {
    body1bold?: React.CSSProperties
    body2bold?: React.CSSProperties
  }

  export interface TypographyVariants {
    pageNotFoundHeading: React.CSSProperties
  }

  export interface TypographyVariantsOptions {
    pageNotFoundHeading?: React.CSSProperties
  }

  export interface TypographyVariants {
    small: React.CSSProperties
    large: React.CSSProperties
  }

  export interface TypographyVariantsOptions {
    small?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    pageNotFoundHeading: true
    body1bold: true
    body2bold: true
    small: true
    large: true
    print: true
  }
}

export const customColors = {
  darkBlue: '#033244',
  lightWhite: '#ffffff11',
  lightSecondary: '#FDA03C33',
  textPrimary: '#30281D',
  ticketHole: '#003647',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DefaultThemeOptions extends ThemeOptions {}

const createDefaultPalette = (primary?: string, secondary?: string): PaletteOptions => {
  const defaultPrimaryColor = '#1A6382'
  const defaultSecondaryColor = '#F46811'
  const primaryColor = primary || defaultPrimaryColor
  const secondaryColor = secondary || defaultSecondaryColor

  const successColor = '#2E7D32'
  const errorColor = '#D32F2F'
  const infoColor = '#0288D1'
  const warningColor = '#ED6C02'

  const textPrimary = '#30281D'
  const textSecondary = '#302B32'

  return {
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 2.5,
    primary: {
      default: defaultPrimaryColor,
      main: primaryColor,
      light: lighten(primaryColor, 0.2),
      dark: darken(primaryColor, 0.2),
      shade5: alpha(primaryColor, 0.05),
      shade8: alpha(primaryColor, 0.08),
      shade10: alpha(primaryColor, 0.1),
      shade12: alpha(primaryColor, 0.12),
      shade30: alpha(primaryColor, 0.3),
    },
    secondary: {
      default: defaultSecondaryColor,
      main: secondaryColor,
      light: lighten(secondaryColor, 0.2),
      dark: darken(secondaryColor, 0.2),
      shade12: alpha(secondaryColor, 0.12),
      shade30: alpha(secondaryColor, 0.3),
    },
    success: {
      main: successColor,
      light: '#4CAF50',
      dark: '#1B5E20',
      shade8: alpha(successColor, 0.08),
      shade10: alpha(successColor, 0.1),
      shade12: alpha(successColor, 0.12),
      shade50: alpha(successColor, 0.5),
      shade60: alpha(successColor, 0.6),
      darken60: darken(successColor, 0.6),
    },
    warning: {
      main: warningColor,
      light: '#FF9800',
      dark: '#E65100',
      shade8: alpha(warningColor, 0.08),
      shade12: alpha(warningColor, 0.12),
      shade50: alpha(warningColor, 0.5),
      darken60: darken(warningColor, 0.6),
    },
    error: {
      main: errorColor,
      light: '#EF5350',
      dark: '#C62828',
      shade8: alpha(errorColor, 0.08),
      shade12: alpha(errorColor, 0.12),
      shade50: alpha(errorColor, 0.5),
      shade60: alpha(errorColor, 0.6),
      darken60: darken(errorColor, 0.6),
      // contrastText: common.white,
    },
    info: {
      main: infoColor,
      light: '#03A9F4',
      dark: '#01579B',
      shade10: alpha(infoColor, 0.1),
      shade60: alpha(infoColor, 0.6),
      darken60: darken(infoColor, 0.6),
      // contrastText: common.white,
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      focus: 'rgba(0, 0, 0, 0.12)',
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
    text: {
      primary: textPrimary,
      secondary: alpha(textSecondary, 0.6),
      disabled: alpha(textSecondary, 0.38),
      secondaryShade18: alpha(textSecondary, 0.18),
      secondaryShade50: alpha(textSecondary, 0.5),
      secondaryShade60: alpha(textSecondary, 0.6),
      secondaryShade70: alpha(textSecondary, 0.7),
      white: '#ffffff',
    },
    divider: 'rgba(0,0,0,0.12)',
    background: {
      paper: '#ffffff',
      default: '#fafafa',
      grey: '#30281D0A',
      darkGrey: '#BCBABC',
    },
  }
}

const getDefaultThemeDefinitions = (basePalette: PaletteOptions): ThemeOptions => {
  return {
    palette: basePalette,
    shape: {
      borderRadius: 6,
    },
    typography: {
      fontFamily: 'Raleway, sans-serif',
      button: {
        fontWeight: 600,
        textTransform: 'none',
        lineHeight: 1.5,
        fontSize: 14,
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: basePalette.primary?.default,
            textDecorationColor: basePalette.primary?.default,
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'link' },
            style: {
              color: basePalette.primary?.default,
            },
          },
        ],
      },
      // MuiButtonBase: {
      //   styleOverrides: {
      //     root: {
      //       '&.Mui-disabled.MuiDateRangePickerDay-day': {
      //         color: 'rgba(203, 203, 203, 0.8)',
      //         fontWeight: 500,
      //       },
      //     },
      //   },
      // },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            height: 0,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginBottom: 16,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            borderBottom: `1px solid ${basePalette.divider}`,
            '& .Mui-selected.MuiTab-fullWidth': {
              svg: {
                fill: basePalette.primary?.main,
              },
              color: basePalette.primary?.dark,
              fontWeight: 700,
              backgroundColor: basePalette.primary?.shade10,
            },
            '& .MuiTabs-indicator': {
              height: 4,
              backgroundColor: basePalette.primary?.main,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: basePalette.primary?.main,
            fontSize: 18,
            fontWeight: 400,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& th, & th *': {
              fontWeight: 300,
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          spacer: {
            maxWidth: 1,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          action: {
            margin: 0,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardWarning: {
            color: '#5F2B01',
            backgroundColor: '#FDF0E6',
            '& .MuiSvgIcon-root': {
              fill: '#ED6C02',
            },
          },
        },
      },
    },
    spacing: 8,
  }
}

const getAdminThemeDefinitions = (basePalette: PaletteOptions): ThemeOptions => {
  basePalette.primary!.main = '#1A6382'
  basePalette.primary!.dark = '#093C4D'
  basePalette.primary!.light = '#3D788B'
  basePalette.primary!.contrastText = '#ffffff'

  basePalette.secondary!.main = '#F46811'
  basePalette.secondary!.dark = '#BF5700'
  basePalette.secondary!.light = '#FDA03C'

  const defaultTheme = getDefaultThemeDefinitions(basePalette)

  return Object.assign({}, defaultTheme, {
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            borderBottom: `1px solid ${basePalette.divider}`,
            '& .Mui-selected.MuiTab-fullWidth': {
              svg: {
                fill: basePalette.secondary?.main,
              },
              color: basePalette.primary?.dark,
              fontWeight: 700,
              backgroundColor: basePalette.secondary?.shade12,
            },
            '& .MuiTabs-indicator': {
              height: 4,
              backgroundColor: basePalette.secondary?.main,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: basePalette.primary?.main,
            fontSize: 16,
            fontWeight: 700,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            color: '#FFF',
            backgroundColor: '#2E7D32',
            '& .MuiSvgIcon-root': {
              fill: '#FFF',
            },
          },
          standardInfo: {
            color: '#013654',
            backgroundColor: '#E6F3FA',
            '& .MuiSvgIcon-root': {
              fill: '#0288D1',
            },
          },
          standardWarning: {
            color: '#5F2B01',
            backgroundColor: '#FDF0E6',
            '& .MuiSvgIcon-root': {
              fill: '#ED6C02',
            },
          },
          standardError: {
            color: '#541313',
            backgroundColor: '#FBEAEA',
            '& .MuiSvgIcon-root': {
              fill: '#D32F2F',
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: basePalette.primary?.main,
            '&.Mui-selected': {
              backgroundColor: basePalette.primary?.shade12,
              color: basePalette.primary?.dark,
              '&:hover': {
                backgroundColor: basePalette.primary?.shade5,
              },
            },
            '&:hover': {
              backgroundColor: basePalette.primary?.shade5,
            },
          },
        },
      },
    },
  })
}

const generateResponsiveSetup = (theme: Theme) => {
  const bxs = theme.breakpoints.up('xs')
  const bsm = theme.breakpoints.up('sm')
  const bmd = theme.breakpoints.up('md')
  const blg = theme.breakpoints.up('lg')
  const bxl = theme.breakpoints.up('xl')
  // const shared = { margin: 0, fontWeight: 400, lineHeight: 1.167 }

  theme.typography.h1 = {
    [bxs]: { fontSize: '28px', fontWeight: 600, lineHeight: '116%' },
    [bsm]: { fontSize: '28px', fontWeight: 600, lineHeight: '116%' },
    [bmd]: { fontSize: '40px', fontWeight: 600, lineHeight: '116%' },
    [blg]: { fontSize: '48px', fontWeight: 600, lineHeight: '116%' },
    [bxl]: { fontSize: '48px', fontWeight: 600, lineHeight: '116%' },
  }
  theme.typography.h2 = {
    [bxs]: { fontSize: '26px', fontWeight: 500, lineHeight: '120%' },
    [bsm]: { fontSize: '26px', fontWeight: 500, lineHeight: '120%' },
    [bmd]: { fontSize: '32px', fontWeight: 500, lineHeight: '120%' },
    [blg]: { fontSize: '36px', fontWeight: 500, lineHeight: '120%' },
    [bxl]: { fontSize: '36px', fontWeight: 500, lineHeight: '120%' },
  }
  theme.typography.h3 = {
    [bxs]: { fontSize: '20px', fontWeight: 500, textTransform: 'uppercase', lineHeight: '116%' },
    [bsm]: { fontSize: '20px', fontWeight: 500, textTransform: 'uppercase', lineHeight: '116%' },
    [bmd]: { fontSize: '26px', fontWeight: 500, textTransform: 'uppercase', lineHeight: '116%' },
    [blg]: { fontSize: '28px', fontWeight: 500, textTransform: 'uppercase', lineHeight: '116%' },
    [bxl]: { fontSize: '28px', fontWeight: 500, textTransform: 'uppercase', lineHeight: '116%' },
  }
  theme.typography.h4 = {
    [bxs]: { fontSize: '18px', fontWeight: 600, lineHeight: '124%' },
    [bsm]: { fontSize: '18px', fontWeight: 600, lineHeight: '124%' },
    [bmd]: { fontSize: '24px', fontWeight: 600, lineHeight: '124%' },
    [blg]: { fontSize: '28px', fontWeight: 600, lineHeight: '124%' },
    [bxl]: { fontSize: '28px', fontWeight: 600, lineHeight: '124%' },
  }
  theme.typography.h5 = {
    [bxs]: { fontSize: '16px', fontWeight: 600, lineHeight: '133%' },
    [bsm]: { fontSize: '16px', fontWeight: 600, lineHeight: '133%' },
    [bmd]: { fontSize: '18px', fontWeight: 600, lineHeight: '133%' },
    [blg]: { fontSize: '22px', fontWeight: 600, lineHeight: '133%' },
    [bxl]: { fontSize: '22px', fontWeight: 600, lineHeight: '133%' },
  }
  theme.typography.h6 = {
    [bxs]: { fontSize: '16px', fontWeight: 700, lineHeight: '143%' },
    [bsm]: { fontSize: '16px', fontWeight: 700, lineHeight: '143%' },
    [bmd]: { fontSize: '16px', fontWeight: 700, lineHeight: '143%' },
    [blg]: { fontSize: '16px', fontWeight: 700, lineHeight: '143%' },
    [bxl]: { fontSize: '16px', fontWeight: 700, lineHeight: '143%' },
  }
  theme.typography.body1 = {
    [bxs]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
    [bsm]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
    [bmd]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
    [blg]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
    [bxl]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
  }
  theme.typography.body1bold = {
    [bxs]: { fontSize: '16px', fontWeight: 600, lineHeight: '125%' },
    [bsm]: { fontSize: '16px', fontWeight: 600, lineHeight: '125%' },
    [bmd]: { fontSize: '16px', fontWeight: 600, lineHeight: '125%' },
    [blg]: { fontSize: '16px', fontWeight: 600, lineHeight: '125%' },
    [bxl]: { fontSize: '16px', fontWeight: 600, lineHeight: '125%' },
  }
  theme.typography.body2 = {
    [bxs]: { fontSize: '14px', fontWeight: 500, lineHeight: '143%' },
    [bsm]: { fontSize: '14px', fontWeight: 500, lineHeight: '143%' },
    [bmd]: { fontSize: '14px', fontWeight: 500, lineHeight: '143%' },
    [blg]: { fontSize: '14px', fontWeight: 500, lineHeight: '143%' },
    [bxl]: { fontSize: '14px', fontWeight: 500, lineHeight: '143%' },
  }
  theme.typography.body2bold = {
    [bxs]: { fontSize: '14px', fontWeight: 700, lineHeight: '143%' },
    [bsm]: { fontSize: '14px', fontWeight: 700, lineHeight: '143%' },
    [bmd]: { fontSize: '14px', fontWeight: 700, lineHeight: '143%' },
    [blg]: { fontSize: '14px', fontWeight: 700, lineHeight: '143%' },
    [bxl]: { fontSize: '14px', fontWeight: 700, lineHeight: '143%' },
  }
  theme.typography.pageNotFoundHeading = {
    [bxs]: { fontSize: '150px', fontWeight: 800, lineHeight: '266.8px', color: '#CBCBCB4D', letterSpacing: '-1.5px' },
    [bsm]: { fontSize: '150px', fontWeight: 800, lineHeight: '266.8px', color: '#CBCBCB4D', letterSpacing: '-1.5px' },
    [bmd]: { fontSize: '230px', fontWeight: 800, lineHeight: '266.8px', color: '#CBCBCB4D', letterSpacing: '-1.5px' },
    [blg]: { fontSize: '230px', fontWeight: 800, lineHeight: '266.8px', color: '#CBCBCB4D', letterSpacing: '-1.5px' },
    [bxl]: { fontSize: '230px', fontWeight: 800, lineHeight: '266.8px', color: '#CBCBCB4D', letterSpacing: '-1.5px' },
  }
  theme.typography.small = {
    [bxs]: { fontSize: '10px', fontWeight: 400 },
    [bsm]: { fontSize: '10px', fontWeight: 400 },
    [bmd]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
    [blg]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
    [bxl]: { fontSize: '16px', fontWeight: 400, lineHeight: '125%' },
  }
  theme.typography.large = {
    [bxs]: { fontSize: '24px', fontWeight: 500 },
    [bsm]: { fontSize: '24px', fontWeight: 500 },
    [bmd]: { fontSize: '36px', fontWeight: 500, lineHeight: '125%' },
    [blg]: { fontSize: '36px', fontWeight: 500, lineHeight: '125%' },
    [bxl]: { fontSize: '36px', fontWeight: 500, lineHeight: '125%' },
  }
  theme.typography.print = {
    [bxs]: { fontSize: '10px', fontWeight: 400 },
    [bsm]: { fontSize: '10px', fontWeight: 400 },
    [bmd]: { fontSize: '10px', fontWeight: 400, lineHeight: '125%' },
    [blg]: { fontSize: '10px', fontWeight: 400, lineHeight: '125%' },
    [bxl]: { fontSize: '10px', fontWeight: 400, lineHeight: '125%' },
  }
}

export const getDefaultTheme = (): Theme => {
  const theme = createTheme(getDefaultThemeDefinitions(createDefaultPalette()))
  generateResponsiveSetup(theme)
  return theme
}

export const getAdminTheme = (): Theme => {
  const theme = createTheme(getAdminThemeDefinitions(createDefaultPalette('#1A6382', '#F46811')))
  generateResponsiveSetup(theme)
  return theme
}

export const buildClientTheme = (primaryColor: string, secondaryColor: string): Theme => {
  const basePalette = createDefaultPalette(primaryColor, secondaryColor)
  const baseTheme = getDefaultThemeDefinitions(basePalette)

  const theme = createTheme(baseTheme)
  generateResponsiveSetup(theme)
  return theme
}
