import { Stack, TextField, Typography } from '@mui/material'
import { i18WithDefault as t } from '@shared/locales'
import { FormikProps } from 'formik'
import React from 'react'
import { ContinueButton } from '../continue_button'
import { TFormikValues } from './signin_tooltip_content'

type EnterEmailFormProps = {
  formik: FormikProps<TFormikValues>
  simpleView?: boolean
  onChange?: (email: string) => void
  continueLabel?: string
  disableSubmit?: boolean
}

export const EnterEmailForm = ({
  formik,
  simpleView,
  onChange,
  continueLabel,
  disableSubmit = false,
}: EnterEmailFormProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value)
    formik.handleChange(event)
  }

  return (
    <Stack spacing={2}>
      {!simpleView && (
        <Stack key="email-view">
          <Typography variant="body2bold" textAlign="center">
            {t('signin.enterEmail.title')}
          </Typography>
        </Stack>
      )}
      <TextField
        key="email"
        name="email"
        value={formik.values.email}
        label={t('signin.enterEmail.email')}
        size="small"
        onChange={handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        FormHelperTextProps={{ sx: { height: 'auto' } }}
      />
      <ContinueButton type="submit" loading={formik.isSubmitting} label={continueLabel} disabled={disableSubmit} />
    </Stack>
  )
}
