import { useFormik } from 'formik'
import { Auth } from 'aws-amplify'
import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'

import { SubmitError } from './submit_error'
import { ModalType, TConfirmResetPasswordProps } from './index.d'
import { confirmResetPassword } from './confirm_reset_password_schema'
import { i18WithDefault as t } from '../shared/locales'

const initialValues = {
  code: '',
  password: '',
  newPassword: '',
}

export const ConfirmResetPasswordForm = ({ switchModalType, email }: TConfirmResetPasswordProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const forgotPasswordSubmit = async (code: string, newPassword: string, setStatus: (status?: string) => void) => {
    setIsSubmitting(true)
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword)
      switchModalType(ModalType.signIn)
    } catch (err) {
      setStatus((err as Error).message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: confirmResetPassword,
    onSubmit: async (values, { setStatus }) => {
      await forgotPasswordSubmit(values.code, values.newPassword, setStatus)
    },
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="code"
          name="code"
          label={t('confirmResetPassword.codeField')}
          value={formik.values.code}
          onChange={formik.handleChange}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          sx={{ marginBottom: 2 }}
          FormHelperTextProps={{ style: { marginLeft: 0 } }}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label={t('confirmResetPassword.newPasswordField')}
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{ marginBottom: 2 }}
          FormHelperTextProps={{ style: { marginLeft: 0 } }}
        />
        <TextField
          fullWidth
          id="newPassword"
          name="newPassword"
          label={t('confirmResetPassword.repeatPasswordField')}
          type="password"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          FormHelperTextProps={{ style: { marginLeft: 0 } }}
          error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <SubmitError value={formik.status} />
        <LoadingButton
          color="secondary"
          variant="contained"
          fullWidth
          type="submit"
          sx={{ height: 42, my: 2 }}
          loading={isSubmitting}
        >
          {t('confirmResetPassword.submitButton')}
        </LoadingButton>
      </form>
    </div>
  )
}
