import { useQuery } from '@tanstack/react-query'
import { ClientLocationServiceTypeService } from '../services/client_location_service_type.service'
import { APILocationServiceTypeClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'
import { TClientLocationServiceTypeOptions } from './hooks_types.d'

export const useClientLocationServiceType = (locationName: string, options?: TClientLocationServiceTypeOptions) => {
  return useQuery<APILocationServiceTypeClientDtoResponse>({
    queryKey: ['clientLocationServiceType', locationName],
    queryFn: () => ClientLocationServiceTypeService.getServiceTypes(locationName, options),
    ...defaultQueryOptions,
  })
}
