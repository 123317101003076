import { getAPIPath, requestGooseAPI } from '../shared/request'
import { BookingSearchConfigsClientDto, CLIENT_BOOKING_SEARCH_CONFIGS_PATH } from '../types'

export const ClientBookingSearchConfigsService = {
  getData: (locationName: string, serviceTypeName: string): Promise<BookingSearchConfigsClientDto> => {
    return requestGooseAPI<BookingSearchConfigsClientDto>({
      url: getAPIPath(CLIENT_BOOKING_SEARCH_CONFIGS_PATH, { locationId: locationName, serviceTypeName }),
      method: 'GET',
    })
  },
}
