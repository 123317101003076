import differenceInDays from 'date-fns/differenceInDays'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import format from 'date-fns/format'

import { Types } from 'service-api'
import { getInvoiceDates } from './invoices'
import { i18WithDefault as t } from './locales'
import { joinPetNamesFromProfile } from './pets'

export const isOrderConfirmed = (order?: Types.OrderClientDto | Types.OrderAdminDto) =>
  order?.orderStatus === 'CONFIRMED'

export const getOrderDates = (
  order: Types.OrderClientDto | Types.OrderAdminDto
): { minDate: Date | null; maxDate: Date | null } => {
  let minDate: Date | null = null,
    maxDate: Date | null = null
  order.invoices?.forEach((invoice) => {
    const { startDate, endDate } = getInvoiceDates(invoice)
    if (!minDate || isBefore(startDate, minDate)) {
      minDate = startDate
    }
    if (!maxDate || isAfter(endDate, maxDate)) {
      maxDate = endDate
    }
  })
  return { minDate, maxDate }
}

export const getServiceDatesText = (order: Types.OrderClientDto, options = {}) => {
  const mergedOptions = Object.assign({}, { fullYear: false, fullPeriod: false }, options)

  const { minDate, maxDate } = getOrderDates(order)
  if (!minDate || !maxDate) {
    return t('global.label.N/A')
  }
  if (order.primaryServiceType?.serviceType?.name === 'daycare') {
    return order.invoices?.length === 1 ? format(minDate, 'MMM d, yyyy') : t('global.label.many')
  }

  if (differenceInDays(maxDate, minDate) <= 1 && !mergedOptions.fullPeriod) {
    return format(minDate, 'MMM d, yyyy')
  } else {
    return `${format(minDate, `MMM d${mergedOptions.fullYear ? ' yyyy' : ''}`)} - ${format(
      maxDate,
      `MMM d${mergedOptions.fullYear ? ' yyyy' : ''}`
    )}`
  }
}

export const getReservationSummary = (invoice: Types.CustomInvoiceAdminDto) => {
  return (invoice.items || []).map((item) => `${item.qty} ${item.displayName}`).join(', ')
}

export const orderFormatPetNames = (order?: Types.OrderAdminDto) => {
  if (!order) {
    return ''
  }
  const profiles =
    order.pets
      ?.map((p) =>
        p.locationPetProfile
          ? p.locationPetProfile
          : ({ displayName: p.displayName } as Types.LocationPetProfileAdminDto)
      )
      ?.filter(Boolean) || []
  return joinPetNamesFromProfile(profiles)
}

export const getOrderPrimaryServiceTypeName = (order?: Types.OrderAdminDto) =>
  order?.primaryServiceType?.serviceType?.name

export const isOvernightFromOrder = (order?: Types.OrderAdminDto) => !!order?.primaryServiceType?.serviceType?.overnight

export const isAdyenEnabled = (order?: Types.OrderAdminDto) => order?.location?.payFacConfig?.type === 'ADYEN'

export const isNewReservationOrder = (order?: Types.OrderAdminDto | Types.CustomOrderAdminDto): boolean =>
  order?.orderStatus === 'PENDING'

export const getOrderStatus = ({
  invoice,
  order,
  overnight,
}: {
  invoice?: Types.InvoiceAdminDto
  order?: Types.OrderAdminDto
  overnight?: boolean
}) => {
  const getStatus = (status?: string) => {
    switch (status) {
      case 'CANCELED':
        return t('global.label.canceled')
      case 'PARTIALLY_CANCELED':
        return t('global.label.partiallyCanceled')
      default:
        return null
    }
  }

  if (overnight === undefined) {
    return invoice ? getStatus(invoice?.invoiceStatus) : getStatus(order?.orderStatus)
  } else if (overnight) {
    return getStatus(invoice?.order?.orderStatus)
  } else {
    return getStatus(invoice?.invoiceStatus)
  }
}

export const getPrimaryInvoices = (
  order: Types.OrderAdminDto | Types.CustomOrderAdminDto
): Types.InvoiceAdminDto[] | Types.CustomInvoiceAdminDto[] => {
  return (
    order.invoices?.filter(
      (invoice) => !invoice.isInStay && invoice.items?.some((item) => item.offerType === 'PRIMARY')
    ) || []
  )
}
