import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useMemo, useCallback } from 'react'
import format from 'date-fns/format'

import { Types } from 'service-api'
import { useBookingContext } from '@providers/booking'
import { formatNumberAsPrice } from '@shared/common'
import { i18WithDefault as t } from '@shared/locale'
import { checkInOutTimeIntervals } from 'components-ui/src/shared'
import { getOfferServiceInterval } from './helper'

const DATE_FORMAT = 'MMM d'

export const DateViewer = () => {
  const bookingCtx = useBookingContext()
  const { state: booking } = bookingCtx

  const onClickTime = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      const { currentTarget: t } = ev
      bookingCtx.dispatch({
        type: 'SET_TEMPER_TEST_TIME',
        payload: { groupIdx: 0, value: t.textContent, timez: t.dataset.timez },
      })
    },
    [bookingCtx]
  )

  const temperDay = booking.cart.temperTest!
  const intervals = useMemo(() => {
    if (!temperDay?.date) {
      return []
    }

    const operationHour = (
      booking.data.temperTest?.restrictionRelations?.filter((rr) => rr.restriction?.type === 'OPERATION_HOUR') || []
    ).map(({ restriction }) => restriction)

    return checkInOutTimeIntervals(temperDay.date, operationHour as Types.RestrictionClientDto[], {
      interval: getOfferServiceInterval(booking.data.temperTestOffer!, 30),
    })
  }, [booking.data.temperTest?.restrictionRelations, booking.data?.temperTestOffer, temperDay?.date])

  const dateFormatted = useMemo(() => (temperDay?.date ? format(temperDay.date, DATE_FORMAT) : ''), [temperDay?.date])
  const price = useMemo(() => formatNumberAsPrice(temperDay?.price ?? 0), [temperDay?.price])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={10}>{`${t('temperTest.timesAvailable')} ${dateFormatted}`}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left" colSpan={2}>
            {intervals.map((i) => (
              <Chip
                key={i.ampm}
                label={i.ampm}
                variant={(temperDay.time || '') === i.ampm ? 'filled' : 'outlined'}
                color="primary"
                sx={{ mx: 1, my: 1 }}
                data-timez={i.timez}
                onClick={onClickTime}
              />
            ))}
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell align="right" colSpan={2} sx={{ borderBottom: 'none' }}>
            <Typography variant="h5">{price}</Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}
