import { MenuItem, Alert, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useMemo } from 'react'

import { useBookingContext } from '@providers/booking'
import { i18WithDefault as t } from '@shared/locale'
import {
  checkInOutTimeIntervals,
  isCheckInTimeEnabled,
  isCheckOutTimeEnabled,
  isSelectedDayInPeriodRestrictions,
  isSelectedTimeInPeriodRestrictions,
  displayHoursInPeriodRestrictions,
  getDOWFromStr,
} from 'components-ui/src/shared'
import { Types } from 'service-api'
import { TCheckInOutProps } from './details_policies.d'
import { Spacer1 } from 'components-ui'

export const CheckInOut = ({ formik, reservation }: TCheckInOutProps) => {
  const { order, locationBookingConf } = reservation
  const bookingCtx = useBookingContext()
  const theme = useTheme()
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'))

  const { startDate, endDate } = useMemo(() => {
    const invoice = order?.invoices?.find(({ items }) => {
      return items?.some(({ locationServiceTypeId }) => locationServiceTypeId === order?.primaryServiceType?.id)
    })

    return invoice?.period?.[0] || {}
  }, [order])

  const checkInDay = startDate && getDOWFromStr(startDate)
  const checkOutDay = endDate && getDOWFromStr(endDate)
  const isSurchargeStrategyEnabled =
    locationBookingConf?.surchargeStrategy === 'ENFORCED' || locationBookingConf?.surchargeStrategy === 'DURATION'

  const isCheckInEnabled =
    checkInDay &&
    isSelectedDayInPeriodRestrictions(reservation.checkInHour!, checkInDay) &&
    locationBookingConf?.surchargeStrategy !== 'DISABLED'
  const checkInPeriod =
    isCheckInEnabled && !isSurchargeStrategyEnabled ? reservation.checkInHour : reservation.operationHour
  const checkInTimes = useMemo(
    () => checkInOutTimeIntervals(startDate, checkInPeriod as Types.RestrictionClientDto[]),
    [startDate, checkInPeriod]
  )

  const isCheckOutEnabled =
    checkOutDay &&
    isSelectedDayInPeriodRestrictions(reservation.checkOutHour!, checkOutDay) &&
    locationBookingConf?.surchargeStrategy !== 'DISABLED'
  const checkOutPeriod =
    isCheckOutEnabled && !isSurchargeStrategyEnabled ? reservation.checkOutHour : reservation.operationHour
  const checkOutTimes = useMemo(
    () => checkInOutTimeIntervals(endDate, checkOutPeriod as Types.RestrictionClientDto[]),
    [endDate, checkOutPeriod]
  )

  const isFeeWarningVisible = () => {
    if (locationBookingConf?.surchargeStrategy === 'ENFORCED') {
      const isCheckInFeeWarningActive =
        isCheckInEnabled &&
        !!formik.values.checkInTime &&
        !isSelectedTimeInPeriodRestrictions(reservation.checkInHour!, checkInDay, formik.values.checkInTime)
      const isCheckOutFeeWarningActive =
        isCheckOutEnabled &&
        !!formik.values.checkOutTime &&
        !isSelectedTimeInPeriodRestrictions(reservation.checkOutHour!, checkOutDay, formik.values.checkOutTime)
      return isCheckInFeeWarningActive || isCheckOutFeeWarningActive
    } else if (locationBookingConf?.surchargeStrategy === 'DURATION') {
      return formik.values.checkInTime < formik.values.checkOutTime
    }
    return false
  }

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ev: React.ChangeEvent<any>) => {
      const { name, id, value } = ev.target
      bookingCtx.dispatch({
        type: 'SET_CHECK_IN_OUT_FORM',
        payload: {
          value: {
            fieldName: name || id,
            value,
          },
        },
      })

      formik.handleChange(ev)
    },
    [bookingCtx, formik]
  )

  return (
    <Paper elevation={1} sx={{ p: 2, pb: 3, borderRadius: `${isBelowMD ? 0 : theme.shape.borderRadius}px` }}>
      <Typography variant="h5">{t('checkInOutForm.title')}</Typography>
      <Typography variant="body1" mt={3} mb={2}>
        {t('checkInOutForm.subtitle')}
      </Typography>

      <Stack direction={{ xs: 'column', sm: 'row' }}>
        {isCheckInTimeEnabled(reservation.locationBookingConf!) && (
          <TextField
            id="checkInTime"
            name="checkInTime"
            label={t('checkInOutForm.checkIn')}
            variant="outlined"
            size="small"
            select
            sx={{ flexBasis: 0, flexGrow: 1, mr: { xs: 0, sm: 1 } }}
            value={formik.values.checkInTime}
            onChange={onChange}
            error={formik.touched.checkInTime && Boolean(formik.errors.checkInTime)}
            helperText={formik.touched.checkInTime && formik.errors.checkInTime}
          >
            {checkInTimes.map((ctime, i) => (
              <MenuItem key={`${ctime.ampm}-${i}`} value={ctime.timez}>
                {ctime.ampm}
              </MenuItem>
            ))}
          </TextField>
        )}
        {isCheckOutTimeEnabled(reservation.locationBookingConf!) && (
          <TextField
            id="checkOutTime"
            name="checkOutTime"
            label={t('checkInOutForm.checkOut')}
            variant="outlined"
            size="small"
            select
            sx={{ flexBasis: 0, flexGrow: 1, mt: { xs: 2, sm: 0 } }}
            value={formik.values.checkOutTime}
            onChange={onChange}
            error={formik.touched.checkOutTime && Boolean(formik.errors.checkOutTime)}
            helperText={formik.touched.checkOutTime && formik.errors.checkOutTime}
          >
            {checkOutTimes.map((ctime, i) => (
              <MenuItem key={`${ctime.ampm}-${i}`} value={ctime.timez}>
                {ctime.ampm}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
      <Stack>
        {isFeeWarningVisible() && (
          <>
            <Alert severity="warning">
              <Typography variant="body2">{t('checkInOutForm.warning')}.</Typography>
            </Alert>
            <Spacer1 />
          </>
        )}
        {locationBookingConf?.surchargeStrategy === 'DURATION' ? (
          <Typography variant="body2" color="text.secondaryShade70">
            {t('checkInOutForm.duration')}
          </Typography>
        ) : (
          <Stack>
            {isCheckInEnabled && (
              <Typography variant="body2" color="text.secondaryShade70">
                {t('checkInOutForm.checkInTimes')}:{' '}
                {displayHoursInPeriodRestrictions(reservation.checkInHour!, checkInDay)}
              </Typography>
            )}
            {isCheckOutEnabled && (
              <Typography variant="body2" color="text.secondaryShade70">
                {t('checkInOutForm.checkOutTimes')}:{' '}
                {displayHoursInPeriodRestrictions(reservation.checkOutHour!, checkOutDay)}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}
