import { useQuery } from '@tanstack/react-query'
import { ClientLocationBookingConfService } from '../services/client_location_booking_conf.service'
import { APILocationBookingConfClientDtoResponse } from '../types'
import { defaultQueryOptions } from './common'

export const useClientLocationBookingConf = (locationName: string, serviceTypeId?: string) => {
  return useQuery<APILocationBookingConfClientDtoResponse>({
    queryKey: ['clientLocationBookingConf', locationName],
    queryFn: () => ClientLocationBookingConfService.getConfiguration(locationName, serviceTypeId || ''),
    enabled: Boolean(serviceTypeId),
    ...defaultQueryOptions,
  })
}
