import { useQuery } from '@tanstack/react-query'
import { ClientContractsService } from '../services'
import { ContractClientDto } from '../types'
import { defaultQueryOptions } from './common'
import { TContractType } from '../types/general'

export const useClientContracts = (locationName: string, contractType?: TContractType) => {
  return useQuery<ContractClientDto | null>({
    queryKey: ['clientContracts', locationName, contractType],
    queryFn: () => ClientContractsService.get(locationName, contractType),
    ...defaultQueryOptions,
  })
}
