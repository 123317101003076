import { Typography } from '@mui/material'
import { i18WithParams as t } from '@shared/locale'
import { Spacer1, Spacer3 } from 'components-ui'

type VerificationModalTitleType = {
  variant: 'REGISTERING' | 'EXISTING_USER' | 'DEFAULT'
  email?: string
}

export const VerificationModalTitle = ({ variant = 'DEFAULT', email = '' }: VerificationModalTitleType) => {
  switch (variant) {
    case 'REGISTERING':
      return (
        <>
          <Typography variant="h6">{t('forcedLogin.register.title')}</Typography>
          <Spacer3 />
          <Typography variant="body1">{t('forcedLogin.register.info', { email })}</Typography>
          <Spacer1 />
        </>
      )
    case 'EXISTING_USER':
      return (
        <>
          <Typography variant="h6">{t('forcedLogin.existingUser.title')}</Typography>
          <Spacer3 />
          <Typography variant="body1">{t('forcedLogin.existingUser.info')}</Typography>
          <Spacer1 />
        </>
      )
      break
    default:
      return (
        <>
          <Typography variant="h6">{t('forcedLogin.enterEmail')}</Typography>
          <Spacer3 />
          <Typography variant="body1">{t('forcedLogin.text')}</Typography>
          <Spacer1 />
        </>
      )
      break
  }
}
