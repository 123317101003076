import * as Sentry from '@sentry/react'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { Services, Types } from 'service-api'
import { createTilledForm, initializeTilled } from './helper'
import type { TBillingDetails } from './types'

export type TSubmitHandler = (billingDetails: TBillingDetails) => Promise<GenericSimpleBag>

const normalizeErrorMsg = (msg: string): string => {
  switch (msg) {
    case "Field of type 'cardNumber'' is not valid":
      return 'Invalid Credit Card number'
    case "Field of type 'cardCvv'' is not valid":
      return 'Invalid CVV value.'
    case "Field of type 'cardExpiry'' is not valid":
      return 'Invalid Expiration Date'
    default:
      return msg || 'Error processing payment'
  }
}

export const useTilled = () => {
  const bookingCtx = useBookingContext()
  const { accountName = '' } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [processor, setProcessor] = useState<any>(null)
  const [paymentData, setPaymentData] = useState<Types.BookingReservationClientDto | null>(null)
  const [loadingPaymentData, setLoadingPaymentData] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [form, setForm] = useState<any>(null)
  const [errorMsg, setErrorMsg] = useState('')
  const ref = useRef(false)

  useEffect(() => {
    if (ref.current || !bookingCtx.state.data.reservation?.order?.id) {
      return
    }
    setLoadingPaymentData(true)
    ref.current = true
    Services.ClientBookingPaymentService.initializePayment(
      accountName,
      bookingCtx.state.data.reservation?.order?.id || ''
    )
      .then((data: Types.BookingReservationClientDto) => setPaymentData(data))
      .catch(Sentry.captureException)
      .finally(() => setLoadingPaymentData(false))

    // setPaymentData({})
  }, [bookingCtx.state.data.reservation?.order?.id, accountName])

  useEffect(() => {
    if (paymentData && window.Tilled && !processor) {
      initializeTilled(paymentData)
        .then((rc) => {
          createTilledForm(rc.form)
          setProcessor(rc.processor)
          setForm(rc.form)
          setErrorMsg('')
        })
        .catch((e) => {
          Sentry.captureException(e)
          setErrorMsg(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      form?.teardown(() => {
        setForm(null)
      })
    }
  }, [form, paymentData, processor])

  const submitHandler: TSubmitHandler = async (billingDetails: TBillingDetails): Promise<GenericSimpleBag> => {
    if (!processor || !paymentData) {
      return {}
    }
    setIsSubmitting(true)
    setErrorMsg('')
    return (
      processor
        .confirmPayment(paymentData.clientSecret, {
          payment_method: {
            form,
            billing_details: billingDetails,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          Sentry.captureException(error)
          // eslint-disable-next-line no-console
          console.error('TILLED ERROR', error)
          setErrorMsg(normalizeErrorMsg(error?.message))
        })
        .finally(() => setIsSubmitting(false))
    )
  }

  return {
    isLoading,
    isSubmitting,
    errorMsg,
    submitHandler,
    paymentData,
    loadingPaymentData,
  }
}
