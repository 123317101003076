import { Types } from 'service-api'

export const STATIC_PETINFO_FIELD_NAME = 'static-petinfo-field-name'
export const STATIC_PETINFO_FIELD_BREED = 'static-petinfo-field-breed'
export const STATIC_PETINFO_FIELD_WEIGHT = 'static-petinfo-field-weight'

export const INTAKE_PETINFO_FIELD_BIRTHDATE = 'intake-petinfo-field-0'
export const INTAKE_PETINFO_FIELD_SEX = 'intake-petinfo-field-1'
export const INTAKE_PETINFO_FIELD_ALTERED = 'intake-petinfo-field-2'

export const petInfoFields = [
  STATIC_PETINFO_FIELD_NAME,
  STATIC_PETINFO_FIELD_BREED,
  STATIC_PETINFO_FIELD_WEIGHT,
  INTAKE_PETINFO_FIELD_BIRTHDATE,
  INTAKE_PETINFO_FIELD_SEX,
  INTAKE_PETINFO_FIELD_ALTERED,
]

export type FlexBookingConfWithValue = {
  conf: Types.FlexBookingConfClientDto
  value?: string
}
export type FlexTarget = 'OWNER' | 'PET'

export const isValidIntakeConf = (conf: Types.FlexBookingConfClientDto, target: FlexTarget) =>
  conf.step === 'INTAKE' &&
  conf.target === target &&
  conf.type === 'FIELD' &&
  !!conf.name &&
  !!conf.group &&
  !!conf.order &&
  !!conf.description

export const partitionFlexBookingConfs = (flexBookingConfs: Types.FlexBookingConfClientDto[], target: FlexTarget) => {
  const intakeFlexConfs: Types.FlexBookingConfClientDto[] = [],
    intakeFlexFileConfs: Types.FlexBookingConfClientDto[] = []
  flexBookingConfs.forEach((conf) => {
    if (isValidIntakeConf(conf, target)) {
      if (['TEXT', 'PHONE', 'NUMBER', 'DATE', 'SELECT'].includes(conf.subtype || '')) {
        intakeFlexConfs.push(conf)
      } else if (conf.subtype === 'FILE') {
        intakeFlexFileConfs.push(conf)
      }
    }
  })
  return { intakeFlexConfs, intakeFlexFileConfs }
}

export const groupedFlexConfs = (flexBookingConfs: Types.FlexBookingConfClientDto[]) => {
  return Array.from(
    flexBookingConfs
      .slice()
      .sort((conf1, conf2) => conf1.order! - conf2.order!)
      .reduce((acc, conf) => {
        if (!acc.has(conf.group!)) {
          acc.set(conf.group!, [conf])
        } else {
          acc.get(conf.group!)!.push(conf)
        }
        return acc
      }, new Map<string, Types.FlexBookingConfClientDto[]>())
  )
}

export const hasIntakeData = (data: Record<string, never> | undefined, target: FlexTarget) =>
  Object.values((data?.flexBookingConfs || {}) as Record<string, FlexBookingConfWithValue>).some(({ conf }) =>
    isValidIntakeConf(conf, target)
  )

export const vaccinesForPet = (
  vaccines: Types.LocationVaccineClientDto[],
  speciesId: string
): { requiredVax: string; optionalVax: string } => {
  const requiredVaccines: string[] = [],
    optionalVaccines: string[] = []
  vaccines?.forEach(({ required, displayName, name, vaccineType }) => {
    if (!speciesId || vaccineType?.speciesId === speciesId) {
      const vaxName = displayName || vaccineType?.displayName || name || vaccineType?.name || ''
      if (vaxName) {
        if (required) {
          requiredVaccines.push(vaxName)
        } else {
          optionalVaccines.push(vaxName)
        }
      }
    }
  })
  return { requiredVax: requiredVaccines.join(', '), optionalVax: optionalVaccines.join(', ') }
}
