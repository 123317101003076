import type { TGooseClaim, TGooseClaimLocation, TUser } from './session'
import { CognitoUser } from '@aws-amplify/auth'

export const USER_STORE_KEY = 'BookingUserState'

export const isLocationValid = (claims: TGooseClaim[], location: string): boolean => {
  return claims?.findIndex((c) => c.locations?.findIndex((l) => l.name === location) >= 0) >= 0
}

export const getLocationData = (claims: TGooseClaim[], location: string): TGooseClaimLocation | undefined => {
  let rc: TGooseClaimLocation | undefined
  claims?.findIndex((c) => c.locations?.findIndex((l) => (l.name === location ? (rc = l) : false)) >= 0)
  return rc
}

export const resolveFromPathname = (claims: TGooseClaim[], pathName: string): TGooseClaimLocation | undefined => {
  const segments = pathName.split('/')
  if (segments.length < 2 || !segments[1]) {
    return
  }

  return getLocationData(claims, segments[1])
}

export const getCognitoGroupId = (user: CognitoUser): string | undefined => {
  const userRegEx = /^USR:([a-z0-9]*):SELF$/
  const payload = user.getSignInUserSession()?.getAccessToken().decodePayload()

  const groups: string = (payload?.['cognito:groups'] || []).find((group: string) => userRegEx.test(group))

  if (!groups) {
    // eslint-disable-next-line no-console
    console.error('Unable to decode user information (err: UA01)')
    return undefined
  }

  const matches = RegExp(userRegEx).exec(groups)
  if (!matches) {
    // eslint-disable-next-line no-console
    console.error('Unable to decode user information (err: UA02)')
    return undefined
  }

  return matches[1]
}

export const isUserLoggedIn = (user?: TUser): boolean => !!user?.token
