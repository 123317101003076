import Link from '@mui/material/Link'
import Tab from '@mui/material/Tab'
import MUITabs from '@mui/material/Tabs'

import type { TTabsProps } from './tabs.d'

export const Tabs = ({ tabs, tabIndex, onChange }: TTabsProps) => {
  const handleChange = (ev: React.SyntheticEvent, newValue: number) => {
    if (onChange) {
      onChange(ev.currentTarget.textContent || '', newValue)
    }
  }

  return (
    <MUITabs value={tabIndex} onChange={handleChange} variant="fullWidth">
      {tabs.map((t, idx) => (
        <Tab key={`${t.label}-${t.to}-${idx}`} component={Link} href={t.to} label={t.label} icon={t.icon} />
      ))}
    </MUITabs>
  )
}
