import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_PET_PROFILES_ID_PATH, CLIENT_PET_PROFILES_PATH } from '../types/constants'
import { PetProfileClientDto, PetProfileCreateInput, PetProfileUpdateInput } from '../types/api'

export const ClientPetProfilesService = {
  create: async (locationId: string, petFamily: string, data: PetProfileCreateInput): Promise<PetProfileClientDto> => {
    return requestGooseAPI<PetProfileClientDto>({
      url: getAPIPath(CLIENT_PET_PROFILES_PATH, { locationId }),
      method: 'POST',
      params: {
        petFamily,
      },
      data,
    })
  },

  update: async (
    locationId: string,
    petId: string,
    petFamily: string,
    data: PetProfileUpdateInput
  ): Promise<PetProfileClientDto> => {
    return requestGooseAPI<PetProfileClientDto>({
      url: getAPIPath(CLIENT_PET_PROFILES_ID_PATH, { locationId, id: petId }),
      method: 'PUT',
      params: {
        petFamily,
      },
      data,
    })
  },

  delete: async (locationId: string, petId: string, petFamily: string): Promise<PetProfileClientDto> => {
    return requestGooseAPI<PetProfileClientDto>({
      url: getAPIPath(CLIENT_PET_PROFILES_ID_PATH, { locationId, id: petId }),
      method: 'DELETE',
      params: {
        petFamily,
      },
    })
  },
}
