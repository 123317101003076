import { Button, Stack, TextField, Typography, useTheme } from '@mui/material'
import { i18WithDefault as t } from '@shared/locales'
import { Auth } from 'aws-amplify'
import { FormikProps } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { ContinueButton } from '../continue_button'
import { TFormikValues } from './signin_tooltip_content'
import { captureError } from 'service-api/src/shared'

type VerifyEmailFormProps = {
  formik: FormikProps<TFormikValues>
  setShowVerifyForm?: React.Dispatch<React.SetStateAction<boolean>> | (() => void)
  setCognitoUser?: React.Dispatch<React.SetStateAction<null>>
  onUseDifferentEmail?: () => void
  simpleView?: boolean
  isNewUser?: boolean
  hideUseDifferentEmail?: boolean
  sx?: { [keyof: string]: number | string }
}

export const VerifyEmailForm = ({
  formik,
  setShowVerifyForm,
  setCognitoUser,
  onUseDifferentEmail,
  simpleView,
  isNewUser,
  hideUseDifferentEmail = false,
  sx,
}: VerifyEmailFormProps) => {
  const theme = useTheme()
  const [disableVerificationCode, setDisableVerificationCode] = useState<boolean>(false)

  const handleRequestNewCodeClick = async () => {
    setDisableVerificationCode(true)
    if (!isNewUser) {
      Auth.signIn(formik.values.email)
        .then((result) => {
          setCognitoUser?.(result)
          enqueueSnackbar(t('signin.verify.sent'), { variant: 'success' })
          setTimeout(() => {
            setDisableVerificationCode(false)
          }, 5000)
        })
        .catch((err) => {
          setDisableVerificationCode(false)
          enqueueSnackbar(err, { variant: 'error' })
          captureError(err as Error)
        })
    } else {
      await Auth.resendSignUp(formik.values.email)
    }
  }

  const handleCorrectEmailClick = () => {
    formik.resetForm()
    onUseDifferentEmail?.()
    setShowVerifyForm?.(false)
  }

  return (
    <Stack spacing={2} sx={sx}>
      {!simpleView && (
        <Stack>
          <Typography variant="h6" textAlign="center">
            {t('signin.confirm.title')}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {t('signin.verify.copy')}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {formik.values.email}
          </Typography>
        </Stack>
      )}
      <TextField
        key="code"
        name="code"
        value={formik.values.code}
        placeholder={t('signin.verify.code')}
        size="small"
        onChange={formik.handleChange}
        onBlur={(e) => formik.setFieldValue('code', e.target.value.trim())}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={formik.touched.code && formik.errors.code}
        FormHelperTextProps={{ sx: { height: 'auto' } }}
      />
      <ContinueButton type="submit" loading={formik.isSubmitting} />
      <Button
        sx={{ color: theme.palette.primary.dark, p: 0 }}
        onClick={handleRequestNewCodeClick}
        disabled={disableVerificationCode}
      >
        {t('signin.verify.requestNewCode')}
      </Button>
      {hideUseDifferentEmail ? null : (
        <Button sx={{ color: theme.palette.primary.dark, p: 0 }} onClick={handleCorrectEmailClick}>
          {t('signin.verify.correctEmail')}
        </Button>
      )}
    </Stack>
  )
}
