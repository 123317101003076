import { Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { AssetImage } from 'components-ui'
import { Types } from 'service-api'
import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

export const Logo = ({
  isLoading,
  asset,
  location,
  heightOverrides,
}: {
  isLoading: boolean
  asset?: Types.AssetClientDto
  location?: Types.LocationClientDto
  heightOverrides?: number
}) => {
  const theme = useTheme()
  const isBellowMD = useMediaQuery(theme.breakpoints.down('md'))
  const imageHeight = heightOverrides ? heightOverrides : isBellowMD ? 100 : 200

  if (isLoading) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Skeleton width={320} height={imageHeight} sx={{ transform: 'scale(1,1)' }} />
      </Stack>
    )
  }
  if (asset) {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <AssetImage asset={asset} height={imageHeight} />
      </Stack>
    )
  }
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Typography variant="h1">{location?.displayName || location?.name}</Typography>
    </Stack>
  )
}
