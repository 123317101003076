import format from 'date-fns/format'
import add from 'date-fns/add'

import { TBookingContext, TBookingContextDef } from '@providers/booking/types'

export const selectedDaysSorted = (state: TBookingContext) => {
  const days = state.cart.groups[0]?.daycareSelected?.map((days) => days.id)
  if (!days) {
    return
  }
  days.sort((a, b) => a - b)

  return days
}

export const daycareSelectionPeriod = (bookingCtx: TBookingContextDef) => {
  const days = selectedDaysSorted(bookingCtx.state)
  if (!days) {
    return
  }

  return {
    startDate: format(days[0], 'yyyy-MM-dd'),
    endDate: format(add(days[days.length - 1], { days: 1 }), 'yyyy-MM-dd'),
  }
}
