import { Box, Button, CircularProgress, Modal, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBookingContext } from '@providers/booking'
import { useSessionContext } from '@providers/session'
import { isUserLoggedIn } from '@providers/session/helpers'
import { i18WithDefault as t } from '@shared/locale'
import { useRoutes } from 'app/use_routes'
import { Spacer1, Spacer3 } from 'components-ui'
import { CatIcon } from 'components-ui/src/asset/cat_icon'
import { DogIcon } from 'components-ui/src/asset/dog_icon'
import { SignInTooltipContent } from 'components-ui/src/signin_tooltip'
import { RegistrationTooltipContents } from 'components-ui/src/signin_tooltip'
import { usePetFamilies } from './use_pet_families'
import { VerificationModalTitle } from './verification_modal_title'
import TagManager from 'react-gtm-module'

export const VerificationModal = () => {
  const { accountName = '', serviceTypeName = '' } = useParams()
  const { navigateRoute } = useRoutes()
  const bookingCtx = useBookingContext()
  const sessionCtx = useSessionContext()
  const theme = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false)
  const [isRegistering, setIsRegistering] = useState<boolean>(false)
  const [codeRequested, setCodeRequested] = useState<boolean>(false)
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState<boolean>(false)
  const [newUserEmail, setNewUserEmail] = useState<string | undefined>(undefined)
  const { isLoading, hasPets, continueBooking } = usePetFamilies()

  const navigateToOffersPage = useCallback(() => {
    bookingCtx.dispatch({ type: 'CLEAR_OFFERS_SELECTION' })
    navigateRoute('searchOffersResults', { accountName, serviceTypeName })
    setIsModalOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountName, serviceTypeName, navigateRoute])

  useEffect(() => {
    // If the user is already Signed In to start the booking, navigate to Offers page
    if (isUserLoggedIn(sessionCtx.state.user) && !hasPets && !isLoading) {
      navigateToOffersPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionCtx.state.user, isLoading, navigateToOffersPage])

  useEffect(() => {
    if (continueBooking) {
      setTimeout(() => {
        navigateToOffersPage()
      }, 0)
    }
  }, [continueBooking, navigateToOffersPage])

  const handleResetBooking = () => {
    bookingCtx.dispatch({ type: 'RESET_STATE' })
    navigateRoute('locationHome', {
      serviceTypeName: bookingCtx.state.serviceTypeName,
    })
  }

  const handleSuccessfulSignIn = async () => setIsLocalLoading(true)
  const handleCodeRequested = () => setCodeRequested(true)
  const newCustomerEmail = bookingCtx.state.cart.contact.email

  const getTitleVariant = () => {
    if (newCustomerEmail.length > 0 || isRegistrationSuccessful) return 'REGISTERING'
    if (codeRequested) return 'EXISTING_USER'
    return 'DEFAULT'
  }

  return (
    <Modal open={isModalOpen}>
      <Paper
        sx={{
          maxWidth: 530,
          margin: '200px auto',
          bgcolor: 'background.paper',
          borderRadius: 1,
          p: 3,
        }}
        className="verificationModal"
      >
        <Stack direction="row">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '40px', height: '40px', borderRadius: '40px', backgroundColor: theme.palette.grey[200] }}
          >
            <DogIcon viewBox="0 -2 28 28" sx={{ stroke: theme.palette.primary.main }} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '40px',
              backgroundColor: theme.palette.grey[200],
              marginLeft: '10px',
            }}
          >
            <CatIcon viewBox="0 -2 28 28" sx={{ stroke: theme.palette.primary.main }} />
          </Box>
        </Stack>
        <Spacer1 />
        {(isLoading || isLocalLoading) && !hasPets ? (
          <>
            <Box sx={{ display: 'flex', margin: 'auto', width: '100%' }}>
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            {hasPets ? (
              <>
                <Typography variant="h6">{t('forcedLogin.letsTryThisAgain')}</Typography>
                <Spacer3 />
                <Typography variant="body1">{t('forcedLogin.restartBookingText')}</Typography>
                <Spacer3 />
                <Button variant="contained" fullWidth onClick={handleResetBooking}>
                  {t('global.label.continue')}
                </Button>
              </>
            ) : (
              <>
                <VerificationModalTitle variant={getTitleVariant()} email={newUserEmail || newCustomerEmail} />
                {newCustomerEmail.length > 0 ? (
                  <RegistrationTooltipContents
                    email={newCustomerEmail}
                    simpleView
                    onSuccessfulSignIn={handleSuccessfulSignIn}
                    tryAgain={() =>
                      bookingCtx.dispatch({
                        type: 'SET_CONTACT_FIELD',
                        payload: {
                          value: {
                            fieldName: 'email',
                            value: '',
                          },
                        },
                      })
                    }
                  />
                ) : (
                  <SignInTooltipContent
                    simpleView
                    onSuccessfulSignIn={handleSuccessfulSignIn}
                    onCodeRequested={handleCodeRequested}
                    continueLabel={t('global.label.continue')}
                    onChange={(email) => setNewUserEmail(email)}
                    onUseDifferentEmail={() => setCodeRequested(false)}
                    newUserEmail={newCustomerEmail}
                    registerIfNeeded={true}
                    onRegistering={() => setIsRegistering(true)}
                    onSuccessfulRegistration={() => setIsRegistrationSuccessful(true)}
                    TagManager={TagManager}
                  />
                )}
                <Spacer1 />
                {!(codeRequested || isRegistering) && (
                  <Button
                    sx={{ color: theme.palette.primary.dark, p: 0 }}
                    fullWidth
                    onClick={() =>
                      navigateRoute('locationHome', {
                        serviceTypeName: bookingCtx.state.serviceTypeName,
                      })
                    }
                  >
                    {t('forcedLogin.exit')}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </Paper>
    </Modal>
  )
}
