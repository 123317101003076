import { Stack, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

type Props = {
  sx?: SxProps<Theme>
  label: ReactNode
  value?: string
  renderIfEmpty?: boolean
}
export const ReadonlyTextField = ({ sx, label, value, renderIfEmpty = false }: Props) => {
  const { palette } = useTheme()
  return value ? (
    <Stack sx={{ ...sx, px: 2 }}>
      <Typography
        variant="caption"
        sx={{ color: palette.text.secondaryShade60, position: 'relative', bottom: 8, left: 2 }}
      >
        {label}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  ) : renderIfEmpty ? (
    <Stack sx={{ ...sx, px: 2, height: 53 }}>
      <Typography
        variant="caption"
        sx={{ color: palette.text.secondaryShade60, position: 'relative', bottom: 8, left: 2 }}
      >
        {label}
      </Typography>
    </Stack>
  ) : null
}
