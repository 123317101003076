import { requestGooseAPI, getAPIPath } from '../shared/request'
import { CLIENT_LOCATION_THEMES_PATH } from '../types/constants'
import { APILocationThemeClientDtoResponse, LocationThemeClientDto } from '../types/api'

export const ClientLocationThemesService = {
  getTheme: async (locationName: string): Promise<LocationThemeClientDto> => {
    const resp = await requestGooseAPI<APILocationThemeClientDtoResponse>({
      url: getAPIPath(CLIENT_LOCATION_THEMES_PATH, { locationId: locationName }),
      method: 'GET',
      params: {
        includes: ['primaryColor', 'secondaryColor', 'logo.asset'],
      },
    })
    return resp.results[0]
  },
}
