import add from 'date-fns/add'

import { TBookingContext } from '@providers/booking/types'
import { buildDateRangeComparator } from 'components-ui/src/shared'
import { TComparatorCB } from 'components-ui/src/shared/dates.d'
import { Types } from 'service-api'
import { isEmpty } from './common'

export const getThresholds = (restrictions?: Types.RestrictionClientDto[], specieId?: string) => {
  if (!restrictions || !specieId) {
    return
  }

  const specieRestriction = restrictions.filter(
    (r) => !(isEmpty(r.min) && isEmpty(r.max)) && (r.restrictions?.findIndex((rr) => rr.text === specieId) ?? -1) >= 0
  )

  if (specieRestriction.length > 0) {
    // note: min/max are inverted
    return {
      min: specieRestriction[0].max || 0,
      max: specieRestriction[0].min || 0,
    }
  }

  return
}

export const daycareStartEndDates = (baseDate: Date, config?: Types.BookingSearchConfigsClientDto) => {
  if (!config) {
    return {
      startDate: baseDate,
      endDate: add(baseDate, { days: 7 }),
    }
  }

  const startDate = add(baseDate, {
    minutes: (config.leadTime?.[0]?.min || config.defaultLeadTime || 0) + (config.temperTest?.[0]?.max || 0),
  })
  const maxSelectableEndDate = add(baseDate, {
    minutes: config?.maxBookingWindow?.[0]?.min || config?.defaultMaxBookingWindow || 0,
  })
  const startDatePlus7Days = add(startDate, { days: 7 })
  return {
    startDate,
    endDate: startDatePlus7Days < maxSelectableEndDate ? startDatePlus7Days : maxSelectableEndDate,
  }
}

export const minimumSelectableDate = (baseDate: Date, config?: Types.BookingSearchConfigsClientDto) => {
  if (!config) {
    return baseDate
  }

  const duration = config.leadTime?.[0]?.max || config.defaultLeadTime || 0
  return add(baseDate, { minutes: duration })
}

export const maximumSelectableDate = (baseDate: Date, state: TBookingContext) => {
  if (!state.config) {
    return baseDate
  }

  const duration =
    state.config.booking?.maxBookingWindow?.[0]?.min || state.config.booking?.defaultMaxBookingWindow || 0
  return add(new Date(), { minutes: duration + 1440 })
}

export const buildClosedDates = (config?: Types.BookingSearchConfigsClientDto): TComparatorCB[] => {
  if (!config) {
    return []
  }

  const comparators = [] as TComparatorCB[]

  config.operationHour
    ?.filter((oh) => !oh.available)
    .forEach((oh) => {
      comparators.push(buildDateRangeComparator(oh.periods))
    })

  config.period
    ?.filter((p) => !p.available)
    .forEach((period) => {
      comparators.push(buildDateRangeComparator(period.periods))
    })

  return comparators
}

export const buildOpenDays = (config?: Types.BookingSearchConfigsClientDto): TComparatorCB[] => {
  if (!config || !config.operationHour) {
    return []
  }

  const comparators = [] as TComparatorCB[]

  config.operationHour
    ?.filter((oh) => oh.available)
    .forEach((oh) => {
      comparators.push(buildDateRangeComparator(oh.periods))
    })

  return comparators
}

const getTemperTestForSpecie = (specieId: string, config?: Types.BookingSearchConfigsClientDto) => {
  return config?.temperTest?.find(
    (t) => (t.restrictions?.findIndex(({ type, text }) => type === 'SPECIES' && text === specieId) ?? 0) >= 0
  )
}

export const requiresTemperTestForSpecie = (
  specieId: string,
  config?: Types.BookingSearchConfigsClientDto
): boolean => {
  if (!config?.temperTest?.length) {
    return false
  }

  return getTemperTestForSpecie(specieId, config) !== undefined
}

// build a lookup table by specieId and if require temper test or not
export const temperTestLookupForSpecie = (state: TBookingContext) => {
  const speciesIds = state.config.species?.map(({ id }) => id!) || []
  return speciesIds.reduce(
    (acc, si) => {
      acc[si] = requiresTemperTestForSpecie(si, state.config.booking)
      return acc
    },
    {} as Record<string, boolean>
  )
}

const checkRestriction = (specieId: string, restrictions?: Types.RestrictionClientDto[]) =>
  (restrictions?.findIndex((r) => r.type === 'SPECIES' && r.text === specieId) ?? -1) >= 0

export const requiresWeightForSpecie = (config?: Types.BookingSearchConfigsClientDto): GenericSimpleBag => {
  if (!config?.species?.length || (!config?.weight?.length && !config?.totalWeight?.length)) {
    return {} as GenericSimpleBag
  }

  const speciesIds = config.species.map(({ text }) => text!) || []

  return speciesIds.reduce((acc, si) => {
    const hasTotalWeight = config.totalWeight!.some(({ restrictions }) => checkRestriction(si, restrictions)) ?? false
    const hasWeight = config.weight!.some(({ restrictions }) => checkRestriction(si, restrictions)) ?? false

    acc[si] = hasWeight || hasTotalWeight
    return acc
  }, {} as GenericSimpleBag)
}

export const isWeightRequiredForSpecie = (ctx: TBookingContext, specieId: string): boolean => {
  const { weight, totalWeight } = ctx.config.booking || {}
  const hasTotalWeight = totalWeight?.some(({ restrictions }) => checkRestriction(specieId, restrictions)) ?? false
  const hasWeight = weight?.some(({ restrictions }) => checkRestriction(specieId, restrictions)) ?? false

  return hasWeight || hasTotalWeight
}

const serviceTypeHomePage = ['boarding', 'daycare']
export const serviceTypeForHomePage = (
  serviceTypes?: Types.LocationServiceTypeClientDto[]
): Types.LocationServiceTypeClientDto | undefined => {
  return serviceTypes?.find((s) => serviceTypeHomePage.includes(s?.serviceType?.name || ''))
}
