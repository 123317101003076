import { Accordion, Typography, AccordionSummary, AccordionDetails, Stack } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { i18WithParams as t } from '@shared/locales'
import { ReceiptDto } from 'service-api/src/types'
import { Fragment } from 'react'

export const DetailsSection = ({ receipt, isPackage }: { receipt: ReceiptDto; isPackage?: boolean }) => {
  const { pets, flexBookingData, contact, vet } = receipt
  return (
    <Accordion elevation={0} disableGutters={true} sx={{ mt: 2, ml: -1, '&:before': { display: 'none' } }}>
      <AccordionSummary
        sx={{ width: 'fit-content' }}
        expandIcon={<ArrowDropDown />}
        aria-controls="booking-content"
        id="booking-header"
      >
        <Typography variant="h6">{t('global.label.details')}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 4 }}>
        {!isPackage && pets?.length && (
          <Stack>
            <Typography variant="h6">{t('global.label.pets')}</Typography>
            {pets.map((pet, index) => (
              <Stack key={index} direction="row" justifyContent="space-between" alignItems="center" mt={1}>
                <Typography variant="body1" flex={1}>
                  {pet.displayName}
                </Typography>
                <Typography variant="body1" flex={1}>
                  {pet.breed || pet.species}
                </Typography>
                <Typography variant="body1" flex={1}>
                  {pet.weight}
                </Typography>
              </Stack>
            ))}
          </Stack>
        )}
        {flexBookingData?.length &&
          flexBookingData.map(({ displayName, description, value, items = [] }, idx) => (
            <Stack key={idx} mt={2}>
              <Typography variant="h6">{displayName}</Typography>
              {(items.length ?? 0) > 0 ? (
                <Fragment>
                  {description && (
                    <>
                      <Typography variant="body2" fontStyle="italic">
                        {description}
                      </Typography>
                      <Typography variant="body1">{value}</Typography>
                    </>
                  )}
                  {items.map((item, index) => (
                    <Fragment key={index}>
                      {item.description && (
                        <>
                          <Typography variant="body2" fontStyle="italic">
                            {item.description}
                          </Typography>
                          <Typography variant="body1">{item.value}</Typography>
                        </>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <Typography variant="body1">{value}</Typography>
              )}
            </Stack>
          ))}
        {contact && (
          <Stack mt={2}>
            <Typography variant="h6">{t('global.label.contact')}</Typography>
            <Stack spacing={0.5}>
              <Typography variant="body1">{contact.name}</Typography>
              {(contact.address || []).map((item, index) => (
                <Typography key={index} variant="body1">
                  {item}
                </Typography>
              ))}
              <Typography variant="body1">{contact.phone}</Typography>
              <Typography variant="body1">{contact.email}</Typography>
            </Stack>
          </Stack>
        )}
        {vet && (
          <Stack mt={2}>
            <Typography variant="h6">{t('global.label.veterinarian')}</Typography>
            <Stack spacing={0.5}>
              <Typography variant="body1">{vet.name}</Typography>
              <Typography variant="body1">{vet.phone}</Typography>
            </Stack>
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
