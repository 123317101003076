import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { setTag, setUser } from '@shared/monitoring'
import { USER_STORE_KEY } from '@providers/session'
import { Vault } from 'shared'
import type { TUserStoreData } from '@providers/session/session.d'

export const useMonitoringData = () => {
  const { accountName: locationName = '' } = useParams()

  // send to Sentry the current location
  useEffect(() => setTag('locationName', locationName), [locationName])

  // sent to Sentry the logged user data
  const user = Vault.getObj<TUserStoreData>(USER_STORE_KEY)
  if (user) {
    setUser(user.user.userId || '', user.user.email)
  }
}
